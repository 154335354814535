import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		Versions: {
			Version: {
				TabButton: {
					version: 'Wersja: ',
					creationDate: 'Data wprowadzenia: ',
				},
			},
		},
	},
	en: {
		Versions: {
			Version: {
				TabButton: {
					version: 'Version: ',
					creationDate: 'Entry Date: ',
				},
			},
		},
	},
};
