import { isToday, isThisWeek, isThisMonth } from 'date-fns';
import React from 'react';

import { displayTimeSpan } from '@sac-tw2/tasker-widgets2/dist/utils';

import { Tooltip, Typography } from '@mui/material';

import { useTrans } from '@/utils';

import { Props } from './model';
import { useStyles } from './styles';
import { TimeColumn } from './TimeColumn';

export const TimeInfo = (props: Props) => {
	const { worklogs } = props;
	const classes = useStyles();
	const { t } = useTrans('IssueModal.IssueModalContent.IssueDetailsPage.RightSideIssueData.ExtraIssueInfo.TimeInfo');

	const calculateTime = (filterFn: (date: Date) => boolean) => {
		const filteredWorklogs = worklogs?.filter(worklog => filterFn(new Date(worklog.DateTimeFrom)));
		return filteredWorklogs?.reduce((total, worklog) => total + (worklog?.Minutes ?? 0), 0);
	};

	const formatTime = (minutes: number) => {
		if (minutes === 0) return '-';
		return displayTimeSpan(minutes);
	};

	return (
		<div>
			<Tooltip
				title={
					<div className={classes.container}>
						<TimeColumn title={t('today')} time={formatTime(calculateTime(isToday))} />
						<TimeColumn title={t('thisWeek')} time={formatTime(calculateTime(isThisWeek))} />
						<TimeColumn title={t('thisMonth')} time={formatTime(calculateTime(isThisMonth))} />
					</div>
				}
			>
				<Typography>{displayTimeSpan(worklogs?.reduce((a, b) => a + (b?.Minutes ?? 0), 0) ?? 0)}</Typography>
			</Tooltip>
		</div>
	);
};
