import { format } from 'date-fns';

import { AsyncAction } from '@sac-rp/redux-promisify';
import { EFormats } from '@sac-tw2/tasker-widgets2/dist/utils';
import { Reckon } from '@sac-tw2/tasker-widgets2/dist/utils/types/reckon';

import { IJson } from '@/store/ITypes';
import axios from '@/utils/axios';

export const RECKON_FETCH_BY_PROFILE = 'RECKON_FETCH_BY_PROFILE';
export const RECKON_FETCH_BY_PROJECT = 'RECKON_FETCH_BY_PROJECT';
export const RECKON_FETCH_BY_ISSUE = 'RECKON_FETCH_BY_ISSUE';

function _fetchReckonByProfile(reckon: Reckon[]) {
	return {
		type: RECKON_FETCH_BY_PROFILE,
		reckon,
	};
}

function _fetchReckonByProject(reckon: Reckon[]) {
	return {
		type: RECKON_FETCH_BY_PROJECT,
		reckon,
	};
}

function _fetchReckonByIssue(reckon: Reckon) {
	return {
		type: RECKON_FETCH_BY_ISSUE,
		reckon,
	};
}

export function reckon_fetchByProfile(profileId: number): AsyncAction {
	return function(context, dispatch) {
		context.chain(json => (json as IJson).data);
		context.then(data => dispatch(_fetchReckonByProfile(data as Reckon[])));
		return axios().get(`/profile/${profileId}/reckon`);
	};
}

export function reckon_addByProfile(profileId: number, reckon: Partial<Reckon>): AsyncAction {
	return function(context) {
		context.chain(() => reckon_fetchByProfile(profileId));
		return axios().post(`/profile/${profileId}/reckon`, reckonFormatDate(reckon));
	};
}

export function reckon_setByProfile(profileId: number, reckon: Reckon[]): AsyncAction {
	return function(context) {
		context.chain(() => reckon_fetchByProfile(profileId));
		return axios().put(`/profile/${profileId}/reckon`, reckonsFormatDate(reckon));
	};
}

export function reckon_fetchByIssue(issueId: number): AsyncAction {
	return function(context, dispatch) {
		context.chain(json => (json as IJson).data);
		context.then(data => dispatch(_fetchReckonByIssue(data as Reckon)));
		return axios().get(`/issues/${issueId}/reckon`);
	};
}

export function reckon_setByIssue(issueId: number, reckon: any): AsyncAction {
	if (reckon?.id) delete reckon?.id;
	return function(context) {
		context.chain(() => reckon_fetchByIssue(issueId));
		return axios().put(`/issues/${issueId}/reckon`, reckonFormatDate(reckon));
	};
}

export function reckon_fetchByProject(projectId: number): AsyncAction {
	return function(context, dispatch) {
		context.chain(json => (json as IJson).data);
		context.then(data => dispatch(_fetchReckonByProject(data as Reckon[])));
		return axios().get(`/projects/${projectId}/reckon`);
	};
}

export function reckon_setByProject(projectId: number, reckon: Reckon[]): AsyncAction {
	return function(context) {
		context.chain(() => reckon_fetchByProject(projectId));
		return axios().put(`/projects/${projectId}/reckon`, reckonsFormatDate(reckon));
	};
}

const reckonsFormatDate = (reckons: Partial<Reckon>[]) => {
	return reckons?.map(reckonFormatDate);
};

const reckonFormatDate = (reckon: Partial<Reckon>) => {
	return {
		...reckon,
		startDate: format(new Date(reckon?.startDate as string), EFormats.EDateFNS.ReckonDateFormat),
		endDate: format(new Date(reckon?.endDate as string), EFormats.EDateFNS.ReckonDateFormat),
	};
};
