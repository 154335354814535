import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};
export const Translation = {
	pl: {
		Kanban: {
			KanbanBoard: {
				KanbanHeader: {
					KanbanPicker: { search: 'Szukaj', chooseKanban: 'Wybierz kanban' },
				},
			},
		},
	},
	en: {
		Kanban: {
			KanbanBoard: {
				KanbanHeader: {
					KanbanPicker: { search: 'Search', chooseKanban: 'Choose kanban' },
				},
			},
		},
	},
};
