import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	links: {
		login: '/auth/login',
		empty: '/',
		issuePage: '#/issues',
		settings: '/settings',
	},
	logoAlternative: 'Logo',
	menuIconClassNameAdditional: 'MuiToolbar-gutters',
	wrap: 'wrap',
	noWrap: 'no-wrap',
	backendPath: `http://${process?.env?.REACT_APP_BACKEND_ADDRESS}/`,
	websocket: 'websocket',
	favIconId: 'favicon',
	favIconBasePath: '/favIconNotReadNotifications-',
	png: '.png',
	moreThan9: '9+',
	basicFavIcon: '/favicon2',
	unset: 'unset' as const,
	activityPath: '/activity',
	versionHref: '#/version',
	disabled: 'disabled' as const,
	...GlobalResources,
};

export const Translation = {
	pl: {
		Dashboard: {
			TopBar: {
				GeneralToolTip: {
					notifications: 'Powiadomienia',
					profile: 'Pokaż profil',
				},
				signOut: 'Wyloguj się',
				worklogTooltip: {
					starterName: 'Zarządzanie czasem',
					action: {
						close: 'Zamknij',
						open: 'Otwórz',
					},
				},
				finalForm: 'Formularz zakończenia pracy',
				version: 'Wersja',
			},
		},
	},
	en: {
		Dashboard: {
			TopBar: {
				GeneralToolTip: {
					notifications: 'Notifications',
					profile: 'View profile',
				},
				signOut: 'Sign out',
				worklogTooltip: {
					starterName: 'Worklog manager',
					action: {
						close: 'Close',
						open: 'Open',
					},
				},
				finalForm: 'Formularz zakończenia pracy',
				version: 'Version',
			},
		},
	},
};
