import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		Settings: {
			WidgetsConfigurationMain: {
				ProfileSelectorConfiguration: {
					NewTeamButton: {
						createNewTeam: 'Stwórz nową listę osób',
					},
				},
			},
		},
	},

	en: {
		Settings: {
			WidgetsConfigurationMain: {
				ProfileSelectorConfiguration: {
					NewTeamButton: {
						createNewTeam: 'Create new team',
					},
				},
			},
		},
	},
};
