import { AsyncAction } from '@sac-rp/redux-promisify';
import { IDelegationLimits } from '@sac-tt/tasker-types/dist/types/dataBaseTypes/delegationLimits';

import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

import { IJson } from '../ITypes';

export const DELEGATION_LIMITS_FETCH: ActionType = 'DELEGATION_LIMITS_FETCH';

const basePath = 'DelegationLimits';

function _fetchDelegationLimits(data: IDelegationLimits[]): Action {
	return {
		type: DELEGATION_LIMITS_FETCH,
		data,
	};
}

export function delegation_fetchLimits(): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchDelegationLimits((json as IJson).data)));
		return axios().get(`${basePath}/all`);
	};
}
