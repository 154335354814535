import uuid from 'uuid';

import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	visibilityConfigurationKey: (key: string) => `DelegationVisibilityConfiguration_${uuid()}_${key}`,
};

export const Translation = {
	pl: {
		OrganizationPanel: {
			TabsContent: {
				Delegation: {
					TabsContent: {
						VisibilityConfiguration: {
							perDiem: {
								title: 'Diety',
								description:
									'Zaznaczenie elementu spowoduje wyłączenie lub włączenie widoczności elementu diety dla użytkowników',
								disabledExplanation:
									'Przełącznik jest wyłączony, dopóki dany element diety nie będzie miał przypisanej wartości domyślnej.',
								1: 'Śniadanie',
								2: 'Obiad',
								3: 'Kolacja',
								4: 'Pobyt w szpitalu',
								5: 'Zakwaterowanie',
								6: 'Transport publiczny',
							},
						},
					},
				},
			},
		},
	},
	en: {
		OrganizationPanel: {
			TabsContent: {
				Delegation: {
					TabsContent: {
						VisibilityConfiguration: {
							perDiem: {
								title: 'Per diem',
								description:
									'Checking the checkbox will disable or enable visibility of the per diem element for users',
								disabledExplanation:
									'The switch is disabled until a given per diem element has a default value assigned.',
								1: 'Breakfast',
								2: 'Dinner',
								3: 'Supper',
								4: 'Hospital stay',
								5: 'Accommodation',
								6: 'Public transport',
							},
						},
					},
				},
			},
		},
	},
};
