import { AsyncAction } from '@sac-rp/redux-promisify';
import { IProjectIssueType } from '@sac-tt/tasker-types';

import { IJson } from '@/store/ITypes';
import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

export const GET_PROJECT_ISSUE_TYPE_OPTIONS: ActionType = 'GET_PROJECT_ISSUE_TYPE_OPTIONS';
export const GET_PROJECT_ISSUE_TYPE_BY_PROJECT: ActionType = 'GET_PROJECT_ISSUE_TYPE_BY_PROJECT';

const basePath = 'ProjectIssueType';
const basePathIssueModal = 'IssueModal';

function _getOptions(projectIssueTypes: IProjectIssueType[]): Action {
	return {
		type: GET_PROJECT_ISSUE_TYPE_OPTIONS,
		data: projectIssueTypes,
	};
}

function _getProjectIssueTypeByProject(data: { ProjectIssueType: IProjectIssueType[]; IsPortalUser: boolean }): Action {
	return {
		type: GET_PROJECT_ISSUE_TYPE_BY_PROJECT,
		data: data,
	};
}

export function get_projectIssueTypeOptions(): AsyncAction {
	return function(context, dispatch) {
		context.then(response => dispatch(_getOptions((response as IJson)?.data)));
		return axios().get(basePath);
	};
}

export function get_projectIssueTypeByProject(projectId: number): AsyncAction {
	return function(context, dispatch) {
		context.then(response => dispatch(_getProjectIssueTypeByProject((response as IJson)?.data)));
		return axios().get(`${basePathIssueModal}/${basePath}/${projectId}`);
	};
}
