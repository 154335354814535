import clsx from 'clsx';
import { Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { renderRoutes, RouteConfigComponentProps } from 'react-router-config';

import { EKeys } from '@sac-tt/tasker-types/dist/types/common/keys';

import { LinearProgress } from '@mui/material';

import { work_log_fetchActive_NEW } from '@/store/actions';
import { IReducerState } from '@/store/reducers/types';
import { Dispatch } from '@/store/types';
import { NavBarWidth, NavBarWidthOnlyIcon } from '@/utils/configuration';

import { IssueModal } from 'view/IssueModal';

import { StyleProps } from './model';
import { NavBar } from './NavBar';
import { Resources } from './resources';
import { useStyles } from './styles';
import { TopBar } from './TopBar';

export const Dashboard = ({ route }: RouteConfigComponentProps): JSX.Element => {
	const history = useHistory();
	const dispatch: Dispatch = useDispatch();
	const [openNavBarMobile, setOpenNavBarMobile] = useState<boolean>(false);

	const navBarState = useSelector((state: IReducerState) => state.Common.sideBarOpen);
	const key: EKeys = useSelector((state: IReducerState) => state?.Key?.key);
	const actionNumber: number = useSelector((state: IReducerState) => state?.Key?.actionNumber);

	useEffect(() => {
		dispatch(work_log_fetchActive_NEW());
		document.documentElement.classList.add(classes.background);
	}, []);

	useEffect(() => {
		if (key === EKeys.I) history.push(Resources.issuePath);
	}, [actionNumber]);

	const styleProps: StyleProps = {
		paddingLeft: navBarState ? NavBarWidthOnlyIcon : NavBarWidth,
	};

	const classes = useStyles(styleProps);

	return (
		<>
			<IssueModal />
			<TopBar onOpenNavBarMobile={() => setOpenNavBarMobile(true)} />
			<NavBar onMobileClose={() => setOpenNavBarMobile(false)} openMobile={openNavBarMobile} />
			<div className={clsx(classes.minvhOfHeight, classes.flexRow, classes.container, classes.background)}>
				<div className={clsx(classes.flexGrow, classes.content)}>
					<Suspense fallback={<LinearProgress />}>{renderRoutes(route?.routes)}</Suspense>
				</div>
			</div>
		</>
	);
};

export default Dashboard;
