import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	ariaValue: 'primary checkbox' as const,
	hashIssue: '#',
	https: 'https://',
	http: 'http://',
};

export const Translation = {
	pl: {
		CustomerDetail: {
			Content: {
				Invoices: {
					HeaderInvoice: {
						InvoiceModal: {
							InvoiceModalTitle: {
								newInvoice: 'Nowa faktura',
								editInvoice: 'Edycja faktury',
							},
						},
					},
				},
			},
		},
	},
	en: {
		CustomerDetail: {
			Content: {
				Invoices: {
					HeaderInvoice: {
						InvoiceModal: {
							InvoiceModalTitle: {
								newInvoice: 'New invoice',
								editInvoice: 'Edit invoice',
							},
						},
					},
				},
			},
		},
	},
};
