import { Permission } from '@sac-tt/tasker-types';

import {
	AccountBoxTwoTone,
	AssignmentOutlined,
	Bookmarks,
	DashboardTwoTone,
	DeveloperBoardTwoTone,
	Dvr,
	Event,
	EventNote,
	HourglassFullTwoTone,
	LabelTwoTone,
	LocalActivity,
	LocalAtmTwoTone,
	LocalOfferTwoTone,
	MenuBookTwoTone,
	PersonTwoTone,
	SettingsSystemDaydreamTwoTone,
	ViewCarouselTwoTone,
} from '@mui/icons-material';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import CardTravelIcon from '@mui/icons-material/CardTravel';

import { Resources } from '@/layouts/Dashboard/NavConfig/resources';

import { NavBarSectionConfig } from '../model';

export type NavBarConfig = NavBarSectionConfig[];

const createNavConfig = (): NavBarConfig => {
	const list: NavBarConfig = [];
	list.push({
		name: Resources.Section.dashboards,
		items: [
			{
				title: Resources.Activity.title,
				href: Resources.Activity.href,
				icon: LocalActivity,
				permissions: [Permission.Activity],
			},
			{
				title: Resources.Issues.title,
				href: Resources.Issues.href,
				icon: DeveloperBoardTwoTone,
				permissions: [Permission.Issues],
			},
			{
				title: Resources.Portal.title,
				href: Resources.Portal.href,
				icon: Dvr,
				permissions: [Permission.Portal],
			},
			{
				title: Resources.Kanban.title,
				href: Resources.Kanban.href,
				icon: ViewCarouselTwoTone,
				permissions: [Permission.KanbanBoard],
			},
			{
				title: Resources.Projects.title,
				href: Resources.Projects.href,
				icon: DashboardTwoTone,
				permissions: [Permission.Projects, Permission.GetProject],
			},

			{
				title: Resources.Customers.title,
				href: Resources.Customers.href,
				icon: AccountBoxTwoTone,
				permissions: [Permission.Customers],
			},
		],
	});
	list.push({
		name: Resources.Section.time,
		items: [
			{
				title: Resources.LeaveRequestCalendar.title,
				href: Resources.LeaveRequestCalendar.href,
				icon: Event,
				permissions: [Permission.ReportTime],
			},
			{
				title: Resources.Time.title,
				href: Resources.Time.href,
				icon: HourglassFullTwoTone,
				permissions: [Permission.ReportTime],
			},
			{
				title: Resources.TimeCalendar.title,
				href: Resources.TimeCalendar.href,
				icon: Event,
				permissions: [Permission.ReportTime],
			},
		],
	});
	list.push({
		name: Resources.Section.reports,
		items: [
			{
				title: Resources.GantCalendar.title,
				href: Resources.GantCalendar.href,
				icon: AssignmentOutlined,
				permissions: [Permission.Calendar],
			},
			{
				title: Resources.Reports.title,
				href: Resources.Reports.href,
				icon: AnalyticsIcon,
				permissions: [Permission.ReportReckon],
			},
			{
				title: Resources.WorkdaySummary.title,
				href: Resources.WorkdaySummary.href,
				icon: EventNote,
				permissions: [Permission.WorkDayEndSummary],
			},
		],
	});
	list.push({
		name: Resources.Section.config,
		items: [
			{
				title: Resources.Budget.title,
				href: Resources.Budget.href,
				icon: AccountBalanceIcon,
				permissions: [Permission.GetBudget],
			},
			{
				title: Resources.LeaveType.title,
				href: Resources.LeaveType.href,
				icon: AccessibilityNewIcon,
				permissions: [Permission.LeaveTypeSite],
			},
			{
				title: Resources.Holidays.title,
				href: Resources.Holidays.href,
				icon: Bookmarks,
				permissions: [Permission.HolidaySite],
			},
			{
				title: Resources.Dictionaries.title,
				href: Resources.Dictionaries.href,
				icon: MenuBookTwoTone,
				permissions: [Permission.Setting],
			},
			{
				title: Resources.Tag.title,
				href: Resources.Tag.href,
				icon: LabelTwoTone,
				permissions: [Permission.AdminTag],
			},
		],
	});
	list.push({
		name: Resources.Section.admin,
		items: [
			{
				title: Resources.Agreement.title,
				href: Resources.Agreement.href,
				icon: LocalAtmTwoTone,
				permissions: [Permission.ContractsSite],
			},
			{
				title: Resources.Organization.title,
				href: Resources.Organization.href,
				icon: SettingsSystemDaydreamTwoTone,
				permissions: [Permission.AdminTenant],
			},
			{
				title: Resources.RoleConfiguration.title,
				href: Resources.RoleConfiguration.href,
				icon: LocalOfferTwoTone,
				permissions: [Permission.AdminPermissions],
			},
		],
	}); //Administration
	list.push({
		name: Resources.Section.user,
		items: [
			{
				title: Resources.Settings.title,
				href: Resources.Settings.href,
				icon: PersonTwoTone,
				permissions: [Permission.Settings],
			},
			{
				title: Resources.Delegations.title,
				href: Resources.Delegations.href,
				icon: CardTravelIcon,
				permissions: [Permission.Settings],
			},
			{
				title: Resources.DelegationsAcceptance.title,
				href: Resources.DelegationsAcceptance.href,
				icon: CardTravelIcon,
				permissions: [Permission.DelegationAcceptance],
			},
		],
	}); // User
	return list;
};
export const NavSections: NavBarConfig = createNavConfig();
