import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	temporary: 'temporary' as const,
};

export const Translation = {
	pl: {
		PortalList: {
			Filters: {
				Options: {
					Status: {
						project: 'Projekt',
						status: 'Pole statusowe',
						form: 'Formularz',
						fieldDefinition: 'Pole statusowe',
						tag: 'Tag',
					},
				},
			},
		},
	},
	en: {
		PortalList: {
			Filters: {
				Options: {
					Status: {
						project: 'Project',
						status: 'Status Field',
						form: 'Form',
						fieldDefinition: 'Status Field',
						tag: 'Tag',
					},
				},
			},
		},
	},
};
