import { IOrganizationStructure, IPosition } from '@sac-tt/tasker-types';
import { ICategoryPosition } from '@sac-tt/tasker-types/dist/types';
import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	string: 'string' as const,
	categoryPositionKey: 'categoryPosition',
	positionKey: 'position',
	structureKey: 'structure',
	...GlobalResources,
};

export const Translation = {
	pl: {
		OrganizationPanel: {
			TabsContent: {
				OrganizationStructure: {
					Details: {
						structureGroup: 'Grupa',
						structure: 'Struktura',
						position: 'Pozycja',
						positionName: 'Nazwa pozycji',
						categoryName: 'Nazwa kategorii',
						pageTitle: {
							loading: 'Ładowanie',
							title: 'Struktura organizacyjna',
						},
						blankStructure: {
							name: 'Nowa struktura',
						},
						cantDeleteParent: 'Nie możesz usunąć nadrzędnej struktury organizacji',
						shortPositionName: 'Skrócona nazwa pozycji',
						user: 'Użytkownik',
						role: 'Rola',
						positionCategory: 'Kategoria pozycji',
						creationDate: 'Data stworzenia',
						cratedBy: 'Stworzone przez',
						creation: 'Tworzenie',
						edition: 'Edycja',
						new: 'Nowy',
						description: 'Opis',
						categoryMessages: {
							deleteTitle: 'Czy na pewno?',
							deleteSubtitle:
								'Usunięcie tego elementu może spowodować nieprawidłowe działanie innych funkcjonalności',
						},
						actionButtons: {
							delete: 'Usuń',
							edit: 'Edytuj',
							save: 'Zapisz',
						},
					},
				},
			},
		},
	},
	en: {
		OrganizationPanel: {
			TabsContent: {
				OrganizationStructure: {
					Details: {
						structureGroup: 'Group',
						structure: 'Structure',
						position: 'Position',
						positionName: 'Position name',
						categoryName: 'Category name',
						pageTitle: {
							loading: 'Loading',
							title: 'Organization structure',
						},
						blankStructure: {
							name: 'New structure',
						},
						cantDeleteParent: 'Parent organization structure cannot be deleted',
						unitName: 'Unit name',
						shortUnitName: 'Short unit name',
						user: 'User',
						role: 'Role',
						positionCategory: 'Position category',
						creationDate: 'Creation date',
						cratedBy: 'Created by',
						creation: 'Creation',
						edition: 'Edition',
						new: 'New',
						description: 'Description',
						categoryMessages: {
							deleteTitle: 'Are you sure you want to delete this category?',
							deleteSubtitle: 'Deleting this category may cause other functionality to not work properly',
						},
						actionButtons: {
							delete: 'Delete',
							edit: 'Edit',
							save: 'Save',
						},
					},
				},
			},
		},
	},
};

export const blankStructure: Partial<IOrganizationStructure> = {
	Id: 0,
	UnitName: '',
	ShortUnitName: '',
};

export const blankPosition: Partial<IPosition> = {
	Id: 0,
	Name: '',
	IsActive: true,
};

export const blankCategory: Partial<ICategoryPosition> = {
	Id: 0,
	Name: '',
	Description: '',
};

export const structureValidationRules = {
	UnitName: {
		length: {
			minimum: 1,
			maximum: 50,
			tooShort: '^needs to have %{count} words or more',
			tooLong: '^Name of the structure is too long (max 50)',
		},
	},
	ShortUnitName: {
		length: {
			minimum: 1,
			maximum: 12,
			tooShort: '^needs to have %{count} words or more',
			tooLong: '^Name of the structure is too long (max 12)',
		},
	},
};

export const positionValidationRules = {
	Name: {
		length: {
			minimum: 1,
			maximum: 50,
			tooShort: `^can't be empty`,
			tooLong: '^is too long (max 50)',
		},
	},
};

export const categoryPositionValidationRules = {
	Name: {
		length: {
			minimum: 1,
			maximum: 50,
			tooShort: `^can't be empty`,
			tooLong: '^is too long (max 50)',
		},
	},
	Description: {
		length: {
			minimum: 1,
			maximum: 2000,
			tooShort: `^can't be empty`,
			tooLong: '^is too long (max 2000)',
		},
	},
};
