import { AsyncAction } from '@sac-rp/redux-promisify';
import { DeepPartial, IDelegationRoleToTaskerUser, ITaskerUser } from '@sac-tt/tasker-types';
import { IDelegationRoleTaskerUserManage } from '@sac-tt/tasker-types/dist/types/endPoints/delegationRoleTaskerUser';

import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

import { IJson } from '../ITypes';

export const DELEGATION_USERS_ROLES_FETCH: ActionType = 'DELEGATION_USERS_ROLES_FETCH';
export const DELEGATION_USERS_ROLES_MANAGE: ActionType = 'DELEGATION_USERS_ROLES_MANAGE';
export const DELEGATION_USERS_ROLES_DELETE: ActionType = 'DELEGATION_USERS_ROLES_DELETE';

const basePath = 'DelegationRoleToTaskerUser';

function _fetchDelegationUsersRoles(data: {
	delegationRoleToTaskerUser: DeepPartial<ITaskerUser>[];
	users: Partial<ITaskerUser>[];
}): Action {
	return {
		type: DELEGATION_USERS_ROLES_FETCH,
		data,
	};
}

function _manageDelegationUsersRoles(data: {
	delegationRoleToTaskerUser: DeepPartial<IDelegationRoleToTaskerUser>[];
	users: Partial<ITaskerUser>[];
}): Action {
	return {
		type: DELEGATION_USERS_ROLES_MANAGE,
		data,
	};
}

function _deleteDelegationUsersRoles(data: {
	delegationRoleToTaskerUser: DeepPartial<IDelegationRoleToTaskerUser>[];
	users: Partial<ITaskerUser>[];
}): Action {
	return {
		type: DELEGATION_USERS_ROLES_DELETE,
		data,
	};
}

export function delegation_fetchUsersRoles(): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchDelegationUsersRoles((json as IJson).data)));
		return axios().get(`${basePath}/data`);
	};
}

export function delegation_manageUsersRoles(data: DeepPartial<IDelegationRoleTaskerUserManage>[]): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_manageDelegationUsersRoles((json as IJson).data)));
		return axios().post(`${basePath}/manage`, data);
	};
}

export function delegation_deleteUsersRoles(data: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_deleteDelegationUsersRoles((json as IJson).data)));
		return axios().delete(`${basePath}/byUser/${data}`);
	};
}
