import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		DelegationList: {
			DelegationModal: {
				DelegationModalActions: {
					ListActions: {
						cancel: 'Anuluj',
						save: 'Zapisz',
						sendToAcceptance: 'Sprawdź i wyślij do akceptacji',
					},
				},
			},
		},
	},
	en: {
		DelegationList: {
			DelegationModal: {
				DelegationModalActions: {
					ListActions: {
						cancel: 'Cancel',
						save: 'Save',
						sendToAcceptance: 'Validate and send to acceptance',
					},
				},
			},
		},
	},
};
