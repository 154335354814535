import { getGlobalStyles } from '@sac-tw2/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { StyleProps } from './model';

export const useStyles = makeStyles<Theme, StyleProps>(
	(theme: Theme) => ({
		...getGlobalStyles(),
		root: {
			width: props => props.maxWidth,
		},
		navigation: {
			overflowY: 'auto',
			padding: theme.spacing(0, 2, 0, 2),
		},
		navbarLine: {
			display: 'block',
			paddingTop: theme.spacing(2),
			borderBottom: '1px solid #e0e0e0',
		},
	}),
	{ name: `Dashboard_NavBar_DrawerContentResized` }
);
