import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};
export const Translation = {
	pl: {
		Kanban: {
			KanbanBoard: {
				KanbanCreator: {
					Actions: {
						save: 'Zapisz',
						cancel: 'Anuluj',
						reset: 'Resetuj kreator',
					},
				},
			},
		},
	},
	en: {
		Kanban: {
			KanbanBoard: {
				KanbanCreator: {
					Actions: {
						save: 'Save',
						cancel: 'Cancel',
						reset: 'Reset creator',
					},
				},
			},
		},
	},
};
