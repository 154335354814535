import { getGlobalStyles } from '@sac-tw2/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';
import { CustomTheme } from '@sac-tw2/tasker-widgets2/dist/utils/theme';

import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(
	(theme: CustomTheme) => {
		return {
			...getGlobalStyles(),
			container: {
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'flex-start',
				padding: theme.spacing(2),
				borderRadius: theme.shape.borderRadius,
				justifyContent: 'space-between',
				width: '100%',
				marginTop: theme.spacing(2),
			},
		};
	},
	{ name: `IssueModal_IssueModalContent_IssueDetailsPage_RightSideIssueData_ExtraIssueInfo_TimeInfo` }
);
