import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		DelegationsList: {
			DelegationListFilters: {
				all: 'Wszystkie',
				preparationAndCorrection: 'Przed akceptacją',
				toAccept: 'Do akceptacji',
				done: 'Zakończone',
			},
		},
	},
	en: {
		DelegationsList: {
			DelegationListFilters: {
				all: 'All',
				preparationAndCorrection: 'Before accept',
				toAccept: 'To accept',
				done: 'Done',
			},
		},
	},
};
