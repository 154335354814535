import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	rounded: 'rounded' as const,
	dash: '-' as const,
};

export const Translation = {
	pl: {
		Reckon: {
			Agreements: {
				AgreementDetails: {
					ContractedPersonDetailsGroup: {
						contractedPerson: 'Osoba zakontraktowana',
						phoneNumber: 'Telefon komórkowy',
						email: 'Email',
					},
				},
			},
		},
	},
	en: {
		Reckon: {
			Agreements: {
				AgreementDetails: {
					ContractedPersonDetailsGroup: {
						contractedPerson: 'Contracted person',
						phoneNumber: 'Phone number',
						email: 'Email',
					},
				},
			},
		},
	},
};
