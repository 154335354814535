import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	menuId: 'long-menu',
	arialLabelledby: 'aria-labelledby',
	longButton: 'long-button',
};

export const Translation = {
	pl: {
		Settings: {
			WidgetsConfigurationMain: {
				ProfileSelectorConfiguration: {
					Team: {
						TeamOptionsButton: {
							edit: 'Edytuj',
							deleteFromList: 'Usuń z mojej listy',
							deleteTeam: 'Usuń listę',
						},
					},
				},
			},
		},
	},

	en: {
		Settings: {
			WidgetsConfigurationMain: {
				ProfileSelectorConfiguration: {
					Team: {
						TeamOptionsButton: {
							edit: 'Edit',
							deleteFromList: 'Delete from my list',
							deleteTeam: 'Delete team',
						},
					},
				},
			},
		},
	},
};
