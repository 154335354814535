import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		Reckon: {
			Agreements: {
				AgreementEdit: {
					Hooks: {
						UseSalariesForm: {
							salaryDeleted: 'Wynagrodzenie usunięt',
							errorOccured: 'Wystąpił błąd',
							changesSaved: 'Zapisano zmiany w wynagrodzeniu',
							salaryCreated: 'Utworzono wynagrodzenie',
						},
					},
				},
			},
		},
	},
	en: {
		Reckon: {
			Agreements: {
				AgreementEdit: {
					Hooks: {
						UseSalariesForm: {
							salaryDeleted: 'Salary has been deleted',
							errorOccured: 'Wystąpił błąd',
							changesSaved: 'Changes has been saved',
							salaryCreated: 'Salary has been created',
						},
					},
				},
			},
		},
	},
};

export const valuesToResetOnSalaryTypeChangeToFixed = {
	Salary100Percent: undefined,
	EffectiveSalary: undefined,
	PeriodId: undefined,
	SalaryPerHour: undefined,
	WeeklyHours: undefined,
	StartDate: undefined,
	EndDate: undefined,
	DescriptionHtml: undefined,
};

export const valuesToResetOnSalaryTypeChangeToHourly = {
	Salary100Percent: undefined,
	StartDate: undefined,
	EndDate: undefined,
	DescriptionHtml: undefined,
};
