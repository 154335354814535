import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		OrganizationPanel: {
			GeneralTab: {
				InvoiceDetails: {
					title: 'Dane adresowe:',
					cityName: 'Nazwa miasta:',
					zipCode: 'Kod pocztowy:',
					nip: 'NIP:',
					email: 'Email:',
					phoneNumber: 'Numer telefonu:',
					bankAccount: 'Numer konta bankowego:',
					logoUrl: 'Url do loga:',
				},
			},
		},
	},
	en: {
		OrganizationPanel: {
			GeneralTab: {
				InvoiceDetails: {
					title: 'Address data:',
					cityName: 'City name:',
					zipCode: 'Zip code:',
					nip: 'VAT code:',
					email: 'Email:',
					phoneNumber: 'Phone number:',
					bankAccount: 'Bank account number:',
					logoUrl: 'Logo url:',
				},
			},
		},
	},
};
