import clsx from 'clsx';
import { format } from 'date-fns';
import { useContext } from 'react';
import { useSelector } from 'react-redux';

import { ITaskerUser, IWorkLog } from '@sac-tt/tasker-types';
import { CustomSelect, InputLabelNew, VisibilityControl } from '@sac-tw2/tasker-widgets2';
import { EDateFNS } from '@sac-tw2/tasker-widgets2/dist/utils';

import { Typography } from '@mui/material';

import { IReducerState } from '@/store/reducers/types';
import { useTrans } from '@/utils/hooks/useTrans';
import { getFullNameNew } from '@/utils/user';
import { ProfileSelector } from '@/views/SearchBar/ProfileSelector';

import { issueModalDataContext } from 'view/IssueModal';
import { TimeInfo } from 'view/IssueModal/IssueModalContent/IssueDetailsPage/RightSideIssueData/ExtraIssueInfo/TimeInfo';

import { EIssueModalDetailsPageFields, IIssueModalData } from '../../model';
import { Option } from '../../Option';
import { ValueContainer } from '../../ValueContainer';

import { AssignMe } from './AssignMe';
import { MultiSelectValueLabelCustom } from './MultiSelectValueLabelCustom';
import { Resources } from './resources';
import { useStyles } from './styles';
import { TotalTimeSpentOnIssue } from './TotalTimeSpentOnIssue';

export const ExtraIssueInfo = () => {
	const classes = useStyles();
	const { t } = useTrans('IssueModal.IssueModalContent.IssueDetailsPage.RightSideIssueData.ExtraIssueInfo');

	const issue = useSelector((state: IReducerState) => state?.IssueModalEditor?.issueModal);

	const taskerUsers = useSelector((state: IReducerState) => state?.IssueModalEditor?.profiles);

	const taskerUser: Partial<ITaskerUser> = useSelector((state: IReducerState) => state?.Session?.taskerUser);
	const workLogs: IWorkLog[] = useSelector((state: IReducerState) => state?.IssueModalEditor?.issueUserWorklogNew);
	const isDarkMode: boolean = useSelector((state: IReducerState) => state?.Session?.darkMode);

	const {
		issueState,
		inputsInEdit,
		handleEditStateChange,
		handleInputChange,
		isEdittingIssue,
		handleReset,
	} = useContext(issueModalDataContext);

	const getCreator = () => {
		if (!isEdittingIssue) return taskerUsers.filter(user => user?.Id === taskerUser?.Id);

		return [issue?.CreatedByUser];
	};

	const handleAssignPerson = (field: EIssueModalDetailsPageFields) => {
		if (!issueState) return;

		const users = (issueState[field as keyof IIssueModalData] ?? []) as Partial<ITaskerUser>[];

		if (users?.map(user => user?.Id).includes(taskerUser?.Id as number)) {
			handleInputChange(
				field,
				users?.filter(user => user?.Id !== taskerUser?.Id)
			);
		} else {
			handleInputChange(field, [...users, taskerUser]);
		}
	};

	return (
		<>
			<div className={classes.flexYCenter}>
				<CustomSelect
					isDarkMode={isDarkMode}
					inEdit={!!inputsInEdit[EIssueModalDetailsPageFields.assigned]}
					isMulti
					canSetInputInNonEdit={isEdittingIssue}
					label={t('assigned')}
					items={taskerUsers}
					values={issueState?.Assigned ?? []}
					className={clsx(classes.avatarSelect, classes.flexGrow)}
					args={{
						['components']: {
							ValueContainer,
							MultiValueLabel: MultiSelectValueLabelCustom,
							Option: Option,
						},
						closeMenuOnSelect: false,
						menuPosition: Resources.fixed,
						valuesLimit: 1,
					}}
					onChange={newUsers => handleInputChange(EIssueModalDetailsPageFields.assigned, newUsers)}
					getName={taskerUser => getFullNameNew(taskerUser)}
					getKey={taskerUser => taskerUser?.Id ?? -1}
					handleInEditStateChange={() => handleEditStateChange(EIssueModalDetailsPageFields.assigned, true)}
					handleReset={() => handleReset(EIssueModalDetailsPageFields.assigned)}
					nonEditComponent={
						<VisibilityControl condition={!!issue?.Members?.Assigned?.length} alternative="-">
							<div className={classes.profileSelectorWrapper}>
								<ProfileSelector
									limit={10}
									getAvatar={profile => profile?.AvatarPath ?? ''}
									getKey={profile => profile.Id}
									items={(issue?.Members?.Assigned as Partial<ITaskerUser>[]) ?? []}
									getTooltip={profile => getFullNameNew(profile)}
									className={classes.profileSelector}
								/>
							</div>
						</VisibilityControl>
					}
				/>
				<VisibilityControl condition={!!inputsInEdit[EIssueModalDetailsPageFields.assigned]}>
					<AssignMe
						isCurrentTaskerUserSelected={
							!!issueState?.Assigned?.map(user => user?.Id)?.includes(taskerUser?.Id)
						}
						handleAssignPerson={handleAssignPerson}
						field={EIssueModalDetailsPageFields?.assigned}
					/>
				</VisibilityControl>
			</div>
			<div className={clsx(classes.flexYCenter)}>
				<CustomSelect
					isMulti
					isDarkMode={isDarkMode}
					canSetInputInNonEdit={isEdittingIssue}
					inEdit={!!inputsInEdit[EIssueModalDetailsPageFields.observer]}
					label={t('observer')}
					items={taskerUsers}
					values={issueState?.Observer ?? []}
					className={clsx(classes.avatarSelect, classes.flexGrow)}
					args={{
						['components']: {
							ValueContainer,
							MultiValueLabel: MultiSelectValueLabelCustom,
							Option: Option,
						},
						closeMenuOnSelect: false,
						menuPosition: Resources.fixed,
						valuesLimit: 1,
					}}
					onChange={newUsers => handleInputChange(EIssueModalDetailsPageFields.observer, newUsers)}
					getName={taskerUser => getFullNameNew(taskerUser)}
					getKey={taskerUser => taskerUser?.Id ?? -1}
					handleInEditStateChange={() => handleEditStateChange(EIssueModalDetailsPageFields.observer, true)}
					handleReset={() => handleReset(EIssueModalDetailsPageFields.observer)}
					nonEditComponent={
						<VisibilityControl condition={!!issue?.Members?.Observer?.length} alternative="-">
							<div className={classes.profileSelectorWrapper}>
								<ProfileSelector
									limit={10}
									getAvatar={profile => profile?.AvatarPath ?? ''}
									getKey={profile => profile.Id}
									items={(issue?.Members?.Observer as Partial<ITaskerUser>[]) ?? []}
									getTooltip={profile => getFullNameNew(profile)}
									className={classes.profileSelector}
								/>
							</div>
						</VisibilityControl>
					}
				/>
				<VisibilityControl condition={!!inputsInEdit[EIssueModalDetailsPageFields.observer]}>
					<AssignMe
						isCurrentTaskerUserSelected={
							!!issueState?.Observer?.map(user => user?.Id)?.includes(taskerUser?.Id)
						}
						handleAssignPerson={handleAssignPerson}
						field={EIssueModalDetailsPageFields?.observer}
					/>
				</VisibilityControl>
			</div>

			<div className={clsx(classes.flexYCenter)}>
				<CustomSelect
					isMulti
					isDarkMode={isDarkMode}
					canSetInputInNonEdit={isEdittingIssue}
					inEdit={!!inputsInEdit[EIssueModalDetailsPageFields.reporter]}
					label={t('reporter')}
					items={taskerUsers}
					values={issueState?.Reporter ?? []}
					className={clsx(classes.avatarSelect, classes.flexGrow)}
					args={{
						['components']: {
							ValueContainer,
							MultiValueLabel: MultiSelectValueLabelCustom,
							Option: Option,
						},
						closeMenuOnSelect: false,
						menuPosition: Resources.fixed,
						valuesLimit: 1,
					}}
					onChange={newUsers => handleInputChange(EIssueModalDetailsPageFields.reporter, newUsers)}
					getName={taskerUser => getFullNameNew(taskerUser)}
					getKey={taskerUser => taskerUser?.Id ?? -1}
					handleInEditStateChange={() => handleEditStateChange(EIssueModalDetailsPageFields.reporter, true)}
					handleReset={() => handleReset(EIssueModalDetailsPageFields.reporter)}
					nonEditComponent={
						<VisibilityControl condition={!!issue?.Members?.Reporter?.length} alternative="-">
							<div className={classes.profileSelectorWrapper}>
								<ProfileSelector
									limit={10}
									getAvatar={profile => profile?.AvatarPath ?? ''}
									getKey={profile => profile.Id}
									items={(issue?.Members?.Reporter as Partial<ITaskerUser>[]) ?? []}
									getTooltip={profile => getFullNameNew(profile)}
									className={classes.profileSelector}
								/>
							</div>
						</VisibilityControl>
					}
				/>
				<VisibilityControl condition={!!inputsInEdit[EIssueModalDetailsPageFields.reporter]}>
					<AssignMe
						isCurrentTaskerUserSelected={
							!!issueState?.Reporter?.map(user => user?.Id)?.includes(taskerUser?.Id)
						}
						handleAssignPerson={handleAssignPerson}
						field={EIssueModalDetailsPageFields?.reporter}
					/>
				</VisibilityControl>
			</div>

			<div className={clsx(classes.flexYCenter)}>
				<CustomSelect
					isMulti
					isDarkMode={isDarkMode}
					inEdit={!!inputsInEdit[EIssueModalDetailsPageFields.auditor]}
					label={t('auditor')}
					items={taskerUsers}
					values={issueState?.Auditor ?? []}
					onChange={newUsers => handleInputChange(EIssueModalDetailsPageFields.auditor, newUsers)}
					className={clsx(classes.avatarSelect, classes.flexGrow)}
					args={{
						['components']: {
							ValueContainer,
							MultiValueLabel: MultiSelectValueLabelCustom,
							Option: Option,
						},
						closeMenuOnSelect: false,
						menuPosition: Resources.fixed,
						valuesLimit: 1,
					}}
					canSetInputInNonEdit={isEdittingIssue}
					getName={taskerUser => getFullNameNew(taskerUser)}
					getKey={taskerUser => taskerUser?.Id ?? -1}
					handleInEditStateChange={() => handleEditStateChange(EIssueModalDetailsPageFields.auditor, true)}
					handleReset={() => handleReset(EIssueModalDetailsPageFields.auditor)}
					nonEditComponent={
						<VisibilityControl condition={!!issue?.Members?.Auditor?.length} alternative="-">
							<div className={classes.profileSelectorWrapper}>
								<ProfileSelector
									limit={10}
									getAvatar={profile => profile?.AvatarPath ?? ''}
									getKey={profile => profile.Id}
									items={(issue?.Members?.Auditor as Partial<ITaskerUser>[]) ?? []}
									getTooltip={profile => getFullNameNew(profile)}
									className={classes.profileSelector}
								/>
							</div>
						</VisibilityControl>
					}
				/>
				<VisibilityControl condition={!!inputsInEdit[EIssueModalDetailsPageFields.auditor]}>
					<AssignMe
						isCurrentTaskerUserSelected={
							!!issueState?.Auditor?.map(user => user?.Id)?.includes(taskerUser?.Id)
						}
						handleAssignPerson={handleAssignPerson}
						field={EIssueModalDetailsPageFields?.auditor}
					/>
				</VisibilityControl>
			</div>

			<div className={classes.flexColumn}>
				<InputLabelNew label={t('creator')} inEdit={false} />
				<div className={classes.profileSelectorWrapper}>
					<ProfileSelector
						limit={1}
						getAvatar={profile => profile?.AvatarPath ?? ''}
						getKey={profile => profile.Id}
						items={getCreator() as Partial<ITaskerUser>[]}
						getTooltip={profile => getFullNameNew(profile)}
						className={classes.profileSelector}
					/>
				</div>
			</div>
			<VisibilityControl condition={isEdittingIssue}>
				<div>
					<InputLabelNew label={t('creationDate')} inEdit={false} />
					<VisibilityControl condition={!!issue?.CreatedDateTime} alternative="-">
						<Typography>
							{issue?.CreatedDateTime &&
								format(new Date(issue?.CreatedDateTime ?? '') as Date, EDateFNS.DateWithoutTime)}
						</Typography>
					</VisibilityControl>
				</div>
			</VisibilityControl>

			<VisibilityControl condition={isEdittingIssue}>
				<div>
					<InputLabelNew label={t('time')} inEdit={false} />
					<VisibilityControl condition={!!issue?.CreatedDateTime} alternative="-">
						<TimeInfo worklogs={workLogs} />
					</VisibilityControl>
				</div>
			</VisibilityControl>
			<VisibilityControl condition={isEdittingIssue}>
				<div>
					<InputLabelNew label={t('totalTimeSpentOnIssue')} inEdit={false} />
					<TotalTimeSpentOnIssue />
				</div>
			</VisibilityControl>
		</>
	);
};
