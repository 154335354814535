import { t } from 'i18next';

import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		CustomerDetail: {
			Content: {
				Invoices: {
					HeaderInvoice: {
						InvoiceModal: {
							Content: {
								generalTab: 'Podstawowe dane',
								itemsTab: 'Przedmioty faktury',
								ItemEdit: 'Edytowany przedmiot faktury',
								NewSeries: 'Nowa seria faktur',
								deleteConfirmation: 'Czy na pewno chcesz obecnie wybraną serię?',
							},
						},
					},
				},
			},
		},
	},
	en: {
		CustomerDetail: {
			Content: {
				Invoices: {
					HeaderInvoice: {
						InvoiceModal: {
							Content: {
								generalTab: 'Basic data',
								itemsTab: 'Items in invoice',
								ItemEdit: 'Invoice item in edit',
								NewSeries: 'New series',
								deleteConfirmation: 'Are you sure you want to delete currently selected series?',
							},
						},
					},
				},
			},
		},
	},
};

export enum ETab {
	General = 'General',
	Items = 'Items',
	ItemEdit = 'ItemEdit',
	NewSeries = 'NewSeries',
}

export const getTabsObject = (): { value: string; label: string; disabled: boolean; inVisible?: boolean }[] => {
	return [
		{
			value: ETab.General,
			label: t('CustomerDetail.Content.Invoices.HeaderInvoice.InvoiceModal.Content.generalTab'),
			disabled: false,
		},
		{
			value: ETab.Items,
			label: t('CustomerDetail.Content.Invoices.HeaderInvoice.InvoiceModal.Content.itemsTab'),
			disabled: false,
		},
		{
			value: ETab.ItemEdit,
			label: t('CustomerDetail.Content.Invoices.HeaderInvoice.InvoiceModal.Content.ItemEdit'),
			disabled: false,
			inVisible: true,
		},
		{
			value: ETab.NewSeries,
			label: t('CustomerDetail.Content.Invoices.HeaderInvoice.InvoiceModal.Content.NewSeries'),
			disabled: false,
			inVisible: true,
		},
	];
};
