import { IProject } from '@sac-tt/tasker-types';
import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		ProjectCreate: {
			Header: {
				management: 'Nowy projekt',
				type: 'Tworzenie projektu',
				typeTemplate: 'Wybór templatki',
			},
			cardHeaderReckonTitle: 'Reckon',
			createProject: 'Stwórz projekt',
			pageTitle: 'Stwórz projekt',
			validationFailed: 'Błąd walidacji',
			chosenTemplate: 'Wybrana templatka',
			edit: 'Zmień templatkę',
		},
	},
	en: {
		ProjectCreate: {
			Header: {
				management: 'New Project',
				type: 'Creating project',
				typeTemplate: 'Choosing Template',
			},
			cardHeaderReckonTitle: 'Reckon',
			createProject: 'Create project',
			pageTitle: 'Project Create',
			validationFailed: 'Validation failed',
			chosenTemplate: 'Choosen template',
			edit: 'Change template',
		},
	},
};

export const initialStatePartialProject: Partial<IProject> = {
	Prefix: undefined,
	Name: '',
	Description: '',
	CustomerId: 0,
};
