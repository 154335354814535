import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		OrganizationPanel: {
			EndWorkDay: {
				EndWorkDayCreate: {
					TaskerStepper: {
						optional: 'Ten krok jest opcjonalny, nie musisz go wypełniać aby przejść dalej',
						step: 'Krok',
					},
				},
			},
		},
	},
	en: {
		OrganizationPanel: {
			EndWorkDay: {
				EndWorkDayCreate: {
					TaskerStepper: {
						optional: 'This step is optional, you dont have to complete it to go next',
						step: 'Step',
					},
				},
			},
		},
	},
};
