import { getGlobalStyles } from '@sac-tw2/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(
	(theme: Theme) => {
		return {
			...getGlobalStyles(),
			container: {
				minWidth: `calc(${theme.spacing(21)} + 15rem)`,
				position: 'relative',

				'& .MuiOutlinedInput-root': {
					padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
				},
			},

			selectError: {
				'& fieldset': {
					border: '2px solid red',

					'&--is-focused': {
						border: '2px solid red',
					},
				},
			},

			valueContainer: {
				marginLeft: theme.spacing(0.5),
				padding: '3px 10px',
			},
			editorContainer: {
				minWidth: `15rem`,
			},
		};
	},
	{
		name: `IssueModalNew.IssueModalContent.IssueDetailsPage.MainIssueData.DependentInputs.IssueFormSection.TextAreaIssueProperty`,
	}
);
