import { AsyncAction } from '@sac-rp/redux-promisify';
import { IInvoice, IInvoicePayment, IInvoiceSeries, IMeasureKind, IPage } from '@sac-tt/tasker-types';

import { IJson } from '@/store/ITypes';
import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

export const INVOICE_FETCH_PAGE: ActionType = 'INVOICE_FETCH_PAGE';
export const INVOICE_FETCH_ONE: ActionType = 'INVOICE_FETCH_ONE';
export const INVOICE_CREATE: ActionType = 'INVOICE_CREATE';
export const INVOICE_UPDATE: ActionType = 'INVOICE_UPDATE';
export const INVOICE_RESTART: ActionType = 'INVOICE_RESTART';
export const INVOICE_OPTIONS_GET: ActionType = 'INVOICE_OPTIONS_GET';
export const INVOICE_MODAL_RESET: ActionType = 'INVOICE_MODAL_RESET';
export const INVOICE_MODAL_CREATE_LOCAL: ActionType = 'INVOICE_MODAL_CREATE_LOCAL';
export const INVOICE_FETCH_ONE_PDF: ActionType = 'INVOICE_FETCH_ONE_PDF';

const basePath = 'Invoice';

function _getPageInvoice(data: IPage<IInvoice[]>): Action {
	return {
		type: INVOICE_FETCH_PAGE,
		data: {
			Count: data?.Count ?? 0,
			Data: data?.Data ?? [],
		},
	};
}

function _getInvoice(data: IInvoice): Action {
	return {
		type: INVOICE_FETCH_ONE,
		data,
	};
}

function _getInvoicePDF(data: IInvoice): Action {
	return {
		type: INVOICE_FETCH_ONE_PDF,
		data,
	};
}

function _createInvoice(data: IInvoice): Action {
	return {
		type: INVOICE_CREATE,
		data,
	};
}

function _updateInvoice(data: IInvoice): Action {
	return {
		type: INVOICE_UPDATE,
		data,
	};
}

function _getInvoiceOptions(data: {
	MeasureKind: IMeasureKind[];
	InvoicePayment: IInvoicePayment[];
	InvoiceSeries: IInvoiceSeries[];
}): Action {
	return {
		type: INVOICE_OPTIONS_GET,
		data,
	};
}

export function _restartInvoice(): Action {
	return {
		type: INVOICE_RESTART,
	};
}

export function invoiceModalReset(): Action {
	return {
		type: INVOICE_MODAL_RESET,
	};
}
export function invoiceCreateLocal(data: IInvoice): Action {
	return {
		type: INVOICE_MODAL_CREATE_LOCAL,
		data,
	};
}

export function invoice_fetchPage(customerId: number, from = 0, count = 6): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_getPageInvoice((json as IJson)?.data)));
		return axios().post(`${basePath}/customer/${customerId}/from/${from}/count/${count}`);
	};
}

export function invoice_fetchOne(id: number, pdfDownland = false): AsyncAction {
	return function(context, dispatch) {
		context.then(json =>
			pdfDownland ? dispatch(_getInvoicePDF((json as IJson)?.data)) : dispatch(_getInvoice((json as IJson)?.data))
		);
		return axios().get(`${basePath}/${id}`);
	};
}

export function invoice_create(data: IInvoice): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_createInvoice((json as IJson)?.data)));
		return axios().post(basePath, { ...data });
	};
}

export function invoice_update(data: IInvoice, id: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_updateInvoice((json as IJson)?.data)));
		return axios().patch(`${basePath}/${id}`, { ...data });
	};
}

export function invoice_delete(id: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_updateInvoice((json as IJson)?.data)));
		return axios().delete(`${basePath}/${id}`);
	};
}

export function invoice_options(): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_getInvoiceOptions((json as IJson)?.data)));
		return axios().get(basePath);
	};
}
