import { isRtl } from '@sac-tw2/tasker-widgets2/dist/utils';
import { getGlobalStyles } from '@sac-tw2/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(
	() => ({
		...getGlobalStyles(),
		justifyContentArabic: {
			justifyContent: isRtl() ? 'flex-end' : 'unset',
			display: 'flex',
		},
	}),
	{ name: `Dashboard_NavBarSection_NavItem` }
);
