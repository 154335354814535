import { AsyncAction } from '@sac-rp/redux-promisify';
import { IHoliday, IHolidayType, IPage } from '@sac-tt/tasker-types';

import { HOLIDAY_DELETE } from '@/store/actions/holidayActions';
import { IJson } from '@/store/ITypes';
import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

const basePath = 'HolidayType';

export const HOLIDAY_TYPES_FETCH: ActionType = 'HOLIDAY_TYPES_FETCH';
export const HOLIDAY_TYPES_OPTIONS_FETCH: ActionType = 'HOLIDAY_TYPES_OPTIONS_FETCH';
export const HOLIDAY_TYPE_FETCH: ActionType = 'HOLIDAY_TYPE_FETCH';
export const HOLIDAY_TYPE_RESET: ActionType = 'HOLIDAY_TYPE_RESET';
export const HOLIDAY_TYPE_DELETE: ActionType = 'HOLIDAY_TYPE_DELETE';

function _fetchHolidayTypes(holidays: IPage<IHolidayType>): Action {
	return {
		type: HOLIDAY_TYPES_FETCH,
		holidays: holidays,
	};
}

function _fetchHolidayTypesOptions(holidays: IHolidayType[]): Action {
	return {
		type: HOLIDAY_TYPES_OPTIONS_FETCH,
		holidays: holidays,
	};
}

function _fetchHolidayType(holiday: IHolidayType): Action {
	return {
		type: HOLIDAY_TYPE_FETCH,
		holiday: holiday,
	};
}

function _deleteHolidayType(holidays: IPage<IHoliday>): Action {
	return {
		type: HOLIDAY_TYPE_DELETE,
		holidays: holidays,
	};
}

export function holidayTypeReset(): Action {
	return { type: HOLIDAY_TYPE_RESET };
}

export function getHolidayTypePage(from: number, count: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchHolidayTypes((json as IJson)?.data)));
		return axios().get(`${basePath}/from/${from}/count/${count}`);
	};
}

export function getHolidayTypes(): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchHolidayTypesOptions((json as IJson)?.data)));
		return axios().get(`${basePath}/options/all`);
	};
}

export function getHolidayType(id: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchHolidayType((json as IJson)?.data)));
		return axios().get(`${basePath}/${id}`);
	};
}

export function updateHolidayType(holidayType: Partial<IHolidayType>): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchHolidayType((json as IJson)?.data)));
		return axios().patch(`${basePath}/${holidayType?.Id}`, { ...holidayType });
	};
}

export function createHolidayType(holidayType: Partial<IHolidayType>): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchHolidayType((json as IJson)?.data)));
		return axios().post(basePath, { ...holidayType });
	};
}

export function deleteHolidayType(id: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchHolidayType((json as IJson)?.data)));
		return axios().delete(`${basePath}/${id}`);
	};
}
