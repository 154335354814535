import { AsyncAction } from '@sac-rp/redux-promisify';
import { IWorkMgtTemplGroup } from '@sac-tt/tasker-types/dist/types/dataBaseTypes/workMgtTemplGroup';

import { IJson } from '@/store/ITypes';
import { Action } from '@/store/types';
import axios from '@/utils/axios';

import { ActionType } from '../types';

export const FETCH_TEMPLATE_OPTIONS: ActionType = 'FETCH_TEMPLATE_OPTIONS';

const basePath = 'Template';

function _fetchTemplates(templates: IWorkMgtTemplGroup[]): Action {
	return {
		type: FETCH_TEMPLATE_OPTIONS,
		templates,
	};
}

export function fetch_templates(): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchTemplates((json as IJson).data)));
		return axios().get(basePath);
	};
}
