import { IIssue, IIssueForm } from '@sac-tt/tasker-types';

import { IIssueDescription } from '../model';

import { IAttachmentDrop } from './TabContent/Attachment/model';

export interface Props {
	issue: IIssue;
	onIssueChange: (issue: IIssue) => void;
	setComment: (comment: IIssueDescription) => void;
	saveIssue: () => void;
	isNew: boolean;
	form?: Partial<IIssueForm>;
	fileDidChangeLocal: (attachment: IAttachmentDrop[]) => void;
	createAnother: boolean;
	setCreateAnother: (createAnother: boolean) => void;
}

export interface StyleProps {
	headerTopHeight: string;
	isNew: boolean;
}

export enum ETab {
	Comments = 'Comments',
	Attachment = 'Attachment',
}
