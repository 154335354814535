import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	errorLink: '/errors/error-500',
};

export const Translation = {
	pl: {
		SnackBarProviderWrapper: {
			dismiss: 'Zamknij',
		},
	},
	en: {
		SnackBarProviderWrapper: {
			dismiss: 'Dismiss',
		},
	},
};
