import { getGlobalStyles } from '@sac-tw2/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(
	(theme: Theme) => ({
		...getGlobalStyles(),

		avatar: {
			width: '26px',
			height: '26px',
			marginRight: theme.spacing(1),
		},

		nameContainer: {
			maxWidth: '100px',
		},
	}),
	{ name: `ProjectDetails_ProjectMembersConfiguration_CommonCells_UserProjectRoles_ValueContainer` }
);
