import { t } from 'i18next';

import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

import { KanbanContent } from '@/views/KanbanBoard/KanbanContent';

import { EditKanbanContent } from '../../..';

export const Resources = {
	...GlobalResources,
	issueId: '?issueId=',
	projectId: 'projects/',
	overview: 'overview',
	customers: 'customers/',
	summary: 'summary',
	issuePath: '?issueId=0',
	simplePopover: 'simple-popover',
	projectPath: '&projectId=',
	unassignedAvatar: '/favicon.png',
	anchorOrigin: {
		vertical: 'center',
		horizontal: 'center',
	} as const,
	user: '/user/',
	plus: '+',
	csv: {
		headers: () => {
			return [
				{ label: t('IssueList.csv.id'), key: 'id' },
				{ label: t('IssueList.csv.issueName'), key: 'issueName' },
				{ label: t('IssueList.csv.displayId'), key: 'displayId' },
				{ label: t('IssueList.csv.url'), key: 'url' },
				{ label: t('IssueList.csv.type'), key: 'type' },
				{ label: t('IssueList.csv.status'), key: 'status' },
				{ label: t('IssueList.csv.time'), key: 'time' },
			];
		},
		filename: () => t('IssueReport.csv'),
	},
	comma: ';',
	csvStyles: { visibility: 'hidden' } as { visibility: 'hidden' },
};

export const Translation = {
	pl: {
		Kanban: {
			KanbanBoard: {
				KanbanContent: {
					EditKanbanContent: {
						TabsContent: {
							SwimlanesTab: {
								SwimlaneList: {
									title: 'Konfiguracja swimlane',
									subtext: `W tym miejscu możesz zarządzać swimlane'ami - ich widocznością oraz kolejnością wyświetlania`,
									ifYouWantToConfigure: `Jeśli chcesz konfigurować  swimlane'y, `,
									useYourOwnConfiguration: 'użyj swojej konfiguracji',
									name: 'Nazwa',
									color: 'Kolor',
									creationDate: 'Data stworzenia',
									createdBy: 'Stworzone przez',
									youAreUsingConfiguration: 'Używasz konfiguracji użytkownika:',
									addSwimlane: 'Dodaj Swimlane',
									exit: 'Zamknij',
									save: 'Zapisz zmiany',
									popupTitle: 'Kreator swimlane',
									popupSubtitle:
										'Swtórz oraz skonfigurj nowy swimlane aby jeszcze lepiej zarządzać zadaniami',
									deleteConfirmationMessage: 'Chy na pewno chcesz usunąć swimlane?',
									generalSwimlane:
										'Ustaw swimlane jako główny. Będą trafiały do niego wszystkie nowe zgłoszenia',
									delete: 'Usuń swimlane',
									Errors: {
										nameSwimlaneErrorMessage: '^Nazwa powinna zawierać od 1 do 50 znaków!',
									},
									cantDeleteDefaultSwimlane: `Nie możesz usunąć głównego swimlane'a`,
									editSwimlane: 'Edytuj swimlane',
								},
							},
						},
					},
				},
			},
		},
	},
	en: {
		Kanban: {
			KanbanBoard: {
				KanbanContent: {
					EditKanbanContent: {
						TabsContent: {
							SwimlanesTab: {
								SwimlaneList: {
									title: 'Swimeline configuration',
									subtext:
										'In this place, you can manage swimlanes - their visibility and display order',
									ifYouWantToConfigure: 'If you want to configure the swimlanes, ',
									useYourOwnConfiguration: 'use your own configuration',
									name: 'Name',
									color: 'Color',
									creationDate: 'Creation date',
									createdBy: 'Created by',
									youAreUsingConfiguration: 'You are using a configuration of user:',
									addSwimlane: 'Add new swimlane',
									save: 'Save changes',
									exit: 'Exit',
									popupTitle: 'Swimlane creator',
									popupSubtitle: 'Create and configure new swimlne to manage your issues even better',
									deleteConfirmationMessage: 'Are you sure you want to delete swimlane?',
									generalSwimlane: 'Set swiline as general. All new issues will apera here',
									delete: 'Delete swimlane',
									Errors: {
										nameSwimlaneErrorMessage:
											'^The name should contain between 1 and 50 characters!',
									},
									cantDeleteDefaultSwimlane: 'You cant delete default swimlane',
									editSwimlane: 'Edit swimlane',
								},
							},
						},
					},
				},
			},
		},
	},
};
