import { IHoliday, IHolidayType } from '@sac-tt/tasker-types';

import * as actionTypes from '@/store/actions';
import { ObjectAction } from '@/store/types';

const State = {
	data: {} as IHoliday,
	holidayTypes: [] as IHolidayType[],
};
export type IHolidayEventDetailsState = typeof State;

const holidayEventDetailsReducer = (state = State, action: ObjectAction): IHolidayEventDetailsState => {
	switch (action.type) {
		case actionTypes.HOLIDAY_DELETE:
		case actionTypes.HOLIDAY_FETCH: {
			return {
				...state,
				data: action.holiday,
			};
		}
		case actionTypes.HOLIDAY_RESET: {
			return {
				...state,
				data: {} as IHoliday,
				holidayTypes: [],
			};
		}
		case actionTypes.HOLIDAY_TYPES_OPTIONS_FETCH: {
			return {
				...state,
				holidayTypes: action.holidays,
			};
		}
		default: {
			return state;
		}
	}
};

export default holidayEventDetailsReducer;
