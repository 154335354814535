import { t } from 'i18next';

import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

import { ETab } from './model';

export const Resources = {
	new: '/holiday/new',
	...GlobalResources,
};

export const Translation = {
	pl: {
		Holiday: {
			pageTitle: 'Dni wolne',
			Tabs: {
				eventLabel: 'Dni wolne',
				typeLabel: 'Rodzaje dni wolnych',
			},
		},
	},
	en: {
		Holiday: {
			pageTitle: 'Holidays',
			Tabs: {
				eventLabel: 'Holidays',
				typeLabel: 'Holidays types',
			},
		},
	},
};

export const getTabs = (): { value: string; label?: string; visible: boolean }[] => {
	return [
		{ value: ETab.Event, label: t('Holiday.Tabs.eventLabel'), visible: true },
		{ value: ETab.Type, label: t('Holiday.Tabs.typeLabel'), visible: true },
	];
};
