import { AsyncAction } from '@sac-rp/redux-promisify';
import { IProject } from '@sac-tt/tasker-types';
import { IProjectToProject } from '@sac-tt/tasker-types/dist/types/dataBaseTypes/projectsToProject';

import { IJson } from '@/store/ITypes';
import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

const basePath = 'ProjectToProject';

export const FETCH_PROJECT_CHILDREN: ActionType = 'FETCH_PROJECT_CHILDREN';
export const FETCH_PROJECT_PARENT: ActionType = 'FETCH_PROJECT_PARENT';
export const DELETE_PROJECT_RELATION: ActionType = 'DELETE_PROJECT_RELATION';
export const DELETE_PROJECT_RELATION_BY: ActionType = 'DELETE_PROJECT_RELATION_BY';
export const ADD_PROJECT_RELATION: ActionType = 'ADD_PROJECT_RELATION';

interface IConnectionResponse {
	Child: IProject[];
	Parent: IProject[];
}

function _fetchProjectParent(projects: IProject[]): Action {
	return {
		type: FETCH_PROJECT_PARENT,
		data: projects,
	};
}

function _fetchProjectChildren(projects: IProject[]): Action {
	return {
		type: FETCH_PROJECT_CHILDREN,
		data: projects,
	};
}

function _postProjectCreation(data: IConnectionResponse): Action {
	return {
		type: ADD_PROJECT_RELATION,
		data: data,
	};
}

function _deleteProjectRelationBy(data: IConnectionResponse): Action {
	return {
		type: DELETE_PROJECT_RELATION_BY,
		data: data,
	};
}

export function project_get_children(projectId: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchProjectChildren((json as IJson).data)));
		return axios().get(`${basePath}/children/${projectId}`);
	};
}

export function project_get_parents(projectId: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchProjectParent((json as IJson).data)));
		return axios().get(`${basePath}/parents/${projectId}`);
	};
}

export function project_add_relation(data: Partial<IProjectToProject>, projectId: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_postProjectCreation((json as IJson).data)));
		return axios().post(`${basePath}/project/${projectId}`, data);
	};
}

export function project_delete_relation_by(
	projectIdParent: number,
	projectIdChild: number,
	projectId: number
): AsyncAction {
	if (!projectIdParent || !projectIdChild) throw new Error('No Id');
	return function(context, dispatch) {
		context.then(json => dispatch(_deleteProjectRelationBy((json as IJson).data)));
		return axios().delete(`${basePath}/project/${projectId}/parent/${projectIdParent}/child/${projectIdChild}`);
	};
}
