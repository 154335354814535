import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	empty: '',
	mailURL: (email: string, subject: string) => `mailto:${email}?&subject=Tasker-${subject}`,
	http: 'http://',
	https: 'https://',
};

export const Translation = {
	pl: {
		Settings: {
			General: {
				ProfileDetails: {
					emailToolTip: 'Wyślij email',
					linkedInToolTip: 'Sprawdź mój LinkedIn',
					fbToolTip: 'Sprawdź mój Facebook',
					twitterToolTip: 'Sprawdź mój Twitter',
					instaToolTip: 'Sprawdź mój Instagram',
					popup: {
						title: 'Avatar',
						fieldLabel: 'Link to twojego awatara',
					},
					popupAddNewSocialMedia: {
						title: 'Co chciałbyś dodać',
					},
					avatar: {
						updated: 'Avatar został zaaktualizowany',
						updateFailed: 'Aktualizowanie avatara zakończone niepowodzeniem proszę spróbować później',
					},
					configureSocialMediaLinks: 'Skonfiguruj linki do social mediów',
					userIdMissing: 'Brak identyfikatora użytkownika',
				},
			},
		},
	},
	en: {
		Settings: {
			General: {
				ProfileDetails: {
					emailToolTip: 'Send an email',
					linkedInToolTip: 'Check out my LinkedIn',
					fbToolTip: 'Check out my Facebook',
					twitterToolTip: 'Check out my Twitter',
					instaToolTip: 'Check out my Instagram',
					popup: {
						title: 'Avatar',
						fieldLabel: 'Link to your avatar',
					},
					popupAddNewSocialMedia: {
						title: 'What would you like to add',
					},
					avatar: {
						updated: 'Avatar updated',
						updateFailed: 'Avatar update failed please try again later',
					},
					configureSocialMediaLinks: 'Configure social media links',
					userIdMissing: 'User identifier is missing',
				},
			},
		},
	},
};
