import { lazy } from 'react';
import { RouteConfig } from 'react-router-config';
import { Redirect } from 'react-router-dom';

import { Permission } from '@sac-tt/tasker-types';

import AuthLayout from './layouts/Auth';
import DashboardLayout from './layouts/Dashboard';
import ErrorLayout from './layouts/Error';

const projectCreateGet = () => import('@/views/ProjectCreate');

export type SecureRouteConfig = RouteConfig & {
	routes?: SecureRouteConfig[];
	secured?: boolean;
	permission?: number;
};

const dashboardRoutes = [
	{
		permission: Permission.GetBudget,
		path: '/budget',
		exact: true,
		component: lazy(() => import('@/views/Budgets')),
	},
	{
		permission: Permission.Activity,
		path: '/activity',
		exact: true,
		component: lazy(() => import('@/views/Activities')),
	},
	{
		permission: Permission.Issues,
		path: '/issues',
		exact: true,
		component: lazy(() => import('@/views/IssueList')),
	},
	{
		permission: Permission.Issues,
		path: '/issues/page/:pageId',
		exact: true,
		component: lazy(() => import('@/views/IssueList')),
	},
	{
		permission: Permission.Customers,
		path: '/customers',
		exact: true,
		component: lazy(() => import('@/views/CustomerList')),
	},
	{
		permission: Permission.Customers,
		path: '/customers/page/:pageId',
		exact: true,
		component: lazy(() => import('@/views/CustomerList')),
	},
	{
		permission: Permission.CustomersCreate,
		path: '/customers/create',
		exact: true,
		component: lazy(() => import('@/views/CustomerCreate')),
	},
	{
		permission: Permission.CustomersDetail,
		path: '/customers/:id/:tab?',
		exact: true,
		component: lazy(() => import('@/views/CustomerDetail')),
	},
	{
		permission: Permission.Projects,
		path: '/projects',
		exact: true,
		component: lazy(() => import('@/views/ProjectList')),
	},
	{
		permission: Permission.Projects,
		path: '/projects/page/:pageId',
		exact: true,
		component: lazy(() => import('@/views/ProjectList')),
	},
	{
		permission: Permission.ProjectsCreate,
		path: '/projects/create',
		exact: true,
		component: lazy(projectCreateGet),
	},
	{
		permission: Permission.ProjectsCreate,
		path: '/projects/create/:customerId/',
		exact: true,
		component: lazy(projectCreateGet),
	},
	{
		permission: Permission.ProjectsDetail,
		path: '/projects/:id/:tab?',
		exact: true,
		component: lazy(() => import('@/views/ProjectDetails')),
	},
	{
		permission: Permission.Calendar,
		path: '/calendar',
		exact: true,
		component: lazy(() => import('@/views/Calendar')),
	},
	{
		permission: Permission.Calendar,
		path: '/leaveRequestCalendar',
		exact: true,
		component: lazy(() => import('@/views/LeaveRequestCalendar')),
	},
	{
		permission: Permission.Calendar,
		path: '/gant',
		exact: true,
		component: lazy(() => import('@/views/Gant')),
	},
	{
		permission: Permission.Settings,
		path: '/settings',
		exact: true,
		component: lazy(() => import('@/views/Settings')),
	},
	{
		permission: Permission.Settings,
		path: '/settings/:tab',
		exact: true,
		component: lazy(() => import('@/views/Settings')),
	},
	{
		permission: Permission.ReportTime,
		path: '/report/time',
		exact: true,
		component: lazy(() => import('@/views/TimeReport')),
	},
	{
		permission: Permission.ReportTime,
		path: '/report/time/:tab',
		exact: true,
		component: lazy(() => import('@/views/TimeReport')),
	},
	{
		permission: Permission.ReportTime,
		path: '/calendar',
		exact: true,
		component: lazy(() => import('@/views/Calendar')),
	},

	{
		permission: Permission.WorkDayEndSummary,
		path: '/workday-summary',
		exact: true,
		component: lazy(() => import('@/views/WorkDayEndSummary')),
	},

	{
		permission: Permission.KanbanBoard,
		path: ['/kanban', '/kanban/:id'],
		component: lazy(() => import('@/views/KanbanBoard')),
		exact: true,
	},
	{
		permission: Permission.ReportTime,
		path: '/user/:id',
		component: lazy(() => import('@/views/Settings/General')),
	},
	{
		permission: Permission.Portal,
		path: '/portal',
		exact: true,
		component: lazy(() => import('@/views/PortalList')),
	},
	{
		permission: Permission.Portal,
		path: '/portal/:id',
		exact: true,
		component: lazy(() => import('@/views/PortalIssue')),
	},
	{
		permission: Permission.DictionarySite,
		path: '/dictionaries',
		exact: true,
		component: lazy(() => import('@/views/Dictionaries')),
	},
	{
		path: '/notifications',
		exact: true,
		component: lazy(() => import('@/views/NotificationList')),
	},
	{
		permission: Permission.ContractsSite,
		path: '/agreements',
		exact: true,
		component: lazy(() => import('@/views/Agreement')),
	},
	{
		path: '/reports',
		exact: true,
		component: lazy(() => import('@/views/Reports')),
	},
	{
		path: '/reports/:tab/:id',
		exact: true,
		component: lazy(() => import('@/views/Reports')),
	},
	{
		path: '/reports/:tab',
		exact: true,
		component: lazy(() => import('@/views/Reports')),
	},
	{
		permission: Permission.ContractsSite,
		path: '/agreements/:tab',
		exact: true,
		component: lazy(() => import('@/views/Agreement')),
	},
	{
		permission: Permission.ContractsSite,
		path: '/agreements/agreement/create',
		exact: true,
		component: lazy(() => import('@/views/Agreement/Agreements/AgreementEdit')),
	},
	{
		permission: Permission.ContractsSite,
		path: '/agreements/agreement/:id',
		exact: true,
		component: lazy(() => import('@/views/Agreement/Agreements/AgreementDetails')),
	},
	{
		permission: Permission.ContractsSite,
		path: '/agreements/agreement/edit/:id',
		exact: true,
		component: lazy(() => import('@/views/Agreement/Agreements/AgreementEdit')),
	},
	{
		permission: Permission.DelegationSite,
		path: ['/delegations', '/delegations/:id'],
		exact: true,
		component: lazy(() => import('@/views/DelegationsList')),
	},
	{
		permission: Permission.DelegationAcceptance,
		path: ['/delegationsAcceptance', '/delegationsAcceptance/:id'],
		exact: true,
		component: lazy(() => import('@/views/DelegationsAcceptanceList')),
	},
	{
		permission: Permission.Activity,
		path: '/version',
		exact: true,
		component: lazy(() => import('@/views/Versions')),
	},
	{
		permission: Permission.Activity,
		path: '/version',
		exact: true,
		component: lazy(() => import('@/views/Versions')),
	},
	{
		permission: Permission.Activity,
		path: '/version',
		exact: true,
		component: lazy(() => import('@/views/Versions')),
	},
	{
		component: () => <Redirect to="/activity" />,
	},
];
const errorRoutes = [
	{
		path: '/errors/error-401',
		exact: true,
		component: lazy(() => import('@/views/Error401')),
	},
	{
		path: '/errors/error-404',
		exact: true,
		component: lazy(() => import('@/views/Error404')),
	},
	{
		path: '/errors/error-500',
		exact: true,
		component: lazy(() => import('@/views/Error500')),
	},
	{
		// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
		component: () => <Redirect to="/errors/error-404" />,
	},
];
const authRoutes = {
	path: '/auth',
	component: AuthLayout,
	routes: [
		{
			path: '/auth/loading',
			exact: true,
			component: lazy(() => import('@/views/Loading')),
		},
		{
			path: '/auth/login',
			exact: true,
			component: lazy(() => import('@/views/Login')),
		},
		{
			path: '/auth/changepassword',
			exact: true,
			component: lazy(() => import('@/views/Login/ChangePassword')),
		},
		{
			path: '/auth/register',
			exact: true,
			component: lazy(() => import('@/views/Register')),
		},
		{
			path: '/auth/recover/',
			exact: true,
			component: lazy(() => import('@/views/RecoverPassword')),
		},
		{
			path: '/auth/recover/:token',
			exact: true,
			component: lazy(() => import('@/views/ResetPassword')),
		},
		{
			// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
			component: () => <Redirect to="/errors/error-404" />,
		},
	],
};
const adminRoutes: SecureRouteConfig[] = [
	{
		permission: Permission.AdminTenant,
		path: '/organization/tabs/:tab',
		component: lazy(() => import('@/views/OrganizationPanel')),
	},
	{
		permission: Permission.AdminTenant,
		path: '/organization/tabs/structure?:path',
		exact: true,
		component: lazy(() => import('@/views/OrganizationPanel')),
	},
	{
		permission: Permission.AdminTenant,
		path: '/organization',
		exact: true,
		component: lazy(() => import('@/views/OrganizationPanel')),
	},
	{
		permission: Permission.AdminTenant,
		path: '/organization/userDetails/:id',
		component: lazy(() => import('@/views/OrganizationPanel/TabsContent/UsersTab/UserDetails')),
	},
	{
		permission: Permission.AdminTag,
		path: '/tag',
		exact: true,
		component: lazy(() => import('@/views/TagAdminPanel')),
	},
	{
		permission: Permission.AdminTag,
		path: '/tag/:id',
		exact: true,
		component: lazy(() => import('@/views/TagGroupInfo')),
	},
	{
		permission: Permission.HolidayTypeSite,
		path: '/holidayType',
		exact: true,
		component: lazy(() => import('@/views/Holiday/Type/List')),
	},
	{
		permission: Permission.HolidaySite,
		path: '/holiday/:id',
		exact: true,
		component: lazy(() => import('@/views/Holiday/Event/Detail')),
	},

	{
		permission: Permission.HolidayTypeSite,
		path: '/holiday',
		exact: true,
		component: lazy(() => import('@/views/Holiday')),
	},
	{
		permission: Permission.UpdateHolidayType,
		path: '/holidayType/:id',
		exact: true,
		component: lazy(() => import('@/views/Holiday/Type/Detail')),
	},
	{
		permission: Permission.LeaveTypeSite,
		path: '/leaveType',
		exact: true,
		component: lazy(() => import('@/views/LeaveTypeList')),
	},
	{
		permission: Permission.AdminPermissions,
		path: '/role/configuration',
		exact: true,
		component: lazy(() => import('@/views/Roles')),
	},
];

export const Routes: SecureRouteConfig[] = [
	authRoutes,
	{
		path: '/errors',
		component: ErrorLayout,
		routes: errorRoutes,
	},
	{
		route: '*',
		secured: true,
		component: DashboardLayout,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		routes: [...adminRoutes, ...dashboardRoutes] as any,
	},
];
