import { isMobile } from '@sac-tw2/tasker-widgets2/dist/utils';
import { getGlobalStyles } from '@sac-tw2/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(
	(theme: Theme) => ({
		...getGlobalStyles(),
		flex: {
			alignItems: 'center',
			display: 'flex',
			justifyContent: 'space-between',
		},
		popover: {
			width: '100%',
			height: '100%',
		},
		paddingBottom: {
			paddingBottom: '0px',
		},
		listLong: {
			height: '510px',
		},
		listLongMobile: {
			height: '400px',
			paddingRight: '3px',
			overflowX: 'hidden',
			overflowY: 'auto',
		},
		loadingBar: {
			minHeight: '11px',
			alignSelf: 'center',
			padding: '3px 20px 3px 20px',
		},
		displayAll: {
			paddingRight: '-10px',
			marginBottom: '20px',
		},
		buttonPosition: {
			position: 'fixed',
			padding: '8px',
			zIndex: 1,
		},
		minWidth: {
			minWidth: isMobile() ? '90vw' : '500px',
			width: isMobile() ? '90vw' : '500px',
		},
		activeTasks: {
			padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
		},
		tab: {
			fontSize: isMobile() ? '10px' : undefined,
		},
		searchSection: {
			paddingTop: theme.spacing(1),
			display: 'flex',
			justifyContent: 'center',
		},
		search: { width: '95%' },
		pagination: {
			marginLeft: theme.spacing(3.25),
			marginRight: theme.spacing(1.5),
		},
	}),
	{ name: `WorklogStarter_WorklogStarterPopover` }
);
