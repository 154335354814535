import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources.js';

export const Resources = {
	...GlobalResources,
	projectId: 'projects/',
	overview: 'overview',
	customers: 'customers/',
	summary: 'summary',
	issuePath: '?issueId=',
};

export const Translation = {
	pl: {
		Activities: {
			ActivityList: {
				ActivityIssues: {
					header: 'Twoje zgłoszenia',
					assignees: 'Wykonujący',
					issueName: 'Nazwa zgłoszenia',
					myRoleInIssue: 'Role',
					today: 'Dziś',
					lastWeek: 'Ostatni tydzień',
					noIssues: 'Brak zgłoszeń...',
					creationDate: 'Data stworzenia',

					tile: {
						creationDate: 'Data stworzenia',
						issueName: 'Nazwa zgłoszenia',
					},
					project: 'Projekt',
				},
			},
		},
	},
	en: {
		Activities: {
			ActivityList: {
				ActivityIssues: {
					header: 'Your Issues',
					issueName: 'Issue name',
					assignees: 'Assignees',
					today: 'Today',
					lastWeek: 'Last week',
					noIssues: 'No issues...',
					myRoleInIssue: 'Roles',
					creationDate: 'Creation date',

					tile: {
						creationDate: 'Creation date',
						issueName: 'Issue name',
					},
					project: 'Project',
				},
			},
		},
	},
};
