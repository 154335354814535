import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	dash: '_',
};

export const Translation = {
	pl: {
		ProjectCreate: {
			Template: {
				TemplateCard: {
					groupName: 'Nazwa grupy',
					template: 'Wzorzec',
				},
			},
		},
	},
	en: {
		ProjectCreate: {
			Template: {
				TemplateCard: {
					groupName: 'GroupName',
					template: 'Template',
				},
			},
		},
	},
};
