import { format } from 'date-fns';

import { EDateFNS } from '@sac-tw2/tasker-widgets2/dist/utils';

export interface IInvoiceTemplateHeading {
	company: IInvoiceTemplateHeadingCompany;
	seller: IInvoiceTemplateHeadingSeller;
	buyer: IInvoiceTemplateHeadingBuyer;
	details: IInvoiceTemplateHeadingDetails;
}

export interface IInvoiceTemplateHeadingCompany {
	companyName: string;
	companyNip: string;
	companyAddress: string;
	companyTel: string;
	companyEmail: string;
}

export interface IInvoiceTemplateHeadingSeller {
	sellerName: string;
	sellerAddress: string;
	sellerCity: string;
	sellerCountry: string;
	sellerNip: string;
}

export interface IInvoiceTemplateHeadingBuyer {
	buyerName: string;
	buyerAddress: string;
	buyerCity: string;
	buyerCountry: string;
	buyerNip: string;
}

export interface IInvoiceTemplateHeadingDetails {
	invoiceNumber: string;
	invoicePlace: string;
	invoiceDate: Date | string;
	paymentMethod: string;
	paymentDueDate: Date | string;
	completionDate: Date | string;
}

export const InvoiceTemplateHeading = (props: IInvoiceTemplateHeading): string => {
	return `
    <div class="heading">
      <img src="logo.svg" alt="Company logo" height="30" />
      <div class="heading-details">
        <p>${props.company.companyName}</p>
        <p>NIP: ${props.company.companyNip}</p>
        <p>${props.company.companyAddress}</p>
        <p>tel. ${props.company.companyTel}</p>
        <p>${props.company.companyEmail}</p>
      </div>
    </div>
    <div class="subheading">
      <p>Invoice No. ${props.details.invoiceNumber}</p>
    </div>
    <div class="contact-table-wrapper">
      <table class="contact-table">
        <thead>
          <tr>
            <th>Seller</th>
            <th>Buyer</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <p>${props.seller.sellerName}</p>
              <p>${props.seller.sellerAddress}</p>
              <p>${props.seller.sellerCity}</p>
              <p>${props.seller.sellerCountry}</p>
              <p>NIP: ${props.seller.sellerNip}</p>
            </td>
            <td>
              <p>${props.buyer.buyerName}</p>
              <p>${props.buyer.buyerAddress}</p>
              <p>${props.buyer.buyerCity}</p>
              <p>${props.buyer.buyerCountry}</p>
              <p>NIP: ${props.buyer.buyerNip}</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="invoice-details">
      <div class="invoice-details-item">
        <p>Place of invoice:</p>
        <p>${props.details.invoicePlace}</p>
      </div>
      <div class="invoice-details-item">
        <p>Invoice date:</p>
        <p>${format(new Date(props.details.invoiceDate), EDateFNS.DateWithoutTime)}</p>
      </div>
      <div class="invoice-details-item">
        <p>Payment method:</p>
        <p>${props.details.paymentMethod}</p>
      </div>
      <div class="invoice-details-item">
        <p>Payment due date:</p>
        <p>${format(new Date(props.details.paymentDueDate), EDateFNS.DateWithoutTime)}</p>
      </div>
      <div>&nbsp;</div>
      <div class="invoice-details-item">
        <p>Completion date of delivery/service:</p>
        <p>${format(new Date(props.details.completionDate), EDateFNS.DateWithoutTime)}</p>
      </div>
    </div>
  `;
};
