import { t } from 'i18next';

import { IHotkeyWithLabel } from '@sac-tt/tasker-types';

export const getHotkeys = (canOpenIssueModal = false): IHotkeyWithLabel[] => {
	const response: IHotkeyWithLabel[] = [];
	const splitLink = window.location.hash.split('/');
	if (canOpenIssueModal) response.push({ keys: ['Alt', 'Ctrl', '+'], label: t('HotkeysPopover.PlusToAdd') });

	if (splitLink?.length > 0) {
		switch (splitLink[1]) {
			case 'kanban':
				response.push({ keys: ['Esc'], label: t('HotkeysPopover.EscapeToClose') });
				break;
			case 'gant':
			case 'calendar':
				response.push({ keys: ['Alt', 'Ctrl', '<', '>'], label: t('HotkeysPopover.ArrowsToTime') });
				break;
			case 'dictionaries':
				response.push({ keys: ['Esc'], label: t('HotkeysPopover.EscapeToCancel') });
				response.push({ keys: ['Enter'], label: t('HotkeysPopover.EnterToSave') });
				break;

			case 'issues':
				response.push({ keys: ['Alt', 'Ctrl', '<', '>'], label: t('HotkeysPopover.ArrowsToChangeIssue') });
				response.push({ keys: ['Esc'], label: t('HotkeysPopover.EscapeToCancel') });
				response.push({ keys: ['Enter'], label: t('HotkeysPopover.EnterToSave') });
				break;
			case 'projects':
				if (splitLink[3] && splitLink[3].includes('configureProjectMembers')) {
					response.push({ keys: ['Alt', 'Ctrl', '<', '>'], label: t('HotkeysPopover.ArrowsToSwitch') });
					response.push({ keys: ['Esc'], label: t('HotkeysPopover.EscapeToCancel') });
					response.push({ keys: ['Enter'], label: t('HotkeysPopover.EnterToSave') });
				}
				break;
			case 'admin':
				if (splitLink[2] && splitLink[2].includes('tag'))
					response.push({ keys: ['Alt', 'Ctrl', '<', '>'], label: t('HotkeysPopover.ArrowsToSwitchGroups') });
				break;
			case 'report':
				if (splitLink[2] && splitLink[2].includes('time')) {
					response.push({ keys: ['Alt', 'Ctrl', '<', '>'], label: t('HotkeysPopover.ArrowsToTime') });
					response.push({ keys: ['Esc'], label: t('HotkeysPopover.EscapeToClose') });
				}
				break;
			default:
				break;
		}
	}
	return response;
};
