import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		Settings: {
			General: {
				pageTitle: {
					settings: 'Ustawienia',
					businessCard: 'Wizytówka',
				},
				noUser: 'Nie ma takiego użytkownika...',
			},
		},
	},
	en: {
		Settings: {
			General: {
				pageTitle: {
					settings: 'Settings',
					businessCard: 'Business card',
				},
				noUser: 'No such user...',
			},
		},
	},
};
