import React from 'react';
import { components } from 'react-select';

import { TooltipTextBase } from '@sac-tw2/tasker-widgets2';

import { useStyles } from './styles';

export const MultiSelectValueLabelCustom = (props: any) => {
	const classes = useStyles();

	return (
		<components.MultiValueLabel {...props}>
			{/* <Tooltip title={getFullNameNew(props.data.object)}>
					<Avatar className={classes.avatar} src={props.data.object.AvatarPath} />
				</Tooltip> */}
			<div
				className={classes.tagContainer}
				style={{
					backgroundColor: props?.data?.object?.TagColor,
				}}
			>
				<TooltipTextBase text={props.children} className={classes.tag} />
				{/* {props.children} */}
				{/* <TooltipTextBase text={getFullNameNew(props.data.object)} className={classes.nameContainer} /> */}
			</div>
		</components.MultiValueLabel>
	);
};
