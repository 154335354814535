import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import { RichEditor2 } from '@sac-tw2/tasker-widgets2';

import { IReducerState } from '@/store/reducers/types';
import { getFirstNotEmptyValue } from '@/utils';
import { MaxLengthText } from '@/utils/configuration';

import { issueModalDataContext } from 'view/IssueModal';

import { EIssueModalDetailsPageFields } from '../../model';
import { Resources } from '../../resources';

import { useStyles } from './styles';

export const IssueDescription = () => {
	const classes = useStyles();

	const darkMode = useSelector((state: IReducerState) => state.Session.darkMode);

	const { handleInputChange, issueState } = useContext(issueModalDataContext);

	const handleChangeDescription = (descriptionHtml: string, description: string) => {
		handleInputChange(EIssueModalDetailsPageFields.description, description);
		handleInputChange(EIssueModalDetailsPageFields.descriptionHtml, descriptionHtml);
	};

	return (
		<div className={classes.container}>
			<div className={classes.richEditor}>
				<RichEditor2
					hasToggleToolbar
					toolbarHidden
					widgetKey={`${Resources.issue}${issueState?.Id}`}
					isDarkMode={darkMode}
					defaultValue={getFirstNotEmptyValue([issueState?.DescriptionHtml, issueState?.Description])}
					onChange={handleChangeDescription}
					maxLength={MaxLengthText}
					disabled={!!issueState?.Id}
				/>
			</div>
		</div>
	);
};
