import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};
export const Translation = {
	pl: {
		Kanban: {
			KanbanBoard: {
				KanbanContent: {
					EditKanbanContent: {
						TabsContent: {
							GeneralTab: {
								InnerEditList: {
									TaskListEdit: {
										TaskListHeader: {
											InEditHeader: {
												editColumn: 'Edytuj kolumnę',
												deleteColumn: 'Usuń kolumnę',
											},
										},
									},
								},
							},
						},
					},
				},
			},
		},
	},
	en: {
		Kanban: {
			KanbanBoard: {
				KanbanContent: {
					EditKanbanContent: {
						TabsContent: {
							GeneralTab: {
								InnerEditList: {
									TaskListEdit: {
										TaskListHeader: {
											InEditHeader: {
												editColumn: 'Edit column',
												deleteColumn: 'Delete column',
											},
										},
									},
								},
							},
						},
					},
				},
			},
		},
	},
};
