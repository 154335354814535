import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,

	path: {
		adminPanel: (id?: number) => (id ? `/tag/${id}` : `/tag`),
		tags: '/tag',
	},
};

export const Translation = {
	pl: {
		TagGroupInfo: {
			general: 'Ogólne',
			pageTitle: 'Szczegóły grupy tagów',
			tagGroupName: 'Nazwa grupy',
			createdBy: 'Stworzone przez',
			groupContext: 'Kontekst grupy',
			creationDate: 'Data stworzenia',
			isImmutable: 'Grupa jest niezmienna',
			goBack: 'Wróć',
			deleteFail: 'Nie udało się usunąć',
			deleteSuccess: 'Pomyślnie usunięto',
			abandonChanges: 'Porzuć zmiany',
			isActive: 'Czy aktywna',
			Delete: {
				title: 'Czy jesteś pewien?',
				subTitle: 'Grupa tagów może być usunięta tylko gdy nic tego nie używa.',
			},
		},
	},
	en: {
		TagGroupInfo: {
			general: 'General',
			pageTitle: 'Tag group details',
			tagGroupName: 'Group name',
			createdBy: 'Created by',
			groupContext: 'Group context',
			creationDate: 'Creation date',
			isImmutable: 'Group is immutable',
			goBack: 'Go back',
			deleteFail: 'Failed to delete',
			deleteSuccess: 'Successfully deleted',
			abandonChanges: 'Abandon the changes',
			isActive: 'Is active',
			Delete: {
				title: 'Are you sure?',
				subTitle: 'Tag group can be deleted if nothing using it.',
			},
		},
	},
};

export enum sortType {
	string = 'string',
	boolean = 'boolean',
	number = 'number',
	date = 'date',
}
