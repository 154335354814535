import { AsyncAction } from '@sac-rp/redux-promisify';
import { EBoolean } from '@sac-tt/tasker-types';
import { ICheckListValue } from '@sac-tt/tasker-types/dist/types/dataBaseTypes/checkListValue';
import { IWorkDayEndSummaryFilter } from '@sac-tt/tasker-types/dist/types/endPoints/workDayEndSummaryFilter';

import { IJson } from '@/store/ITypes';
import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

const basePath = 'CheckListValue';
const checkListValueReport = 'CheckListValueReport';

export const CHECKLIST_VALUE_CREATE: ActionType = 'CHECKLIST_VALUE_CREATE';
export const SET_COMPLETED_DAY: ActionType = 'SET_COMPLETED_DAY';
export const CHECKLIST_UPDATE_LOCAL_VALUES: ActionType = 'CHECKLIST_UPDATE_LOCAL_VALUES';
export const RESET_END_OF_WORK: ActionType = 'RESET_END_OF_WORK';
export const FETCH_ALL_CHECKLIST: ActionType = 'FETCH_ALL_CHECKLIST';
export const USER_HAS_ENDED_DAY: ActionType = 'USER_HAS_ENDED_DAY';

function _fetchAllCheckListValue(checkListValue: ICheckListValue): Action {
	return {
		type: FETCH_ALL_CHECKLIST,
		checkListValue: checkListValue,
	};
}

export function _checkListValueCreate(data: boolean): Action {
	return {
		type: CHECKLIST_VALUE_CREATE,
		data,
	};
}

function _user_HasEndedDay(data: EBoolean): Action {
	return {
		type: USER_HAS_ENDED_DAY,
		data,
	};
}

export function checkListValue_Create(data: ICheckListValue[], formId: number): AsyncAction {
	return function(context, dispatch) {
		context.then(data => dispatch(_checkListValueCreate((data as IJson).data)));
		return axios().post(`${basePath}`, { data, formId });
	};
}

export function setLocalValues(data: { [x: number]: ICheckListValue[] }): Action {
	return {
		type: CHECKLIST_UPDATE_LOCAL_VALUES,
		data,
	};
}

export function resetEndOfWork(): Action {
	return { type: RESET_END_OF_WORK };
}

export function fetch_all_active_checkListValue(
	from: number,
	count: number,
	data: IWorkDayEndSummaryFilter
): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchAllCheckListValue((json as IJson).data)));
		return axios().post(`${checkListValueReport}/from/${from}/count/${count}`, { ...data });
	};
}

export function fetch_page_active_checkListValue(
	from: number,
	count: number,
	data: IWorkDayEndSummaryFilter
): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchAllCheckListValue((json as IJson).data)));
		return axios().post(`${checkListValueReport}/from/${from}/count/${count}`, { ...data });
	};
}

export function userHasEndedDay(): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_user_HasEndedDay((json as IJson).data)));
		return axios().get(`${basePath}/isEndedDay`);
	};
}
