import { isRtl } from '@sac-tw2/tasker-widgets2/dist/utils';
import { getGlobalStyles } from '@sac-tw2/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { StyleProps } from './model';

export const useStyles = makeStyles<Theme, StyleProps>(
	(theme: Theme) => ({
		...getGlobalStyles(),
		container: {
			'@media all and (-ms-high-contrast:none)': {
				height: 0, // IE11 fix
			},
		},
		content: {
			paddingTop: 64,
			overflowX: 'unset',
			margin: theme.spacing(2, 2, 2, 2),
			maxWidth: 'calc(100% - 32px)',
			[theme.breakpoints.up('lg')]: {
				paddingRight: props => (isRtl() ? props.paddingLeft : 0),
				paddingLeft: props => (isRtl() ? 0 : props.paddingLeft),
			},
			[theme.breakpoints.down('xs')]: {
				paddingTop: 56,
			},
		},
		background: {
			backgroundColor: theme.palette.background.default,
		},
	}),
	{ name: `Dashboard` }
);
