import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		Settings: {
			General: {
				ProfileDetails: {
					Actions: {
						popup: {
							save: 'Zapisz',
							cancel: 'Anuluj',
						},
					},
				},
			},
		},
	},
	en: {
		Settings: {
			General: {
				ProfileDetails: {
					Actions: {
						popup: {
							save: 'Save',
							cancel: 'Cancel',
						},
					},
				},
			},
		},
	},
};
