import { EDictionaryItemType, IDictionary } from '@sac-tt/tasker-types';
import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	dictionaryDefault: {
		Name: '',
		Description: '',
		Id: -1,
		IsActive: true,
		DictionaryItem: [],
	} as IDictionary,
	non: '---',
};

export const Translation = {
	pl: {
		Dictionaries: {
			Modal: {
				Content: {
					Items: {
						type: 'Type wartości',
						noValue: 'Brak wartości',
						value: 'Wartość',
						actions: 'Akcje',
						active: 'Aktywny',
						inactive: 'Nieaktywny',
						isActive: 'Status',
						deleteIcon: 'Usuń przedmiot słownika',
						editIcon: 'Edytuj przedmiot słownika',
						[EDictionaryItemType.DateTime]: 'Data z czasem',
						[EDictionaryItemType.Date]: 'Data',
						[EDictionaryItemType.TagGroup]: 'Grupa tagów',
						[EDictionaryItemType.Decimal]: 'Liczba dziesiętna',
						[EDictionaryItemType.Integer]: 'Liczba całkowita',
						[EDictionaryItemType.Text]: 'Text',
						[EDictionaryItemType.Boolean]: 'Prawda/Fałsz',
						[EDictionaryItemType.Dictionary]: 'Słownik',
						true: 'Prawda',
						false: 'Fałsz',
						unset: 'Nie ustalono',
					},
				},
			},
		},
	},
	en: {
		Dictionaries: {
			Modal: {
				Content: {
					Items: {
						type: 'Type',
						noValue: 'No value',
						value: 'Value',
						actions: 'Actions',
						active: 'Active',
						inactive: 'Inactive',
						isActive: 'Status',
						deleteIcon: 'Delete dictionary items',
						editIcon: 'Edit dictionary items',
						[EDictionaryItemType.DateTime]: 'Datetime',
						[EDictionaryItemType.Date]: 'Date',
						[EDictionaryItemType.TagGroup]: 'Tag group',
						[EDictionaryItemType.Decimal]: 'Decimal',
						[EDictionaryItemType.Integer]: 'Integer',
						[EDictionaryItemType.Text]: 'Text',
						[EDictionaryItemType.Boolean]: 'Boolean',
						[EDictionaryItemType.Dictionary]: 'Dictionary',
						true: 'True',
						false: 'False',
						unset: 'Not set',
					},
				},
			},
		},
	},
};
