import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	column: 'column' as const,
	ctrl: 'Ctrl',
	alt: 'Alt',
	plus: '+',
	minus: '-',
	esc: 'Esc',
	enter: 'Enter',
	Shift: 'Shift',
	arrowRight: '→',
	arrowLeft: '←',
	one: 1,
	two: 2,
	three: 3,
	...GlobalResources,
};

export const Translation = {
	pl: {
		HotkeysPopover: {
			PlusToAdd: 'otwiera formularz zadania.',
			EscapeToClose: 'zamyka okienko.',
			EscapeToCancel: 'anuluje zmiany.',
			ArrowsToTime: 'zmiana okresu czasu.',
			EnterToSave: 'zapis wyników.',
			ArrowsToChangeIssue: 'zmiana zadania.',
			ArrowsToSwitch: 'przełączenie kart okna.',
			ArrowsToSwitchGroups: 'przełączenie grupy tagów.',
			kanbanBoard: 'Kanban',
			calendar: 'Kalendarz',
			issue: 'Detale zadania',
			time: 'Raport czasu',
			timeModal: 'Formularz dodawania czasu',
			dictionary: 'Słowniki',
			project: 'Dodawanie użytkowników do projektu',
			gant: 'Gant',
			or: 'lub',
			clock: 'Zegar',
			StartIssue: 'start ostatniego zadania',
			PauseIssue: 'pauza zadania',
			EndIssue: 'zakończenie zadania',
			ResizePlus: 'Zwiększ ilość linii w polach tekstowych',
			ResizeMinus: 'Zmniejsz ilość linii w polach tekstowych',
		},
	},
	en: {
		HotkeysPopover: {
			PlusToAdd: 'add an Issue.',
			EscapeToClose: 'close popup.',
			EscapeToCancel: 'cancel changes.',
			ArrowsToTime: 'change time period.',
			EnterToSave: 'save results.',
			ArrowsToChangeIssue: 'change issue.',
			ArrowsToSwitch: 'change popup options.',
			ArrowsToSwitchGroups: 'change tag groups.',
			kanbanBoard: 'Kanban',
			calendar: 'Calendar',
			time: 'Time report',
			timeModal: 'Time form',
			dictionary: 'Dictionary',
			issue: 'Issue Details',
			project: 'Add users to project',
			gant: 'Gant',
			or: 'or',
			clock: 'clock',
			StartIssue: 'start last issue',
			PauseIssue: 'pause issue',
			EndIssue: 'end issue',
			ResizePlus: 'Increase line amount in textfields',
			ResizeMinus: 'Reduce line amount in textfields',
		},
	},
};
