import { AsyncAction } from '@sac-rp/redux-promisify';
import { IFavouriteIssue, IIssue } from '@sac-tt/tasker-types';

import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

import { IJson } from '../ITypes';

const basePath = 'favouritesIssues';

export const ADD_ISSUE_TO_FAVOURITES: ActionType = 'ADD_ISSUE_TO_FAVOURITES';

export const SET_FAVOURITE_ISSUES_ORDER: ActionType = 'SET_FAVOURITE_ISSUES_ORDER';
export const REMOVE_ISSUE_FROM_FAVOURITES: ActionType = 'DELETE_ISSUE_FROM_FAVOURITES';

function _addIssueToFavourites(favouriteIssue: IFavouriteIssue): Action {
	return {
		type: ADD_ISSUE_TO_FAVOURITES,
		favouriteIssue,
	};
}

function _removeIssueFromFavourites(issueId: number): Action {
	return {
		type: REMOVE_ISSUE_FROM_FAVOURITES,
		issueId,
	};
}

function _setFavouriteIssuesOrder(issues: IIssue[]): Action {
	return {
		type: SET_FAVOURITE_ISSUES_ORDER,
		issues,
	};
}

export function add_issue_to_favourites(issueId: number): AsyncAction {
	return function(context, dispatch) {
		context.then(data => dispatch(_addIssueToFavourites((data as IJson).data)));
		return axios().post(`${basePath}/add/${issueId}`);
	};
}

export function remove_issue_from_favourites(issueId: number): AsyncAction {
	return function(context, dispatch) {
		context.then(() => dispatch(_removeIssueFromFavourites(issueId)));
		return axios().delete(`${basePath}/${issueId}`);
	};
}

export function set_favourite_issues_order(issues: IIssue[]): AsyncAction {
	return function(context, dispatch) {
		context.then(() => {
			dispatch(_setFavouriteIssuesOrder(issues));
		});
		return axios().post(`${basePath}/order`, { issues });
	};
}
