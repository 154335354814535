import { IFullFiltersIssueTextBy } from '@sac-tt/tasker-types';
import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const DEFAULT_SEARCH_BY: IFullFiltersIssueTextBy = {
	Title: true,
	DisplayId: true,
	Description: false,
	Comment: false,
	User: false,
};

export const Resources = {
	...GlobalResources,
	menuId: 'menuId',
	OptionsKey: {
		Title: 'Title',
		DisplayId: 'DisplayId',
		Description: 'Description',
		Comment: 'Comment',
		User: 'User',
	},
};

export const Translation = {
	pl: {
		SearchBar: {
			Search: {
				SearchBy: {
					Options: {
						title: 'Wyszukaj po tytule',
						displayId: 'Wyszukaj po id',
						description: 'Wyszukaj po opisie',
						comment: 'Wyszukaj po komentarzu',
						user: 'Wyszukaj po użytkowniku',
					},
				},
			},
		},
	},
	en: {
		SearchBar: {
			Search: {
				SearchBy: {
					Options: {
						title: 'Search by title',
						displayId: 'Search by id',
						description: 'Search by description',
						comment: 'Search by comment',
						user: 'Search by user',
					},
				},
			},
		},
	},
};
