import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	customers: '#/customers/',
	projectsPath: '/projects/',
};

export const Translation = {
	pl: {
		ProjectDetail: {
			ProjectNesting: {
				ProjectList: {
					pageTitle: 'Zarządzanie projektami',
					clientName: 'Klient',
					projectName: 'Nazwa projektu',
					creationDate: 'Data stworzenia',
					startDate: 'Data rozpoczęcia',
					endDate: 'Data zakończenia',
					managers: 'Menadżerowie',
					owner: 'Właściciel',
					customer: 'Klient',
					createdBy: 'Utworzony przez',
					addProjectToCustomer: 'Dodaj projekty do klienta',
					add: 'Dodaj projekt',
					addShort: 'Dodaj',
					openProject: 'Otwórz projekt',
					projectDeadline: 'Deadline',
					assignedTo: 'Przypisano do',
					delete: 'Usuń',
					members: 'Członkowie projektu',
				},
			},
		},
	},
	en: {
		ProjectDetail: {
			ProjectNesting: {
				ProjectList: {
					pageTitle: 'Project Management List',
					clientName: 'Client',
					projectName: 'Project name',
					creationDate: 'Creation date',
					startDate: 'Start date',
					endDate: 'End date',
					managers: 'Managers',
					owner: 'Owner',
					customer: 'Customer',
					createdBy: 'Created by',
					addProjectToCustomer: 'Add project to the customer',
					add: 'Add project',
					addShort: 'Add',
					openProject: 'Open project',
					projectStarted: 'Project started',
					details: 'Details',
					projectDeadline: 'Deadline',
					delete: 'Delete',
					members: 'Project members',
				},
			},
		},
	},
};
