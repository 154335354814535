import clsx from 'clsx';
import { addMinutes, differenceInMinutes, format } from 'date-fns';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { CellContext, ColumnDef } from '@tanstack/react-table';

import { ITaskerUser, IWorkLog } from '@sac-tt/tasker-types';
import { TooltipTextBase, VisibilityControl } from '@sac-tw2/tasker-widgets2';
import { EFormats, getFullNameNew } from '@sac-tw2/tasker-widgets2/dist/utils';

import { DeleteForever, Edit } from '@mui/icons-material';
import { Avatar, IconButton, Tooltip, Typography } from '@mui/material';

import { IReducerState } from '@/store/reducers/types';
import { useTrans } from '@/utils/hooks/useTrans';

import { getTimeInDefaultFormat } from 'view/TimeAssign/Content/utils';

import { Resources } from './resources';
import { useStyles } from './styles';

export const useTableData = (
	setWorkLogToEdit: (workLog: IWorkLog) => void,
	handleDelete: (workLogId: number) => void
) => {
	const classes = useStyles();
	const { t, tString } = useTrans('IssueModal.IssueModalContent.Worklogs');

	const loggedUser: Partial<ITaskerUser> = useSelector((state: IReducerState) => state.Session.taskerUser);
	const workLogsSelector: IWorkLog[] = useSelector(
		(state: IReducerState) => state?.IssueModalEditor?.workLogByIssue
	).sort((d1, d2) => new Date(d2.DateTimeFrom).getTime() - new Date(d1.DateTimeFrom).getTime());

	const columns: ColumnDef<IWorkLog, any>[] = useMemo(
		() => [
			{
				id: 'from',
				accessorFn: row => row,
				header: () => (
					<Typography
						className={clsx(classes.overflowHidden, classes.defaultCursor, classes.typographyCustom)}
						variant={Resources.options.variant}
					>
						{t('from')}
					</Typography>
				),
				size: 0.15,
				cell: (data: CellContext<IWorkLog, IWorkLog | undefined>) => {
					const workLog = data.getValue();

					let workLogString = '';
					if (workLog?.DateTimeFrom)
						workLogString = format(new Date(workLog.DateTimeFrom), EFormats.EDateFNS.DateWithTime24);

					return (
						<Typography
							className={clsx(classes.overflowHidden, classes.defaultCursor, classes.typographyCustom)}
							variant={Resources.GlobalResources.h5}
						>
							{workLogString}
						</Typography>
					);
				},
			},
			{
				id: 'to',
				accessorFn: row => row,
				header: () => (
					<Typography
						className={clsx(classes.overflowHidden, classes.defaultCursor, classes.typographyCustom)}
						variant={Resources.options.variant}
					>
						{t('to')}
					</Typography>
				),
				size: 0.15,
				cell: (data: CellContext<IWorkLog, IWorkLog | undefined>) => {
					const workLog = data.getValue();
					const isActive = !workLog?.DateTimeTo;

					let fromDate;
					let dateEnd;
					let dateEndString;

					if (workLog?.DateTimeFrom) fromDate = new Date(workLog.DateTimeFrom);
					if (fromDate) dateEnd = addMinutes(fromDate, workLog?.Minutes ?? 0);
					if (dateEnd) dateEndString = format(dateEnd, EFormats.EDateFNS.DateWithTime24);

					return (
						<Typography
							className={clsx(classes.overflowHidden, classes.defaultCursor, classes.typographyCustom)}
							variant={Resources.GlobalResources.h5}
						>
							<VisibilityControl
								condition={!isActive}
								alternative={
									<Typography className={classes.stillActiveText}>
										{t('worklogStillActive')}
									</Typography>
								}
							>
								{dateEndString}
							</VisibilityControl>
						</Typography>
					);
				},
			},
			{
				id: 'logged',
				accessorFn: row => row,
				header: () => (
					<Typography
						className={clsx(classes.overflowHidden, classes.defaultCursor, classes.typographyCustom)}
						variant={Resources.options.variant}
					>
						{t('logged')}
					</Typography>
				),
				size: 0.15,
				cell: (data: CellContext<IWorkLog, IWorkLog | undefined>) => {
					const workLog = data.getValue();
					const isActive = !workLog?.DateTimeTo;

					let fromDate;
					let since;

					if (workLog?.DateTimeFrom) fromDate = new Date(workLog.DateTimeFrom);
					if (fromDate) since = differenceInMinutes(new Date(), fromDate);

					return (
						<div className={classes.flexRow}>
							<Typography
								className={clsx(
									classes.overflowHidden,
									classes.defaultCursor,
									classes.flexRow,
									classes.typographyCustom
								)}
								variant={Resources.GlobalResources.h5}
							>
								<VisibilityControl
									condition={!isActive}
									alternative={
										<Typography className={classes.stillActiveText}>
											{`${since}${Resources.minutes}`}
										</Typography>
									}
								>
									{getTimeInDefaultFormat(workLog?.Minutes) || Resources.noMinutes}
								</VisibilityControl>
							</Typography>
						</div>
					);
				},
			},
			{
				id: 'user',
				accessorFn: row => row,
				header: () => (
					<Typography
						className={clsx(classes.overflowHidden, classes.defaultCursor, classes.typographyCustom)}
						variant={Resources.options.variant}
					>
						{t('user')}
					</Typography>
				),
				size: 0.1,
				cell: (data: CellContext<IWorkLog, IWorkLog | undefined>) => {
					const workLog = data.getValue();

					return (
						<div className={clsx(classes.overflowHidden, classes.flexRow)}>
							<Tooltip title={getFullNameNew(workLog?.TaskerUser)}>
								<Avatar
									src={workLog?.TaskerUser?.AvatarPath}
									className={clsx(classes.defaultCursor, classes.avatar)}
								/>
							</Tooltip>
						</div>
					);
				},
			},
			{
				id: 'creationDate',
				accessorFn: row => row,
				header: () => (
					<Typography
						className={clsx(classes.overflowHidden, classes.defaultCursor, classes.typographyCustom)}
						variant={Resources.options.variant}
					>
						{t('description')}
					</Typography>
				),
				size: 0.4,
				cell: (data: CellContext<IWorkLog, IWorkLog | undefined>) => {
					const workLog = data.getValue();

					return (
						<TooltipTextBase
							text={workLog?.Description || '-'}
							className={clsx(classes.overflowHidden, classes.fullWidth, classes.desc)}
						/>
					);
				},
			},
			{
				id: 'actions',
				accessorFn: row => row,
				header: '',
				size: 0.05,
				cell: (data: CellContext<IWorkLog, IWorkLog | undefined>) => {
					const workLog = data.getValue() as IWorkLog;
					const isActive = !workLog?.DateTimeTo;

					return (
						<VisibilityControl condition={loggedUser.Id == workLog?.TaskerUserId && !isActive}>
							<div
								className={clsx(
									classes.overflowHidden,
									classes.defaultCursor,
									classes.typographyCustom
								)}
							>
								<Tooltip title={tString('edit')}>
									<IconButton
										onClick={() => setWorkLogToEdit(workLog)}
										size={Resources.GlobalResources.small}
									>
										<Edit fontSize={Resources.GlobalResources.small} />
									</IconButton>
								</Tooltip>
								<Tooltip title={tString('delete')}>
									<IconButton
										onClick={() => handleDelete(workLog.Id)}
										size={Resources.GlobalResources.small}
									>
										<DeleteForever fontSize={Resources.GlobalResources.small} />
									</IconButton>
								</Tooltip>
							</div>
						</VisibilityControl>
					);
				},
			},
		],
		[t]
	);

	return { columns, data: workLogsSelector ?? [] };
};
