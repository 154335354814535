import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		Roles: {
			TabsContent: {
				RoleAssignment: {
					title: 'Przypisywanie ról w organizacji',
					description: 'Przypisuj uprawnienia poszczególnym użytkownikom w twojej organizacji.',
					user: 'Nazwa użytkownika',
					error: 'Wystąpił błąd podczas nadawania roli użytkownikowi. Spróbuj ponownie.',
					confirmSelf:
						'Czy na pewno chcesz zmienić swoją rolę? Może to spowodować utratę dostępu do niektórych funkcji.',
				},
			},
		},
	},
	en: {
		Roles: {
			TabsContent: {
				RoleAssignment: {
					title: 'Assigning roles in organization',
					description: 'Assign permissions to individual users in your organization.',
					user: 'Username',
					error: 'An error occurred while assigning a role to the user. Please try again.',
					confirmSelf:
						'Are you sure you want to change your role? This may result in loss of access to some features.',
				},
			},
		},
	},
};
