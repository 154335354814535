import { IExtendedWorkLog, IIssue, IValidationErrorV2, IWorkLog } from '@sac-tt/tasker-types';

import { EWorklogInputName, IWorklogForm } from './model';

export class WorklogDataMapper {
	static toWorklogForm(worklog: IWorkLog | null): IWorklogForm {
		return {
			DateTimeTo: worklog?.DateTimeTo,
			DateTimeFrom: worklog?.DateTimeFrom,
			Issue: worklog?.Issue as IIssue,
			Minutes: worklog?.Minutes,
			Description: worklog?.Description,
			Id: worklog?.Id,
		};
	}

	static toWorklogFromUpdateForm(
		formState: Partial<IWorklogForm>,
		initialWorklogFormValues: IWorklogForm
	): Partial<IExtendedWorkLog> {
		return {
			...formState,
			Id: initialWorklogFormValues.Id,
		};
	}

	static toWorklogFromCreateForm(
		formState: Partial<IWorklogForm>,
		WantToSetEndDateWithMinutes: boolean
	): Partial<IExtendedWorkLog> {
		return {
			...formState,
			WantToSetEndDateWithMinutes,
		};
	}

	static toWorklogFormErrors(errors: IValidationErrorV2[]): IValidationErrorV2[] {
		return errors?.map(error => {
			let inputName: string;

			switch (error.inputName) {
				case 'Description':
					inputName = EWorklogInputName.Description;
					break;

				case 'DateTimeFrom':
					inputName = EWorklogInputName.DateTimeFrom;
					break;

				case 'DateTimeTo':
					inputName = EWorklogInputName.DateTimeTo;
					break;

				case 'Minutes':
					inputName = EWorklogInputName.Minutes;
					break;

				default:
					inputName = '';
			}

			return { ...error, inputName };
		});
	}
}
