import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	issueIdPath: '?issueId=',
	projectPath: 'projects/',
	overview: '/overview',
};

export const Translation = {
	pl: {
		ProjectDetails: {
			ProjectUsersActiveTasksList: {
				headerType: 'Aktywne zadania',
				placeHolder: 'Szukaj',
				time: 'Czas pracy',
				project: 'Projekt',
				issue: 'Zgłoszenie',
				fullName: 'Pracownik',
				avatar: Resources.GlobalResources.empty,
			},
		},
	},
	en: {
		ProjectDetails: {
			ProjectUsersActiveTasksList: {
				headerType: 'Active tasks',
				placeHolder: 'Search',
				time: 'Work time',
				project: 'Project',
				issue: 'Issue',
				fullName: 'Employee',
				avatar: Resources.GlobalResources.empty,
			},
		},
	},
};
