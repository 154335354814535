import { AsyncAction } from '@sac-rp/redux-promisify';
import { IPasswordPolicy } from '@sac-tt/tasker-types';

import { IJson } from '@/store/ITypes';
import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

export const FETCH_PASSWORD_POLICIES: ActionType = 'FETCH_PASSWORD_POLICY';
export const FETCH_SHARED_PASSWORD_POLICIES: ActionType = 'FETCH_SHARED_PASSWORD_POLICY';
export const FETCH_PASSWORD_POLICY_REGEX: ActionType = 'FETCH_PASSWORD_POLICY_REGEX';
export const UPDATE_PASSWORD_POLICY: ActionType = 'UPDATE_PASSWORD_POLICY';
export const RECREATE_PASSWORDS: ActionType = 'RECREATE_PASSWORDS';
export const USER_MUST_CHANGE_PASS: ActionType = 'USER_MUST_CHANGE_PASS';

const basePath = 'passwordPolicy';

function _fetchPasswordPolicies(policies: IPasswordPolicy[]): Action {
	return {
		type: FETCH_PASSWORD_POLICIES,
		policies,
	};
}

function _fetchSharedPasswordPolicies(policies: IPasswordPolicy[]): Action {
	return {
		type: FETCH_SHARED_PASSWORD_POLICIES,
		policies,
	};
}

function _fetchPasswordPolicyRegex(regex: string): Action {
	return {
		type: FETCH_PASSWORD_POLICIES,
		regex,
	};
}

function _updatePasswordPolicy(policies: IPasswordPolicy[]): Action {
	return {
		type: UPDATE_PASSWORD_POLICY,
		policies,
	};
}

function _reqNewPasswords(): Action {
	return {
		type: RECREATE_PASSWORDS,
	};
}

function _userMustChangePass(must: boolean): Action {
	return {
		type: USER_MUST_CHANGE_PASS,
		data: must,
	};
}

export function get_PasswordPolicies(): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchPasswordPolicies((json as IJson).data)));
		return axios().get(basePath);
	};
}

export function get_SharedPasswordPolicies(userId: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchSharedPasswordPolicies((json as IJson).data)));
		return axios().get(`${basePath}/shared/${userId}`);
	};
}

export function get_PasswordPolicyRegex(): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchPasswordPolicyRegex((json as IJson).data)));
		return axios().get(`${basePath}/regex`);
	};
}

export function update_PasswordPolicy(policies: Partial<IPasswordPolicy>[]): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_updatePasswordPolicy((json as IJson).data)));
		return axios().patch(basePath, policies);
	};
}

export function request_ChangePasswords(): AsyncAction {
	return function(context, dispatch) {
		context.then(() => dispatch(_reqNewPasswords()));
		return axios().post(`${basePath}/request/changePassword`);
	};
}

export function userMustChangePassword(): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_userMustChangePass((json as IJson).data)));
		return axios().get(`${basePath}/mustChange`);
	};
}
