import { getGlobalStyles } from '@sac-tw2/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(
	(theme: Theme) => {
		return {
			...getGlobalStyles(),
			container: { width: '100%' },
			buttonsContainer: { '& :not(:first-child)': { marginLeft: theme.spacing(1) } },
			errorButton: {
				backgroundColor: theme.palette.red.light,
				color: theme.palette.white.light,

				'&:hover': { backgroundColor: theme.palette.red.light },
			},
			restoreButton: {
				backgroundColor: theme.palette.success.main,
				color: theme.palette.white.light,

				'&:hover': { backgroundColor: theme.palette.success.light },
			},
		};
	},
	{ name: `IssueModal_IssueModalActions` }
);
