import { EFieldDefinitionType, ICreateFieldDefinition, ICreateIssueFormSection } from '@sac-tt/tasker-types';
import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	formFieldKey: 'formFieldKey_',
	type: 'FieldDefinitionTypeId' as keyof ICreateFieldDefinition,
	isMandatory: 'IsMandatory' as keyof ICreateFieldDefinition,
	isActive: 'IsActive' as keyof ICreateFieldDefinition,
	name: 'Name' as keyof ICreateFieldDefinition,
	dictionary: 'DictionaryId' as keyof ICreateFieldDefinition,
	tagGroup: 'TagGroupId' as keyof ICreateFieldDefinition,
	warning: 'warning' as const,

	key: 'Key_Section_',
	dash: '_',
	fieldSectionBase: {
		Name: '',
		FieldDefinition: [],
		SectionChildren: [],
	} as ICreateIssueFormSection,
};

export const Translation = {
	pl: {
		ProjectDetails: {
			Form: {
				IssueForm: {
					New: {
						IssueFormGroup: {
							IssueFormCreate: {
								FieldDefinitionTypeId: EFieldDefinitionType.Text,
								Name: 'Nowy rekord',
								Description: 'Opis',
								DescriptionHtml: 'Opis',
							} as ICreateFieldDefinition,
							addRecord: 'Dodaj rekord',
							groupName: 'Nazwa grupy',
							deleteIcon: 'Usuń grupę',
							addGroupIcon: 'Dodaj grupę',
							deleteConfirmation: 'Czy na pewno chcesz usunąć ten rekord?',
						},
					},
				},
			},
		},
	},
	en: {
		ProjectDetails: {
			Form: {
				IssueForm: {
					New: {
						IssueFormGroup: {
							IssueFormCreate: {
								FieldDefinitionTypeId: EFieldDefinitionType.Text,
								Name: 'New record',
								Description: 'Description',
								DescriptionHtml: 'Description',
							} as ICreateFieldDefinition,
							addRecord: 'Add record',
							groupName: 'Group name',
							deleteIcon: 'Delete group',
							addGroupIcon: 'Add group',
							deleteConfirmation: 'Are you sure you want to delete this record?',
						},
					},
				},
			},
		},
	},
};
