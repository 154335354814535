import { getGlobalStyles } from '@sac-tw2/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(
	(theme: Theme) => ({
		...getGlobalStyles(),
		tab: {
			fontSize: '13px',
			padding: '12px 18px',
			textTransform: 'none',
			minHeight: '0px',
		},
		tabSelected: { color: theme.palette.green.light },
		tabNotSelected: { color: theme.palette.darkGray.contrastText },
		tabList: {
			minHeight: '0px',

			'& .MuiTabs-indicator': {
				backgroundColor: '#33B35C',
				height: '3px',
			},
		},
		divider: {
			height: '20px',
			borderWidth: '0px',
			borderColor: 'rgba(0,0,0,0.5)',
			alignSelf: 'center',
		},
		tabIcon: {
			marginRight: theme.spacing(0.5),
		},
		tabIconSelected: {
			color: theme.palette.green.light,
		},
		iconRotated: {
			transform: 'rotate(180deg)',
		},
	}),
	{ name: `IssueModal_IssueModalContent_Tabs` }
);
