import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		Reckon: {
			Agreements: {
				AgreementDetails: {
					personContractedLabel: 'Osoba zakontraktowana',
					agreementLabel: 'Umowa',
					noSalaries: 'Brak wynagrodzeń ... ',
				},
			},
		},
	},
	en: {
		Reckon: {
			Agreements: {
				AgreementDetails: {
					personContractedLabel: 'Contracted person',
					agreementLabel: 'Agreement',
					noSalaries: 'No salaries ...',
				},
			},
		},
	},
};
