import { IRole, ITaskerObject } from '@sac-tt/tasker-types';

import * as actionTypes from '@/store/actions';
import { ObjectAction } from '@/store/types';

export const State = {
	roles: [] as IRole[],
	permissionObjects: [] as ITaskerObject[],
};
export type IPermissionPanelEditorState = typeof State;

const permissionPanelReducer = (state = State, action: ObjectAction): IPermissionPanelEditorState => {
	switch (action?.type) {
		case actionTypes.FETCH_ALL_ROLES_NEW: {
			return {
				...state,
				roles: action.roles,
			};
		}
		case actionTypes.SET_PROJECT_USERS: {
			return {
				...state,
				roles: action.roles,
			};
		}
		case actionTypes.FETCH_ROLE_PERMISSIONS:
		case actionTypes.FETCH_ALL_PERMISSIONS_NEW: {
			return {
				...state,
				permissionObjects: action.permissions,
			};
		}
		default: {
			return state;
		}
	}
};

export default permissionPanelReducer;
