import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	Index: {
		RecoverPassword: {
			link: '/auth/recover',
		},
	},
	...GlobalResources,
};

export const Translation = {
	pl: {
		Login: {
			Index: {
				pageTitle: 'Panel Logowania',
				panelHeader: 'Panel Logowania',
				rememberMe: 'Zapamiętaj mnie',
			},
		},
	},
	en: {
		Login: {
			Index: {
				pageTitle: 'Sign In Panel',
				panelHeader: 'Sign In Panel',
				rememberMe: 'Remember me',
			},
		},
	},
};
