import clsx from 'clsx';
import { format, formatDistanceStrict } from 'date-fns';
import parse from 'html-react-parser';
import { useEffect, useRef } from 'react';

import { ENotificationPriority } from '@sac-tt/tasker-types';
import { VisibilityControl } from '@sac-tw2/tasker-widgets2';
import { EFormats } from '@sac-tw2/tasker-widgets2/dist/utils';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import DraftsIcon from '@mui/icons-material/Drafts';
import { Avatar, IconButton, Tooltip, Typography } from '@mui/material';

import { useTrans } from '@/utils/hooks/useTrans';
import { getFullNameNew } from '@/utils/user';

import { Props } from './model';
import { Resources } from './resources';
import { useStyles } from './styles';

export const Notification = (props: Props) => {
	const { notification, setSingleNotificationAsRead, closeModal } = props;
	const classes = useStyles();
	const cardRef = useRef<HTMLDivElement>(null);

	const { t, i18n } = useTrans('NotificationPopover.Notification');

	const getNotificationPriorityColor = () => {
		switch (notification.NotificationPriorityId) {
			case ENotificationPriority.Low:
				return classes.lowPriorityColor;
			case ENotificationPriority.Medium:
				return classes.mediumPriorityColor;
			case ENotificationPriority.High:
				return classes.highPriorityColor;
			case ENotificationPriority.Critical:
				return classes.criticalPriorityColor;
		}
	};

	const getTooltipOfPriorityIcon = () => {
		switch (notification.NotificationPriorityId) {
			case ENotificationPriority.Low:
				return t('lowPriority');
			case ENotificationPriority.Medium:
				return t('mediumPriority');
			case ENotificationPriority.High:
				return t('highPriority');
			case ENotificationPriority.Critical:
				return t('criticalPriority');
		}
	};

	const handleClickOnLink = () => {
		if (notification?.UserNotificationSystemInfo?.HasBeenRead) return;
		setSingleNotificationAsRead(notification?.Id);
		closeModal();
	};

	useEffect(() => {
		cardRef.current?.querySelectorAll(Resources.linkClasses).forEach(elem => {
			elem.addEventListener(Resources.click, handleClickOnLink);
		});

		return () => {
			cardRef.current?.querySelectorAll(Resources.linkClasses).forEach(elem => {
				elem.removeEventListener(Resources.click, handleClickOnLink);
			});
		};
	}, [cardRef]);

	return (
		<div className={classes.card} ref={cardRef}>
			<VisibilityControl condition={!notification?.UserNotificationSystemInfo?.HasBeenRead}>
				<Tooltip title={<span>{t('markAsRead')}</span>}>
					<IconButton
						className={classes.markAsReadIcon}
						onClick={() => setSingleNotificationAsRead(notification.Id)}
					>
						<DraftsIcon fontSize={Resources.GlobalResources.small} />
					</IconButton>
				</Tooltip>
			</VisibilityControl>
			<div className={clsx(classes.flexRow, classes.notificationContent)}>
				<div className={classes.avatarColumn}>
					<Tooltip title={getFullNameNew(notification?.CreatedByUser)}>
						<Avatar
							src={notification?.CreatedByUser?.AvatarPath}
							sx={{ width: 36, height: 36 }}
							variant={Resources.rounded}
							className={classes.avatar}
						/>
					</Tooltip>
					<div className={clsx(classes.notificationPriority, getNotificationPriorityColor())} />
				</div>

				<div>
					<div className={clsx(classes.flexYCenter, classes.notificationHeader, classes.flexRow)}>
						<Typography className={classes.notificationHeader} variant={Resources.GlobalResources.body2}>
							{getFullNameNew(notification?.CreatedByUser)}
						</Typography>
						<div className={classes.headerSeparator}></div>
						<div className={clsx(classes.newDateColor, classes.flexYCenter)}>
							<Tooltip
								title={
									notification?.CreatedDateTime
										? format(
												new Date(notification?.CreatedDateTime),
												EFormats.EDateFNS.DateWithTime24
										  )
										: Resources.GlobalResources.empty
								}
							>
								<AccessTimeIcon fontSize={Resources.GlobalResources.small} />
							</Tooltip>
							<Typography className={classes.newDate} variant={Resources.GlobalResources.body2}>
								{notification?.QueuedDateTime &&
									formatDistanceStrict(new Date(notification?.QueuedDateTime), new Date(), {
										locale: i18n.t('GlobalResourcesLanguage.language'),
										addSuffix: true,
									})}
							</Typography>
						</div>
						<div className={classes.headerSeparator}></div>
						<Tooltip title={getTooltipOfPriorityIcon() ?? Resources.GlobalResources.empty}>
							<BookmarkIcon
								className={getNotificationPriorityColor()}
								fontSize={Resources.GlobalResources.small}
							/>
						</Tooltip>
					</div>
					<span className={classes.notificationMessage}>
						{parse(notification?.notificationMessage ?? Resources.GlobalResources.empty)}
					</span>
				</div>
			</div>
		</div>
	);
};
