import { IHoliday, IPage } from '@sac-tt/tasker-types';

import * as actionTypes from '@/store/actions';
import { ObjectAction } from '@/store/types';

const State = {
	data: {} as IPage<IHoliday[]>,
};
export type IHolidayEventListState = typeof State;

const holidayEventListReducer = (state = State, action: ObjectAction): IHolidayEventListState => {
	switch (action.type) {
		case actionTypes.HOLIDAYS_FETCH: {
			return {
				...state,
				data: action.holidays,
			};
		}
		default: {
			return state;
		}
	}
};

export default holidayEventListReducer;
