import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	redirect: {
		general: '/settings/general',
		notFound: '/errors/error-404',
	},
	tabs: {
		general: { value: 'general' },
		security: { value: 'security' },
		preferences: { value: 'preferences' },
		widgetsConfiguration: { value: 'widgetsConfiguration' },
		organization: { value: 'organization' },
	},
};

export const Translation = {
	pl: {
		Settings: {
			Header: { type: 'Ustawienia Profilu' },
			tabs: {
				general: { label: 'Ogólne' },
				security: { label: 'Zabezpieczenia' },
				preferences: { label: 'Preferencje użytkownika' },
				widgetsConfiguration: { label: 'Konfiguracja widżetów' },
				organization: { label: 'Organizacja' },
			},
			pageTitle: 'Ustawienia',
		},
	},
	en: {
		Settings: {
			Header: { type: 'Profile Preferences' },
			tabs: {
				general: { label: 'General' },
				security: { label: 'Security' },
				preferences: { label: 'Preferences' },
				widgetsConfiguration: { label: 'Widgets configuration' },
				organization: { label: 'Organization' },
			},
			pageTitle: 'Settings',
		},
	},
};

export const tabs = [
	Resources.tabs.general,
	Resources.tabs.security,
	Resources.tabs.preferences,
	Resources.tabs.widgetsConfiguration,
	Resources.tabs.organization,
];
