import clsx from 'clsx';

import { IIssue } from '@sac-tt/tasker-types';
import { TextWidget } from '@sac-tw2/tasker-widgets2/dist/widgets/TextWidget';

import { Typography } from '@mui/material';

import { Resources } from '../resources';

import { Props } from './model';
import { useStyles } from './styles';

export const IssueHeader = (props: Props): JSX.Element => {
	const { issue, onOpen } = props;
	const classes = useStyles();

	const getCustomTitle = (issue: IIssue) => {
		const issueNumber = issue?.DisplayId ? `#${issue?.DisplayId}` : Resources.GlobalResources.empty;
		return (
			<Typography className={classes.issueNumber} color={Resources.GlobalResources.textSecondary}>
				{issueNumber}
			</Typography>
		);
	};

	return (
		<div className={clsx(classes.overflowAuto, classes.maxOfWidth, classes.flexRow, classes.header)}>
			<div className={clsx(classes.flexColumn, classes.overflowHidden, classes.maxOfWidth, classes.column)}>
				<div className={clsx(classes.flexYCenter, classes.pointerCursor)} onClick={onOpen}>
					{getCustomTitle(issue)}
					<TextWidget
						hover
						text={issue?.Title ?? Resources.GlobalResources.empty}
						className={clsx(
							classes.pointerCursor,
							classes.overflowHidden,
							classes.maxOfWidth,
							classes.issueTitle
						)}
					/>
				</div>
			</div>
		</div>
	);
};
