import { ICreateIssueFormSection } from '@sac-tt/tasker-types';
import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	fieldSectionBase: {
		Name: '',
		FieldDefinition: [],
		SectionChildren: [],
	} as ICreateIssueFormSection,
	key: 'issueForm_Main_Section',
	dash: '_',
};
export const Translation = {
	pl: {
		OrganizationPanel: {
			EndWorkDay: {
				EndWorkDayCreate: {
					CreateForm: {
						keyLabel: 'Klucz',
						firstStep: 'Konfiguracja formularza',
						secondStep: 'Konfiguracja powiadomień',
						thirdStep: 'Przypisywanie użytkowników',
						finish: 'Zapisz',
						next: 'Następny krok',
						back: 'Wróć',
						formCardTitle: 'Formularz zgłoszeniowy',
						pageTitle: 'Formularz',
						Header: {
							formPropertiesTitle: 'Konfiguracja właściwości formularza',
							subTitleNew: 'Nowy formularz',
						},
						createForm: 'Stwórz formularz',
						saveForm: 'Zapisz',
						cancel: 'Anuluj',
						addGroupIcon: 'Dodaj grupę',
						addRecord: 'Dodaj rekord',
						youShallNotPass: 'Aby przejść dalej musisz uzupełnić ten krok',
						youShallNotPassSame: 'Niemożna przejść na ten sam krok',
					},
				},
			},
		},
	},
	en: {
		OrganizationPanel: {
			EndWorkDay: {
				EndWorkDayCreate: {
					CreateForm: {
						keyLabel: 'Key',
						firstStep: 'Configure form',
						secondStep: 'Configure notification',
						thirdStep: 'Assign users',
						finish: 'Finish',
						next: 'Next',
						back: 'Back',
						formCardTitle: 'Issue form',
						pageTitle: 'Form',
						Header: {
							formPropertiesTitle: 'Configuration form properties',
							subTitleNew: 'New form',
						},
						createForm: 'Create form',
						saveForm: 'Save',
						cancel: 'Cancel',
						addGroupIcon: 'Dodaj grupę',
						addRecord: 'Add record',
						youShallNotPass: 'In case of going forward you have to complete this step.',
						youShallNotPassSame: 'Can not go to same step as is current on',
					},
				},
			},
		},
	},
};
