import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	defaultColor: '#33b35c',
	kanbanImagePath: 'images/undraw_scrum_board_re_wk7v.svg',
	...GlobalResources,
};

export const Translation = {
	pl: {
		Kanban: {
			KanbanBoard: {
				KanbanContent: {
					ColumnsKanbanContent: {
						SwimlaneConfiguration: {
							SwimlaneConfigurationDrawer: {
								SwimlaneCreationModal: {
									title: 'Kreator swimlane',
									subtext:
										'Swtórz oraz skonfigurj nowy swimlane aby jeszcze lepiej zarządzać zadaniami',
									altKanbanImage: 'Zdjęcie kanbana',
									name: 'Nazwa',
									nameInputError: 'Pole musie zawierać od 1 do 50 znaków',
									color: 'Kolor',
									save: 'Zapisz',
									close: 'Zamknij',
								},
							},
						},
					},
				},
			},
		},
	},
	en: {
		Kanban: {
			KanbanBoard: {
				KanbanContent: {
					ColumnsKanbanContent: {
						SwimlaneConfiguration: {
							SwimlaneConfigurationDrawer: {
								SwimlaneCreationModal: {
									altKanbanImage: 'Kanban image',
									name: 'Name',
									nameInputError: 'This field must contain from 1 to 50 characters',
									color: 'Color',
									save: 'Save',
									close: 'Close',
								},
							},
						},
					},
				},
			},
		},
	},
};
