import { getGlobalStyles } from '@sac-tw2/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(
	(theme: Theme) => ({
		...getGlobalStyles(),
		icon: { fontSize: theme.spacing(2.5) },
		url: {
			display: 'flex',
			width: theme.spacing(3.25),
			height: theme.spacing(3.25),
		},
		label: {
			marginLeft: theme.spacing(1),
			maxWidth: theme.spacing(18.75),
		},
		container: {
			border: `1px solid ${theme.palette.grey[300]}`,
			backgroundColor: theme.palette.background.default,
			padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
			borderRadius: theme.spacing(1),
			marginBottom: theme.spacing(1),
		},
	}),
	{ name: `IssueModal_IssueModalTitle_MoreOptions_SocialMediaLinks.SocialMediaLink` }
);
