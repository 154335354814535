import uuid from 'uuid';

import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	settlementGroupSummaryKey: (key: string) => `SettlementGroupSummary_${uuid()}_${key}`,
};

export const Translation = {
	pl: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationSettlement: {
						SettlementTable: {
							SettlementTableGroupRowNew: {
								country_settlement: 'Rozliczenie kraju',
							},
						},
					},
				},
			},
		},
	},
	en: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationSettlement: {
						SettlementTable: {
							SettlementTableGroupRowNew: {
								country_settlement: 'Country settlement',
							},
						},
					},
				},
			},
		},
	},
};
