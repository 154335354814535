import { differenceBy } from 'lodash';

import {
	EFieldDefinitionType,
	IComplexUpdate,
	IErrorValidationV2,
	IIssue,
	ITaskerUser,
	IValidationErrorV2,
} from '@sac-tt/tasker-types';
import { IIssueFormSection } from '@sac-tt/tasker-types/dist/types/dataBaseTypes/issueFormSection';

import { IErrorV3 } from '@/store/ITypes';

import {
	EIssueModalDetailsPageFields,
	IIssueModalData,
} from 'view/IssueModal/IssueModalContent/IssueDetailsPage/model';
import { IExtendedFieldValue } from 'view/IssueModal/modal';

const getRequiredFieldsFromSection = (data?: IIssueFormSection): number[] => {
	let ids: number[] = [];
	for (let i = 0; i < (data?.IssueFormSectionToIssueField?.length ?? 0); i++) {
		if (data?.IssueFormSectionToIssueField) {
			const subData = data?.IssueFormSectionToIssueField[i];
			if (subData?.AttributeDefinition?.IsMandatory && subData?.AttributeDefinitionId)
				ids.push(subData?.AttributeDefinitionId);
		}
	}

	for (let i = 0; i < (data?.IssueFormSection?.length ?? 0); i++) {
		if (data?.IssueFormSection) ids = ids.concat(getRequiredFieldsFromSection(data?.IssueFormSection[i]));
	}
	return ids;
};

export const getChangesInUsers = (
	oldUsers: Partial<ITaskerUser>[],
	newUsers: Partial<ITaskerUser>[]
): IComplexUpdate => {
	const oldUsersIds = oldUsers?.map(user => user?.Id) ?? [];
	const newUsersIds = newUsers?.map(user => user?.Id) ?? [];

	const addedUsersIds = differenceBy(newUsersIds, oldUsersIds);
	const deletedUsersIds = differenceBy(oldUsersIds, newUsersIds);

	return { addItems: addedUsersIds, deleteItems: deletedUsersIds };
};

export const mapInitialStateToIssueState = (issue: Partial<IIssue>): IIssueModalData => {
	return {
		Id: issue?.Id,
		Assigned: issue?.Members?.Assigned ?? [],
		Observer: issue?.Members?.Observer ?? [],
		Auditor: issue?.Members?.Auditor ?? [],
		Reporter: issue?.Members?.Reporter ?? [],
		DescriptionHtml: issue?.DescriptionHtml ?? '',
		Description: issue?.Description ?? '',
		ProjectId: issue?.ProjectId ?? null,
		Title: issue?.Title ?? '',
		ProjectIssueTypeId: issue?.ProjectIssueTypeId ?? null,
		Properties: {
			[EFieldDefinitionType.Boolean]: {},
			[EFieldDefinitionType.Date]: {},
			[EFieldDefinitionType.Decimal]: {},
			[EFieldDefinitionType.Integer]: {},
			[EFieldDefinitionType.Text]: {},
			[EFieldDefinitionType.TagGroup]: {},
			[EFieldDefinitionType.DateTime]: {},
			[EFieldDefinitionType.Dictionary]: {},
			[EFieldDefinitionType.TextArea]: {},
		},
	};
};

export const formatErrors = (error: IErrorV3): IErrorValidationV2<EIssueModalDetailsPageFields>[] => {
	return error?.response?.data?.message?.map((e: IValidationErrorV2) => ({
		...e,
		inputName: e.inputName.replace('issue.', '') as EIssueModalDetailsPageFields,
	}));
};

export const mapFieldValueToDelete = (fieldValue: Partial<IExtendedFieldValue>) => {
	return {
		Id: fieldValue.Id,
		ValueTagId: fieldValue?.ValueTagId,
		AttributeDefinitionId: fieldValue?.AttributeDefinitionId,
	};
};
