import * as Joi from 'joi';
import { Dispatch, SetStateAction } from 'react';

import { IDelegationToAttachment, IFileTypeInfo } from '@sac-tt/tasker-types';

import { EDropzoneFileStatus } from './DropzoneAttachmentRecord/model';

export interface Props<T extends { DisableFunctions?: boolean }> {
	baseProps: IDropzoneBaseProps<T>;
	handlers: IDropzoneOnDropHandlers<T> | IDropzoneAttachmentFormHandlers<T>;
	classes?: {
		tile?: string;
		icon?: string;
		iconButton?: string;
		dropZoneArea?: string;
		dropZoneAreaIcon?: string;
		dropzoneContent?: string;
		dropzoneLabel?: string;
	};
	dropZonePlacement?: EDropzonePlacement;
}

export interface IDropzoneBaseProps<T> {
	attachments: T[];
	setAttachments: Dispatch<SetStateAction<T[]>>;
	isEdit: boolean;
	isDisabled?: boolean;
	allowedFiletypes: IFileTypeInfo;
	maxFilesize: number;
}

export interface IDropzoneBaseHandlers<T> {
	getFilename: (attachment: T) => string;
	getAttachmentId: (attachment: T) => number;
	onRemove: (attachment: T) => void;
}

export enum EDropzoneType {
	Drop,
	AttachmentForm,
}

export enum EDropzonePlacement {
	Top = 1,
	Bottom = 2,
}

export interface IDropzoneOnDropHandlers<T> extends IDropzoneBaseHandlers<T> {
	type?: EDropzoneType.Drop;
	onDrop: (attachments: T[]) => void;
}

export interface IDropzoneAttachmentFormHandlers<T> extends IDropzoneBaseHandlers<T> {
	type?: EDropzoneType.AttachmentForm;
	renderForm: (attachment: T, index: number) => React.ReactNode;
	renderFormInfo: (attachment: T, index: number) => React.ReactNode;
	onFormSubmit: (attachment: T, index: number, setUploadProgress: Dispatch<SetStateAction<number>>) => void;
	onFormValidate: (attachment: T) => Joi.ValidationError | undefined;
}

export interface IDropzoneAttachment {
	file: File | null;
	fileLocalId: string;
	fileStatus: EDropzoneFileStatus;
	collapsed: boolean;
	formReady: boolean;
}

export type IDelegationAttachment = IDropzoneAttachment & IDelegationToAttachment;
