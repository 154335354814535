import * as H from 'history';
import { useHistory } from 'react-router';

import { useModal } from '@sac-tw2/tasker-widgets2';

import { ModalType } from '@/utils/modals';

import { IssueHeader } from 'view/IssueList/IssueHeader';

import { Props } from './model';
import { Resources } from './resources';

export const TileIssueHeader = (props: Props): JSX.Element => {
	const { issue, isPortal, onClick } = props;
	const history = useHistory();

	const context = useModal(ModalType.Issue);

	const handleOpenAdd = (id: number, history: H.History): void => {
		if (onClick) onClick();
		history.push(`${history?.location?.pathname}${isPortal ? Resources.portalIssueId : Resources.issueId}${id}`);
		// context.open();
	};

	return <IssueHeader issue={issue} onOpen={() => handleOpenAdd(issue.Id, history)} />;
};

export default TileIssueHeader;
