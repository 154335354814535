import { EEmployeeAgreementSalaryPeriod, ESalaryType, EEmployeeAgreementType } from '@sac-tt/tasker-types';
import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		Reckon: {
			Agreements: {
				Shared: {
					agreementType: {
						[EEmployeeAgreementType.serviceAgreement]: 'Umowa o świadczenie usługi',
						[EEmployeeAgreementType.employmentContract]: 'Umowa o pracę',
						[EEmployeeAgreementType.managementContract]: 'Kontrakt managerski',
						[EEmployeeAgreementType.cooperationAgreement]: 'Umowa o współpracy handlowej',
						[EEmployeeAgreementType.agencyAgreement]: 'Umowa agencyjna',
						[EEmployeeAgreementType.copyrightTransferAgreement]: 'Umowa z przekazaniem praw autorskich',
						[EEmployeeAgreementType.mandateContract]: 'Umowa zlecenie',
						[EEmployeeAgreementType.commissionContract]: 'Umowa z prowizją',
					},
					salaryType: {
						[ESalaryType.hourlySalary]: 'Wynagrodzenie godzinowe',
						[ESalaryType.fixSalary]: 'Wynagrodzenie stałe',
					},
					salaryPeriod: {
						[EEmployeeAgreementSalaryPeriod.annual]: 'Roczne',
						[EEmployeeAgreementSalaryPeriod.monthly]: 'Miesięczne',
					},
					agreementFields: {
						agreementStatus: 'Status umowy',
						agreementNo: 'Numer umowy',
						agreementType: 'Typ umowy',
						isForIndefinitePeriod: 'Czy na czas nieokreślony',
						startDate: 'Data rozpoczęcia',
						endDate: 'Data zakończenia',
						signedDate: 'Data zawarcia',
						agreementCreatTime: 'Umowę dodano',
						agreementCreatedBy: 'Umowę dodał(a)',
					},
					salaryFields: {
						salaryType: 'Typ wynagrodzenia',
						salaryPeriod: 'Okres wynagrodzenia',
						salaryDescription: 'Opis wynagrodzenia',
						salaryPerHour: 'Wynagrodzenie za godzinę',
						weeklyHours: 'Liczba godzin w tygodniu',
						startDate: 'Data rozpoczęcia',
						endDate: 'Data zakończenia',
						salary100PercentLabelHourlySalaryType: 'Wynagrodzenie dla 100% etatu',
						salary100PercentLabelFixedSalaryType: 'Wynagrodzenie',
						effectiveSalary: 'Rzeczywiste wynagrodzenie',
						currency: 'Waluta wynagrodzenia',
					},
					agreementStatusTypes: {
						expired: 'Umowy wygasłe',
						forIndefinitePeriod: 'Umowy na czas nieokreślony',
						lessThanTwoMonthstoExpire: 'Umowy ważne mniej niż 60 dni, ale więcej niż 30 dni',
						lessThanMonthtoExpire: 'Umowy ważne mniej niż 30 dni',
						moreThanTwoMonthsToExpire: 'Umowy ważne więcej niż 60 dni',
						isAboutToStart: 'Dopiero zacznie obowiązywać',
					},
					untilTheEndOfAgreement: 'Do zakończenia umowy',
				},
			},
		},
	},
	en: {
		Reckon: {
			Agreements: {
				Shared: {
					serviceAgreement: 'Service Agreement',
					employmentContract: 'Employment Contract',
					managementContract: 'Management Contract',
					cooperationAgreement: 'Cooperation Agreement',
					agencyAgreement: 'Agency Agreement',
					copyrightTransferAgreement: 'Copyright Transfer Agreement',
					mandateContract: 'Mandate Contract',
					commissionContract: 'Commission Contract',
					agreementType: {
						[EEmployeeAgreementType.serviceAgreement]: 'Service Agreement',
						[EEmployeeAgreementType.employmentContract]: 'Employment Contract',
						[EEmployeeAgreementType.managementContract]: 'Management Contract',
						[EEmployeeAgreementType.cooperationAgreement]: 'Cooperation Agreement',
						[EEmployeeAgreementType.agencyAgreement]: 'Agency Agreement',
						[EEmployeeAgreementType.copyrightTransferAgreement]: 'Copyright Transfer Agreement',
						[EEmployeeAgreementType.mandateContract]: 'Mandate Contract',
						[EEmployeeAgreementType.commissionContract]: 'Commission Contract',
					},
					salaryType: {
						[ESalaryType.hourlySalary]: 'Hourly salary',
						[ESalaryType.fixSalary]: 'Fixed salary',
					},
					salaryPeriod: {
						[EEmployeeAgreementSalaryPeriod.annual]: 'Annual',
						[EEmployeeAgreementSalaryPeriod.monthly]: 'Monthly',
					},
					agreementFields: {
						agreementStatus: 'Agreement status',
						agreementNo: 'Agreement No.',
						agreementType: 'Agreement type',
						isForIndefinitePeriod: 'Is for indefinite period',
						startDate: 'Start date',
						endDate: 'End date',
						signedDate: 'Signed date',
						agreementCreatTime: 'Created at',
						agreementCreatedBy: 'Created by',
					},
					salaryFields: {
						salaryType: 'Salary type',
						salaryPeriod: 'Salary period',
						salaryPerHour: 'Salary per hour',
						salaryDescription: 'Salary description',
						weeklyHours: 'Weekly hours',
						startDate: 'Start date',
						endDate: 'End date',
						salary100PercentLabelHourlySalaryType: 'Salary for full-time employment',
						salary100PercentLabelFixedSalaryType: 'Salary',
						effectiveSalary: 'Actual salary',
						currency: 'Salary currency',
					},
					agreementStatusTypes: {
						expired: 'Expired agreements',
						forIndefinitePeriod: 'Agreements for indefinite period',
						lessThanTwoMonthstoExpire: 'Agreements valid for less than 60 days but more than 30 days',
						lessThanMonthtoExpire: 'Agreements valid for less than 30 days',
						moreThanTwoMonthsToExpire: 'Agreements valid for more than 2 months',
						isAboutToStart: 'The agreement is yet to take effect',
					},
					untilTheEndOfAgreement: 'Until the end of the contract',
				},
			},
		},
	},
};
