import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		Reckon: {
			Agreements: {
				AgreementEdit: {
					SalaryForm: {
						NavSection: {
							salaryAmount: 'Suma wynagrodzenia',
							summary: 'Podsumowanie',
							goBack: 'Cofnij',
							save: 'Zapisz',
							goNext: 'Dalej',
						},
					},
				},
			},
		},
	},
	en: {
		Reckon: {
			Agreements: {
				AgreementEdit: {
					SalaryForm: {
						NavSection: {
							salaryAmount: 'Salary amount',
							summary: 'Summary',
							goBack: 'Back',
							save: 'Save',
							goNext: 'Continue',
						},
					},
				},
			},
		},
	},
};
