import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	backendPath: `${process?.env?.REACT_APP_BACKEND_ADDRESS}/`,
	websocket: 'websocket',
	favIconId: 'favicon',
	favIconBasePath: '/favIconNotReadNotifications-',
	png: '.png',
	moreThan9: '9+',
	basicFavIcon: '/favicon2',
	...GlobalResources,
};

export const Translation = {
	pl: {},
	en: {},
};
