import { IHoliday, ILeaveRequest, ITaskerUser } from '@sac-tt/tasker-types';
import { IDataCalendar } from '@sac-tt/tasker-types/dist/types/custom/leaveRequestCalendar';

import * as actionTypes from '@/store/actions';
import { ObjectAction } from '@/store/types';

const State = {
	calendarLeaveRequest: [] as ILeaveRequest[],
	holidays: [] as IHoliday[],
	taskerUserOptions: [] as ITaskerUser[],
};
export type ILeaveRequestCalendarState = typeof State;

const leaveRequestCalendarReducer = (state = State, action: ObjectAction): ILeaveRequestCalendarState => {
	switch (action.type) {
		case actionTypes.CALENDAR_WORKLOG_FETCH: {
			return {
				...state,
				calendarLeaveRequest: action?.worklogs?.WorkLogs,
				holidays: action?.worklogs?.Holiday,
			};
		}
		case actionTypes.CALENDAR_USERS_FETCH: {
			return {
				...state,
				taskerUserOptions: action?.users,
			};
		}
		case actionTypes.CALENDAR_WORKLOG_CREATE: {
			return {
				...state,
				calendarLeaveRequest: [...state?.calendarLeaveRequest, action?.data],
			};
		}
		case actionTypes.LEAVE_REQUEST_CALENDAR_FETCH: {
			const data: IDataCalendar<ILeaveRequest[]> = action?.data;
			return {
				...state,
				calendarLeaveRequest: data?.Data,
				holidays: data?.Holiday ?? [],
			};
		}
		case actionTypes.LEAVE_REQUEST_CREATE_OR_UPDATE_SINGLE: {
			let isAdded = false;
			const data = state?.calendarLeaveRequest?.map(x => {
				if (x?.Id === action?.data?.Id) {
					isAdded = true;
					return action?.data;
				}
				return x;
			});
			if (!isAdded) data.push(action?.data);

			return {
				...state,
				calendarLeaveRequest: data,
			};
		}
		default: {
			return state;
		}
	}
};

export default leaveRequestCalendarReducer;
