const issueField = 'issue';
const startDate = 'startDate';
const dueDate = 'dueDate';

import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	defaultSubCollapseState: true,
	TableRowKeys: {
		9: 'TableRow9',
		8: 'TableRow8',
		7: 'TableRow7',
		6: 'TableRow6',
	},
	IssueFilter: {
		StartDate: {
			Begin: {
				id: 'startDateBegin',
				subSubField: 'begin',
				subField: startDate,
				field: issueField,
			},
			End: {
				id: 'startDateEnd',
				subSubField: 'end',
				subField: startDate,
				field: issueField,
			},
		},
		DueDate: {
			Begin: {
				id: 'dueDateBegin',
				subSubField: 'begin',
				subField: dueDate,
				field: issueField,
			},
			End: {
				id: 'dueDateEnd',
				subSubField: 'end',
				subField: dueDate,
				field: issueField,
			},
		},
		AssignedTo: {
			field: issueField,
			subField: 'assignedTo',
		},
		ReportedBy: {
			field: issueField,
			subField: 'reportedBy',
		},
	},
};

export const Translation = {
	pl: {
		SearchBar: {
			Filter: {
				IssueTabFilter: {
					IssueFilter: {
						StartDate: {
							name: 'Data rozpoczęcia',
							Begin: {
								label: 'Początek',
							},
							End: {
								label: 'Koniec',
							},
						},
						DueDate: {
							name: 'Data zakończenia',
							Begin: {
								label: 'Początek',
							},
							End: {
								label: 'Koniec',
							},
						}, //8
						AssignedTo: {
							label: 'Przypisano do',
						}, //16
						ReportedBy: {
							label: 'Stworzone przez',
						},
					},
				},
			},
		},
	},
	en: {
		SearchBar: {
			Filter: {
				IssueTabFilter: {
					IssueFilter: {
						StartDate: {
							name: 'Start Date',
							Begin: {
								label: 'Begin',
							},
							End: {
								label: 'End',
							},
						},
						DueDate: {
							name: 'Due Date',
							Begin: {
								label: 'Begin',
							},
							End: {
								label: 'End',
							},
						},
						AssignedTo: {
							label: 'Assigned to',
						},
						ReportedBy: {
							label: 'Reported by',
						},
					},
				},
			},
		},
	},
};
