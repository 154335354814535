import { getGlobalStyles } from '@sac-tw2/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const fitContent = 'fit-content';

export const useStyles = makeStyles(
	(theme: Theme) => ({
		...getGlobalStyles(),
		column: {
			width: fitContent,
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
		},
		issueTitle: {
			fontWeight: 500,
			letterSpacing: theme.spacing(-0.0075),
			width: fitContent,
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
		},
		issueNumber: {
			marginRight: theme.spacing(0.625),
			fontSize: theme.spacing(2),
		},
		header: {
			flex: 1,
			// width: 240,
			width: fitContent,
			minWidth: '10%',
			[theme.breakpoints.down('sm')]: {
				marginBottom: theme.spacing(2),
				flexBasis: '100%',
			},
		},
	}),
	{ name: `IssueList_IssueHeader` }
);
