import { IDictionary } from '@sac-tt/tasker-types';
import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	dictionaryDefault: {
		Name: '',
		Description: '',
		Id: -1,
		IsActive: true,
		DictionaryItem: [],
	} as IDictionary,
};

export const Translation = {
	pl: {
		Dictionaries: {
			Modal: {
				Content: {
					Tabs: {
						General: 'Ogólne',
						Items: 'Rekordy słownika',
					},
				},
			},
		},
	},
	en: {
		Dictionaries: {
			Modal: {
				Content: {
					Tabs: {
						General: 'General',
						Items: 'Items',
					},
				},
			},
		},
	},
};
