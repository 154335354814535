import { ILeaveType, IPage } from '@sac-tt/tasker-types';

import * as actionTypes from '@/store/actions';
import { ObjectAction } from '@/store/types';

const State = {
	page: { Data: [], Count: 0 } as IPage<ILeaveType[]>,
	leaveType: {} as Partial<ILeaveType>,
};
export type ILeaveTypeState = typeof State;

const leaveTypeReducer = (state = State, action: ObjectAction): ILeaveTypeState => {
	switch (action.type) {
		case actionTypes.LEAVE_TYPE_FETCH_PAGE: {
			return {
				...state,
				page: action?.data,
			};
		}
		case actionTypes.LEAVE_TYPE_FETCH_SINGLE: {
			return {
				...state,
				leaveType: action?.data,
			};
		}
		case actionTypes.LEAVE_TYPE_DELETE: {
			return {
				...state,
				page: {
					Data: state?.page?.Data?.filter(x => x?.Id != action?.data),
					Count: state?.page?.Count - 1,
				},
			};
		}
		default: {
			return state;
		}
	}
};

export default leaveTypeReducer;
