import { ICustomer, IProject } from '@sac-tt/tasker-types';

import * as actionTypes from '@/store/actions';
import { ObjectAction } from '@/store/types';

const State = {
	projects: [] as IProject[],
	customers: [] as ICustomer[],
};
export type IReportsState = typeof State;

const reportsReducer = (state = State, action: ObjectAction): IReportsState => {
	switch (action.type) {
		case actionTypes.FETCH_ALL_PROJECTS: {
			return {
				...state,
				projects: action.projects,
			};
		}
		case actionTypes.GANT_CUSTOMER_FETCH: {
			return {
				...state,
				customers: action.customers,
			};
		}
		default: {
			return state;
		}
	}
};

export default reportsReducer;
