import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	icon: 'dc.svg',
	url: 'https://discord.com/api/oauth2/authorize?client_id=1113401032819626024&permissions=139586948160&scope=bot',
};

export const Translation = {
	pl: {
		OrganizationPanel: {
			TabsContent: {
				Integration: {
					TabsContent: {
						Discord: {
							100015: 'Status',
							100016: 'Status',
							firstStepHeader: 'Krok 1: Aktywacja integracji',
							firstStepDescription: 'Aby aktywować lub dezaktywować integrację, kliknij ikonę poniżej.',
							enabled: 'Integracja aktywowana',
							disabled: 'Integracja dezaktywowana',

							secondStepHeader: 'Krok 2: Zaproszenie na serwer',
							secondStepDescription1:
								'Zaproszenie na serwer jest konieczne aby można było przesyłać wiadomości.',
							secondStepDescription2: 'Musisz również nadać uprawnienia użytkownikowi na swoim serwerze.',
							secondStepDescription3:
								'Aby sprawdzić poprawność integracji sprawdź czy istnieje na twoim serwerze użytkownik Tasker Bot.',
							authorize: 'Zaproś',

							stepPending: ' - Oczekuje na zakończenie',
							stepCompleted: ' - Zakończono',
							successSave: 'Zapisano dane integracji z aplikacji Discord',
						},
					},
				},
			},
		},
	},
	en: {
		OrganizationPanel: {
			TabsContent: {
				Integration: {
					TabsContent: {
						Discord: {
							100015: 'Status',
							100016: 'Status',
							firstStepHeader: 'Step 1: Activation of the integration',
							firstStepDescription: 'To activate or deactivate the integration, click the icon below.',
							enabled: 'Integration activated',
							disabled: 'Integration dis activated',

							secondStepHeader: 'Step 2: Invitation',
							secondStepDescription1: 'We need to invite user in order to write messages.',
							thirdStepDescription2: 'Also you need to give him permissions to channels.',
							secondStepDescription3:
								'In order to check if its configured check that in your Discord exists user Tasker Bot.',
							authorize: 'Invite',

							stepPending: ' - Pending',
							stepCompleted: ' - Completed',
							successSave: 'Saved integration data from Discord',
						},
					},
				},
			},
		},
	},
};
