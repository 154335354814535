import { getGlobalStyles } from '@sac-tw2/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(
	(theme: Theme) => ({
		...getGlobalStyles(),

		starIcon: {
			color: 'red',
			fontSize: '10px',
			alignSelf: 'flex-start',
			marginRight: theme.spacing(0.2),
			marginLeft: 'auto',
		},

		text: {
			marginRight: theme.spacing(1),
		},
	}),

	{ name: `IssueModal_IssueModalActions_FieldsRequiredHelperText` }
);
