import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	VC: 'VC',
	...GlobalResources,
};

export const Translation = {
	pl: {
		Dashboard: {
			NavBar: {
				DrawerContentResized: {
					signOut: 'Wyloguj',
				},
			},
		},
	},
	en: {
		Dashboard: {
			NavBar: {
				DrawerContentResized: {
					signOut: 'Sign out',
				},
			},
		},
	},
};
