import { AsyncAction } from '@sac-rp/redux-promisify';
import { IDictionary, IDictionaryItem, IPage } from '@sac-tt/tasker-types';

import { IJson } from '@/store/ITypes';
import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

export const SET_DICTIONARY_PAGE: ActionType = 'SET_DICTIONARY_PAGE';
export const SET_ONE_DICTIONARY: ActionType = 'SET_ONE_DICTIONARY';
export const CREATE_DICTIONARY_NEW_WITH_DEFAULTVALUES: ActionType = 'CREATE_DICTIONARY_NEW_WITH_DEFAULTVALUES';
export const CREATE_DICTIONARYITEM_NEW: ActionType = 'CREATE_DICTIONARYITEM_NEW';
export const CREATE_DICTIONARYWITHITEM: ActionType = 'CREATE_DICTIONARYWITHITEM';
export const DELETE_DICTIONARY_WITH_DEFAULTVALUES: ActionType = 'DELETE_DICTIONARY_WITH_DEFAULTVALUES';
export const DELETE_DICTIONARYITEMS_WITH_DEFAULTVALUES: ActionType = 'DELETE_DICTIONARYITEMS_WITH_DEFAULTVALUES';
export const GET_ALL_DICTIONARIES_AND_TAG_GROUPS: ActionType = 'GET_ALL_DICTIONARIES_AND_TAG_GROUPS';
export const GET_ALL_DICTIONARIES: ActionType = 'GET_ALL_DICTIONARIES';
export const GET_DELEGATION_DICTIONARIES: ActionType = 'GET_DELEGATION_DICTIONARIES';

export const SET_DICTIONARY_ITEMS: ActionType = 'SET_DICTIONARY_ITEMS';
export const CLEAR_ALL_DICTIONARYITEMS: ActionType = 'CLEAR_ALL_DICTIONARYITEMS';
export const MANAGE_DICTIONARY: ActionType = 'MANAGE_DICTIONARY';

const basePathD = 'dictionary';
const basePathDI = 'dictionaryItem';

function _setDictionaryPage(data: IPage<IDictionary[]>): Action {
	return {
		type: SET_DICTIONARY_PAGE,
		dictionaries: data.Data,
		count: data.Count,
	};
}

function _manage(): Action {
	return {
		type: MANAGE_DICTIONARY,
	};
}

function _createDictionaryWithItems(data: IDictionary): Action {
	return {
		type: CREATE_DICTIONARYWITHITEM,
		data: data,
	};
}

function _setDictionaryOne(dictionary: IDictionary): Action {
	return {
		type: SET_ONE_DICTIONARY,
		dictionary,
	};
}

function _getDictionaries(dictionaries: IDictionary[]): Action {
	return {
		type: GET_ALL_DICTIONARIES,
		data: dictionaries,
	};
}

function _setDictionaryItems(data: IPage<IDictionaryItem[]>) {
	return {
		type: SET_DICTIONARY_ITEMS,
		dictionaryItems: data?.Data,
		dictionaryItemsCount: data?.Count,
	};
}

function _getDelegationDictionaries(data: { Budget: IDictionary; Project: IDictionary }) {
	return {
		type: GET_DELEGATION_DICTIONARIES,
		data,
	};
}

export function create_dictionary_withDefaultValues_NEW(dictionary: Partial<IDictionary>): Action {
	return {
		type: CREATE_DICTIONARY_NEW_WITH_DEFAULTVALUES,
		dictionary: dictionary,
	};
}

export function delete_dictionary_withDefaultValues_NEW(): Action {
	return {
		type: DELETE_DICTIONARY_WITH_DEFAULTVALUES,
	};
}

export function _get_All_Dictionaries_And_TagGroups(data: any): Action {
	return {
		type: GET_ALL_DICTIONARIES_AND_TAG_GROUPS,
		allDictionariesIdAndLabel: data.allDictionariesIdAndLabel,
		allTagGroupsIdAndLabel: data.allTagGroupsIdAndLabel,
	};
}

export function create_dictionaryItem_withDefaultValues(dictionaryItem: Partial<IDictionaryItem>): Action {
	return {
		type: CREATE_DICTIONARYITEM_NEW,
		dictionaryItem: dictionaryItem,
	};
}

export function delete_dictionaryItems_withDefaultValues_NEW(): Action {
	return {
		type: DELETE_DICTIONARYITEMS_WITH_DEFAULTVALUES,
	};
}

export function clear_all_dictionaryItems(): Action {
	return {
		type: CLEAR_ALL_DICTIONARYITEMS,
	};
}

export function fetch_dictionary_page(from: number, count: number, name?: string): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_setDictionaryPage((json as IJson).data)));
		return axios().post(`${basePathD}/from/${from}/count/${count}`, { name });
	};
}

export function fetch_dictionary_one(id: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_setDictionaryOne((json as IJson).data)));
		return axios().post(`${basePathD}/${id}`);
	};
}

export function update_dictionary_NEW(
	dictionary: Partial<IDictionary>,
	from: number,
	count: number,
	filterBySearchValue: string
): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_setDictionaryPage((json as IJson).data)));
		return axios().patch(`${basePathD}/${dictionary.Id}`, {
			dictionary: dictionary,
			skip: from,
			take: count,
			filterBySearchValue,
		});
	};
}

export function menageDictionary(dictionary: Partial<IDictionary>): AsyncAction {
	return function(context, dispatch) {
		context.then(() => dispatch(_manage()));
		return axios().post(`${basePathD}/menage`, dictionary);
	};
}

export function create_dictionary_NEW(
	dictionary: Partial<IDictionary>,
	from: number,
	count: number,
	filterBySearchValue: string
): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_setDictionaryPage((json as IJson).data)));
		return axios().post(basePathD, {
			dictionary: dictionary,
			skip: from,
			take: count,
			filterBySearchValue,
		});
	};
}

export function delete_dictionary_NEW(
	id: number,
	from: number,
	count: number,
	filterBySearchValue: string
): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_setDictionaryPage((json as IJson).data)));
		return axios().delete(`${basePathD}/${id}`, { data: { skip: from, take: count, filterBySearchValue } });
	};
}

export function get_All_Dictionaries_And_TagGroups(dictionaryId: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_get_All_Dictionaries_And_TagGroups((json as IJson).data)));
		return axios().post(`${basePathD}/dictionariesAndTagGroups`, { dictionaryId });
	};
}

export function create_dictionaryItem_NEW(
	dictionaryItem: Partial<IDictionaryItem>,
	skip: number,
	take: number,
	filterBySearchValue: string
): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_setDictionaryItems((json as IJson).data)));
		return axios().post(`${basePathDI}`, { dictionaryItem, filterBySearchValue, skip, take });
	};
}

export function update_dictionaryItem_NEW(
	dictionaryItem: Partial<IDictionaryItem>,
	skip: number,
	take: number,
	filterBySearchValue?: string
): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_setDictionaryItems((json as IJson).data)));
		return axios().patch(`${basePathDI}/${dictionaryItem.Id}`, { dictionaryItem, filterBySearchValue, skip, take });
	};
}

export function delete_dictionaryItem_NEW(
	dictionaryItemId: number,
	skip: number,
	take: number,
	filterBySearchValue: string
): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_setDictionaryItems((json as IJson).data)));
		return axios().delete(`${basePathDI}/${dictionaryItemId}`, { data: { filterBySearchValue, skip, take } });
	};
}

export function get_dictionaries(): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_getDictionaries((json as IJson).data)));
		return axios().get(basePathD);
	};
}

export function create_dictionary(dictionary: Partial<IDictionary>, items: string[]): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_createDictionaryWithItems((json as IJson).data)));
		return axios().post(`${basePathD}/WithItems`, { dictionary: dictionary, items: items });
	};
}

export function get_dictionary_items(
	from: number,
	count: number,
	dictionaryId: number,
	searchValue: string
): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_setDictionaryItems((json as IJson).data)));
		return axios().post(`${basePathDI}/from/${from}/count/${count}`, { dictionaryId, searchValue });
	};
}

export function get_delegation_dictionaries(): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_getDelegationDictionaries((json as IJson).data)));
		return axios().get(`${basePathD}/Delegation`);
	};
}
