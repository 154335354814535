import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		Reckon: {
			Agreements: {
				AgreementEdit: {
					FieldsRequiredHelperText: {
						fieldsRequired: 'Pola wymagane',
					},
				},
			},
		},
	},
	en: {
		Reckon: {
			Agreements: {
				AgreementEdit: {
					FieldsRequiredHelperText: {
						fieldsRequired: 'Fields required',
					},
				},
			},
		},
	},
};
