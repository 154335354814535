import { format } from 'date-fns';

import { EFormats } from '@sac-tw2/tasker-widgets2/dist/utils';

import { Tooltip, Typography } from '@mui/material';

import { useTrans } from '@/utils/hooks/useTrans';

import { Props } from './model';
import { Resources } from './resources';
import { useStyles } from './style';

export const TileCreationDate = (props: Props): JSX.Element => {
	const { issue } = props;
	const classes = useStyles();
	const { t } = useTrans('IssueList.TileComponents.CreationDate');

	return (
		<div>
			<Tooltip
				className={classes.tooltip}
				placement={Resources.GlobalResources.bottom}
				title={
					<div>
						{issue?.CreatedDateTime &&
							format(
								new Date(issue?.CreatedDateTime ?? Resources.GlobalResources.empty),
								EFormats.EDateFNS.DateWithoutTime
							)}
					</div>
				}
			>
				<Typography variant={Resources.GlobalResources.h6}>
					{issue?.CreatedDateTime &&
						format(
							new Date(issue?.CreatedDateTime ?? Resources.GlobalResources.empty),
							EFormats.EDateFNS.DateWithoutTime
						)}
				</Typography>
			</Tooltip>
			<Typography className={classes.defaultCursor} variant={Resources.GlobalResources.body2}>
				{t('creationDate')}
			</Typography>
		</div>
	);
};

export default TileCreationDate;
