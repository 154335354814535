import clsx from 'clsx';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { isRtl } from '@sac-tw2/tasker-widgets2/dist/utils';

import { Drawer, Hidden } from '@mui/material';

import { DrawerContent } from '@/layouts/Dashboard/NavBar/DrawerContent';
import { reset_session_metadata } from '@/store/actions';
import { IReducerState } from '@/store/reducers/types';
import { headerHeightInString, NavBarWidth, NavBarWidthOnlyIcon } from '@/utils/configuration';
import { CheckRoles } from '@/utils/permissions';

import { NavSections } from '../NavConfig';

import { DrawerContent as DrawerContentV2 } from './DrawerContentResized';
import { Props, StyleProps } from './model';
import { Resources } from './resources';
import { useStyles } from './styles';

export const NavBar = (props: Props): JSX.Element => {
	const { openMobile, onMobileClose, className } = props;
	const dispatch = useDispatch();
	const location = useLocation();

	const session = useSelector((state: IReducerState) => state.Session);
	const navBarState = useSelector((state: IReducerState) => state.Common.sideBarOpen);

	const handleLogout = () => {
		dispatch(reset_session_metadata());
	};

	const styleProps: StyleProps = {
		maxWidth: navBarState ? NavBarWidthOnlyIcon : NavBarWidth,
		navBarHeight: headerHeightInString,
	};

	const classes = useStyles(styleProps);

	const sections = NavSections.map(navSection => ({
		...navSection,
		items: navSection.items.filter(navSectionItem => {
			return (
				navSectionItem.permissions?.reduce((curr, next) => {
					return CheckRoles(session.permissions, next) && curr;
				}, true as boolean) ?? true
			);
		}),
	}))?.filter(x => x.items.length > 0);

	useEffect(() => {
		if (openMobile && onMobileClose) onMobileClose();
	}, [location.pathname]);

	return (
		<>
			<Hidden lgUp>
				<Drawer
					anchor={isRtl() ? 'right' : 'left'}
					variant={Resources.temporary}
					open={openMobile}
					onClose={onMobileClose}
					classes={{ paper: classes.mobileDrawer }}
				>
					<DrawerContent className={className} onLogOut={handleLogout} sections={sections} />
				</Drawer>
			</Hidden>
			<Hidden lgDown>
				<div>
					<Drawer
						open
						anchor={isRtl() ? 'right' : 'left'}
						variant={Resources.persistent}
						PaperProps={{ style: { zIndex: Resources.GlobalResources.auto } }}
						classes={{ paper: clsx(classes.desktopDrawer, classes.overflowHidden) }}
					>
						<DrawerContentV2 className={className} onLogOut={handleLogout} sections={sections} />
					</Drawer>
				</div>
			</Hidden>
		</>
	);
};
