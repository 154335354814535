import { IIssue, IIssueIssueModal } from '@sac-tt/tasker-types';
import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	issueId: '?issueId=',
	unitsInterval: ['minutes'],
	listWeek: 'listWeek',
	backgroundColor: 'backgroundColor',
	dayGridMonth: 'dayGridMonth',
	digit2: '2-digit' as const,
	background: 'background',
	red: 'rgba(255,0,0, 0.6)',
	redString: 'red',
	blueString: 'blue',
	greenString: 'green',
	plSmall: 'pl',
	pl: 'PL-PL',
	invalidDate: 'Invalid Date',
	dimensionCalendar: 'calc(100% - 20px)',
	clearIssue: {
		Issue: {} as IIssue,
		Comments: [],
		UserWorkLog: [],
		IsPortalUser: false,
	} as IIssueIssueModal,
	dash: '-',
	...GlobalResources,
};

export const Translation = {
	pl: {
		LeaveRequestCalendar: {
			calendar: 'Kalendarz',
			timeIsToShort: 'Czas zakończenia zdarzenia musi zachodzić po rozpoczęciu.',
			idNotExists:
				'Brak możliwości edycji tego rekordu z powodu braku wpisu w bazie danych lub błędu połączenia.',
			timeEstimate: 'Czas: ',
			wrongUserDay: 'Nie jesteś właścicielem tego czasu pracy. Więc nie możesz go zmienić.',
			TimeFormat: {
				hours: 'Godzin',
				hour: 'Godzina',
				minute: 'Minuta',
				minutes: 'Minut',
			},
			leaveRequestDelete: 'Usunięto urlop',
			errorLeaveRequestDelete: 'Wystąpił błąd podczas usuwania urlopu',
			confirmDelete: 'Czy na pewno chcesz usunąć ten urlop?',
		},
	},
	en: {
		LeaveRequestCalendar: {
			calendar: 'Calendar ',
			timeIsToShort: 'End date have to be after start date.',
			idNotExists: 'No possibility edit record because no data in database or connection error.',
			timeEstimate: 'Time: ',
			wrongUserDay: "You are not owner of this work time record. So u can't change it.",
			TimeFormat: {
				hours: 'Hours',
				hour: 'Hour',
				minute: 'Minute',
				minutes: 'Minutes',
			},
			leaveRequestDelete: 'LeaveRequest deleted',
			errorLeaveRequestDelete: 'Error while deleting leave request',
			confirmDelete: 'Do you really want to delete this leave?',
		},
	},
};

export enum CalendarView {
	timeGridWeek = 'timeGridWeek',
	dayGridMonth = 'dayGridMonth',
}

export enum DateRange {
	start = 'start',
	end = 'end',
}

export const calendarDefaultFormatObject = {
	hour: Resources.digit2,
	minute: Resources.digit2,
	hour12: false,
};

export enum EDay {
	Monday = 1,
	Tuesday = 2,
	Wednesday = 3,
	Thursday = 4,
	Friday = 5,
	Saturday = 6,
	Sunday = 0,
}
