import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	caption: 'caption' as const,
};

export const Translation = {
	pl: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationElements: {
						DelegationPerDiem: {
							PerDiemRow: {
								PerDiemHeader: {
									tooltipSelectAllRow: 'Zaznacz/odznacz wszystkie wartości w kraju',
									tooltipReverseRow: 'Odwróć wartości w kraju',
									legendDay: 'Dzień',
								},
							},
						},
					},
				},
			},
		},
	},
	en: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationElements: {
						DelegationPerDiem: {
							PerDiemRow: {
								PerDiemHeader: {
									tooltipSelectAllRow: 'Select/deselect all values in the country',
									tooltipReverseRow: 'Reverse values in the country',
									legendDay: 'Day',
								},
							},
						},
					},
				},
			},
		},
	},
};
