import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	dots: ': ',
	comma: ', ',
};

export const Translation = {
	pl: {
		ProjectDetails: {
			ProjectMembersConfiguration: {
				CommonCells: {
					UserActiveProjectsCell: {
						SingleProject: {
							projectRoles: 'Role projektowe w projekcie ',
						},
					},
				},
			},
		},
	},
	en: {
		ProjectDetails: {
			ProjectMembersConfiguration: {
				CommonCells: {
					UserActiveProjectsCell: {
						SingleProject: {
							projectRoles: 'Project roles in project ',
						},
					},
				},
			},
		},
	},
};
