import { ITag, ITagGroup, ITagGroupContext, ITaskerObject } from '@sac-tt/tasker-types';

import * as actionTypes from '@/store/actions';
import { ObjectAction } from '@/store/types';
import { applyUpdate } from '@/utils/reducer';

export const State = {
	tagGroups: [] as ITagGroup[],
	countTagGroups: 0 as number,
	tagGroupContexts: [] as ITagGroupContext[],

	groups: [] as ITagGroup[],
	kanbanTagGroups: [] as ITagGroup[],
	groupsNoMultiple: {} as { [x: number]: ITagGroup },
	tags: {} as { [x: number]: ITag },
	portalInfoTags: {} as { [x: number]: ITag },
	portalFormTags: {} as { [x: number]: ITag },
	issueTypeTags: [] as ITag[],

	groupsNew: {} as { [x: number]: ITagGroup },
	contextTagsNew: [] as ITaskerObject[],
	tagsByGroupId: {} as { [x: number]: ITag },
	singleTagGroup: {} as ITagGroup,
};
export type ITagAdminPanelEditorState = typeof State;

const tagAdminPanelReducer = (state = State, action: ObjectAction): ITagAdminPanelEditorState => {
	switch (action?.type) {
		case actionTypes.TAG_GROUP_CONTEXTS_FETCH: {
			return {
				...state,
				tagGroupContexts: action.contexts,
			};
		}
		case actionTypes.FETCH_TAG_GROUPS_WITH_TAGS: {
			// let groups: ITagGroup[] = [];
			//
			// if (action.clearTagGroups) groups = [...action.groups];
			// else groups = [...state.tagGroups, ...action.groups];
			//
			// return {
			// 	...state,
			// 	tagGroups: groups,
			// 	countTagGroups: action.count,
			// };
			return {
				...state,
				tagGroups: action?.groups,
				countTagGroups: action?.count,
			};
		}
		case actionTypes.TAG_GROUP_FETCH: {
			return {
				...state,
				groups: action.groups,
			};
		}
		case actionTypes.PORTAL_INFO_TAGS: {
			return {
				...state,
				portalInfoTags: action.tags,
			};
		}

		case actionTypes.FETCH_TAG_GROUPS_WITH_TAGS_NO_MULTIPLE:
		case actionTypes.FETCH_TAG_CLOCK_GROUPS_WITH_TAGS: {
			return {
				...state,
				groupsNoMultiple: action.groups,
			};
		}
		case actionTypes.FETCH_TAG_GROUPS_BY_CONTEXT: {
			return {
				...state,
				kanbanTagGroups: action.groups,
			};
		}
		case actionTypes.GET_SINGLE_TAG_GROUP: {
			return {
				...state,
				singleTagGroup: action.group,
			};
		}
		case actionTypes.TAG_UPDATE: {
			return {
				...state,
				tags: applyUpdate(action.tag, state.tags),
			};
		}
		case actionTypes.TAG_FETCH: {
			return {
				...state,
				tags: action.tags,
			};
		}
		case actionTypes.PORTAL_FORM_TAGS: {
			return {
				...state,
				portalFormTags: action.tags,
			};
		}
		case actionTypes.TAG_GROUP_FETCH_NEW: {
			return {
				...state,
				groupsNew: action.groups,
			};
		}
		// case actionTypes.ISSUE_MODAL_FETCH_BASIC: {
		// 	return {
		// 		...state,
		// 		groups: action.modal.tagGroupWithTags,
		// 	};
		// }
		case actionTypes.TAGS_BY_TAG_GROUP: {
			return {
				...state,
				tagsByGroupId: action.tags,
			};
		}
		case actionTypes.CLEAR_TAG_GROUPS: {
			return {
				...state,
				groups: [],
			};
		}
		default: {
			return state;
		}
	}
};

export default tagAdminPanelReducer;
