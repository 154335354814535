import { IEmployeeAgreement } from '@sac-tt/tasker-types/dist/types/dataBaseTypes/employeeAgreement';

import * as actionTypes from '@/store/actions';
import { SET_EMPLOYEE_AGREEMENTS } from '@/store/actions/employeeAgreementsActions';
import { ObjectAction } from '@/store/types';

const State = {
	employeeAgreements: [] as IEmployeeAgreement[],
	employeeAgreementsCount: 0 as number,
};
export type IEmployeeAgreementsState = typeof State;

const employeeAgreementsReducer = (state = State, action: ObjectAction): IEmployeeAgreementsState => {
	switch (action.type) {
		case SET_EMPLOYEE_AGREEMENTS: {
			return {
				...state,
				employeeAgreements: action.data,
				employeeAgreementsCount: action.count,
			};
		}

		default: {
			return state;
		}
	}
};

export default employeeAgreementsReducer;
