import { useMemo, useState } from 'react';

import { CellContext, ColumnDef } from '@tanstack/react-table';

import { IFieldValue } from '@sac-tt/tasker-types';

import { Typography } from '@mui/material';

import { rowsPerTablePage } from '@/utils/configuration';
import { useTrans } from '@/utils/hooks/useTrans';

import { TileCreationDate, TileIssueHeader, Tag } from 'view/IssueList/TileComponents';

import { Delete } from './Delete';
import { IIssueRelationship, ITableProps } from './model';
import { Resources } from './resources';

export const useTableData = (props: ITableProps) => {
	const { issuesPage, changeRelationToBasic, onDelete } = props;
	const { t } = useTrans('IssueModal.IssueModalContent.Relation.useTableData');

	const [tableData, setTableData] = useState({
		searchValue: '',
		page: 0,
		rowsPerPage: rowsPerTablePage,
	});

	const handleSearch = (value: string) => {
		setTableData(prevState => ({ ...prevState, searchValue: value }));
	};

	const handlePageChange = (value: number) => {
		setTableData(prevState => ({ ...prevState, page: value }));
	};

	const columns: ColumnDef<IIssueRelationship, any>[] = useMemo(
		() => [
			{
				id: 'name',
				accessorFn: row => row,
				header: t('name'),
				size: 0.3,
				cell: (data: CellContext<IIssueRelationship, IIssueRelationship>) => {
					const item = data.getValue();
					if (item) return <TileIssueHeader onClick={changeRelationToBasic} issue={item} />;
					return Resources.GlobalResources.empty;
				},
			},
			{
				id: 'project',
				accessorFn: row => row,
				header: t('project'),
				size: 0.2,
				cell: (data: CellContext<IIssueRelationship, IIssueRelationship>) => {
					const item = data.getValue();
					return item.Project?.Name;
				},
			},
			{
				id: 'status',
				accessorFn: row => row,
				header: t('status'),
				size: 0.2,
				cell: (data: CellContext<IIssueRelationship, IIssueRelationship>) => {
					const issue = data.getValue();
					const issueField = issue?.IssueField;
					let issueFieldFirst = undefined;
					if (issueField && (issueField?.length ?? 0) > 0) issueFieldFirst = issueField[0];
					if (issueFieldFirst) {
						const tag = issueFieldFirst?.FieldValue?.ValueTag;
						return (
							<Tag
								color={tag?.TagColor ?? Resources.GlobalResources.empty}
								label={issueFieldFirst?.FieldValue?.ValueTag?.Label ?? Resources.GlobalResources.empty}
								issueId={issue?.Id}
								fieldValue={issueFieldFirst?.FieldValue as IFieldValue}
								count={tableData?.rowsPerPage}
								from={tableData?.page * tableData?.rowsPerPage}
							/>
						);
					}
					return <Typography>{Resources.connector}</Typography>;
				},
			},
			{
				id: 'creationDate',
				accessorFn: row => row,
				header: t('creationDate'),
				size: 0.25,
				cell: (data: CellContext<IIssueRelationship, IIssueRelationship>) => {
					const item = data.getValue();
					return <TileCreationDate issue={item} />;
				},
			},
			{
				id: 'action',
				accessorFn: row => row,
				header: t('action'),
				size: 0.05,
				cell: (data: CellContext<IIssueRelationship, IIssueRelationship>) => {
					const item = data.getValue();

					return (
						<div>
							<Delete onDelete={onDelete} relationId={item?.RelationId} issueId={item?.Id as number} />
						</div>
					);
				},
			},
		],
		[t]
	);

	return {
		columns,
		data: issuesPage?.Data ?? [],
		handleSearch,
		handlePageChange,
		tableData,
	};
};
