import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	caption: 'caption' as const,
	rounded: 'rounded' as const,
	space: ' ' as const,
	dot: '.' as const,
	boxShadow: '0px 0px 5px 1px' as const,
	stepToSkip: ['Step', 'delegation'],
};

export const Translation = {
	pl: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationAcceptance: {
						DelegationAcceptanceHistory: {
							HistoryCard: {
								status: 'Status delegacji - ',
								changes: 'Wprowadzone zmiany:',
								statusChanged: 'Zmieniono status z ',
								to: ' na ',
								1: 'Przygotowanie',
								2: 'Akceptacja',
								3: 'Rozliczenie',
								4: 'Akceptacja rozliczenia',
								5: 'Poprawa',
								6: 'Zakończono',
								Origin: 'Parametry miejsca wyjazdu',
								Destination: 'Parametry miejsca docelowego',
								OriginEnd: 'Parametry miejsca powrotnego',
								name: 'Nazwa ',
								totalDistance: 'Dystans delegacji',
								description: 'Opis',
								budget: 'Budżet',
								project: 'Projekt',
								route: 'Ścieżka delgacji',
								delegation: 'Delgacja została stworzona i przesłana do akceptacji',
								perDiem: 'Elementy diety',
								payment: 'Zaliczki',
							},
						},
					},
				},
			},
		},
	},
	en: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationAcceptance: {
						DelegationAcceptanceHistory: {
							HistoryCard: {
								changes: 'Changes:',
								status: 'Status - ',
								statusChanged: 'Status has been changed from ',
								to: ' to ',
								1: 'Preparation',
								2: 'Acceptance',
								3: 'Settlement',
								4: 'Settlement acceptance',
								5: 'Correction',
								6: 'Done',
								Origin: 'Origin parameters',
								Destination: 'Destination parameters',
								OriginEnd: 'Return place parameters',
								name: 'Name',
								totalDistance: 'Total distance',
								description: 'Description',
								budget: 'Budget',
								project: 'Project',
								route: 'Route',
								delegation: 'Delegation has been created and passed to',
								perDiem: 'Per diem',
								payment: 'Advances',
							},
						},
					},
				},
			},
		},
	},
};
