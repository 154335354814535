import { IDelegation, IPage } from '@sac-tt/tasker-types';

import * as actionTypes from '@/store/actions';
import { ObjectAction } from '@/store/types';

export const State = {
	Page: {} as IPage<IDelegation[]>,
};
export type IDelegationAcceptanceListState = typeof State;

const delegationAcceptanceListReducer = (state = State, action: ObjectAction): IDelegationAcceptanceListState => {
	switch (action?.type) {
		case actionTypes.DELEGATION_FETCH_ACCEPTANCE_MANY:
		case actionTypes.DELEGATION_REQUEST_TO_FETCH_MANY:
		case actionTypes.DELEGATION_REQUEST_TO_WITH_ID_FETCH_MANY: {
			return {
				...state,
				Page: action?.data,
			};
		}
		default: {
			return state;
		}
	}
};

export default delegationAcceptanceListReducer;
