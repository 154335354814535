import uuid from 'uuid';

import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	projectId: 'projects/',
	overview: 'overview',
	menuId: 'menuId',
	ariaLabelledby: 'aria-labelledby',
	basicButton: 'basic-button',
	key: (x: number) => `Tag_${uuid()}_${x}`,
	...GlobalResources,
};

export const Translation = {
	pl: {
		IssueList: {
			TileComponents: {
				Project: {
					projectName: 'Projekt',
					searchByProject: 'Filtruj po tym projekcie',
				},
			},
		},
	},
	en: {
		IssueList: {
			TileComponents: {
				Project: {
					projectName: 'Project',
					searchByProject: 'Filter by this project',
				},
			},
		},
	},
};
