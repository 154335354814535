import uuid from 'uuid';

import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	key: (x: number) => `${x}_Feature_${uuid()}`,
};

export const Translation = {
	pl: {
		Roles: {
			TabsContent: {
				RoleConfiguration: {
					FeatureSelection: {
						selectRole: 'Aby wyświetlić listę funkcji, najpierw wybierz rolę...',
						1: 'Zgłoszenia',
						2: 'Projekty',
						4: 'Klienci',
						5: 'Komentarze',
						7: 'Ogólne',
						8: 'Konfiguracja zegarka',
						9: 'Konfiguracja bota auto-odpowiedzi',
						10: 'Formularze',
						11: 'Organizacje',
						12: 'Kanbany',
						13: 'Portale',
						14: 'Użytkownicy',
						15: 'Rachunki',
						16: 'Raporty',
						17: 'Role',
						18: 'Tagi',
						19: 'Web',
						21: 'Grupy tagów',
						29: 'Kontrakty',
						30: 'Delegacje',
					},
				},
			},
		},
	},
	en: {
		Roles: {
			TabsContent: {
				RoleConfiguration: {
					FeatureSelection: {
						selectRole: 'To display the list of features, first select a role...',
						1: 'Issues',
						2: 'Projects',
						4: 'Customers',
						5: 'Comments',
						7: 'General',
						8: 'Clock configuration',
						9: 'Comment bot configuration',
						10: 'Forms',
						11: 'Organizations',
						12: 'Kanbans',
						13: 'Portals',
						14: 'Users',
						15: 'Reckons',
						16: 'Reports',
						17: 'Roles',
						18: 'Tags',
						19: 'Web',
						21: 'Tag groups',
						29: 'Contracts',
						30: 'Delegations',
					},
				},
			},
		},
	},
};
