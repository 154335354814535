import { getGlobalStyles } from '@sac-tw2/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(
	(theme: Theme) => ({
		...getGlobalStyles(),
		issueNameArea: { gridColumn: '1 / 3' },
		titleArea: { gridColumn: '1 / 3' },
		descArea: { gridColumn: '1 / 3' },
		checkbox: { marginTop: theme.spacing(1) },

		root: {
			width: '100%',
			maxWidth: '500px',
			margin: 'auto',
			display: 'grid',
			marginTop: theme.spacing(3),
			gridGap: theme.spacing(3),
			gridTemplateColumns: 'minmax(0, 1fr) minmax(0, 1fr)',
		},
		errorButton: {
			backgroundColor: theme.palette.red.light,
			color: theme.palette.white.light,

			'&:hover': {
				backgroundColor: theme.palette.red.light,
			},
		},
		buttonsContainer: {
			display: 'grid',
			gridTemplateColumns: '100px 100px 100px',
			gridGap: theme.spacing(1),
		},
	}),
	{ name: 'IssueModalNew_IssueModalContent_Worklogs_Form' }
);
