import uuid from 'uuid';

import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	usersRolesConfigurationKey: (key: string) => `DelegationUsersRolesConfiguration_${uuid()}_${key}`,
};

export const Translation = {
	pl: {
		OrganizationPanel: {
			TabsContent: {
				Delegation: {
					TabsContent: {
						UsersRolesConfiguration: {
							usersSelectionSection: {
								title: 'Wybór użytkowników',
								subtitle1:
									'Wybrani użytkownicy mogą zostać dodani do listy osób akceptujących delegację. Minimum jedno pole oznaczone',
								subtitle2: 'musi być zaznaczone.',
								placeholder: 'Wybierz użytkowników do przypisania ról...',
								tooltip: 'Dodaj wybranych użytkowników do przypisania ról',
								buttonText: 'Dodaj',
								labelSupervisor: 'Osoba nadzorująca',
								labelAccountant: 'Księgowy/a',
							},
							usersRolesListSection: {
								title: 'Lista przypisanych użytkowników',
								subtitle:
									'Poniżej wyświetlona jest lista użytkowników, którzy zostali przypisani do roli. Możesz dowolnie modyfikować role użytkowników i usuwać użytkowników z listy.',
							},
							roleRequirementSection: {
								title: 'Ustawienia obowiązkowych akceptacji ról',
								subtitle: 'Wybrane role będą miały obowiązek zaakceptowania delegacji.',
								labelSupervisor: 'Wymagana akceptacja osoby nadzorującej',
								labelAccountant: 'Wymagana akceptacja księgowego/ej',
							},
							UserRole: {
								labelName: 'Imię',
								buttonTextSupervisor: 'Osoba nadzorująca',
								buttonTextAccountant: 'Księgowy/a',
								labelUserRoles: 'Role użytkownika',
								deleteButtonConfirmMessage1: 'Czy na pewno chcesz usunąć użytkownika',
								deleteButtonConfirmMessage2: 'z listy przypisanych użytkowników?',
								toggleButtonConfirmMessage1: 'Wyłączenie wszystkich ról użytkownika',
								toggleButtonConfirmMessage2:
									'spowoduje usunięcie użytkownika z listy przypisanych użytkowników. Czy na pewno chcesz to zrobić?',
							},
						},
					},
				},
			},
		},
	},
	en: {
		OrganizationPanel: {
			TabsContent: {
				Delegation: {
					TabsContent: {
						UsersRolesConfiguration: {
							usersSelectionSection: {
								title: 'User selection',
								subtitle1:
									'Selected users can be added to the list of delegation approvers. Minimum one field marked',
								subtitle2: 'must be selected.',
								placeholder: 'Select users to assign roles...',
								tooltip: 'Add selected users to assign roles',
								buttonText: 'Add',
								labelSupervisor: 'Supervisor',
								labelAccountant: 'Accountant',
							},
							usersRolesListSection: {
								title: 'List of assigned users',
								subtitle:
									'Below is a list of users who have been assigned to the role. You can freely modify the roles of users and remove users from the list.',
							},
							roleRequirementSection: {
								title: 'Mandatory role approval settings',
								subtitle: 'The selected roles will be required to approve the delegation.',
								labelSupervisor: 'Supervisor approval required',
								labelAccountant: 'Accountant approval required',
							},
							UserRole: {
								labelName: 'Name',
								buttonTextSupervisor: 'Supervisor',
								buttonTextAccountant: 'Accountant',
								labelUserRoles: 'User roles',
								deleteButtonConfirmMessage1: 'Are you sure you want to remove the user',
								deleteButtonConfirmMessage2: 'from the list of assigned users?',
								toggleButtonConfirmMessage1: 'Disabling all user roles',
								toggleButtonConfirmMessage2:
									'will remove the user from the list of assigned users. Are you sure you want to do this?',
							},
						},
					},
				},
			},
		},
	},
};
