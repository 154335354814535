import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

import { Variant } from '@mui/material/styles/createTypography';

export const Resources = {
	...GlobalResources,
	caption: 'caption' as Variant,
};

export const Translation = {
	pl: {
		LeaveType: {
			List: {
				add: 'Dodaj typ urlopu',
				name: 'Nazwa',
				headerType: 'Rodzaje nieobecności',
				pageTitle: 'Rodzaje nieobecności',
				creator: 'Twórca',
				confirm: 'Czy jesteś pewien, że chcesz usunąć ten typ?',
				useTableData: {
					name: 'Nazwa',
					creator: 'Twórca',
					action: 'Akcje',
				},
			},
		},
	},
	en: {
		LeaveType: {
			List: {
				add: 'Add leave type',
				name: 'Name',
				headerType: 'Leave types',
				pageTitle: 'Leave types',
				creator: 'Creator',
				confirm: 'Are you sure you want to delete this type?',
				useTableData: {
					name: 'Name',
					creator: 'Creator',
					action: 'Actions',
				},
			},
		},
	},
};
