import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources.js';

export const Resources = {
	...GlobalResources,
	mainKey: 'UserList',
	userDetailsLink: '/organization/userDetails',
	hover: 'hover' as const,
};

export const Translation = {
	pl: {
		OrganizationPanel: {
			TabsContent: {
				UsersTab: {
					hooks: {
						userName: 'Nazwa użytkownika',
						lastLoginDate: 'Data ostatniego logowania',
						inactive: '(Nieaktywny)',
					},
				},
			},
		},
	},
	en: {
		OrganizationPanel: {
			TabsContent: {
				UsersTab: {
					hooks: {
						userName: 'User name',
						lastLoginDate: 'Last login date',
						inactive: '(Inactive)',
					},
				},
			},
		},
	},
};
