import uuid from 'uuid';

import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	key: (x: number) => `${uuid()}_Projects_${x}`,
	...GlobalResources,
};

export const Translation = {
	pl: {
		IssueList: {
			Filters: {
				Tags: {
					Status: 'Status:',
					Tag: 'Tag:',
					excludedTag: 'Zgłoszenie nieposiadająca taga:',
					name: 'Nazwa po, której wyszukujemy: ',
					project: 'Project w, którym wyszukujemy: ',
					notProject: 'Project w, którym nie wyszukujemy: ',
					notCustomer: 'Klient w, którym nie wyszukujemy: ',
					notIssueForm: 'Formularz w, którym nie wyszukujemy: ',
				},
			},
		},
	},
	en: {
		IssueList: {
			Filters: {
				Tags: {
					Status: 'Status:',
					Tag: 'Tag:',
					excludedTag: 'Excluded tag:',
					name: 'Search name: ',
					project: 'Search by project: ',
					notProject: 'Do not search in project: ',
					notCustomer: 'Do not search in customer: ',
					notIssueForm: 'Do not search in form: ',
				},
			},
		},
	},
};
