import { getGlobalStyles } from '@sac-tw2/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(
	(theme: Theme) => ({
		...getGlobalStyles(),
		popoverOption: {
			cursor: 'pointer',
			padding: '5px 8px',
			margin: '6px',
			borderRadius: '5px',
			transition: '.2s',

			'&:hover': {
				backgroundColor: theme.palette.background.default,
			},
		},

		menu: {
			'& .MuiMenu-list': {
				paddingTop: '4px',
				paddingBottom: '4px',
				color: 'red',
			},
		},

		menuItem: {
			'&:hover': {
				backgroundColor: 'rgba(0, 0, 0, 0.1)',
			},
		},

		issueTitle: {
			fontWeight: 500,
			letterSpacing: theme.spacing(-0.0075),
			width: 'fit-content',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
		},
	}),
	{ name: `NotificationListMoreOptions` }
);
