import { getGlobalStyles } from '@sac-tw2/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(
	(theme: Theme) => {
		const dimension = '80%';
		return {
			...getGlobalStyles(),
			modal: {
				backgroundColor: theme.palette.white.light,
				outline: 'none',
				width: '1300px',
				maxHeight: dimension,
				height: dimension,
				marginBottom: theme.spacing(2),
				position: 'relative',
				display: 'flex',
				flexDirection: 'column',
				'& .MuiCardHeader-root': {
					padding: theme.spacing(3, 3),
					display: 'flex',
					alignItems: 'center',
					backgroundColor: theme.palette.background.default,
				},
				'&>.MuiCardActions-root': {
					display: 'flex',
					padding: '8px 24px',
					alignItems: 'center',
					height: 'auto',
					backgroundColor: theme.palette.background.default,
				},

				'&>.MuiCardContent-root': {
					paddingTop: '0px',
					backgroundColor: theme.palette.background.default,
				},
			},
			container: {
				maxWidth: 'md',
				minWidth: '100%',
				padding: 0,
			},
			kanbanPopupContent: {
				display: `flex`,
				flexDirection: `column`,
				alignItems: `center`,
			},
			marginBottomS: {
				marginBottom: `12px`,
			},
		};
	},
	{ name: `IssueModal` }
);
