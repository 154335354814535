import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		Favourite: {
			remove: 'Usuń z ulubionych',
			add: 'Dodaj do ulubionych',
		},
	},
	en: {
		Favourite: {
			remove: 'Remove from favorites',
			add: 'Add to favorites',
		},
	},
};
