import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	Organization: {
		Name: { inputName: 'organizationName' },
		DisplayId: { inputName: 'displayId' },
	},
	User: {
		FirstName: { inputName: 'firstName' },
		LastName: { inputName: 'lastName' },
		Login: { inputName: 'login' },
		Email: { inputName: 'email' },
		Password: { inputName: 'password' },
	},
	BasicUser: {
		FirstName: '',
		LastName: '',
		Login: '',
		Email: '',
		Password: '',
	},
	GeneralTab: 'general',
};

export const Translation = {
	pl: {
		OrganizationPanel: {
			TabsContent: {
				CreateOrganization: {
					Organization: {
						Name: {
							errorMessage: 'To pole jest wymagane',
							label: 'Nazwa organizacji',
						},
						DisplayId: {
							errorMessage: 'To pole jest wymagane',
							label: 'Publiczny identyfikator organizacji',
						},
					},
					User: {
						FirstName: {
							errorMessage: 'To pole jest wymagane',
							label: 'Imię',
						},
						LastName: {
							errorMessage: 'To pole jest wymagane',
							label: 'Nazwisko',
						},
						Login: {
							errorMessage: 'To pole jest wymagane',
							label: 'Login',
						},
						Email: {
							errorMessage: 'To pole jest wymagane',
							label: 'Email',
						},
						Password: {
							errorMessage: 'To pole jest wymagane',
							label: 'Hasło',
						},
					},
					withUser: 'Czy chcesz sprecyzować użytkownika',
					save: 'Stwórz',
				},
			},
		},
	},
	en: {
		OrganizationPanel: {
			TabsContent: {
				CreateOrganization: {
					Organization: {
						Name: {
							errorMessage: 'This field is required',
							label: 'To Organization name',
						},
						DisplayId: {
							errorMessage: 'This field is required',
							label: 'Public identifier of organization',
						},
					},
					User: {
						FirstName: {
							errorMessage: 'This field is required',
							label: 'First name',
						},
						LastName: {
							errorMessage: 'This field is required',
							label: 'Last name',
						},
						Login: {
							errorMessage: 'This field is required',
							label: 'Login',
						},
						Email: {
							errorMessage: 'This field is required',
							label: 'Email',
						},
						Password: {
							errorMessage: 'This field is required',
							label: 'Password',
						},
					},
					withUser: 'Do you want to personalize user',
					save: 'Create',
				},
			},
		},
	},
};
