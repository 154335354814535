import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	redirect: {
		notFound: '/errors/error-404',
		login: '/auth/login',
	},
};

export const Translation = {
	pl: {
		Register: {
			pageTitle: 'Rejestracja',
			register: 'Zarejestruj',
			registerInternal: 'Zarejestruj na wewnętrznej platformie',
			account: 'Masz konto?',
		},
	},
	en: {
		Register: {
			pageTitle: 'Register',
			register: 'Sign up',
			registerInternal: 'Sign up on the internal platform',
			account: 'Have an account?',
		},
	},
};
