import { getGlobalStyles } from '@sac-tw2/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(
	(theme: Theme) => {
		return {
			...getGlobalStyles(),

			tag: {
				borderRadius: '20px',
				padding: '3px 10px',
				fontSize: '12px',
				marginLeft: theme.spacing(0.25),
				width: 'max-content',
			},
		};
	},
	{ name: `IssueModalEditor` }
);
