import { Howl } from 'howler';
import { useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';

import { EParameter, IReducerState } from '@sac-tt/tasker-types';

import { useStyles } from './styles';

export const Sound = () => {
	const classes = useStyles();

	const preferences = useSelector((x: IReducerState) => x.Session?.preferences);
	const notifications = useSelector((x: IReducerState) => x.DashBoard?.countOfNotReadUserNotifications);

	const [index, upIndex] = useReducer(x => x + 1, 0);

	useEffect(() => {
		if (isAllowedToPlaySounds() === true) {
			const sound = new Howl({ src: ['/notification.mp3'] });
			sound.play();
		}
		upIndex();
	}, [notifications]);

	const isAllowedToPlaySounds = () => {
		if (notifications === 0 || index <= 1) return false;
		try {
			const silentPreference = preferences?.find(x => x?.ParameterId === EParameter.Silent_Notification);
			if (silentPreference?.ValueBoolean === true) return false;
			if (silentPreference?.ValueBoolean === false) return true;
			return true;
		} catch {
			return true;
		}
	};

	return <div className={classes.invisible} />;
};

export default Sound;
