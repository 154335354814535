import { IIssue } from '@sac-tt/tasker-types';

export function updateIssueWorklogStatus(issues: IIssue[], lastIssueId: number | null, isActive: boolean): IIssue[] {
	return issues?.map(issue => {
		if (issue?.Id === lastIssueId) {
			return {
				...issue,
				Worklog: issue?.Worklog
					? issue.Worklog.map(worklog => ({
							...worklog,
							IsActive: isActive,
					  }))
					: [],
			};
		}
		return issue;
	});
}
