import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		RolePanel: {
			PopupActions: {
				ButtonSave: 'Zapisz i zmień',
				ButtonChange: 'Zmień bez zapisu',
				ButtonCancel: 'Anuluj',
			},
		},
	},
	en: {
		RolePanel: {
			PopupActions: {
				ButtonSave: 'Save and switch',
				ButtonChange: 'Switch without saving',
				ButtonCancel: 'Cancel',
			},
		},
	},
};
