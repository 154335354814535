// @flow

import { VisibilityControl } from '@sac-tw2/tasker-widgets2/dist/widgets/VisibilityControl';

import { List, ListSubheader } from '@mui/material';

import { useTrans } from '@/utils/hooks/useTrans';

import { NavBarTabConfig, Props } from './model';
import { CreateNavItem } from './NavItem';
import { useStyles } from './style';

export const NavBarSection = (props: Props): JSX.Element => {
	const { name, items } = props.section;
	const { t } = useTrans('Nav.Section');
	const classes = useStyles();

	return (
		<List key={name}>
			<VisibilityControl condition={!!name} alternative={<div />}>
				<ListSubheader disableSticky disableGutters className={classes.justifyContentArabic}>
					{t(name)}
				</ListSubheader>
			</VisibilityControl>
			{items.map((item: NavBarTabConfig) => CreateNavItem(item))}
		</List>
	);
};

export default NavBarSection;
