import clsx from 'clsx';
import { components } from 'react-select';

import { VisibilityControl } from '@sac-tw2/tasker-widgets2';

import AddIcon from '@mui/icons-material/Add';
import { Typography } from '@mui/material';

import { useStyles } from './styles';

export const ValueContainer = ({ children, hasValue, ...rest }: any) => {
	const { valuesLimit = 3 } = rest.selectProps;
	const classes = useStyles();

	if (!hasValue) {
		return <components.ValueContainer {...rest}>{children}</components.ValueContainer>;
	}

	const [chips, otherChildren] = children;

	const firstThreeElements = Array.from(children[0]).slice(0, valuesLimit);

	return (
		<components.ValueContainer {...rest} className={classes.flexYCenter}>
			{firstThreeElements}

			{otherChildren}
			<VisibilityControl condition={children[0]?.length > valuesLimit}>
				<div className={clsx(classes.flexYCenter, classes.counter)}>
					<AddIcon className={classes.icon} />
					<Typography className={classes.counterText}>{children[0].length - valuesLimit}</Typography>
				</div>
			</VisibilityControl>
		</components.ValueContainer>
	);
};
