import { AsyncAction } from '@sac-rp/redux-promisify';
import { IEmployeeSalary } from '@sac-tt/tasker-types';

import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

import { IJson } from '../ITypes';

export const SET_UPDATED_SALARY: ActionType = 'SET_UPDATED_SALARY';
export const FILTER_OUT_SALARY: ActionType = 'FILTER_OUT_SALARY';

const BASE_PATH = 'EmployeeSalary';

export function _replace_salary(salary: Partial<IEmployeeSalary>, indexToReplace: number): Action {
	return {
		type: SET_UPDATED_SALARY,
		salary,
		indexToReplace,
	};
}

export function _remove_salary(salaryId: number): Action {
	return {
		type: FILTER_OUT_SALARY,
		salaryId,
	};
}

export function update_employee_salary(salary: Partial<IEmployeeSalary>, indexToReplace: number): AsyncAction {
	return function(context, dispatch) {
		context.then(data => dispatch(_replace_salary((data as IJson).data, indexToReplace)));
		return axios().patch(`${BASE_PATH}/${salary.Id}`, salary);
	};
}

export function create_employee_salary(salary: Partial<IEmployeeSalary>, indexToReplace: number): AsyncAction {
	return function(context, dispatch) {
		context.then(data => dispatch(_replace_salary((data as IJson).data, indexToReplace)));
		return axios().post(`${BASE_PATH}`, salary);
	};
}

export function delete_employee_salary(salaryId: number): AsyncAction {
	return function(context, dispatch) {
		context.then(data => {});
		return axios().delete(`${BASE_PATH}/${salaryId}`);
	};
}
