import { IIssue } from '@sac-tt/tasker-types';

import { store } from '@/App';
import { IReducerState } from '@/store/reducers/types';

import { Resources } from './resources';

export const getIssueTitle = (issue: IIssue): string => {
	return `${issue?.DisplayId ?? Resources.GlobalResources.empty} ${issue?.Title}`;
};

export const startPauseShouldBeVisible = (issue: IIssue, isEdittingIssue: boolean): boolean => {
	const reducer: IReducerState = store.getState() as IReducerState;
	const currentUser = reducer.Session.profile;

	if (issue?.Members?.Assigned?.some(assignee => assignee?.Id == currentUser?.Id)) return true;
	if (issue?.Id && issue?.Id > 0) return true;
	return isEdittingIssue;
};
