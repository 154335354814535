import React, { useMemo } from 'react';

import { EFieldDefinitionType } from '@sac-tt/tasker-types';

import { Typography } from '@mui/material';

import { BooleanIssueProperty } from '../BooleanIssueProperty';
import { DateIssueProperty } from '../DateIssueProperty';
import { DateTimeIssueProperty } from '../DateTimeIssueProperty';
import { DecimalIssueProperty } from '../DecimalIssueProperty';
import { DictionaryIssueProperty } from '../DictionaryIssueProperty';
import { IntegerIssueProperty } from '../IntegerIssueProperty';
import { TagGroupIssueProperty } from '../TagGroupIssueProperty';
import { TextAreaIssueProperty } from '../TextAreaIssueProperty';
import { TextIssueProperty } from '../TextIssueProperty';

import { Props } from './model';

export const IssuePropertyFactory = (props: Props) => {
	const { issueFormSectionToIssueField, inEdit } = props;

	const typeOfIssueProperty = useMemo(() => {
		return issueFormSectionToIssueField?.AttributeDefinition?.FieldDefinitionTypeId;
	}, [issueFormSectionToIssueField]);

	switch (typeOfIssueProperty) {
		case EFieldDefinitionType.TagGroup:
			return <TagGroupIssueProperty {...props} />;

		case EFieldDefinitionType.Text:
			return <TextIssueProperty {...props} />;

		case EFieldDefinitionType.Integer:
			return <IntegerIssueProperty {...props} />;

		case EFieldDefinitionType.Decimal:
			return <DecimalIssueProperty {...props} />;

		case EFieldDefinitionType.Boolean:
			return <BooleanIssueProperty {...props} />;

		case EFieldDefinitionType.Date:
			return <DateIssueProperty {...props} />;

		case EFieldDefinitionType.DateTime:
			return <DateTimeIssueProperty {...props} />;

		case EFieldDefinitionType.Dictionary:
			return <DictionaryIssueProperty {...props} />;

		case EFieldDefinitionType.TextArea:
			return <TextAreaIssueProperty {...props} />;

		default:
			return <Typography>-</Typography>;
	}
};
