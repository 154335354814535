import { getGlobalStyles } from '@sac-tw2/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(
	(theme: Theme) => {
		const borderError = `${theme.spacing(0.25)} solid red`;
		//ToDo sprawdzić kolory AD do KW
		return {
			...getGlobalStyles(),
			mainContainer: { marginRight: theme.spacing(5) },
			secondRow: {
				overflow: 'hidden',
				display: 'grid',
				gridTemplateColumns: '3fr 3fr 6fr',
				gridGap: theme.spacing(2),
				marginTop: theme.spacing(3),
				alignItems: 'start',
				minHeight: '200px',
			},
			profileSelector: {
				paddingLeft: theme.spacing(1.25),

				'& img, & svg': {
					width: '26px',
					height: '26px',
					borderRadius: '50%',
				},

				'& .MuiAvatar-root': {
					width: '30px',
					height: '30px',
				},
			},
			avatarSelect: {
				'& .select__multi-value': {
					margin: theme.spacing(0, 0.5),
					borderRadius: theme.spacing(0.625),
				},

				'& .select__value-container ': {
					padding: theme.spacing(0.5, 1),
					display: 'flex',
				},

				'& .select__option': {
					cursor: 'pointer',

					'&--is-selected': {
						backgroundColor: 'white',
						color: 'black',

						'&:hover': { backgroundColor: '#9beab5' },
					},
				},

				'& .select__control': {
					minHeight: theme.spacing(4.25),
					backgroundColor: 'white',
					border: `${theme.spacing(0.25)} solid #e3e3e3`,
					borderRadius: theme.spacing(0.625),
					boxShadow: 'none',

					'&--is-focused': { border: `${theme.spacing(0.25)} solid #33b35c` },
					'& .select__single-value ': { fontSize: theme.spacing(1.9375) },
					'& .select__indicator': { padding: theme.spacing(0.5) },
				},

				'& .basic-multi-select': { margin: '0' },
			},
			tooltipTextBaseText: { fontSize: theme.spacing(1.75) },
			select: {
				'& .select__control': {
					minHeight: theme.spacing(4.25),
					border: `${theme.spacing(0.25)} solid #e3e3e3`,
					borderRadius: theme.spacing(0.625),
					boxShadow: 'none',

					'&--is-focused': { border: `${theme.spacing(0.25)} solid #33b35c` },
					'& .select__single-value ': { fontSize: theme.spacing(1.9375) },
					'& .select__indicator': { padding: theme.spacing(0.5) },
				},

				'& .select__value-container ': { padding: theme.spacing(0, 1) },
				'& .basic-multi-select': { margin: 0 },
			},
			selectError: {
				'& .select__control': {
					border: borderError,
					'&--is-focused': { border: borderError },
				},
			},
			tag: {
				borderRadius: theme.spacing(2.5),
				padding: theme.spacing(0.375, 1.25),
				fontSize: theme.spacing(1.5),
				marginLeft: theme.spacing(0.25),
				width: 'max-content',
			},
			tagText: { filter: 'invert(100%)' },
		};
	},
	{ name: `IssueModal_IssueModalContent_IssueDetailsPage_MainIssueData` }
);
