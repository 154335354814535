import * as https from 'https';

import { Dispatch, SetStateAction } from 'react';

import { AsyncAction } from '@sac-rp/redux-promisify';
import { DeepPartial, IDelegationToAttachment } from '@sac-tt/tasker-types';

import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';
import { IDelegationAttachment } from '@/views/DelegationsList/DelegationModal/DelegationModalContent/DelegationDetails/DelegationSettlementAttachments/Dropzone/model';

import { IJson } from '../ITypes';

export const DELEGATION_ATTACHMENTS_UPLOAD: ActionType = 'DELEGATION_ATTACHMENTS_UPLOAD';
export const DELEGATION_ATTACHMENTS_REMOVE: ActionType = 'DELEGATION_ATTACHMENTS_REMOVE';
export const DELEGATION_ATTACHMENT_UPDATE: ActionType = 'DELEGATION_ATTACHMENT_UPDATE';

const basePath = 'DelegationToAttachment';

export function _uploadDelegationAttachments(data: IDelegationToAttachment[]): Action {
	return {
		type: DELEGATION_ATTACHMENTS_UPLOAD,
		data,
	};
}

export function _removeDelegationAttachments(data: IDelegationToAttachment[]): Action {
	return {
		type: DELEGATION_ATTACHMENTS_REMOVE,
		data,
	};
}

export function _updateDelegationAttachment(data: IDelegationToAttachment[]): Action {
	return {
		type: DELEGATION_ATTACHMENT_UPDATE,
		data,
	};
}

export function delegation_uploadAttachments(
	files: IDelegationAttachment[],
	delegationId: number,
	setUploadProgress?: Dispatch<SetStateAction<number>>
): AsyncAction {
	const fd = new FormData();
	files.forEach(file => {
		if (file.file) fd.append('files', file.file);
	});

	const data: DeepPartial<IDelegationToAttachment>[] = files.map(file => {
		return {
			DelegationId: delegationId,
			IsSettlement: file.DelegationToAttachmentAdditionalInfo?.Gross !== undefined ? true : false,
			DelegationToAttachmentAdditionalInfo: {
				Description: file.DelegationToAttachmentAdditionalInfo?.Description ?? '',
				DescriptionHtml: file.DelegationToAttachmentAdditionalInfo?.Description ?? '',
				Date: file.DelegationToAttachmentAdditionalInfo?.Date ?? undefined,
				DelegationAttachmentTypeId: file.DelegationToAttachmentAdditionalInfo?.DelegationAttachmentTypeId ?? 0,
				Gross: file.DelegationToAttachmentAdditionalInfo?.Gross ?? 0,
				Tax: file.DelegationToAttachmentAdditionalInfo?.Tax ?? undefined,
				Net: file.DelegationToAttachmentAdditionalInfo?.Net ?? undefined,
				CurrencyId: file.DelegationToAttachmentAdditionalInfo?.CurrencyId ?? 0,
				DirectionId: file.DelegationToAttachmentAdditionalInfo?.DirectionId ?? 0,
			},
		};
	});
	fd.append('data', JSON.stringify(data));

	return function(context, dispatch) {
		context.then(json => dispatch(_uploadDelegationAttachments((json as IJson).data)));
		return axios().post(
			basePath,
			fd,
			setUploadProgress
				? {
						onUploadProgress: (progressEvent: { total: number; loaded: number }) => {
							if (setUploadProgress)
								setUploadProgress(Math.round((progressEvent.loaded / progressEvent.total) * 100));
						},
						httpsAgent: new https.Agent({ rejectUnauthorized: false }),
						maxBodyLength: Infinity,
						maxContentLength: Infinity,
						headers: {
							'Access-Control-Allow-Origin': '*',
						},
				  }
				: undefined
		);
	};
}

export function delegation_updateAttachment(file: IDelegationAttachment, delegationId?: number): AsyncAction {
	const data = {
		Id: file.Id,
		DelegationToAttachmentAdditionalInfo: file.DelegationToAttachmentAdditionalInfo,
		DelegationId: delegationId,
	};

	return function(context, dispatch) {
		context.then(json => dispatch(_updateDelegationAttachment((json as IJson).data)));
		return axios().patch(basePath, data);
	};
}

export function delegation_removeAttachment(file: IDelegationAttachment): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_removeDelegationAttachments((json as IJson).data)));
		return axios().delete(`${basePath}/${file.Id}`);
	};
}
