import clsx from 'clsx';

import { IErrorValidationV2, IProject, IProjectIssueType } from '@sac-tt/tasker-types';

import { EIssueModalDetailsPageFields } from 'view/IssueModal/IssueModalContent/IssueDetailsPage/model';

import { Resources } from './resources';

export const getProjectErrors = (
	issueErrors: IErrorValidationV2<EIssueModalDetailsPageFields>[]
): string | undefined => {
	return issueErrors.find(error => error.inputName === EIssueModalDetailsPageFields.projectId)?.errorMessage;
};

export const getProjectValues = (projectsList: IProject[], projectId: number): IProject | undefined => {
	return projectsList.find(project => project.Id === projectId);
};

export const getProjectClasses = (
	classes: { select: string; selectError: string },
	issueErrors: IErrorValidationV2<EIssueModalDetailsPageFields>[]
): string => {
	return clsx(
		classes.select,
		issueErrors.find(error => error.inputName === EIssueModalDetailsPageFields.projectId)?.errorMessage &&
			classes.selectError
	);
};

export const getProjectName = (projectsList: IProject[], projectId: number): string => {
	return projectsList.find(project => project.Id === projectId)?.Name ?? Resources.noData;
};

export const getProjectIssueTypeErrors = (
	issueErrors: IErrorValidationV2<EIssueModalDetailsPageFields>[]
): string | undefined => {
	return issueErrors.find(error => error.inputName === EIssueModalDetailsPageFields.projectIssueTypeId)?.errorMessage;
};

export const getProjectIssueTypeValues = (
	projectIssueTypes: IProjectIssueType[],
	projectIssueTypeId: number
): IProjectIssueType | undefined => {
	return projectIssueTypes?.find(projectIssueType => projectIssueType.Id === projectIssueTypeId);
};

export const getProjectIssueTypeClasses = (
	classes: { select: string; selectError: string },
	issueErrors: IErrorValidationV2<EIssueModalDetailsPageFields>[]
): string => {
	return clsx(
		classes.select,
		issueErrors.find(error => error.inputName === EIssueModalDetailsPageFields.projectIssueTypeId)?.errorMessage &&
			classes.selectError
	);
};
