import uuid from 'uuid';

import { IDictionaryItem } from '@sac-tt/tasker-types';
import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	list: 'list' as const,
	rounded: 'rounded' as const,
	caption: 'caption' as const,
	warning: 'warning' as const,
};

export const Translation = {
	pl: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationAcceptance: {
						DelegationAcceptanceComments: {
							comments: 'Komentarze',
							editErrorSnackbar: 'Error edycji komentarza',
							deleteErrorSnackbar: 'Error usuwania komentarza',
							commentErrorSnackbar: 'Error wysyłania komentarza',
						},
					},
				},
			},
		},
	},
	en: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationAcceptance: {
						DelegationAcceptanceComments: {
							comments: 'Comments',
							editErrorSnackbar: 'An error occurred while editing the comment',
							deleteErrorSnackbar: 'An error occurred while deleting the comment',
							commentErrorSnackbar: 'An error occurred while commenting',
						},
					},
				},
			},
		},
	},
};
