import { AsyncAction } from '@sac-rp/redux-promisify';
import { Modifiable, Project } from '@sac-tw2/tasker-widgets2/dist/utils/types';
import { Tag, TagGroup } from '@sac-tw2/tasker-widgets2/dist/utils/types/tag';

import { IJson } from '@/store/ITypes';
import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

export const PORTAL_FETCH_GROUP_TAGS: ActionType = 'PORTAL_FETCH_GROUP_TAGS';
export const NON_PORTAL_FETCH_GROUP_TAGS: ActionType = 'NON_PORTAL_FETCH_GROUP_TAGS';
export const PORTAL_CONFIGURATION: ActionType = 'PORTAL_CONFIGURATION';
export const PORTAL_CONFIGURATION_LOCAL: ActionType = 'PORTAL_CONFIGURATION_LOCAL';
export const USED_TAG_GROUP_IDS_STATE: ActionType = 'USED_TAG_GROUP_IDS_STATE';
export const USED_RESET: ActionType = 'USED_RESET';

export interface PortalConfigurationServer {
	configurations: { [x: number]: ConfigurationDetail };
	groupTag: TagGroup;
	portalGroupTag: TagGroup;
}

export interface ConfigurationDetail {
	GroupTag: TagGroup;
	PortalGroupTag: TagGroup;
	PortalTag: Tag;
	Tag: Tag;
	PortalTagToTag: PortalToTag;
}

export interface PortalToTag extends Modifiable {
	portalGroupTagId: number;
	portalTagId?: number;
	tagId?: number | null;
	groupTagId: number;
	projectId: number;
	portalGroupTag?: TagGroup;
	groupTag?: TagGroup;
	tag?: Tag;
	portalTag?: Tag;
	project?: Project;
	isPortalToIssue: boolean;
}

function _portalTags_fetch(tags: PortalConfigurationServer[]): Action {
	return {
		type: PORTAL_FETCH_GROUP_TAGS,
		tags,
	};
}

function _nonPortalTags_fetch(tags: PortalConfigurationServer[]): Action {
	return {
		type: NON_PORTAL_FETCH_GROUP_TAGS,
		tags,
	};
}
export function _setUsedTagGroupIdsState(action: EAction, groupId: number): Action {
	return {
		type: USED_TAG_GROUP_IDS_STATE,
		action,
		groupId,
	};
}

export function _setUsedTagGroupReset(): Action {
	return {
		type: USED_RESET,
	};
}

export enum EAction {
	REMOVE = 0,
	ADD = 1,
}

function _portalTagsConfiguration_fetch(
	configuration: PortalConfigurationServer[],
	portalToTag: PortalToTag[]
): Action {
	return {
		type: PORTAL_CONFIGURATION,
		configuration,
		portalToTag,
	};
}

export function portalTagsConfiguration_local(portalToTag: PortalToTag): Action {
	return {
		type: PORTAL_CONFIGURATION_LOCAL,
		portalToTag,
	};
}

export function portalTags_delete_configuration(
	groupTagId: number,
	portalTagId: number,
	projectId: number
): AsyncAction {
	return function(context, dispatch) {
		context.then(() => dispatch(portalTags_fetch_configuration(projectId)));
		return axios().delete(
			`/portal/tagToTag/project/${projectId}/tagGroupPortal/${portalTagId}/groupTag/${groupTagId}`
		);
	};
}

export function portalGroupTags_Fetch(): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_portalTags_fetch((json as IJson).data)));
		return axios().get(`/portal/tag`);
	};
}

export function noPortalGroupTags_Fetch(): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_nonPortalTags_fetch((json as IJson).data)));
		return axios().get(`/not/portal/tag`);
	};
}

export function portalTags_fetch_configuration(projectId: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json =>
			dispatch(
				_portalTagsConfiguration_fetch((json as IJson).data, getConfigurationFromGrouped((json as IJson).data))
			)
		);
		return axios().get(`portal/tagToTag/project/${projectId}/grouped`);
	};
}

export function portalTags_post_configurations(portalToTag: PortalToTag[], projectId: number): AsyncAction {
	return function(context, dispatch) {
		context.then(() => dispatch(portalTags_fetch_configuration(projectId)));
		return axios().post(`/portal/tagToTag/project/${projectId}`, portalToTag);
	};
}

const getConfigurationFromGrouped = (data: PortalConfigurationServer[]) => {
	const list: PortalToTag[] = [];
	data?.forEach(configuration => {
		Object.values(configuration?.configurations ?? {})?.forEach(singleConfiguration => {
			list.push(singleConfiguration?.PortalTagToTag);
		});
	});
	return list;
};
