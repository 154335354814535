import { IHoliday, ILeaveRequest, ITaskerUser, IWorkLog } from '@sac-tt/tasker-types';

import * as actionTypes from '@/store/actions';
import { ObjectAction } from '@/store/types';

const State = {
	calendarWorklogs: [] as IWorkLog[],
	holidays: [] as IHoliday[],
	calendarTimeTotal: 0 as number,
	taskerUserOptions: [] as ITaskerUser[],
	options: {} as { startDate: Date; endDate: Date; userIds: number[] },
	leaveRequest: [] as ILeaveRequest[],
};
export type ICalendarWorklogsState = typeof State;

const calendarWorkLogReducer = (state = State, action: ObjectAction): ICalendarWorklogsState => {
	switch (action.type) {
		case actionTypes.CALENDAR_WORKLOG_FETCH: {
			return {
				...state,
				leaveRequest: action?.worklogs?.Vacation,
				holidays: action?.worklogs?.Holiday,
				options: action?.options,
				calendarTimeTotal: action?.worklogs?.Total,
				calendarWorklogs: action?.worklogs?.WorkLogs,
			};
		}
		case actionTypes.WORKLOG_ACCEPTANCE_CREATE_NEW: {
			if (action?.data?.Total > 0) {
				return {
					...state,
					calendarWorklogs: action?.data?.WorkLogs,
					calendarTimeTotal: action?.data?.Total,
					holidays: action?.data?.Holiday,
				};
			}
			return state;
		}
		case actionTypes.CALENDAR_USERS_FETCH: {
			return {
				...state,
				taskerUserOptions: action?.users,
			};
		}
		case actionTypes.CALENDAR_WORKLOG_CREATE: {
			return {
				...state,
				calendarWorklogs: [...state?.calendarWorklogs, action?.data],
				calendarTimeTotal: state?.calendarTimeTotal + (action?.data?.Minutes ?? 0),
			};
		}
		default: {
			return state;
		}
	}
};

export default calendarWorkLogReducer;
