import uuid from 'uuid';

import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	fixed: 'fixed' as const,

	path: (id: number) => `/reports/customer/${id}`,
	getCustomerKey: (id: number) => `customer_${id}_${uuid()}`,
};

export const Translation = {
	pl: {
		Reports: {
			Customer: {
				pageTitle: 'Raport klienta',
				cardHeading: 'Raport biznesowy klienta',
				customerName: 'Klient',
			},
		},
	},
	en: {
		Reports: {
			Customer: {
				pageTitle: 'Customer report',
				cardHeading: 'Customer business report',
				customerName: 'Client',
			},
		},
	},
};
