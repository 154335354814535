import { getGlobalStyles } from '@sac-tw2/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';
import { CustomTheme } from '@sac-tw2/tasker-widgets2/dist/utils/theme';

import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(
	(theme: CustomTheme) => {
		return {
			...getGlobalStyles(),

			container: {
				flexGrow: 1,
				marginBottom: theme.spacing(2),
			},

			innerContainer: {
				display: 'grid',
				gridTemplateColumns: '1fr',
				gridGap: theme.spacing(4),
				marginLeft: theme.spacing(3),
				alignSelf: 'flex-start',
			},

			innerWrapper: {
				'&:not(:first-child)': {
					marginTop: theme.spacing(3),
				},
			},

			divider: { marginLeft: theme.spacing(3) },

			issuePropertiesContainer: {},

			issuePropertiesInnerContainer: {
				marginLeft: theme.spacing(0),
			},

			creatorName: { fontSize: '14px' },

			creatorAvatar: {
				width: '25px',
				height: '25px',
				marginRight: theme.spacing(0.5),
			},

			creationDetailsContainer: {
				marginTop: theme.spacing(2),
				display: 'grid',
				gridTemplateColumns: '1fr 1fr',
				gridGap: theme.spacing(2),

				'& > *': {},
			},

			errorText: { color: theme.palette.red.main },
		};
	},
	{ name: `IssueModal_IssueModalContent_IssueDetailsPage_RightSideIssueData` }
);
