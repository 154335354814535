import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	basicButtonId: 'basic-button' as const,
	basicMenuId: 'basic-menu' as const,
	ariaLabel: 'aria-labelledby' as const,
	...GlobalResources,
};
export const Translation = {
	pl: {
		Kanban: {
			KanbanBoard: {
				KanbanContent: {
					EditKanbanContent: {
						LeftSideButtons: {
							generateForAll: 'Generuj dla wszystkich formularzy',
							generateMappings: 'Generuj mapowania',
							missingMappings: 'brakujące mapowania',
							allMappings: 'wszystkie mapowania',
							generateForThisForm: 'Generuj dla tego formularza',
						},
					},
				},
			},
		},
	},
	en: {
		Kanban: {
			KanbanBoard: {
				KanbanContent: {
					EditKanbanContent: {
						LeftSideButtons: {
							generateMappings: 'Generate mappings',
							generateForAll: 'Generate mappings for all forms',
							missingMappings: 'missing mappings',
							allMappings: 'all mappings',
							generateForThisForm: 'Generate for this from',
						},
					},
				},
			},
		},
	},
};
