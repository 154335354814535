import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	small: 'small' as const,
	id: 'Id',
	bottomEnd: 'bottom-end' as const,
	Email: 'Email',
	mailURL: (email: string) => `mailto:${email}?"&subject="Tasker"`,
};

export const Translation = {
	pl: {
		OrganizationPanel: {
			EndWorkDay: {
				EndWorkDayCreate: {
					CreateUsers: {
						placeholder: 'Wyszukaj użytkownika...',
						noRecords: 'Brak użytkowników',
						noRecordsInfo: 'Dodaj użytkowników, którzy będą wypełniać formularz zakończenia dnia pracy',
						addUsers: 'Dodaj użytkowników',
						add: 'Dodaj',
						user: 'Użytkownik',
						delete: 'Usuń',
						save: 'Zapisz',
						deleteConfirmation: 'Czy na pewno chcesz usunąć tego użytkownika?',
					},
				},
			},
		},
	},
	en: {
		OrganizationPanel: {
			EndWorkDay: {
				EndWorkDayCreate: {
					CreateUsers: {
						placeholder: 'Search for user...',
						noRecords: 'No users',
						noRecordsInfo: 'Add users who will fill out the end-of-day form',
						addUsers: 'Add users',
						add: 'Add',
						user: 'User',
						delete: 'Delete',
						save: 'Save',
						deleteConfirmation: 'Are you sure you want delete this user?',
					},
				},
			},
		},
	},
};
