import React, { useState } from 'react';

import { IFullFiltersIssueTextBy } from '@sac-tt/tasker-types';
import { VisibilityControl } from '@sac-tw2/tasker-widgets2/dist/widgets/VisibilityControl';

import { Checkbox, FormControlLabel, Menu, MenuItem, Typography } from '@mui/material';

import { useTrans } from '@/utils/hooks/useTrans';

import { Props } from './model';
import { DEFAULT_SEARCH_BY, Resources } from './resources';
import { useStyles } from './styles';

export const SearchBy = (props: Props): JSX.Element => {
	const { onChange, isOpen, anchorEl, onClose } = props;
	const classes = useStyles();
	const { t } = useTrans('SearchBar.Search.SearchBy');

	const [state, setState] = useState<IFullFiltersIssueTextBy>(DEFAULT_SEARCH_BY);

	const onChangeWrapper = (checked: boolean, key: string) => {
		const object = { ...state, [key]: checked };
		setState(object);
		onChange(object);
	};

	const Item = (props: { mainKey: keyof IFullFiltersIssueTextBy; translationLastKey: string }): JSX.Element => {
		return (
			<VisibilityControl condition={!!state}>
				<MenuItem>
					<FormControlLabel
						control={
							<Checkbox
								color={Resources.GlobalResources.primary}
								checked={state?.[props.mainKey]}
								onChange={event =>
									onChangeWrapper(event.target.checked, Resources.OptionsKey[props.mainKey])
								}
							/>
						}
						label={
							<Typography variant={Resources.GlobalResources.h5}>
								{t(`Options.${props.translationLastKey}`)}
							</Typography>
						}
					/>
				</MenuItem>
			</VisibilityControl>
		);
	};

	return (
		<div>
			<VisibilityControl condition={isOpen}>
				<Menu
					id={Resources.menuId}
					open={isOpen}
					anchorEl={anchorEl}
					onClose={onClose}
					className={classes.menu}
				>
					<Item mainKey={'Title'} translationLastKey={'title'} />
					<Item mainKey={'DisplayId'} translationLastKey={'displayId'} />
					<Item mainKey={'Description'} translationLastKey={'description'} />
					<Item mainKey={'Comment'} translationLastKey={'comment'} />
					<Item mainKey={'User'} translationLastKey={'user'} />
				</Menu>
			</VisibilityControl>
		</div>
	);
};

export default SearchBy;
