import { INotificationFilterExpandState, ISelectedFilterItems } from '@sac-tt/tasker-types';
import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	defaultOption: { value: {}, label: '' },
	temporary: 'temporary' as const,
	defaultExpandState: { Issue: false, Project: false, TaskerUser: false } as INotificationFilterExpandState,
	defaultSelectedFilterItems: { Project: [], Issue: [], TaskerUser: [] } as ISelectedFilterItems,
	...GlobalResources,
};

export const Translation = {
	pl: {
		NotificationList: {
			Filters: {
				notificationContext: 'Kontekst powiadomienia',
				project: 'Projekt',
				issue: 'Zgłoszenie',
				users: 'Użytkownicy',
				clearFilters: 'Wyczyść filtry',
				applyFilters: 'Zastosuj filtry',
				filters: 'Filtry',
			},
		},
	},
	en: {
		NotificationList: {
			Filters: {
				notificationContext: 'Notification context',
				project: 'Project',
				issue: 'Issue',
				users: 'Users',
				clearFilters: 'Clear filters',
				applyFilters: 'Apply filters',
				filters: 'Filters',
			},
		},
	},
};
