import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

import { Resources as sub } from '../resources';

export const Resources = {
	...sub,
	...GlobalResources,
};

export const Translation = {
	pl: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationElements: {
						DelegationRoute: {
							AddAdditionalRoute: {
								beforeDestinationLabel: '+ Dodaj docelowy etap delegacji',
								afterDestinationLabel: '+ Dodaj powrotny etap delegacji',
								beforeDestinationTooltip: 'Dodaj etap w drodze do głównego celu delegacji',
								afterDestinationTooltip: 'Dodaj etap w drodze powrotnej',
							},
						},
					},
				},
			},
		},
	},
	en: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationElements: {
						DelegationRoute: {
							AddAdditionalRoute: {
								beforeDestinationLabel: '+ Add destination route',
								afterDestinationLabel: '+ Add return route',
								beforeDestinationTooltip: 'Add a step on the way to the destination of the delegation.',
								afterDestinationTooltip: 'Add a step on the way back.',
							},
						},
					},
				},
			},
		},
	},
};
