import clsx from 'clsx';
import { format } from 'date-fns';
import { useContext } from 'react';

import { EFieldDefinitionType } from '@sac-tt/tasker-types';
import { CustomDateTimePicker } from '@sac-tw2/tasker-widgets2';
import { EDateFNS } from '@sac-tw2/tasker-widgets2/dist/utils';

import { Typography } from '@mui/material';

import { IssueFormSectionsWidgetContext } from '../..';
import { IssuePropertyTitle } from '../IssuePropertyTitle';
import { useInitialAttributeDefinitionValues } from '../utils/useInitialAttributeDefinitionValues';

import { Props } from './model';
import { useStyles } from './styles';

export const DateTimeIssueProperty = (props: Props) => {
	const { issueFormSectionToIssueField, inEdit } = props;
	const classes = useStyles();

	const { handleChange, state } = useContext(IssueFormSectionsWidgetContext);

	const AttributeDefinitionId = issueFormSectionToIssueField?.AttributeDefinitionId as number;

	const initial = useInitialAttributeDefinitionValues(issueFormSectionToIssueField);

	const initialInputValue = initial?.length ? (initial?.[0]?.ValueDateTime as Date) : null;

	const newValue = state && (state?.[EFieldDefinitionType.DateTime]?.[AttributeDefinitionId] as Date | undefined);

	const value = newValue ?? initialInputValue ?? null;

	function getNonEditValue() {
		if (initialInputValue) return format(new Date(initialInputValue), EDateFNS.DateWithTime);
		else return '-';
	}

	return (
		<div className={clsx(classes.flexYCenter, classes.container)}>
			<IssuePropertyTitle {...props} />
			<div className={clsx(classes.flexGrow, classes.dateContainer)}>
				<CustomDateTimePicker
					label=""
					handleChange={val => handleChange && handleChange(val as Date, issueFormSectionToIssueField)}
					value={value}
					inEdit={inEdit}
					canSetInputInNonEdit
					nonEditComponent={<Typography className={classes.valueContainer}>{getNonEditValue()}</Typography>}
					hideErrorMessage
				/>
			</div>
		</div>
	);
};
