import { getGlobalStyles } from '@sac-tw2/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(
	(theme: Theme) => {
		return {
			...getGlobalStyles(),
			container: {
				height: '400px',
			},
			content: {
				width: '400px',
				maxHeight: '100%',
				padding: theme.spacing(1),
				overflowY: 'scroll',
			},
			button: {
				padding: `${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(1)} ${theme.spacing(2)}`,
				display: 'flex',
				justifyContent: 'flex-start',
				textDecoration: 'none',
				textTransform: 'none',
			},
			menu: {
				borderRight: `1px solid ${theme.palette.grey[300]}`,
				marginRight: theme.spacing(2),
				alignItems: 'stretch',
				minHeight: '400px',
				width: '200px',
			},
			boldMenuItem: {
				fontWeight: 600,
				fontSize: '15px',
			},
			archivMenuItem: {
				alignItems: 'flex-start',
			},
			subText: {
				textAlign: 'left',
			},
		};
	},
	{ name: `IssueModal_IssueModalTitle_MoreOptions` }
);
