import { IEmployeeAgreement } from '@sac-tt/tasker-types/dist/types/dataBaseTypes/employeeAgreement';

import * as actionTypes from '@/store/actions';
import { SET_EMPLOYEE_AGREEMENT_DETAILS_PAGE } from '@/store/actions';
import { ObjectAction } from '@/store/types';

const State = {
	employeeAgreement: {} as IEmployeeAgreement,
};
export type IEmployeeAgreementDetailsState = typeof State;

const employeeAgreementDetailsReducer = (state = State, action: ObjectAction): IEmployeeAgreementDetailsState => {
	switch (action.type) {
		case SET_EMPLOYEE_AGREEMENT_DETAILS_PAGE: {
			return {
				...state,
				employeeAgreement: action.employeeAgreement,
			};
		}

		default: {
			return state;
		}
	}
};

export default employeeAgreementDetailsReducer;
