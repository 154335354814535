import { IFieldDefinition } from '@sac-tt/tasker-types';
import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	formFieldKey: 'formFieldKey_',
	type: 'FieldDefinitionTypeId' as keyof IFieldDefinition,
	isMandatory: 'IsMandatory' as keyof IFieldDefinition,
	isActive: 'IsActive' as keyof IFieldDefinition,
	name: 'Name' as keyof IFieldDefinition,
	dictionary: 'DictionaryId' as keyof IFieldDefinition,
	tagGroup: 'TagGroupId' as keyof IFieldDefinition,
	warning: 'warning' as const,

	key: 'Key_Section_',
	dash: '_',
};

export const Translation = {
	pl: {
		ProjectDetails: {
			Form: {
				IssueForm: {
					Edit: {
						IssueFormGroup: {
							addRecord: 'Dodaj rekord',
							groupName: 'Nazwa grupy',
							deleteIcon: 'Usuń grupę',
							addGroupIcon: 'Dodaj grupę',
							deleteConfirmation: 'Czy na pewno chcesz usunąć ten rekord?',
							sectionNumber: 'Numer sekcji (zagnieżdżenia): ',
							groupNumberInSection: 'Numer grupy w tej sekcji: ',
						},
					},
				},
			},
		},
	},
	en: {
		ProjectDetails: {
			Form: {
				IssueForm: {
					Edit: {
						IssueFormGroup: {
							addRecord: 'Add record',
							groupName: 'Group name',
							deleteIcon: 'Delete group',
							addGroupIcon: 'Add group',
							deleteConfirmation: 'Are you sure you want to delete this record?',
							sectionNumber: 'Section number (nesting):',
							groupNumberInSection: 'Group number in this section: ',
						},
					},
				},
			},
		},
	},
};
