import { getGlobalStyles } from '@sac-tw2/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(
	(theme: Theme) => ({
		...getGlobalStyles(),
		root: {
			margin: theme.spacing(1.25, 0),
			backgroundColor: theme.palette.background.paper,
			padding: theme.spacing(1),
			borderRadius: '10px',
		},
		pinned: {
			paddingLeft: theme.spacing(0.625),
			textWeight: 'bold',
		},
		content: {
			margin: theme.spacing(2, 5, 0, 5.625),
			wordBreak: 'break-word',
			'& p': {
				minHeight: theme.spacing(3),
			},
		},
	}),
	{ name: `IssueModal_IssueModalContent_IssueDetailsPage_TabsContent_IssueComments_CommendCard` }
);
