import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	leaveTypeDefault: {
		Id: 0,
		Name: '',
		Description: '',
	},
};
export const Translation = {
	pl: {
		LeaveRequestCalendar: {
			Content: {
				title: 'Urlop',
				save: 'Zapisz',
				cancel: 'Anuluj',
				partDay: 'Urlop na część dnia',
				fullDay: 'Urlop na pełny dzień',
			},
		},
	},
	en: {
		LeaveRequestCalendar: {
			Content: {
				title: 'Leave',
				save: 'Save',
				cancel: 'Cancel',
				partDay: 'Leave part day',
				fullDay: 'Leave full day',
			},
		},
	},
};
