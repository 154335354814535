import { getGlobalStyles } from '@sac-tw2/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(
	(theme: Theme) => {
		return {
			...getGlobalStyles(),

			container: {
				position: 'relative',
				minWidth: `calc(${theme.spacing(21)} + 15rem)`,
			},

			text: {
				textOverflow: 'ellipsis',
				width: '100px',
				overflow: 'hidden',
				whiteSpace: 'nowrap',
			},

			// select: {
			// 	'& .select__control': {
			// 		minHeight: '0px',
			// 		border: 'none',
			// 	},

			// 	'& .select__indicator': {
			// 		padding: theme.spacing(0.2),
			// 	},

			// 	'& .select__value-container': {
			// 		paddingLeft: '0px',
			// 	},
			// },

			selectError: {
				'& .select__control': {
					border: '2px solid red',

					'&--is-focused': {
						border: '2px solid red',
					},
				},
			},

			starIcon: {
				position: 'absolute',
				top: '10px',
				left: '-10px',
				color: 'red',
				fontSize: '10px',
			},

			errorText: {
				color: theme.palette.red.main,
			},

			tag: {
				borderRadius: '20px',
				padding: '3px 10px',
				fontSize: '12px',
				marginLeft: theme.spacing(0.5),
			},

			valueContainer: {
				// display: 'grid',
				// gridTemplateColumns: '100px 1fr',
				position: 'relative',
				padding: `${theme.spacing(0.5)} 0 ${theme.spacing(0.5)} ${theme.spacing(0)} `,
				// width: 'max-content',
				marginLeft: theme.spacing(1.5),
			},

			multiValueContainer: {
				'& .select__value-container': {
					padding: '1px 0px 1px 8px !important',
				},
			},

			tagText: {
				maxWidth: '60px',
			},

			counter: {
				padding: theme.spacing(0.3),
				backgroundColor: '#5c6277',
				color: 'white',
				borderRadius: '3px',
				marginLeft: theme.spacing(1),
			},

			icon: {
				fontSize: '15px',
			},

			counterText: {
				color: 'white',
				fontSize: '15px',
			},

			dictionaryItemContainerMargin: {
				marginLeft: theme.spacing(1),
				// backgroundColor: theme.palette.grey[400],
				// borderRadius: '10px',
			},

			dictionaryItemContainer: {
				// backgroundColor: theme.palette.grey[400],
				// borderRadius: '10px',
				// padding: `${theme.spacing(0.2)} ${theme.spacing(1)}`,
				whiteSpace: 'nowrap',
			},

			dictionaryItemText: {
				maxWidth: '75px',
				textOverflow: 'ellipsis',
				overflow: 'hidden',
				whiteSpace: 'nowrap',
			},
			selectContainer: {
				minWidth: `15rem`,
			},
		};
	},
	{
		name: `IssueModalNew.IssueModalContent.IssueDetailsPage.MainIssueData.DependentInputs.IssueFormSection.DictionaryIssueProperty`,
	}
);
