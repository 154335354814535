import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	googleCloudCredentialsLink: 'https://console.cloud.google.com/apis/credentials',
	googleDriveFolderExample: 'https://drive.google.com/drive/u/0/folders/',
	googleDriveFolderIdExample: '1vIUkbokxuUQF_VDuMsAqVoi9u-PpyJuv',
	googleIcon: `/images/google.svg`,
	authcode: 'auth-code' as const,
	scope: 'https://www.googleapis.com/auth/drive.file' as const,
};

export const Translation = {
	pl: {
		OrganizationPanel: {
			TabsContent: {
				Integration: {
					TabsContent: {
						GoogleDrive: {
							100008: 'Status',
							firstStepHeader: 'Krok 1: Aktywacja integracji',
							firstStepDescription: 'Aby aktywować lub dezaktywować integrację, kliknij ikonę poniżej.',
							enabled: 'Integracja aktywowana',
							disabled: 'Integracja dezaktywowana',

							100006: 'Identyfikator folderu',
							secondStepHeader: 'Krok 2: Podanie identyfikatora folderu',
							secondStepDescription:
								'Aby podać identyfikator folderu, musisz przejść do dysku google i skopiować identyfikator folderu z adresu url.',
							secondStepExample: 'Przykład: ',

							thirdStepHeader: 'Krok 3: Autoryzacja konta Google',
							thirdStepDescription1:
								'Autoryzacja konta Google jest wymagana, w celu uzyskania dostępu do tokenu odświeżającego.',
							thirdStepDescription2:
								'Należy wykonać ją z konta Google, które ma dostęp do folderu oraz kluczy autoryzacyjnych podanych w kroku 1.',
							authorize: 'Autoryzuj',

							stepPending: ' - Oczekuje na zakończenie',
							stepCompleted: ' - Zakończono',
							successSave: 'Zapisano dane integracji z google drive',

							errorNoCode: 'Brak kodu autoryzacyjnego w żądaniu. Proszę spróbować ponownie później.',
							errorNoScope:
								'Aby przeprowadzić pomyślnie autoryzacje, potrzebujesz zaznaczyć zgodę na dostęp do plików Google Drive. Spróbuj ponownie.',
						},
					},
				},
			},
		},
	},
	en: {
		OrganizationPanel: {
			TabsContent: {
				Integration: {
					TabsContent: {
						GoogleDrive: {
							100008: 'Status',
							firstStepHeader: 'Step 1: Activation of the integration',
							firstStepDescription: 'To activate or deactivate the integration, click the icon below.',
							enabled: 'Integration activated',
							disabled: 'Integration disactivated',

							100006: 'Folder identifier',
							secondStepHeader: 'Step 2: Provide folder identifier',
							secondStepDescription:
								'To provide the folder identifier, you need to go to the google drive and copy the folder identifier from the url address.',
							secondStepExample: 'Example: ',

							thirdStepHeader: 'Step 3: Google account authorization',
							thirdStepDescription1:
								'Google account authorization is required to access the refresh token.',
							thirdStepDescription2:
								'It must be performed from a Google account that has access to the folder and authorization keys provided in step 1.',
							authorize: 'Authorize',

							stepPending: ' - Pending',
							stepCompleted: ' - Completed',
							successSave: 'Saved integration data from google drive',

							errorNoCode: 'No authorization code in the request. Please try again later.',
							errorNoScope:
								'To successfully authorize, you need to check the access to Google Drive files. Try again.',
						},
					},
				},
			},
		},
	},
};
