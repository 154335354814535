import uuid from 'uuid';

import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	emptyCell: '----------',
	settlementNestedTableElementKey: (key: string) => `SettlementNestedTableElement_${uuid()}_${key}`,
	settlementNestedTableFieldKey: (key: string) => `SettlementNestedTableField_${uuid()}_${key}`,
};

export const Translation = {
	pl: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationSettlement: {
						SettlementTable: {
							SettlementNestedTableNew: {
								timeInCountry: 'Czas spędzony w kraju',
								allowanceSum: 'Suma należności diety za czas spędzony w kraju',
								total: 'Należność całkowita',
								1: 'Śniadanie',
								2: 'Obiad',
								3: 'Kolacja',
								4: 'Pobyt w szpitalu',
								5: 'Zakwaterowanie',
								6: 'Transport publiczny',
							},
						},
					},
				},
			},
		},
	},
	en: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationSettlement: {
						SettlementTable: {
							SettlementNestedTableNew: {
								timeInCountry: 'Time in country',
								allowanceSum: 'Sum of per diem allowances for time spent in the country',
								total: 'Total allowance',
								1: 'Breakfast',
								2: 'Dinner',
								3: 'Supper',
								4: 'Hospital stay',
								5: 'Accommodation',
								6: 'Public transport',
							},
						},
					},
				},
			},
		},
	},
};
