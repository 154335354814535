import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	sadFace: ':(',
};

export const Translation = {
	pl: {
		Settings: {
			WidgetsConfigurationMain: {
				ProfileSelectorConfiguration: {
					activeTeam: 'Aktywna lista',
					noActiveTeams: 'Brak aktywnych list',
					inactiveTeams: 'Nieaktywne listy',
					teamActivated: 'Lista została aktywowana',
					teamDisactivated: 'Lista została dezaktywowana',
					teamDeletedFromList: 'Lista została usunięta',
				},
			},
		},
	},

	en: {
		Settings: {
			WidgetsConfigurationMain: {
				ProfileSelectorConfiguration: {
					activeTeam: 'Activated team',
					noActiveTeams: 'No active teams',
					inactiveTeams: 'Inactive teams',
					teamActivated: 'Team activated',
					teamDisactivated: 'Team disactivated',
					teamDeletedFromList: 'Team has been deleted from your list',
				},
			},
		},
	},
};
