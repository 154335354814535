import { AsyncAction } from '@sac-rp/redux-promisify';
import { IWorkLog, IWorkLogAcceptance } from '@sac-tt/tasker-types';

import { IJson } from '@/store/ITypes';
import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

const basePath = 'WorkLogAcceptance';

export const WORKLOG_ACCEPTANCE_CREATE_NEW: ActionType = 'WORKLOG_ACCEPTANCE_CREATE_NEW';
export const WORKLOG_ACCEPTANCE_CREATE_SINGLE_NEW: ActionType = 'WORKLOG_ACCEPTANCE_CREATE_SINGLE_NEW';

function _createWorklogAcceptance(data: IWorkLogAcceptance[] | IWorkLog[]): Action {
	return {
		type: WORKLOG_ACCEPTANCE_CREATE_NEW,
		data,
	};
}

function _createWorklogAcceptanceSingle(acceptance: IWorkLogAcceptance[]): Action {
	return {
		type: WORKLOG_ACCEPTANCE_CREATE_SINGLE_NEW,
		acceptance,
	};
}

export function create_worklogAcceptance(data: {
	TaskerUserId: number;
	DateRange: { Start: string | Date; End: string | Date };
	IssueId: number;
}): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_createWorklogAcceptance((json as IJson)?.data)));
		return axios().post(`${basePath}/addMany`, data);
	};
}

export function create_worklogAcceptanceMany(data: {
	TaskerUserId: number[];
	DateRange: { Start: string | Date; End: string | Date };
}): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_createWorklogAcceptance((json as IJson)?.data)));
		return axios().post(`${basePath}/users/many`, { ...data, CalendarFetch: true });
	};
}

export function create_worklogAcceptanceSingle(worklogId?: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_createWorklogAcceptance((json as IJson)?.data)));
		return axios().post(`${basePath}/${worklogId}`);
	};
}
