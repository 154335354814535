import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	extended: 'extended' as const,
	add: 'add',
	menu: (id: number) => `Profile_menu_type_${id}`,
};

export const Translation = {
	pl: {
		IssueList: {
			Filters: {
				filterButton: {
					// button: 'Filtry',
					tooltip: 'Filtruj',
				},
			},
		},
	},
	en: {
		IssueList: {
			Filters: {
				filterButton: {
					// button: 'Filter',
					tooltip: 'Filter',
				},
			},
		},
	},
};
