import { AsyncAction } from '@sac-rp/redux-promisify';
import { INotificationFilters, INotifications } from '@sac-tt/tasker-types';

import { IJson } from '@/store/ITypes';
import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

export const SET_USER_NOTIFICATIONS_POPOVER_DATA: ActionType = 'SET_USER_NOTIFICATIONS_POPOVER';
export const SET_USER_NOTIFICATIONS_LIST_DATA: ActionType = 'SET_USER_NOTIFICATIONS_LIST';
export const SET_COUNT_OF_NOT_READ_USER_NOTIFICATIONS: ActionType = 'SET_COUNT_OF_NOT_READ_USER_NOTIFICATIONS';

const basePath = 'UserNotification';

function _set_user_notifications_popover(data: INotifications): Action {
	return {
		type: SET_USER_NOTIFICATIONS_POPOVER_DATA,
		notifications: data?.userNotifications,
		countOfNotReadUserNotifications: data?.countOfNotReadUserNotifications,
		countOfAllUserNotifications: data?.countOfAllUserNotifications,
	};
}

function _set_user_notifications_list(data: INotifications): Action {
	return {
		type: SET_USER_NOTIFICATIONS_LIST_DATA,
		notifications: data?.userNotifications,
		countOfNotReadUserNotifications: data?.countOfNotReadUserNotifications,
		countOfAllUserNotifications: data?.countOfAllUserNotifications,
	};
}

export function set_Count_Of_Not_Read_User_Notifications(countOfNotReadUserNotifications: number): Action {
	return {
		type: SET_COUNT_OF_NOT_READ_USER_NOTIFICATIONS,
		countOfNotReadUserNotifications,
	};
}

export function get_user_notifications_popover(filters?: INotificationFilters): Action {
	return function(context, dispatch) {
		context.then(data => dispatch(_set_user_notifications_popover((data as IJson).data)));
		return axios().post(`${basePath}/from/${0}/count/${6}`, { filters });
	};
}

export function set_All_Notification_As_Read_Popover(skip = 0, take = 6, filters?: INotificationFilters): Action {
	return function(context, dispatch) {
		context.then(data => dispatch(_set_user_notifications_popover((data as IJson).data)));

		return axios().patch(`${basePath}`, { skip, take, filters });
	};
}

export function set_Single_Notification_As_Read_Popover(
	notificationId: number,
	skip: number,
	take: number,
	filters?: INotificationFilters
): AsyncAction {
	return function(context, dispatch) {
		context.then(data => dispatch(_set_user_notifications_popover((data as IJson).data)));
		return axios().patch(`${basePath}/${notificationId}`, { skip, take, filters });
	};
}

export function set_Single_Notification_As_Read_List(
	notificationId: number,
	skip: number,
	take: number,
	filters?: INotificationFilters
): AsyncAction {
	return function(context, dispatch) {
		context.then(data => dispatch(_set_user_notifications_list((data as IJson).data)));
		return axios().patch(`${basePath}/${notificationId}`, { skip, take, filters });
	};
}

export function set_All_Notifications_As_Read_List(
	skip: number,
	take: number,
	filters?: INotificationFilters
): AsyncAction {
	return function(context, dispatch) {
		context.then(() => dispatch(set_Count_Of_Not_Read_User_Notifications(0)));
		return axios().patch(`${basePath}`, { skip, take, filters });
	};
}

export function get_user_notifications_list(from: number, count: number, filters?: INotificationFilters): AsyncAction {
	return function(context, dispatch) {
		context.then(data => dispatch(_set_user_notifications_list((data as IJson).data)));
		return axios().post(`${basePath}/from/${from}/count/${count}`, { filters });
	};
}

export function get_count_notRead_user_notifications(): AsyncAction {
	return function(context, dispatch) {
		context.then(data => dispatch(set_Count_Of_Not_Read_User_Notifications((data as IJson).data)));
		return axios().get(`${basePath}/userNewNotificationCount`);
	};
}
