import { format } from 'date-fns';
import { t } from 'i18next';

import { ICustomer, IInvoice, IInvoiceItem, IOrganization } from '@sac-tt/tasker-types';
import { EDateFNS } from '@sac-tw2/tasker-widgets2/dist/utils';

import { IInvoiceParams, IInvoiceParamsInput, IItem } from 'view/CustomerDetail/Content/Invoices/model';

import { Resources } from './resources';

function getUnits() {
	const unity = [
		Resources.GlobalResources.empty,
		t(`${Resources.translationKey.unity}.1`),
		t(`${Resources.translationKey.unity}.2`),
		t(`${Resources.translationKey.unity}.3`),
		t(`${Resources.translationKey.unity}.4`),
		t(`${Resources.translationKey.unity}.5`),
		t(`${Resources.translationKey.unity}.6`),
		t(`${Resources.translationKey.unity}.7`),
		t(`${Resources.translationKey.unity}.8`),
		t(`${Resources.translationKey.unity}.9`),
	];
	const next = [
		Resources.GlobalResources.empty,
		t(`${Resources.translationKey.next}.11`),
		t(`${Resources.translationKey.next}.12`),
		t(`${Resources.translationKey.next}.13`),
		t(`${Resources.translationKey.next}.14`),
		t(`${Resources.translationKey.next}.15`),
		t(`${Resources.translationKey.next}.16`),
		t(`${Resources.translationKey.next}.17`),
		t(`${Resources.translationKey.next}.18`),
		t(`${Resources.translationKey.next}.19`),
	];
	const tens = [
		Resources.GlobalResources.empty,
		t(`${Resources.translationKey.tens}.10`),
		t(`${Resources.translationKey.tens}.20`),
		t(`${Resources.translationKey.tens}.30`),
		t(`${Resources.translationKey.tens}.40`),
		t(`${Resources.translationKey.tens}.50`),
		t(`${Resources.translationKey.tens}.60`),
		t(`${Resources.translationKey.tens}.70`),
		t(`${Resources.translationKey.tens}.80`),
		t(`${Resources.translationKey.tens}.90`),
	];
	const hundreds = [
		Resources.GlobalResources.empty,
		t(`${Resources.translationKey.hundreds}.100`),
		t(`${Resources.translationKey.hundreds}.200`),
		t(`${Resources.translationKey.hundreds}.300`),
		t(`${Resources.translationKey.hundreds}.400`),
		t(`${Resources.translationKey.hundreds}.500`),
		t(`${Resources.translationKey.hundreds}.600`),
		t(`${Resources.translationKey.hundreds}.700`),
		t(`${Resources.translationKey.hundreds}.800`),
		t(`${Resources.translationKey.hundreds}.900`),
	];
	const uppers = [
		[Resources.GlobalResources.empty, Resources.GlobalResources.empty, Resources.GlobalResources.empty],
		[
			t(`${Resources.translationKey.thousand}.1`),
			t(`${Resources.translationKey.thousand}.2`),
			t(`${Resources.translationKey.thousand}.3`),
		],
		[
			t(`${Resources.translationKey.million}.1`),
			t(`${Resources.translationKey.million}.2`),
			t(`${Resources.translationKey.million}.3`),
		],
		[
			t(`${Resources.translationKey.billion}.1`),
			t(`${Resources.translationKey.billion}.2`),
			t(`${Resources.translationKey.billion}.3`),
		],
		[
			t(`${Resources.translationKey.trillion}.1`),
			t(`${Resources.translationKey.trillion}.2`),
			t(`${Resources.translationKey.trillion}.3`),
		],
		[
			t(`${Resources.translationKey.billiard}.1`),
			t(`${Resources.translationKey.billiard}.2`),
			t(`${Resources.translationKey.billiard}.3`),
		],
		[
			t(`${Resources.translationKey.aTrillion}.1`),
			t(`${Resources.translationKey.aTrillion}.2`),
			t(`${Resources.translationKey.aTrillion}.3`),
		],
	];
	return { unity, next, tens, hundreds, uppers };
}
export const numbersToText = (number: number): string => {
	if (99999999999999999999 < number) return 'Number is to high';
	const { unity, next, tens, hundreds, uppers } = getUnits();
	let result = '';
	let char = '';
	if (number == 0) result = Resources.values['0'];
	if (number < 0) {
		char = Resources.values.minus;
		number = -number;
	}

	let iterator = 0;
	while (number > 0) {
		const hundredsValue = Math.floor((number % 1000) / 100);
		let restTensTmp = 0;
		let restHundreds = Math.floor((number % 100) / 10);
		let restTens = Math.floor(number % 10);

		if (restHundreds == 1 && restTens > 0) {
			restTensTmp = restTens;
			restHundreds = 0;
			restTens = 0;
		}

		let temp2 = 2;
		if (restTens == 1 && hundredsValue + restHundreds + restTensTmp == 0) temp2 = 0;
		if (restTens == 2 || restTens == 3 || restTens == 4) temp2 = 1;
		if (hundredsValue + restHundreds + restTensTmp + restTens > 0)
			result =
				hundreds[hundredsValue] +
				tens[restHundreds] +
				next[restTensTmp] +
				unity[restTens] +
				uppers[iterator][temp2] +
				result;

		iterator++;
		number = Math.floor(number / 1000);
	}
	return char + result + ' PLN';
};

export const prepareDataInvoice = (data: IInvoiceParamsInput): IInvoiceParams => {
	const modifyTemplateData: IInvoiceParams = {} as IInvoiceParams;

	let calculateBrutto1 = 0;
	let sumNetto = 0;
	const payed = data?.Payed;

	const items: IItem[] = data?.Items?.map((x, i) => {
		calculateBrutto1 = calculateBrutto1 + x?.GrossPrice * x?.Quantity;
		sumNetto = sumNetto + x?.NetPrice * x?.Quantity;

		return {
			GrossPrice: x?.GrossPrice,
			Jm: getMeasureKindPLLabel(x?.MeasureKind?.Id) ?? Resources.GlobalResources.empty,
			Lp: i,
			Name: x?.Name,
			NetPrice: x?.NetPrice,
			Quantity: x?.Quantity,
			TotalPriceGross: x?.GrossPrice * x?.Quantity,
			TotalPriceNet: x?.NetPrice * x?.Quantity,
			Vat: x?.Vat,
		};
	});
	const toPay = calculateBrutto1 - payed;

	modifyTemplateData.___BankNumber___ = data?.BankNumber;
	modifyTemplateData.___OrganizationNameSender___ = data?.OrganizationNameSender;
	modifyTemplateData.___PostCodeSender___ = data?.PostCodeSender;
	modifyTemplateData.___CitySender___ = data?.CityReceiver;
	modifyTemplateData.___ContactPhoneSender___ = data?.ContactPhoneSender;
	modifyTemplateData.___NIPSender___ = data?.NIPSender;
	modifyTemplateData.___ContactEMailSender___ = data?.ContactEMailSender;
	modifyTemplateData.___INVOICE_SERIES___ = data?.INVOICE_SERIES;
	modifyTemplateData.___OrganizationNameReceiver___ = data?.OrganizationNameReceiver;
	modifyTemplateData.___PostCodeReceiver___ = data?.PostCodeReceiver;
	modifyTemplateData.___CityReceiver___ = data?.CityReceiver;
	modifyTemplateData.___NIPReceiver___ = data?.NIPReceiver;
	modifyTemplateData.___CreatePlace___ = data?.CreatePlace;
	modifyTemplateData.___DueDate___ = data?.DueDate
		? format(typeof data?.DueDate == 'string' ? new Date(data?.DueDate) : data?.DueDate, EDateFNS.DayMonthYear)
		: Resources.GlobalResources.empty;
	modifyTemplateData.___FormPayment___ = data?.FormPayment;
	modifyTemplateData.___CompleteDate___ = data?.CompleteDate
		? format(
				typeof data?.CompleteDate == 'string' ? new Date(data?.CompleteDate) : data?.CompleteDate,
				EDateFNS.DayMonthYear
		  )
		: Resources.GlobalResources.empty;
	modifyTemplateData.___SumWorld___ = numbersToText(toPay);
	modifyTemplateData.___CSIPN___ = sumNetto.toString();
	modifyTemplateData.___CSIPB___ = calculateBrutto1.toString();
	modifyTemplateData.___Payed___ = payed.toString();
	modifyTemplateData.___ToPay___ = toPay.toString();
	modifyTemplateData.___logo___ = data?.Logo;
	modifyTemplateData.___CreateDate___ = data?.CreateDate;
	modifyTemplateData.Items = items;

	return modifyTemplateData;
};

export function modifyTemplateBasedOnData(
	template: string,
	data: IInvoice,
	organization: IOrganization,
	customer: ICustomer
) {
	return modifyTemplate(template, {
		Items: data?.InvoiceItem as IInvoiceItem[],
		FormPayment: getInvoicePaymentPLLabel(data?.InvoicePayment?.Id) ?? Resources.GlobalResources.empty,
		DueDate: data?.DueDate,
		CreatePlace: 'Empty for now',
		NIPReceiver: organization?.InvoiceDetail?.NIP ?? Resources.GlobalResources.empty,
		CityReceiver: customer?.InvoiceDetails?.CityName ?? Resources.GlobalResources.empty,
		PostCodeReceiver: customer?.InvoiceDetails?.ZipCode ?? Resources.GlobalResources.empty,
		OrganizationNameReceiver: customer?.Name ?? Resources.GlobalResources.empty,
		ContactEMailSender: customer?.InvoiceDetails?.Email ?? Resources.GlobalResources.empty,
		NIPSender: organization?.InvoiceDetail?.NIP ?? Resources.GlobalResources.empty,
		ContactPhoneSender: organization?.InvoiceDetail?.PhoneNumber ?? Resources.GlobalResources.empty,
		PostCodeSender: organization?.InvoiceDetail?.ZipCode ?? Resources.GlobalResources.empty,
		OrganizationNameSender: organization?.Name ?? Resources.GlobalResources.empty,
		BankNumber: organization?.InvoiceDetail?.BankAccount ?? Resources.GlobalResources.empty,
		CitySender: organization?.InvoiceDetail?.CityName ?? Resources.GlobalResources.empty,
		CompleteDate: data?.DateOfCompletion,
		INVOICE_SERIES: `${data?.InvoiceSeries?.Value}${data?.InvoiceSeries?.ValueSeparator}${data?.SerialNumber}`,
		Payed: data?.Payed ?? 0,
		Logo: organization?.InvoiceDetail?.Logo?.LogoUrl ?? Resources.GlobalResources.empty,
		CreateDate: new Date(),
	});
}

export const modifyTemplate = (template: string, data: IInvoiceParamsInput): string => {
	const templateData = prepareDataInvoice(data);

	let htmlTemplate = template;
	const logoInString = templateData[`___logo___`];
	const logo =
		!!templateData[`___logo___`] && templateData[`___logo___`] != ''
			? `<img src="${logoInString}" alt="Company logo" style="width: 80px; height: 40px" />`
			: '';

	const createDate: string =
		typeof templateData[`___CreateDate___`] === 'string'
			? templateData[`___CreateDate___`]
			: format(templateData[`___CreateDate___`], EDateFNS.DayMonthYear);

	htmlTemplate = htmlTemplate.replaceAll(
		`<img src="___logo___" alt="Company logo" style="width: 100%; max-width: 40px" />`,
		logo
	);

	let lines = '';
	templateData.Items.forEach((x: IItem, i: number) => {
		lines += `<tr class="tdAlignCenter dotted-table">
					<td>${i}</td>
					<td>${x?.Name}</td>
					<td>${x?.Jm}</td>
					<td>${x?.Quantity}</td>
					<td>${x?.NetPrice}</td>
					<td>${x?.Vat}</td>
					<td>${x?.GrossPrice}</td>
					<td>${x?.TotalPriceNet}</td>
					<td>${x?.TotalPriceGross}</td>
				</tr>`;
	});
	htmlTemplate = htmlTemplate.replaceAll(`<tr  id="___items___"/>`, lines);

	const cSender = templateData['___CitySender___'];
	const pcSender = templateData['___PostCodeSender___'];
	const cSenderValid = !!(cSender && cSender != '');
	const pcSenderValid = !!(pcSender && pcSender != '');
	const cityPostSender = cSenderValid || pcSenderValid ? `${cSender} ${pcSender}` : '';
	htmlTemplate = htmlTemplate.replaceAll(
		`___OrganizationNameSender___`,
		templateData[`___OrganizationNameSender___`]
	);

	const cReceiver = templateData['___CityReceiver___'];
	const pcReceiver = templateData['___PostCodeReceiver___'];
	const cReceiverValid = !!(cReceiver && cReceiver != '');
	const pcReceiverValid = !!(pcReceiver && pcReceiver != '');
	const cityPostReceiver = cReceiverValid || pcReceiverValid ? `${cSender} ${pcSender}` : '';
	htmlTemplate = htmlTemplate.replaceAll(
		`___OrganizationNameSender___`,
		templateData[`___OrganizationNameSender___`]
	);

	htmlTemplate = htmlTemplate.replaceAll('___PostCodeSender___ ___CitySender___', cityPostSender);
	htmlTemplate = htmlTemplate.replaceAll(`___ContactPhoneSender___`, templateData[`___ContactPhoneSender___`]);
	htmlTemplate = htmlTemplate.replaceAll(`___NIPSender___`, templateData[`___NIPSender___`]);
	htmlTemplate = htmlTemplate.replaceAll(`___ContactEMailSender___`, templateData[`___ContactEMailSender___`]);
	htmlTemplate = htmlTemplate.replaceAll(`___INVOICE_SERIES___`, templateData[`___INVOICE_SERIES___`]);
	htmlTemplate = htmlTemplate.replaceAll(
		`___OrganizationNameReceiver___`,
		templateData[`___OrganizationNameReceiver___`]
	);
	htmlTemplate = htmlTemplate.replaceAll(`___PostCodeReceiver___ ___CityReceiver___`, cityPostReceiver);
	htmlTemplate = htmlTemplate.replaceAll(`___NIPReceiver___`, templateData[`___NIPReceiver___`]);
	htmlTemplate = htmlTemplate.replaceAll(`___CreatePlace___`, templateData[`___CreatePlace___`]);
	htmlTemplate = htmlTemplate.replaceAll(`___DueDate___`, templateData[`___DueDate___`]);
	htmlTemplate = htmlTemplate.replaceAll(`___FormPayment___`, templateData[`___FormPayment___`]);
	htmlTemplate = htmlTemplate.replaceAll(`___CreateDate___`, createDate);
	htmlTemplate = htmlTemplate.replaceAll(`___CompleteDate___`, templateData[`___CompleteDate___`]);
	// htmlTemplate = htmlTemplate.replaceAll(`___items___`, templateData[`___items___`]);
	htmlTemplate = htmlTemplate.replaceAll(`___SumWorld___`, templateData[`___SumWorld___`]);
	htmlTemplate = htmlTemplate.replaceAll(`___CSIPN___`, templateData[`___CSIPN___`]);
	htmlTemplate = htmlTemplate.replaceAll(`___CSIPB___`, templateData[`___CSIPB___`]);

	htmlTemplate = htmlTemplate.replaceAll('___Payed___', templateData[`___Payed___`]);
	htmlTemplate = htmlTemplate.replaceAll('___ToPay___', templateData[`___ToPay___`]);
	htmlTemplate = htmlTemplate.replaceAll('___BankNumber___', templateData[`___BankNumber___`]);

	return htmlTemplate;
};

//Rozwiązanie tymczasowe dopóki nie wprowadzimy tłumaczeń do tego
export const getInvoicePaymentPLLabel = (id?: number): string => {
	switch (id) {
		case 1:
			return 'Gotówka';
		case 2:
			return 'Czek';
		case 3:
			return 'Karta kredytowa';
		case 4:
			return 'Przelew bankowy';
		case 5:
			return 'Płatność internetowa';
		default:
			return '';
	}
};

//Rozwiązanie tymczasowe dopóki nie wprowadzimy tłumaczeń do tego
export const getMeasureKindPLLabel = (id?: number): string => {
	switch (id) {
		case 1:
			return 'Sztuki';
		case 2:
			return 'Metry kwadratowe';
		case 3:
			return 'Metry sześcienne';
		case 4:
			return 'Kilogramy';
		case 5:
			return 'Tony';
		case 6:
			return 'Metry';
		default:
			return '';
	}
};
