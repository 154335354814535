import { AsyncAction } from '@sac-rp/redux-promisify';
import { IDelegationField, IDelegationVisibilityConfiguration } from '@sac-tt/tasker-types';

import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

import { IJson } from '../ITypes';

export const DELEGATION_VISIBILITY_CONFIGURATION_FETCH: ActionType = 'DELEGATION_VISIBILITY_CONFIGURATION_FETCH';
export const DELEGATION_VISIBILITY_CONFIGURATION_MANAGE: ActionType = 'DELEGATION_VISIBILITY_CONFIGURATION_MANAGE';

const basePath = 'Delegation/Visibility';

function _fetchDelegationVisibilityConfiguration(data: IDelegationField[]): Action {
	return {
		type: DELEGATION_VISIBILITY_CONFIGURATION_FETCH,
		data,
	};
}

function _manageDelegationVisibilityConfiguration(data: IDelegationField[]): Action {
	return {
		type: DELEGATION_VISIBILITY_CONFIGURATION_MANAGE,
		data,
	};
}

export function delegation_fetchVisibilityConfiguration(): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchDelegationVisibilityConfiguration((json as IJson).data)));
		return axios().get(`${basePath}/getMany`);
	};
}

export function delegation_manageVisibilityConfiguration(
	data: Partial<IDelegationVisibilityConfiguration>
): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_manageDelegationVisibilityConfiguration((json as IJson).data)));
		return axios().post(`${basePath}/manage`, data);
	};
}
