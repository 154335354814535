import { getGlobalStyles } from '@sac-tw2/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(
	(theme: Theme) => {
		return {
			...getGlobalStyles(),

			container: {
				position: 'relative',
				minWidth: `calc(${theme.spacing(21)} + 15rem)`,
			},

			tag: {
				borderRadius: '20px',
				padding: '3px 10px',
				fontSize: '12px',
				marginLeft: theme.spacing(0.5),
			},

			valueContainer: {
				display: 'grid',
				gridTemplateColumns: '100px 1fr',
				position: 'relative',
				padding: `${theme.spacing(0.5)} 0 ${theme.spacing(0.5)} ${theme.spacing(0)} `,
				width: 'min-content',
			},

			verticaLine: {
				position: 'absolute',
				width: '3px',
				backgroundColor: '#E7922B',
				top: '-5px',
				bottom: '0px',
				left: '-16px',
			},

			horizontalLine: {
				position: 'absolute',
				width: '10px',
				height: '3px',
				backgroundColor: '#E7922B',
				top: '50%',
				left: '-16px',
				transform: 'translateY(-50%)',
			},

			vericalLineForLastElement: {
				top: '-5px',
				bottom: '50%',
				left: '-16px',
			},

			horizontalLineForLastElement: {
				// borderRadius: '0 0 0 20px',
			},

			issueFormSectionTitleContainer: {
				position: 'relative',
			},

			issueFormSectionTitle: {
				fontWeight: '600',
				fontSize: '14px',
				maxWidth: '200px',
			},

			values: {
				marginLeft: theme.spacing(3),
				// marginTop: theme.spacing(0),
				// display: 'grid',
				// gridTemplateColumns: '1fr 1fr 1fr',
				// gridColumnGap: theme.spacing(5),
				// gridRowGap: theme.spacing(1),
			},

			iconButton: {
				padding: '0px',
			},
		};
	},
	{ name: `IssueModalNew.IssueModalContent.IssueDetailsPage.MainIssueData.DependentInputs.IssueFormSection` }
);
