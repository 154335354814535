import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	simpleTable: 'simple-table',
	...GlobalResources,
};

export const Translation = {
	pl: {
		NotificationList: {
			notifications: 'Powiadomienia',
			yourNotifications: 'Twoje powiadomienia',
			notification: 'Powiadomienie',
			priority: 'Priorytet',
			changesMadeBy: 'Zmian dokonał(a)',
			notificationDate: 'Data powiadomienia',
			notificationReadDate: 'Data odczytania',
			noNotificationToShow: 'Brak powiadomień do wyświetlenia ...',
		},
	},
	en: {
		NotificationList: {
			notifications: 'Notifications',
			yourNotifications: 'Your notifications',
			notification: 'Notification',
			priority: 'Priority',
			changesMadeBy: 'Changes made by',
			notificationDate: 'Date of notification',
			notificationReadDate: 'Date read',
			noNotificationToShow: 'There are none notifications to display ...',
		},
	},
};
