import clsx from 'clsx';
import { useContext, useEffect } from 'react';

import { EFieldDefinitionType } from '@sac-tt/tasker-types';
import { VisibilityControl } from '@sac-tw2/tasker-widgets2';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Switch } from '@mui/material';

import { IssueFormSectionsWidgetContext } from '../..';
import { IssuePropertyTitle } from '../IssuePropertyTitle';
import { useInitialAttributeDefinitionValues } from '../utils/useInitialAttributeDefinitionValues';

import { Props } from './model';
import { useStyles } from './styles';

export const BooleanIssueProperty = (props: Props) => {
	const { issueFormSectionToIssueField, inEdit } = props;
	const classes = useStyles();

	const { handleChange, state } = useContext(IssueFormSectionsWidgetContext);

	const AttributeDefinitionId = issueFormSectionToIssueField?.AttributeDefinitionId as number;
	const IsMandatory = issueFormSectionToIssueField?.AttributeDefinition?.IsMandatory;

	const initial = useInitialAttributeDefinitionValues(issueFormSectionToIssueField);

	const initialValue = initial?.length ? (initial?.[0]?.ValueBoolean as boolean) : undefined;

	const newValue = state && state?.[EFieldDefinitionType.Boolean]?.[AttributeDefinitionId];

	const value = newValue ?? initialValue ?? false;

	useEffect(() => {
		if (typeof newValue === 'undefined' && typeof initialValue === 'undefined' && IsMandatory)
			handleInputChange(false);
	}, [newValue, initialValue]);

	const handleInputChange = (isChecked: boolean | null) => {
		if (!handleChange) return;

		handleChange(isChecked ?? false, issueFormSectionToIssueField);
	};

	return (
		<div className={clsx(classes.flexYCenter, classes.container)}>
			<IssuePropertyTitle {...props} />
			<VisibilityControl
				condition={inEdit}
				alternative={
					<VisibilityControl
						condition={typeof initialValue !== 'undefined' && initialValue !== null}
						alternative={<div className={clsx(classes.noData)}>-</div>}
					>
						<VisibilityControl
							condition={!!initialValue}
							alternative={<CloseIcon className={clsx(classes.icon, classes.noIcon)} />}
						>
							<CheckIcon className={clsx(classes.icon, classes.yesIcon)} />
						</VisibilityControl>
					</VisibilityControl>
				}
			>
				<div className={clsx(classes.flexGrow, classes.booleanContainer)}>
					<Switch value={value} onChange={(e, checked) => handleInputChange(checked)} checked={!!value} />
				</div>
			</VisibilityControl>
		</div>
	);
};
