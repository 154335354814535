import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	list: 'list' as const,
};

export const Translation = {
	pl: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationAcceptance: {
						DelegationAcceptanceHistory: {
							history: 'Historia',
						},
					},
				},
			},
		},
	},
	en: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationAcceptance: {
						DelegationAcceptanceHistory: {
							history: 'History',
						},
					},
				},
			},
		},
	},
};
