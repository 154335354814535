import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	NotificationList: {
		routerLinkRedirect: '#',
	},
	muiPaperElevation: 'MuiPaper-elevation2',
	normal: 'NORMAL' as const,
	comment: 'comment' as const,
	standard: 'standard' as const,
	issueId: '?issueId=',
	activityPath: '/activity',
	...GlobalResources,
};

export const Translation = {
	pl: {
		Dashboard: {
			WorklogStarter: {
				button: 'Zobacz wszystkie',
				comment: 'Komentarz',
				save: 'Zapisz',
				issueMore: 'Więcej o zgłoszeniu',
				sleepIssue: 'Nad niczym nie pracujesz...',
				due: 'Termin',
				yourIssues: 'Twoje zgłoszenia',
				worklogTooltip: {
					starterName: 'Zarządzanie czasem',
					action: {
						close: 'Zamknij',
						open: 'Otwórz',
					},
				},
				estimatedTime: 'Szacowany czas',
			},
		},
	},
	en: {
		Dashboard: {
			WorklogStarter: {
				button: 'See all',
				comment: 'Comment',
				save: 'Save',
				issueMore: 'More about the issue',
				sleepIssue: "You're not working...",
				due: 'Due',
				yourIssues: 'Your ActivityIssues',
				worklogTooltip: {
					starterName: 'Worklog manager',
					action: {
						close: 'Close',
						open: 'Open',
					},
				},
				estimatedTime: 'Estimated time',
			},
		},
	},
};

export enum EWorklogState {
	Normal = 'NORMAL',
	Finish = 'FINISH',
	Pause = 'PAUSE',
}
