import { Translation as DelegationListFilters } from './DelegationListFilters/resources';
import { Translation as AcceptanceActions } from './DelegationModal/DelegationModalActions/AcceptanceActions/resources';
import { Translation as AcceptanceEditActions } from './DelegationModal/DelegationModalActions/AcceptanceEditActions/resources';
import { Translation as FieldsRequiredIndicator } from './DelegationModal/DelegationModalActions/FieldsRequiredIndicator/resources';
import { Translation as ListActions } from './DelegationModal/DelegationModalActions/ListActions/resources';
import { Translation as DelegationModalActions } from './DelegationModal/DelegationModalActions/resources';
import { Translation as SettlementAcceptanceActions } from './DelegationModal/DelegationModalActions/SettlementAcceptanceActions/resources';
import { Translation as SettlementAcceptanceEditActions } from './DelegationModal/DelegationModalActions/SettlementAcceptanceEditActions/resources';
import { Translation as CommentCardMenu } from './DelegationModal/DelegationModalContent/DelegationAcceptance/DelegationAcceptanceComments/CommentCard/CommentCardMenu/resources';
import { Translation as CommentCard } from './DelegationModal/DelegationModalContent/DelegationAcceptance/DelegationAcceptanceComments/CommentCard/resources';
import { Translation as EditCommentEditor } from './DelegationModal/DelegationModalContent/DelegationAcceptance/DelegationAcceptanceComments/EditCommentEditor/resources';
import { Translation as NewCommentEditor } from './DelegationModal/DelegationModalContent/DelegationAcceptance/DelegationAcceptanceComments/NewCommentEditor/resources';
import { Translation as DelegationAcceptanceComments } from './DelegationModal/DelegationModalContent/DelegationAcceptance/DelegationAcceptanceComments/resources';
import { Translation as DelegationAcceptanceHistoryCard } from './DelegationModal/DelegationModalContent/DelegationAcceptance/DelegationAcceptanceHistory/HistoryCard/resources';
import { Translation as DelegationAcceptanceHistory } from './DelegationModal/DelegationModalContent/DelegationAcceptance/DelegationAcceptanceHistory/resources';
import { Translation as DelegationAcceptanceInfo } from './DelegationModal/DelegationModalContent/DelegationAcceptance/DelegationAcceptanceInfo/resources';
import { Translation as DelegationAccountingAttachment } from './DelegationModal/DelegationModalContent/DelegationAccounting/DelegationAccountingAttachment/resources';
import { Translation as DelegationAdvances } from './DelegationModal/DelegationModalContent/DelegationDetails/DelegationAdvances/resources';
import { Translation as DelegationSettlementAttachmentsForm } from './DelegationModal/DelegationModalContent/DelegationDetails/DelegationSettlementAttachments/DelegationSettlementAttachmentsForm/resources';
import { Translation as DelegationSettlementAttachmentsFormInfo } from './DelegationModal/DelegationModalContent/DelegationDetails/DelegationSettlementAttachments/DelegationSettlementAttachmentsFormInfo/resources';
import { Translation as DropzoneAttachmentRecord } from './DelegationModal/DelegationModalContent/DelegationDetails/DelegationSettlementAttachments/Dropzone/DropzoneAttachmentRecord/resources';
import { Translation as DropzoneInfo } from './DelegationModal/DelegationModalContent/DelegationDetails/DelegationSettlementAttachments/Dropzone/DropzoneInfo/resources';
import { Translation as Dropzone } from './DelegationModal/DelegationModalContent/DelegationDetails/DelegationSettlementAttachments/Dropzone/resources';
import { Translation as DelegationSettlementAttachments } from './DelegationModal/DelegationModalContent/DelegationDetails/DelegationSettlementAttachments/resources';
import { Translation as DelegationDetails } from './DelegationModal/DelegationModalContent/DelegationDetails/resources';
import { Translation as DelegationDetailsTabs } from './DelegationModal/DelegationModalContent/DelegationDetails/Tabs/resources';
import { Translation as PerDiemAlternative } from './DelegationModal/DelegationModalContent/DelegationElements/DelegationPerDiem/PerDiemAlternative/resources';
import { Translation as PerDiemColorLegend } from './DelegationModal/DelegationModalContent/DelegationElements/DelegationPerDiem/PerDiemColorLegend/resources';
import { Translation as DelegationPerDiemHeader } from './DelegationModal/DelegationModalContent/DelegationElements/DelegationPerDiem/PerDiemRow/PerDiemHeader/resources';
import { Translation as DelegationPerDiemToggleButton } from './DelegationModal/DelegationModalContent/DelegationElements/DelegationPerDiem/PerDiemRow/PerDiemToggleButton/resources';
import { Translation as DelegationPerDiem } from './DelegationModal/DelegationModalContent/DelegationElements/DelegationPerDiem/resources';
import { Translation as AddAdditionalRoute } from './DelegationModal/DelegationModalContent/DelegationElements/DelegationRoute/AddAdditionalRoute/resources';
import { Translation as AdditionalRoute } from './DelegationModal/DelegationModalContent/DelegationElements/DelegationRoute/AdditionalRoute/resources';
import { Translation as BorderCrossing } from './DelegationModal/DelegationModalContent/DelegationElements/DelegationRoute/BorderCrossing/resources';
import { Translation as DestinationRoute } from './DelegationModal/DelegationModalContent/DelegationElements/DelegationRoute/DestinationRoute/resources';
import { Translation as OriginEndRoute } from './DelegationModal/DelegationModalContent/DelegationElements/DelegationRoute/OriginEndRoute/resources';
import { Translation as OriginRoute } from './DelegationModal/DelegationModalContent/DelegationElements/DelegationRoute/OriginRoute/resources';
import { Translation as SettlementAlternative } from './DelegationModal/DelegationModalContent/DelegationSettlement/SettlementAlternative/resources';
import { Translation as SettlementAttachmentsNestedTable } from './DelegationModal/DelegationModalContent/DelegationSettlement/SettlementTable/SettlementAttachmentsNestedTable/resources';
import { Translation as SettlementNestedTableNew } from './DelegationModal/DelegationModalContent/DelegationSettlement/SettlementTable/SettlementNestedTableNew/resources';
import { Translation as SettlementTableGroupRowNew } from './DelegationModal/DelegationModalContent/DelegationSettlement/SettlementTable/SettlementTableGroupRowNew/resources';
import { Translation as DelegationModalContentTabs } from './DelegationModal/DelegationModalContent/Tabs/resources';
import { Translation as DelegationModalTitle } from './DelegationModal/DelegationModalTitle/resources';
import { Translation as DelegationModal } from './DelegationModal/resources';
import { Translation as HeaderSuffix } from './HeaderSuffix/resources';
import { Translation as DelegationsList } from './resources';
import { Translation as DelegationMenu } from './TileComponents/DelegationMenu/resources';
import { Translation as Status } from './TileComponents/Status/resources';

export const DelegationsListTranslationEN = [
	HeaderSuffix.en,
	DelegationsList.en,
	DelegationModalTitle.en,
	DelegationModalContentTabs.en,
	DelegationDetails.en,
	DelegationDetailsTabs.en,
	DelegationModalActions.en,
	FieldsRequiredIndicator.en,
	DelegationSettlementAttachments.en,
	AddAdditionalRoute.en,
	AdditionalRoute.en,
	DestinationRoute.en,
	OriginEndRoute.en,
	OriginRoute.en,
	BorderCrossing.en,
	DelegationPerDiem.en,
	DelegationPerDiemHeader.en,
	PerDiemAlternative.en,
	SettlementAlternative.en,
	Dropzone.en,
	DropzoneInfo.en,
	DropzoneAttachmentRecord.en,
	DelegationPerDiemToggleButton.en,
	SettlementAttachmentsNestedTable.en,
	DelegationSettlementAttachmentsForm.en,
	DelegationSettlementAttachmentsFormInfo.en,
	DelegationMenu.en,
	Status.en,
	DelegationAcceptanceHistoryCard.en,
	DelegationModal.en,
	DelegationAcceptanceComments.en,
	NewCommentEditor.en,
	EditCommentEditor.en,
	CommentCard.en,
	CommentCardMenu.en,
	DelegationAcceptanceHistory.en,
	DelegationAcceptanceInfo.en,
	ListActions.en,
	AcceptanceActions.en,
	AcceptanceEditActions.en,
	SettlementAcceptanceActions.en,
	SettlementAcceptanceEditActions.en,
	DelegationAdvances.en,
	DelegationListFilters.en,
	DelegationAccountingAttachment.en,
	SettlementNestedTableNew.en,
	SettlementTableGroupRowNew.en,
	PerDiemColorLegend.en,
];

export const DelegationsListTranslationPL = [
	HeaderSuffix.pl,
	DelegationsList.pl,
	DelegationModalTitle.pl,
	DelegationModalContentTabs.pl,
	DelegationDetails.pl,
	DelegationDetailsTabs.pl,
	DelegationModalActions.pl,
	FieldsRequiredIndicator.pl,
	DelegationSettlementAttachments.pl,
	AddAdditionalRoute.pl,
	AdditionalRoute.pl,
	DestinationRoute.pl,
	OriginEndRoute.pl,
	OriginRoute.pl,
	BorderCrossing.pl,
	DelegationPerDiem.pl,
	DelegationPerDiemHeader.pl,
	PerDiemAlternative.pl,
	SettlementAlternative.pl,
	Dropzone.pl,
	DropzoneInfo.pl,
	DropzoneAttachmentRecord.pl,
	DelegationPerDiemToggleButton.pl,
	SettlementAttachmentsNestedTable.pl,
	DelegationSettlementAttachmentsForm.pl,
	DelegationSettlementAttachmentsFormInfo.pl,
	DelegationMenu.pl,
	Status.pl,
	DelegationAcceptanceHistoryCard.pl,
	DelegationModal.pl,
	DelegationAcceptanceComments.pl,
	NewCommentEditor.pl,
	EditCommentEditor.pl,
	CommentCard.pl,
	CommentCardMenu.pl,
	DelegationAcceptanceHistory.pl,
	DelegationAcceptanceInfo.pl,
	ListActions.pl,
	AcceptanceActions.pl,
	AcceptanceEditActions.pl,
	SettlementAcceptanceActions.pl,
	SettlementAcceptanceEditActions.pl,
	DelegationAdvances.pl,
	DelegationListFilters.pl,
	DelegationAccountingAttachment.pl,
	SettlementTableGroupRowNew.pl,
	SettlementNestedTableNew.pl,
	PerDiemColorLegend.pl,
];
