import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		Kanban: {
			Filters: {
				Options: {
					Comments: {
						tab: 'Komentarze',
						withComment: 'Komentarze',
						onlyWithComments: 'Tylko z komentarzami',
						onlyWithoutComments: 'Tylko bez komentarzy',
						off: 'Wyłączone',
					},
				},
			},
		},
	},
	en: {
		Kanban: {
			Filters: {
				Options: {
					Comments: {
						tab: 'Comments',
						withComment: 'Comments',
						onlyWithComments: 'Only with comments',
						onlyWithoutComments: 'Only with out comments',
						off: 'Off',
					},
				},
			},
		},
	},
};
