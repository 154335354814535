import { CustomTheme } from '@sac-tw2/tasker-widgets2/dist/utils';
import { getGlobalStyles } from '@sac-tw2/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(
	(theme: CustomTheme) => ({
		...getGlobalStyles(),

		card: {
			display: 'flex',
			margin: '0 10px',
			padding: '15px 0',
			borderBottom: '2px solid #D2D6DD',
		},

		textContainer: {
			marginLeft: theme.spacing(1.5),
		},

		detailsSkeleton: {
			marginTop: theme.spacing(-0.4),
		},

		avatarSkeleton: {
			borderRadius: '7px',
		},
	}),
	{ name: `Dashboard_NotificationPopover_NotificationSkeleton_NotificationSkeletonCard` }
);
