import * as Joi from 'joi';

import { IErrorValidationV2 } from '@sac-tt/tasker-types';

export const useFormValidators = () => {
	const validateSpecificInputs = <T extends string>(
		schema: Joi.Schema,
		inputNames: T[],
		value: { [x: string]: unknown },
		schemaGlobalVariables?: any
	) => {
		const newSchemaData = inputNames.reduce((prev, currInputName) => {
			return {
				...prev,
				[currInputName]: schema.extract(currInputName as string),
			};
		}, {});

		const newSchema = Joi.object(newSchemaData).options({
			abortEarly: false,
			stripUnknown: true,
		});

		const { error } = newSchema.validate(value, schemaGlobalVariables);

		return !error
			? []
			: error.details.map(d => ({
					errorMessage: d.message,
					inputName: (d as any).context.label,
			  }));
	};

	const validate = <T>(scheme: Joi.Schema, dataToValidate: unknown) => {
		const result = scheme.validate(dataToValidate);

		let errors: IErrorValidationV2<T>[] = [];

		if (result?.error) {
			errors = ((result.error.details
				.map(detail => ({
					errorMessage: detail.message,
					inputName: (detail.context as Joi.Context).label,
				}))
				?.filter(error => !!error?.errorMessage) as unknown) as IErrorValidationV2<T>[]) as IErrorValidationV2<
				T
			>[];
		}

		return { errors, validatedValue: result.value };
	};

	return { validateSpecificInputs, validate };
};
