import { AsyncAction } from '@sac-rp/redux-promisify';
import {
	IDelegationDictionary,
	IDelegationDictionaryGroup,
	IDelegationDictionaryItem,
	IDelegationDictionaryItemType,
	IPage,
} from '@sac-tt/tasker-types';

import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

import { IJson } from '../ITypes';

export const DELEGATION_SET_CUSTOM_DICTIONARY_ITEMS: ActionType = 'DELEGATION_SET_CUSTOM_DICTIONARY_ITEMS';
export const DELEGATION_FETCH_CUSTOM_DICTIONARY_ITEMS: ActionType = 'DELEGATION_FETCH_CUSTOM_DICTIONARY_ITEMS';
export const DELEGATION_CREATE_CUSTOM_DICTIONARY_ITEM: ActionType = 'DELEGATION_CREATE_CUSTOM_DICTIONARY_ITEM';
export const DELEGATION_UPDATE_CUSTOM_DICTIONARY_ITEM: ActionType = 'DELEGATION_UPDATE_CUSTOM_DICTIONARY_ITEM';
export const DELEGATION_REMOVE_CUSTOM_DICTIONARY_ITEM: ActionType = 'DELEGATION_REMOVE_CUSTOM_DICTIONARY_ITEM';
export const DELEGATION_FETCH_CUSTOM_DICTIONARY_ITEM_TYPES: ActionType =
	'DELEGATION_FETCH_CUSTOM_DICTIONARY_ITEM_TYPES';

const basePath = 'DelegationDictionaryItem';

function _setDelegationCustomDictionaryItems(data: IPage<IDelegationDictionaryItem[]>): Action {
	return {
		type: DELEGATION_SET_CUSTOM_DICTIONARY_ITEMS,
		data,
	};
}

function _fetchDelegationCustomDictionaryItems(data: IPage<IDelegationDictionaryItem[]>): Action {
	return {
		type: DELEGATION_FETCH_CUSTOM_DICTIONARY_ITEMS,
		data,
	};
}

function _createDelegationCustomDictionaryItem(data: IPage<IDelegationDictionaryItem[]>): Action {
	return {
		type: DELEGATION_CREATE_CUSTOM_DICTIONARY_ITEM,
		data,
	};
}

function _updateDelegationCustomDictionaryItem(data: IPage<IDelegationDictionaryItem[]>): Action {
	return {
		type: DELEGATION_UPDATE_CUSTOM_DICTIONARY_ITEM,
		data,
	};
}

function _removeDelegationCustomDictionaryItem(data: IPage<IDelegationDictionaryItem[]>): Action {
	return {
		type: DELEGATION_REMOVE_CUSTOM_DICTIONARY_ITEM,
		data,
	};
}

function _fetchDelegationCustomDictionaryItemTypes(data: IDelegationDictionaryItemType[]): Action {
	return {
		type: DELEGATION_FETCH_CUSTOM_DICTIONARY_ITEM_TYPES,
		data,
	};
}

export function delegation_setCustomDictionaryItems(data: IPage<IDelegationDictionaryItem[]>): Action {
	return _setDelegationCustomDictionaryItems(data);
}

export function delegation_fetchCustomDictionaryItems(from: number, to: number, dictionaryId: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchDelegationCustomDictionaryItems((json as IJson).data)));
		return axios().post(`${basePath}/from/${from}/count/${to}`, { dictionaryId });
	};
}

export function delegation_createDictionaryItem(data: Partial<IDelegationDictionaryItem>): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_createDelegationCustomDictionaryItem((json as IJson).data)));
		return axios().post(basePath, data);
	};
}

export function delegation_updateDictionaryItem(data: Partial<IDelegationDictionaryItem>, id: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_updateDelegationCustomDictionaryItem((json as IJson).data)));
		return axios().patch(`${basePath}/${id}`, data);
	};
}

export function delegation_removeDictionaryItem(id: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_removeDelegationCustomDictionaryItem((json as IJson).data)));
		return axios().delete(`${basePath}/${id}`);
	};
}

export function delegation_fetchDelegationDictionaryItemTypes(): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchDelegationCustomDictionaryItemTypes((json as IJson).data)));
		return axios().get(`${basePath}/types`);
	};
}
