import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	customerPath: '/customers/',
};

export const Translation = {
	pl: {
		ProjectDetails: {
			Overview: {
				ProjectInfo: {
					GeneralRight: {
						projectOwners: 'Właściciele projektu',
						projectManagers: 'Menadżerowie projektu',
						client: 'Klient:',
						budget: 'Budżet:',
						addProjectOwner: 'Dodaj właściciela projektu',
						addProjectManager: 'Dodaj menadżera projektu',
						projectNameLabel: 'Nazwa projektu',
						chooseCustomer: 'Wybierz klienta',
					},
				},
			},
		},
	},
	en: {
		ProjectDetails: {
			Overview: {
				ProjectInfo: {
					GeneralRight: {
						projectOwners: 'Project owners',
						projectManagers: 'Project managers',
						client: 'Customer:',
						budget: 'Budget:',
						addProjectOwner: 'Add project owner',
						addProjectManager: 'Add project manager',
						projectNameLabel: 'Project name',
						chooseCustomer: 'Choose customer',
					},
				},
			},
		},
	},
};
