const projectField = 'project';
const startDate = 'startDate';
const dueDate = 'dueDate';

import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	fixed: 'fixed' as const,
	defaultSubCollapseState: true,
	TableRow: {
		3: 'TableRow3',
		2: 'TableRow2',
		1: 'TableRow1',
	},
	defaultCollapseState: false,
	defaultId: -1,
	ProjectFilter: {
		ProjectId: {
			field: 'projectId',
		},
		StartDate: {
			Begin: {
				id: 'startDate',
				subSubField: 'begin',
				subField: startDate,
				field: projectField,
			},
			End: {
				id: 'startDate',
				subSubField: 'end',
				subField: startDate,
				field: projectField,
			},
		},
		DueDate: {
			Begin: {
				id: 'dueDateBeginProject',
				subSubField: 'begin',
				subField: dueDate,
				field: projectField,
			},
			End: {
				id: 'dueDateEndProject',
				subSubField: 'end',
				subField: dueDate,
				field: projectField,
			},
		},
	},
};

export const Translation = {
	pl: {
		SearchBar: {
			Filter: {
				ProjectTabFilter: {
					Buttons: {
						close: 'Zamknij',
						clear: 'Wyczyść',
						apply: 'Zastosuj filtry',
					},
					IssueFilter: {
						StartDate: {
							Begin: {
								label: 'Początek',
							},
							End: {
								label: 'Koniec',
							},
						},
						DueDate: {
							Begin: {
								label: 'Początek',
							},
							End: {
								label: 'Koniec',
							},
						}, //8
						AssignedTo: {
							label: 'Przypisano do',
						}, //16
						ReportedBy: {
							label: 'Stworzone przez',
						},
						Status: {
							label: 'Status',
						}, //22
						Priority: {
							label: 'Priorytet',
						},
						Type: {
							label: 'Typ',
						},
						Tags: {
							Group: {
								label: 'Grupa tagów',
							},
							label: 'Tag',
						},
					},
					ProjectFilter: {
						ProjectId: {
							label: 'Projekt',
						},
						StartDate: {
							name: 'Data rozpoczęcia',
							Begin: {
								label: 'Początek',
							},
							End: {
								label: 'Koniec',
							},
						},
						DueDate: {
							name: 'Data końcowa',
							Begin: {
								label: 'Początek',
							},
							End: {
								label: 'Koniec',
							},
						},
					},
				},
			},
		},
	},
	en: {
		SearchBar: {
			Filter: {
				ProjectTabFilter: {
					Buttons: {
						close: 'Close',
						clear: 'Clear',
						apply: 'Apply filters',
					},
					IssueFilter: {
						StartDate: {
							Begin: {
								label: 'Begin',
							},
							End: {
								label: 'End',
							},
						},
						DueDate: {
							Begin: {
								label: 'Begin',
							},
							End: {
								label: 'End',
							},
						}, //8
						AssignedTo: {
							label: 'Assigned to',
						}, //18
						ReportedBy: {
							label: 'Reported by',
						},
						Status: {
							label: 'Status',
						}, //27
						Priority: {
							label: 'Priority',
						},
						Type: {
							label: 'Type',
						},
						Tags: {
							Group: {
								label: 'Tag group',
							},
							label: 'Tag',
						},
					},
					ProjectFilter: {
						ProjectId: {
							label: 'Project',
						},
						StartDate: {
							name: 'Start date',
							Begin: {
								label: 'Begin',
							},
							End: {
								label: 'End',
							},
						},
						DueDate: {
							name: 'Due date',
							Begin: {
								label: 'Begin',
							},
							End: {
								label: 'End',
							},
						},
					},
				},
			},
		},
	},
};
