import { ActivityWorklog, IActivityComment, IIssue, IPage } from '@sac-tt/tasker-types';

import * as actionTypes from '@/store/actions';
import { ObjectAction } from '@/store/types';

const State = {
	activityIssues: {} as IPage<Partial<IIssue>[]>,
	activeIssuePage: 0 as number,
	activityToDosIssues: {} as IPage<Partial<IIssue>[]>,
	currentPage: 0 as number,
	activityWorklogsMonth: [] as ActivityWorklog[],
	activityComments: [] as IActivityComment[],
	activeActiveWorklogPage: 0 as number,
};
export type IActivitiesState = typeof State;

const activitiesReducer = (state = State, action: ObjectAction): IActivitiesState => {
	switch (action.type) {
		case actionTypes.ACTIVITY_WORKLOG_MONTH_GET: {
			return {
				...state,
				activityWorklogsMonth: action?.activity,
			};
		}
		case actionTypes.GET_ACTIVITY_COMMENTS: {
			return { ...state, activityComments: action?.comments };
		}
		case actionTypes.ACTIVITY_FETCH_COMMENT: {
			return { ...state, activityComments: action.activities };
		}
		case actionTypes.ACTIVITY_SET_PAGE: {
			return { ...state, currentPage: action.currentPage };
		}
		case actionTypes.GET_ACTIVITY_ISSUES: {
			if (action?.isToday != undefined)
				return {
					...state,
					activityIssues: action?.issueList,
				};
			else
				return {
					...state,
					activityIssues: action?.issueList,
				};
		}
		case actionTypes.GET_ACTIVITY_ISSUES_TO_DOS: {
			if (action?.isToday != undefined)
				return {
					...state,
					activityToDosIssues: action?.issueList,
				};
			else
				return {
					...state,
					activityToDosIssues: action?.issueList,
				};
		}
		case actionTypes.ACTIVITY_ISSUE_PAGE_DID_CHANGE: {
			return {
				...state,
				activeIssuePage: action.data,
			};
		}
		case actionTypes.ACTIVITY_ACTIVE_WORKLOG_PAGE_DID_CHANGE: {
			return {
				...state,
				activeActiveWorklogPage: action.data,
			};
		}
		default: {
			return state;
		}
	}
};

export default activitiesReducer;
