import { ETaskerTranslationLanguage } from '@sac-tt/tasker-types/dist/types/custom/language';
import { LangResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/languageResources';

type x = { [x: string]: string };
type y = { [x: string]: x | string };

export const merge = (target: y, source: y) => {
	for (const key of Object.keys(source)) {
		if (source[key] instanceof Object && key in target)
			Object.assign(source[key], merge(target[key] as y, source[key] as y));
	}
	Object.assign(target || {}, source);
	return target;
};

export const multipleMerge = (s: any[]) => {
	const x = {};
	s.forEach(obj => {
		merge(x, obj);
	});
	return x;
};

export const printKeys = (obj: any, prefix = ''): any => {
	// @ts-ignore
	return Object.keys(obj).reduce((res, el) => {
		if (Array.isArray(obj[el])) {
			return res;
		} else if (typeof obj[el] === 'object' && obj[el] !== null) {
			return [...res, ...printKeys(obj[el], prefix + el + '.')];
		}
		return [...res, prefix + el];
	}, []);
};

export const printValues = (obj: any, tab: string[]): any => {
	const returnData: string[] = tab ?? [];

	for (const key in obj) {
		if (typeof obj[key] === 'object') {
			printValues(obj[key], returnData);
		} else {
			const data: string = obj[key];
			returnData.push(data);
		}
	}
	return returnData;
};

const assignValues = (key: string, value: any) => {
	const obj: any = {};
	if (!!key && !!value) {
		if (key.indexOf('.') > 0) {
			obj[key.split('.')[0]] = assignValues(key.substring(key.indexOf('.') + 1), value);
		} else {
			obj[key] = value;
		}
	}
	return obj;
};

export const combineTranslationData = (keys: any[], values: any[], language: ETaskerTranslationLanguage) => {
	let mixedObject: any = {};
	keys?.forEach((myKey, index) => {
		mixedObject = multipleMerge([mixedObject, assignValues(keys[index], values[index])]);
	});
	return multipleMerge([
		mixedObject,
		{
			FRONTEND: LangResources[language],
			WIDGETS: LangResources[language],
		},
	]);
};
