import { getGlobalStyles } from '@sac-tw2/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(
	(theme: Theme) => ({
		...getGlobalStyles(),
		noAvatar: {
			border: `2px solid ${theme.palette.background.paper}`,
			marginLeft: -10,
			'&:hover': {
				zIndex: 2,
			},
		},
		avatar: {
			border: `2px solid ${theme.palette.background.paper}`,
			backgroundColor: theme.palette.background.paper,
			marginLeft: -10,
			'&:hover': {
				zIndex: 2,
			},
		},
		selected: {
			//padding: '1px',
			border: `4px solid ${theme.palette.background.paper}`,
			zIndex: 2,
		},
		more: {
			backgroundColor: theme.palette.error.main,
			color: theme.palette.error.contrastText,
			fontSize: 14,
			fontWeight: theme.typography.fontWeightMedium as 'normal',
		},
		textBlack: {
			color: theme.palette.grey[700],
		},
		inheritPosition: {
			position: 'inherit',
		},
	}),
	{ name: 'SearchBar_ProfileSelector_AvatarTooltip' }
);
