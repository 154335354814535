import { IHolidayType } from '@sac-tt/tasker-types';

import * as actionTypes from '@/store/actions';
import { ObjectAction } from '@/store/types';

const State = {
	data: {} as IHolidayType,
};
export type IHolidayTypeDetailsState = typeof State;

const holidayTypeDetailsReducer = (state = State, action: ObjectAction): IHolidayTypeDetailsState => {
	switch (action.type) {
		case actionTypes.HOLIDAY_TYPE_DELETE:
		case actionTypes.HOLIDAY_TYPE_FETCH: {
			return {
				...state,
				data: action.holiday,
			};
		}
		case actionTypes.HOLIDAY_TYPE_RESET: {
			return {
				...state,
				data: {} as IHolidayType,
			};
		}
		default: {
			return state;
		}
	}
};

export default holidayTypeDetailsReducer;
