import { AsyncAction } from '@sac-rp/redux-promisify';
import { ILeaveType, IPage } from '@sac-tt/tasker-types';

import { IJson } from '@/store/ITypes';
import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

const basePath = 'LeaveType';

export const LEAVE_TYPE_FETCH_PAGE: ActionType = 'LEAVE_TYPE_FETCH_PAGE';
export const LEAVE_TYPE_FETCH_SINGLE: ActionType = 'LEAVE_TYPE_FETCH_SINGLE';
export const LEAVE_TYPE_DELETE: ActionType = 'LEAVE_TYPE_DELETE';
export const LEAVE_TYPE_ALL: ActionType = 'LEAVE_TYPE_ALL';

export function _fetchLeaveTypes(data: ILeaveType[]): Action {
	return {
		type: LEAVE_TYPE_ALL,
		data,
	};
}

export function _fetchPageLeaveType(data: IPage<ILeaveType[]>): Action {
	return {
		type: LEAVE_TYPE_FETCH_PAGE,
		data,
	};
}

export function _fetchSingleLeaveType(data: ILeaveType): Action {
	return {
		type: LEAVE_TYPE_FETCH_SINGLE,
		data,
	};
}

export function resetLeaveType(): Action {
	return {
		type: LEAVE_TYPE_FETCH_SINGLE,
		data: {},
	};
}

export function _deleteLeaveType(id: number): Action {
	return {
		type: LEAVE_TYPE_DELETE,
		data: id,
	};
}

export function fetchLeaveTypePage(from = 0, to = 6): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchPageLeaveType((json as IJson).data)));
		return axios().get(`${basePath}/from/${from}/to/${to}`);
	};
}

export function fetchLeaveTypeSingle(id: number): AsyncAction {
	if (!id) throw new Error('Not found id');
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchSingleLeaveType((json as IJson).data)));
		return axios().get(`${basePath}/${id}`);
	};
}

export function deleteLeaveType(id: number): AsyncAction {
	if (!id) throw new Error('Not found id');
	return function(context, dispatch) {
		context.then(() => dispatch(_deleteLeaveType(id)));
		return axios().delete(`${basePath}/${id}`);
	};
}

export function createLeaveType(leaveType: Partial<ILeaveType>): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchPageLeaveType((json as IJson).data)));
		return axios().post(basePath, { ...leaveType });
	};
}

export function updateLeaveType(leaveType: Partial<ILeaveType>, id: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchPageLeaveType((json as IJson).data)));
		return axios().patch(`${basePath}/${id}`, { ...leaveType });
	};
}

export function getAllLeaveTypes(): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchLeaveTypes((json as IJson).data)));
		return axios().get(`${basePath}/all`);
	};
}
