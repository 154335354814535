import { AsyncAction } from '@sac-rp/redux-promisify';
import { IActivityComment, IActivityIssues } from '@sac-tt/tasker-types';

import { IJson } from '@/store/ITypes';
import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

const basePath = 'Activities';

export const ACTIVITY_FETCH_DAY: ActionType = 'ACTIVITY_FETCH_DAY';
export const ACTIVITY_FETCH_COMMENT: ActionType = 'ACTIVITY_FETCH_COMMENT';
export const ACTIVITY_RESET: ActionType = 'ACTIVITY_RESET';

export const GET_ACTIVITY_ISSUES: ActionType = 'GET_ACTIVITY_ISSUES';
export const GET_ACTIVITY_ISSUES_TO_DOS: ActionType = 'GET_ACTIVITY_ISSUES_TO_DOS';
export const GET_ACTIVITY_COMMENTS: ActionType = 'GET_ACTIVITY_COMMENTS';
export const ACTIVITY_SET_PAGE: ActionType = 'ACTIVITY_SET_PAGE';
export const ACTIVITY_ISSUE_PAGE_DID_CHANGE: ActionType = 'ACTIVITY_ISSUE_PAGE_DID_CHANGE';
export const ACTIVITY_ACTIVE_WORKLOG_PAGE_DID_CHANGE: ActionType = 'ACTIVITY_ACTIVE_WORKLOG_PAGE_DID_CHANGE';

function _getActivityComments(comments: IActivityComment[]): Action {
	return {
		type: GET_ACTIVITY_COMMENTS,
		comments,
	};
}

function _getActivityIssues(issueList: IActivityIssues): Action {
	return {
		type: GET_ACTIVITY_ISSUES,
		issueList,
	};
}

function _getActivityToDosIssues(issueList: IActivityIssues): Action {
	return {
		type: GET_ACTIVITY_ISSUES_TO_DOS,
		issueList,
	};
}

export function activitySetPage(currentPage: number): Action {
	return {
		type: ACTIVITY_SET_PAGE,
		currentPage,
	};
}

export function comments_activity_get(userId: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_getActivityComments((json as IJson).data)));
		return axios().get(`${basePath}/comments/${userId}`);
	};
}

export function issues_activity_get_frame(from: number, count: number, name = ''): AsyncAction {
	return function(context, dispatch) {
		context.then(data => dispatch(_getActivityIssues((data as IJson).data)));
		return axios().post(`${basePath}/issues`, { name, from, count });
	};
}

export function issues_activityToDos_get_frame(from: number, count: number, name = ''): AsyncAction {
	return function(context, dispatch) {
		context.then(data => dispatch(_getActivityToDosIssues((data as IJson).data)));
		return axios().post(`${basePath}/toDo`, { name, from, count });
	};
}

export function activitySetIssuePage(currentPage: number): Action {
	return {
		type: ACTIVITY_ISSUE_PAGE_DID_CHANGE,
		data: currentPage,
	};
}

export function activitySetWorklogsPage(currentPage: number): Action {
	return {
		type: ACTIVITY_ACTIVE_WORKLOG_PAGE_DID_CHANGE,
		data: currentPage,
	};
}
