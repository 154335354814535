const customerField = 'customer';

import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	fixed: 'fixed' as const,
	field: customerField,
	subField: 'customerId',
	tableRow0: 'TableRow0' as const,
};

export const Translation = {
	pl: {
		SearchBar: {
			Filter: {
				CustomerTabFilter: {
					label: 'Klient',
				},
			},
		},
	},
	en: {
		SearchBar: {
			Filter: {
				CustomerTabFilter: {
					label: 'Klient',
				},
			},
		},
	},
};
