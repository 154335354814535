import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	temporary: 'temporary' as const,
	nodeId: (id = '-1', y: string) => `${y}_treeView_${id}`,
	key: (id = '-1', y: string) => `${y}_treeView_key_${id}`,
	customer: 'customer',
	project: 'project',
	tag: 'tag',
	status: 'status',
	treeViewSx: { maxHeight: 500, flexGrow: 1, overflowY: 'auto' },
	textFieldStyles: {
		'.MuiInputBase-input': {
			fontSize: '1rem',
			paddingTop: '0.3rem',
			paddingBottom: '0.3rem',
		},
	},
};

export const Translation = {
	pl: {
		IssueList: {
			Filters: {
				Options: {
					TagFilter: {
						tags: 'Tagi',
						search: 'Wyszukaj pozycji na drzewku',
					},
				},
			},
		},
	},
	en: {
		IssueList: {
			Filters: {
				Options: {
					TagFilter: {
						tags: 'Tags',
						search: 'Search record on tree',
					},
				},
			},
		},
	},
};
