import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		Reckon: {
			Agreements: {
				AgreementEdit: {
					SalaryForm: {
						SalarySummaryStep: {
							mainTitle: 'Podsumowanie',
							year: 'rok',
							month: 'miesiąc',
							week: 'tydzień',
							untilTheEnd: 'Do zakończenia umowy',
						},
					},
				},
			},
		},
	},
	en: {
		Reckon: {
			Agreements: {
				AgreementEdit: {
					SalaryForm: {
						SalarySummaryStep: {
							mainTitle: 'Summary',
							year: 'year',
							month: 'month',
							week: 'week',
							untilTheEnd: 'Until the end of the contract',
						},
					},
				},
			},
		},
	},
};
