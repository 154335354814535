import { IIssue, IIssueIssueModal } from '@sac-tt/tasker-types';
import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	issueId: '?issueId=',
	unitsInterval: ['minutes'],
	listWeek: 'listWeek',
	backgroundColor: 'backgroundColor',
	dayGridMonth: 'dayGridMonth',
	digit2: '2-digit' as const,
	background: 'background',
	red: 'rgba(255,0,0, 0.6)',
	redString: 'red',
	blueString: 'blue',
	greenString: 'green',
	plSmall: 'pl',
	pl: 'PL-PL',
	invalidDate: 'Invalid Date',
	dimensionCalendar: 'calc(100% - 20px)',
	clearIssue: {
		Issue: {} as IIssue,
		Comments: [],
		UserWorkLog: [],
		IsPortalUser: false,
	} as IIssueIssueModal,
	locked: 'Resources locked',
	...GlobalResources,
};

export const Translation = {
	pl: {
		Calendar: {
			calendar: 'Kalendarz',
			timeIsToShort: 'Czas zakończenia zdarzenia musi zachodzić po rozpoczęciu.',
			idNotExists:
				'Brak możliwości edycji tego rekordu z powodu braku wpisu w bazie danych lub błędu połączenia.',
			timeEstimate: 'Czas: ',
			wrongUserDay: 'Nie jesteś właścicielem tego czasu pracy. Więc nie możesz go zmienić.',
			TimeFormat: {
				hours: 'Godzin',
				hour: 'Godzina',
				minute: 'Minuta',
				minutes: 'Minut',
			},
			workLogDelete: 'Usunięto wpis czasu pracy',
			errorWorkLogDelete: 'Wystąpił błąd podczas usuwania wpisu czasu pracy',
			locked:
				'Zapis czasu pracy, który próbujesz zmodyfikować został już zaakceptowany w związku z tym modyfikacja jest zabroniona.',
			error: 'Nastąpił błąd systemowy proszę o kontakt z administracją.',
			deleteWorklog: 'Czy na pewno chcesz usunąć zadanie?',
		},
	},
	en: {
		Calendar: {
			calendar: 'Calendar ',
			timeIsToShort: 'End date have to be after start date.',
			idNotExists: 'No possibility edit record because no data in database or connection error.',
			timeEstimate: 'Time: ',
			wrongUserDay: "You are not owner of this work time record. So u can't change it.",
			TimeFormat: {
				hours: 'Hours',
				hour: 'Hour',
				minute: 'Minute',
				minutes: 'Minutes',
			},
			workLogDelete: 'Worklog deleted',
			errorWorkLogDelete: 'Error while deleting worklog',
			locked: `Worklog is locked, because of that worklog cano't be changed.`,
			error: 'System error. Please contact administration.',
			deleteWorklog: 'Are you sure you want to delete this task?',
		},
	},
};

export enum CalendarView {
	listWeek = 'listWeek',
	timeGridDay = 'timeGridDay',
	timeGridWeek = 'timeGridWeek',
	dayGridMonth = 'dayGridMonth',
	dayGridCustom = 'dayGridCustom',
}

export enum DateRange {
	start = 'start',
	end = 'end',
}

export const calendarDefaultFormatObject = {
	hour: Resources.digit2,
	minute: Resources.digit2,
	hour12: false,
};

export enum EDay {
	Monday = 1,
	Tuesday = 2,
	Wednesday = 3,
	Thursday = 4,
	Friday = 5,
	Saturday = 6,
	Sunday = 0,
}
