import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		ProjectDetails: {
			Overview: {
				ProjectInfo: {
					GeneralLeft: {
						createdBy: 'Stworzony przez:',
						projectPrefix: 'Prefiks projektu:',
						iconLink: 'Link do ikony',
					},
				},
			},
		},
	},
	en: {
		ProjectDetails: {
			Overview: {
				ProjectInfo: {
					GeneralLeft: {
						createdBy: 'Created by:',
						projectPrefix: 'Project prefix:',
						iconLink: 'Link to icon',
					},
				},
			},
		},
	},
};
