import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	tooltip: 'tooltip' as const,
	...GlobalResources,
	english: 'English',
	polish: 'Polski',
	arabic: 'عبري',
};

export const Translation = {
	pl: {
		Dashboard: {
			TopBar: {
				LanguageButton: {
					tooltip: 'Wybierz język',
				},
			},
		},
	},
	en: {
		Dashboard: {
			TopBar: {
				LanguageButton: {
					tooltip: 'Choose language',
				},
			},
		},
	},
};
