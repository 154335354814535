import Axios, { CancelTokenSource } from 'axios';

import { AsyncAction } from '@sac-rp/redux-promisify';
import {
	DeepPartial,
	FullFilters,
	IComment,
	IIssue,
	IIssueIssueModal,
	IPage,
	IWorkableIssue,
	IWorkLog,
} from '@sac-tt/tasker-types';
import { IPortalFilterOptions } from '@sac-tt/tasker-types/dist/types/endPoints/portalFilter';

import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

import { IJson } from '../ITypes';

const basePath = 'Issue';
const worklogPath = 'Worklog';

export const ISSUE_FETCH_NEW: ActionType = 'ISSUE_FETCH_NEW';
export const ISSUE_FETCH_PORTAL: ActionType = 'ISSUE_FETCH_PORTAL';
export const ISSUE_FETCH_UPDATE: ActionType = 'ISSUE_FETCH_UPDATE';
export const ISSUE_RE_FETCH: ActionType = 'ISSUE_RE_FETCH';
export const ISSUE_RESET: ActionType = 'ISSUE_RESET';
export const ISSUE_FETCH_WORKABLE_NEW: ActionType = 'ISSUE_FETCH_WORKABLE_NEW';
export const SEARCH_FETCH_WORKABLE_ISSUES: ActionType = 'SEARCH_FETCH_WORKABLE_ISSUES';
export const ISSUE_FETCH_WORKABLE_LAST: ActionType = 'ISSUE_FETCH_WORKABLE_LAST';
export const CLEAR_WORKABLE_ISSUES: ActionType = 'CLEAR_WORKABLE_ISSUES';
export const ISSUE_FETCH_ONE_NEW: ActionType = 'ISSUE_FETCH_ONE_NEW';
export const ISSUE_TIME_MODAL_LOCAL: ActionType = 'ISSUE_TIME_MODAL_LOCAL';
export const ISSUE_DELETE: ActionType = 'ISSUE_DELETE';
export const ISSUE_FETCH_OPTIONS: ActionType = 'ISSUE_FETCH_OPTIONS';
export const SET_PAGE: ActionType = 'SET_PAGE';
export const FILTER_FETCH: ActionType = 'FILTER_FETCH';
export const ISSUE_LIST_RESET: ActionType = 'ISSUE_LIST_RESET';

export function setPage(page: number): Action {
	return {
		type: SET_PAGE,
		data: page,
	};
}

export function reFetch_IssueList(filters: FullFilters): Action {
	return {
		type: ISSUE_RE_FETCH,
		filters,
	};
}

function _fetchIssuesV2(issues: { count: number; issue: IIssue[] }): Action {
	return {
		type: ISSUE_FETCH_NEW,
		issues,
	};
}

function _fetchIssuesOptions(issues: { count: number; issue: IIssue[] }): Action {
	return {
		type: ISSUE_FETCH_OPTIONS,
		issues,
	};
}

function _fetchWorkableIssues_NEW(data: IWorkableIssue, from: number): Action {
	return {
		type: ISSUE_FETCH_WORKABLE_NEW,
		workableIssues: data?.WorkableIssues?.Data,
		workableIssuesCount: data?.WorkableIssues?.Count,
		activeIssues: data?.Active,
		from: from,
	};
}
function _fetchSearchWorkableIssues(data: IPage<IIssue[]>): Action {
	return {
		type: SEARCH_FETCH_WORKABLE_ISSUES,
		SearchWorkableIssues: data,
	};
}

function _fetchWorkableLastIssues_NEW(data: IIssue): Action {
	return {
		type: ISSUE_FETCH_WORKABLE_LAST,
		data: data,
	};
}

export function _clearWorkableIssues(): Action {
	return {
		type: CLEAR_WORKABLE_ISSUES,
	};
}

export function issueTimeModalLocalSet(issue: IIssue): Action {
	return {
		type: ISSUE_TIME_MODAL_LOCAL,
		issue,
	};
}

// export function setIssueSubtasks(data: IPage<IIssue[]>): Action {
// 	return {
// 		type: SET_ISSUE_SUBTASKS,
// 		issueSubtasks: data.Data,
// 		issueSubtasksCount: data.Count,
// 	};
// }

export function _updateIssueNew(data: IIssueIssueModal): Action {
	return {
		type: ISSUE_FETCH_ONE_NEW,
		data,
	};
}

function _deleteIssue(id: number, swimlaneId: number | undefined): Action {
	return {
		type: ISSUE_DELETE,
		id,
		swimlaneId,
	};
}

export function issue_reset(): Action {
	return {
		type: ISSUE_RESET,
	};
}

export function issue_list_reset(): Action {
	return {
		type: ISSUE_LIST_RESET,
	};
}

function _fetchIssuesPortal(issues: { count: number; issue: IIssue[] }): Action {
	return {
		type: ISSUE_FETCH_PORTAL,
		data: issues,
	};
}

function _fetchFilterStatusOptions(data: IPortalFilterOptions): Action {
	return {
		type: FILTER_FETCH,
		data,
	};
}

let cancelTokenIssueFetch: CancelTokenSource | undefined;
export function issue_fetchWithParams(params: FullFilters, from?: number, count?: number): AsyncAction {
	if (cancelTokenIssueFetch) cancelTokenIssueFetch.cancel();
	cancelTokenIssueFetch = Axios.CancelToken.source();
	const secondPartOfPart = count ? `/from/${from}/count/${count}` : '';
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchIssuesV2((json as IJson)?.data)));
		return axios().post(`${basePath}${secondPartOfPart}`, params);
	};
}

export function issue_fetchWithParamsOptionsNew(
	params: DeepPartial<FullFilters>,
	from?: number,
	count?: number
): AsyncAction {
	const secondPartOfPart = count ? `/from/${from}/count/${count}` : '';
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchIssuesOptions((json as IJson)?.data)));
		return axios().post(`${basePath}${secondPartOfPart}`, params);
	};
}

export function issue_fetchWorkable(from: number, count: number, tab: string): AsyncAction {
	return function(context, dispatch) {
		context.then(json => {
			dispatch(_fetchWorkableIssues_NEW((json as IJson).data, from));
		});
		return axios().get(`${basePath}/Workable/${from}/${count}/${tab}`);
	};
}

export function get_searchWorkableIssues(text: string, skip: number, take: number, tab: string): AsyncAction {
	return function(context, dispatch) {
		context.then(data => dispatch(_fetchSearchWorkableIssues((data as IJson).data)));
		return axios().get(`${basePath}/Workable/Search/${text}/take/${take}/skip/${skip}/tab/${tab}`);
	};
}

export function get_Last_Workable_Issues(skip: number, take: number, taskerUserId: number): AsyncAction {
	return function(context, dispatch) {
		context.then(data => dispatch(_fetchWorkableLastIssues_NEW((data as IJson).data)));
		return axios().post(`${worklogPath}/LastUserWorkLogIssues`, { skip, take, taskerUserId });
	};
}

export function issue_fetchOneNew(id: number): AsyncAction {
	if (!id || id < 1) throw new Error('There is no ID');
	return function(context, dispatch) {
		context.then(data => dispatch(_updateIssueNew((data as IJson).data)));
		return axios().get(`${basePath}/${id}`);
	};
}

export function issue_fetchOnePortal(id: number): AsyncAction {
	if (!id || id < 1) throw new Error('There is no ID');
	return function(context, dispatch) {
		context.then(data => dispatch(_updateIssueNew((data as IJson).data)));
		return axios().get(`${basePath}/Portal/${id}`);
	};
}

export function issue_clearFetchOneData(): void {
	_updateIssueNew({
		Issue: {} as IIssue,
		Comments: [] as IComment[],
		UserWorkLog: [] as IWorkLog[],
		IsPortalUser: false,
	});
}

export function issue_delete(issueId: number, swimlaneId: number | undefined): AsyncAction {
	return function(context, dispatch) {
		context.then(() => dispatch(_deleteIssue(issueId, swimlaneId)));
		return axios().delete(`${basePath}/${issueId}`);
	};
}

export function issue_restore(issueId: number): AsyncAction {
	return function(context, dispatch) {
		context.then(data => dispatch(_updateIssueNew((data as IJson).data)));
		return axios().post(`${basePath}/restore/${issueId}`);
	};
}

export function issue_fetchPortal(from: number, count: number, filters?: FullFilters): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchIssuesPortal((json as IJson)?.data)));
		return axios().post(`${basePath}/Portal/from/${from}/count/${count}`, filters);
	};
}

export function get_Issue_Filter_Status_Options(): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchFilterStatusOptions((json as IJson).data ?? {})));
		return axios().post(`${basePath}/Status/All`);
	};
}
