import uuid from 'uuid';

import { IDelegationDictionaryItem } from '@sac-tt/tasker-types';
import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

import { IModalData } from '@/views/DelegationsList/DelegationModal/model';

import { Resources as sub } from '../resources';

export const Resources = {
	...sub,
	...GlobalResources,
	getTransportTypeKey: (item: IDelegationDictionaryItem) => `Transport_${item?.Id}_${uuid()}`,
};

export const Translation = {
	pl: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationElements: {
						DelegationRoute: {
							DestinationRoute: {
								legend: 'Miejsce docelowe',
								unspecified: 'nieokreślone',
								dateTimePickerLabel: 'Data wyjazdu',
								distanceInputLabel: 'Dystans (km)',
								locationInputLabel: 'Miejsce wyjazdu',
								transportSelectLabel: 'Środek transportu',
								carPlateNumberLabel: 'Numer rejestracyjny pojazdu',
								transport_1: 'Samochód prywatny powyżej 900 cm3',
								transport_2: 'Samochód prywatny do 900 cm3',
								transport_3: 'Samochód służbowy do 900 cm3',
								transport_4: 'Samochód służbowy powyżej 900 cm3',
								transport_5: 'Samolot',
								transport_6: 'Pociąg',
								transport_7: 'Statek',
							},
						},
					},
				},
			},
		},
	},
	en: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationElements: {
						DelegationRoute: {
							DestinationRoute: {
								legend: 'Destination',
								unspecified: 'unspecified',
								dateTimePickerLabel: 'Departure date',
								distanceInputLabel: 'Distance (km)',
								locationInputLabel: 'Departure location',
								transportSelectLabel: 'Mean of transport',
								carPlateNumberLabel: 'Car plate number',
								transport_1: 'Private car above 900 cm3',
								transport_2: 'Private car below 900 cm3',
								transport_3: 'Company car below 900 cm3',
								transport_4: 'Company car above 900 cm3',
								transport_5: 'Plane',
								transport_6: 'Train',
								transport_7: 'Ship',
							},
						},
					},
				},
			},
		},
	},
};

export const getLegendSuffix = (modalData: IModalData, unspecified: string) => {
	const location = modalData?.delegationDetails?.destination?.location?.displayName;
	return location ? ` - ${location}` : ` - ${unspecified}`;
};
