import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	inputPropsRefs: 'Name',
	hasErrors: 'Name',
	fileId: 'Name',
	customerName: 'Name',
	...GlobalResources,
};

export const Translation = {
	pl: {
		CustomerCreate: {
			AboutCustomer: {
				customerNameLabel: 'Nazwa klienta',
			},
		},
	},
	en: {
		CustomerCreate: {
			AboutCustomer: {
				customerNameLabel: 'Customer name',
			},
		},
	},
};
