import { t } from 'i18next';

import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	overviewTab: { name: 'overview' },
	issuesTab: { name: 'issues' },
	clockTagsTab: { name: 'clock' },
	formTab: { name: 'form' },
	issueConfigTab: { name: 'issueConfig' },
	mapPortalStatus: { name: 'statusPortalConfig' },
	kanbans: { name: 'kanbans' },
	forms: { name: 'form' },
	clockConfiguration: { name: 'clockConfiguration' },
	projectUsersActiveTasksTab: { name: 'activeUserTasks' },
	configureProjectUsersTab: { name: 'configureProjectMembers' },
	formNew: { name: 'formNew' },
	formEdit: { name: 'formEdit' },
	projectNesting: { name: 'projectNesting' },
	projectBudgetConfiguration: { name: 'projectBudgetConfiguration' },
	projectBudgetShow: { name: 'projectBudgetShow' },
	projectBudget: { name: 'projectBudget' },
};

export const Translation = {
	pl: {
		ProjectDetail: {
			TabsContent: {
				overviewTab: { label: 'Ogólne' },
				issuesTab: { label: 'Zgłoszenia' },
				formTab: { label: 'Formularz zgłoszeniowy' },
				mapPortalStatus: { label: 'Dopasowanie tagów' },
				clockTagsTab: { label: 'Konfiguracja tagów zegarka' },
				projectUsersActiveTasksTab: { label: 'Aktywne zadania w projekcie' },
				configureProjectUsersTab: { label: 'Konfiguracja członków projektu' },
				forms: { label: 'Formularze zgłoszeniowe' },
				projectNesting: { label: 'Relacje projektowe' },
				projectBudgetConfiguration: { label: 'Konfiguracja budżetu projektowego' },
				projectBudgetShow: { label: 'Prezentacja budżetu projektowego' },
				projectBudget: { label: 'Konfiguracja budżetu' },
			},
		},
	},
	en: {
		ProjectDetail: {
			TabsContent: {
				overviewTab: { label: 'Overview' },
				issuesTab: { label: 'Issues' },
				formTab: { label: 'Application form' },
				mapPortalStatus: { label: 'Match tags' },
				clockTagsTab: { label: 'Clock tags configuration' },
				projectUsersActiveTasksTab: { label: 'Active tasks in project' },
				configureProjectUsersTab: { label: 'Configure project members' },
				forms: { label: 'Forms' },
				projectNesting: { label: 'Project relations' },
				projectBudgetConfiguration: { label: 'Configure project budget' },
				projectBudgetShow: { label: 'Show project budget' },
				projectBudget: { label: 'Configure project budget' },
			},
		},
	},
};

export const getTabs = (): { value: string; label?: string; visible: boolean }[] => {
	return [
		{ value: Resources.overviewTab.name, label: t('ProjectDetail.TabsContent.overviewTab.label'), visible: true },
		{ value: Resources.issuesTab.name, label: t('ProjectDetail.TabsContent.issuesTab.label'), visible: true },
		{
			value: Resources.projectUsersActiveTasksTab.name,
			label: t('ProjectDetail.TabsContent.projectUsersActiveTasksTab.label'),
			visible: true,
		},
		{
			value: Resources.configureProjectUsersTab.name,
			label: t('ProjectDetail.TabsContent.configureProjectUsersTab.label'),
			visible: true,
		},
		{
			value: Resources.forms.name,
			label: t('ProjectDetail.TabsContent.forms.label'),
			visible: true,
		},
		{
			value: Resources.formEdit.name,
			visible: false,
		},
		{
			value: Resources.formNew.name,
			visible: false,
		},
		{
			value: Resources.projectNesting.name,
			label: t('ProjectDetail.TabsContent.projectNesting.label'),
			visible: true,
		},
		{
			value: Resources.projectBudgetShow.name,
			label: t('ProjectDetail.TabsContent.projectBudgetShow.label'),
			visible: true,
		},
		{
			value: Resources.projectBudget.name,
			label: t('ProjectDetail.TabsContent.projectBudget.label'),
			visible: true,
		},
	];
};
