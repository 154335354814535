import clsx from 'clsx';
import { format, formatDistance } from 'date-fns';
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { EFormats } from '@sac-tw2/tasker-widgets2/dist/utils/date';
import { TextWidget } from '@sac-tw2/tasker-widgets2/dist/widgets/TextWidget';
import { VisibilityControl } from '@sac-tw2/tasker-widgets2/dist/widgets/VisibilityControl';

import { Dvr } from '@mui/icons-material';
import { Avatar, Link, Tooltip, Typography } from '@mui/material';

import { useTrans } from '@/utils/hooks/useTrans';

import { Props } from './model';
import { Resources } from './resources';
import { useStyles } from './styles';

export const Header = (props: Props): JSX.Element => {
	const { avatar, nickName, createdAt, isDeleted = false, isPortal = false, isOnIssue, children, user } = props;
	const classes = useStyles();
	const { i18n } = useTrans(
		'IssueModal.IssueModalContent.IssueDetailsPage.TabsContent.IssueComments.Comments.CommentCard.Header'
	);

	const [displayTime, setDisplayTime] = useState<string>(
		format(
			((createdAt as unknown) as string) === Resources.invalidDate ? new Date() : createdAt,
			EFormats.EDateFNS.DefaultDateFormatWithHours
		)
	);

	useEffect(() => {
		setDisplayTime(format(createdAt ?? new Date(), EFormats.EDateFNS.DefaultDateFormatWithHours));
	}, [createdAt]);

	return (
		<div className={clsx(classes.defaultCursor, classes.flexYCenter, classes.header)}>
			<Tooltip title={nickName}>
				<Link component={RouterLink} to={Resources.user(user?.Id)} target={Resources.GlobalResources.blank}>
					<Avatar className={classes.avatar} src={avatar} />
				</Link>
			</Tooltip>
			<VisibilityControl condition={isPortal && !!isOnIssue}>
				<Dvr color={Resources.GlobalResources.primary} />
			</VisibilityControl>
			<div className={classes.nickname}>
				<TextWidget variant={Resources.GlobalResources.h6} text={nickName} />
			</div>
			<Typography className={classes.timeBold} variant={Resources.GlobalResources.h6}>
				{`${displayTime} (${formatDistance(new Date(), createdAt, i18n.t('GlobalResourcesLanguage.DateFns'))})`}
			</Typography>
			<VisibilityControl condition={!isDeleted}>{children}</VisibilityControl>
		</div>
	);
};
