import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		ProjectDetails: {
			Overview: {
				ProjectMembers: {
					ColumnHeaderAvatar: {
						sort: 'sortuj',
					},
				},
			},
		},
	},
	en: {
		ProjectDetails: {
			Overview: {
				ProjectMembers: {
					ColumnHeaderAvatar: {
						sort: 'sort',
					},
				},
			},
		},
	},
};
