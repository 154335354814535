import uuid from 'uuid';

import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	defaultsConfigurationKey: (key: string) => `DelegationDefaultsConfiguration_${uuid()}_${key}`,
};

export const Translation = {
	pl: {
		OrganizationPanel: {
			TabsContent: {
				Delegation: {
					TabsContent: {
						DefaultsConfiguration: {
							perDiem: {
								title: 'Diety',
								description:
									'Zaznaczenie elementu spowoduje domyślne włączenie lub wyłączenie elementu dla użytkowników, środkowa wartość oznacza brak wartości domyślnej',
								1: 'Śniadanie',
								2: 'Obiad',
								3: 'Kolacja',
								4: 'Pobyt w szpitalu',
								5: 'Zakwaterowanie',
								6: 'Transport publiczny',
							},
						},
					},
				},
			},
		},
	},
	en: {
		OrganizationPanel: {
			TabsContent: {
				Delegation: {
					TabsContent: {
						DefaultsConfiguration: {
							perDiem: {
								title: 'Per diem',
								description:
									'Checking the checkbox will enable or disable the element by default for users, the middle value means no default value',
								1: 'Breakfast',
								2: 'Dinner',
								3: 'Supper',
								4: 'Hospital stay',
								5: 'Accommodation',
								6: 'Public transport',
							},
						},
					},
				},
			},
		},
	},
};
