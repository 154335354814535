import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		Reckon: {
			Agreements: {
				AgreementEdit: {
					AddNewSalaryButton: {
						addNewSalary: 'Dodaj wynagrodzenie do umowy',
						noSalaries: 'Brak wynagrodzeń ... ',
					},
				},
			},
		},
	},
	en: {
		Reckon: {
			Agreements: {
				AgreementEdit: {
					AddNewSalaryButton: {
						addNewSalary: 'Add new salary',
						noSalaries: 'No salaries ...',
					},
				},
			},
		},
	},
};
