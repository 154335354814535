import { EKeys } from '@sac-tt/tasker-types/dist/types/common/keys';

import { Action, ActionType } from '@/store/types';

export const KEY_DID_CHANGE: ActionType = 'KEY_DID_CHANGE';

export function keyDidChange(key: EKeys): Action {
	return {
		type: KEY_DID_CHANGE,
		key: key,
	};
}
