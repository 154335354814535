import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	simpleTable: 'simple-table',
	basicMenu: 'basic-menu',
	ariaLabelledBy: 'aria-labelledby',
	basicButton: 'basic-button',
};

export const Translation = {
	pl: {
		ProjectDetails: {
			ProjectMembersConfiguration: {
				ProjectUsers: {
					TableOptions: {
						allUsersDeleted: 'Usunięto wszystkich użytkowników z projektu',
						deletedAllConfirmation: 'Czy na pewno chcesz usunąć wszystkich z projektu ?',
						selectedUsersDeleted: 'Usunięto zaznaczonych użytkowników z projektu',
						constraintErrorOnDeleteSelectedUsers:
							'Następujący użytkownicy nie są obecni w projekcie: {{deletedUsers}}',
						deleteSelected: 'Usuń zaznaczone',
						deleteEveryone: 'Usuń wszystkich',
					},
				},
			},
		},
	},
	en: {
		ProjectDetails: {
			ProjectMembersConfiguration: {
				ProjectUsers: {
					TableOptions: {
						allUsersDeleted: 'All users have been deleted from project',
						deletedAllConfirmation: 'Are you sure you want to remove everyone from the project ?',
						selectedUsersDeleted: 'Selected users have been deleted from project',
						constraintErrorOnDeleteSelectedUsers:
							'The following users are not present in the project: {{deletedUsers}}',
						deleteSelected: 'Delete selected',
						deleteEveryone: 'Delete everyone',
					},
				},
			},
		},
	},
};
