import { format } from 'date-fns';
import { floor } from 'lodash';

import { EDateFNS } from '@sac-tw2/tasker-widgets2/dist/utils';

import { Resources } from './resources';

export interface IDelegationTemplateExpensesTable {
	data: IDelegationTemplateExpensesRow[];
	from?: number;
	to?: number;
}

export const DelegationTemplateExpensesTable = (props: IDelegationTemplateExpensesTable): string => {
	return `
    <div class="report-table-wrapper">
      <table id="report-table" class="report-table">
        <thead>
          <tr>
            <th>Expenses</th>
            <th>Details</th>
            <th>VAT</th>
            <th>Gross amount</th>
          </tr>
        </thead>
        <tbody>
          ${props.data
				.slice(props.from, props.to)
				.map(row => DelegationTemplateExpensesRow(row))
				.join('')}
        </tbody>
      </table>
    </div>
  `;
};

export interface IDelegationTemplateExpensesRow {
	Name: string;
	Type: string;
	Description: string;
	DateStart: Date;
	DateEnd?: Date;
	Vat?: number;
	CurrencyBeforeConversion?: string;
	VatAmountBeforeConversion?: number;
	VatAmount?: number;
	GrossAmountBeforeConversion?: number;
	GrossAmount: number;
}

export const DelegationTemplateExpensesRow = (props: IDelegationTemplateExpensesRow): string => {
	const formattedDateStart = format(props.DateStart, EDateFNS.DateWithoutTime);
	const formattedDateEnd = props.DateEnd
		? ` - ${format(props.DateEnd, EDateFNS.DateWithoutTime)}`
		: Resources.GlobalResources.empty;

	return `
    <tr>
      <td>
        <p>${props.Name}</p>
        <p>${formattedDateStart}${formattedDateEnd} • ${props.Type}</p>
      </td>
      <td>
        <p>${props.Description}</p>
      </td>
      <td>
        ${
			props.Vat && props.VatAmount
				? `
          <p>${props.Vat}%</p>
          <p>${floor(props.VatAmount ?? 0, 2).toFixed(2)} PLN</p>
          ${
				props.CurrencyBeforeConversion && props.CurrencyBeforeConversion !== 'PLN'
					? `
            <p class="small">${floor(props.VatAmountBeforeConversion ?? 0, 2).toFixed(2)} ${
							props.CurrencyBeforeConversion
					  }</p>
          `
					: Resources.GlobalResources.empty
			}
        `
				: Resources.GlobalResources.empty
		}
      </td>
      <td>
        <p>${floor(props.GrossAmount, 2).toFixed(2)} PLN</p>
        ${
			props.CurrencyBeforeConversion && props.CurrencyBeforeConversion !== 'PLN'
				? `
              <p class="small">${floor(props.GrossAmountBeforeConversion ?? 0, 2).toFixed(2)} ${
						props.CurrencyBeforeConversion
				  }</p>
            `
				: Resources.GlobalResources.empty
		}
      </td>
    </tr>
  `;
};

export interface IDelegationTemplateExpensesSummary {
	Gross: number;
	NotReimbursable: number;
	Payout: number;
}

export const DelegationTemplateExpensesSummary = (props: IDelegationTemplateExpensesSummary): string => {
	return `
    <div class="summary">
      <p class="summary-gross-label">Total gross:</p>
      <p class="summary-gross-amount">${floor(props.Gross, 2).toFixed(2)} zł</p>
      <p class="summary-not-reimbursable-label">Not reimbursable:</p>
      <p class="summary-not-reimbursable-amount">${floor(props.NotReimbursable, 2).toFixed(2)} zł</p>
      <p class="summary-payout-label">Payout:</p>
      <p class="summary-payout-amount">${floor(props.Payout, 2).toFixed(2)} zł</p>
    </div>
  `;
};
