import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	summaryTabValue: 'summary',
	projectsTabValue: 'projects',
	projectsAddTabValue: 'projectsAdd',
	formsTabValues: 'forms',
	translationKey: {
		unity: 'NumberToText.Unity',
		next: 'NumberToText.Next',
		tens: 'NumberToText.Tens',
		hundreds: 'NumberToText.Hundreds',
		thousand: 'NumberToText.Uppers.Thousand',
		million: 'NumberToText.Uppers.Million',
		billion: 'NumberToText.Uppers.Billion',
		trillion: 'NumberToText.Uppers.Trillion',
		billiard: 'NumberToText.Uppers.Billiard',
		aTrillion: 'NumberToText.Uppers.ATrillion',
	},
	values: {
		0: 'zero',
		minus: 'minus',
	},
	lowDash: '_',
	...GlobalResources,
};

export const Translation = {
	pl: {
		CustomerDetail: {
			Content: {
				Invoices: {
					pageTitle: 'Faktury klienta',
					name: 'Nazwa faktury',
					due: 'Termin płatności',
					serialNumber: 'Numer seryjny',
					invoice: '_Faktura',
					pdf: 'Faktura.pdf',
				},
			},
		},
	},
	en: {
		CustomerDetail: {
			Content: {
				Invoices: {
					pageTitle: 'Client invoices',
					name: 'Name of invoice',
					due: 'Due date',
					serialNumber: 'Numer seryjny',
					invoice: '_Invoice',
					pdf: 'Invoice.pdf',
				},
			},
		},
	},
};
