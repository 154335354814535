import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	separator: '/',
};

export const Translation = {
	pl: {
		PortalList: {
			addIssue: 'Dodaj zgłoszenie',
			portal: 'Portal',
			creationDate: 'Data rozpoczęcia',
			useTableData: {
				assignees: 'Osoba wykonująca',
				name: 'Nazwa',
				project: 'Projekt',
				customer: 'Klient',
				created: 'Data stworzenia',
				tags: 'Tagi',
				attachments: 'Załączniki',
			},
		},
	},
	en: {
		PortalList: {
			addIssue: 'Add issue',
			portal: 'Portal',
			creationDate: 'Start date',
			useTableData: {
				assignees: 'Assigned people',
				name: 'Name',
				project: 'Project',
				customer: 'Customer',
				created: 'Created date time',
				tags: 'Tags',
				attachments: 'Attachments',
			},
		},
	},
};
