import { ICustomer, IProject, ITagGroup, ITaskerUser } from '@sac-tt/tasker-types';

import * as actionTypes from '@/store/actions';
import { ObjectAction } from '@/store/types';

export const State = {
	carouselUsers: [] as Partial<ITaskerUser>[],
	customers: [] as ICustomer[],
	users: [] as Partial<ITaskerUser>[],
	tagGroups: [] as ITagGroup[],
	projects: [] as IProject[],
};
export type ISearchBarEditorState = typeof State;

const searchBarReducer = (state = State, action: ObjectAction): ISearchBarEditorState => {
	switch (action?.type) {
		case actionTypes.FETCH_ALL_USERS: {
			return {
				...state,
				users: action.profiles,
				carouselUsers: action.taskerUsers,
			};
		}
		case actionTypes.CUSTOMER_FETCH_LIST_NEW: {
			return {
				...state,
				customers: action.customers,
			};
		}
		case actionTypes.TAG_GROUP_ISSUE_FILTERS_FETCH_NEW:
		case actionTypes.TAG_GROUP_FETCH_NEW: {
			return {
				...state,
				tagGroups: action.groups,
			};
		}
		case actionTypes.PROJECT_FETCH_TYPE_NEW: {
			return {
				...state,
				projects: action.projects,
			};
		}
		default: {
			return state;
		}
	}
};

export default searchBarReducer;
