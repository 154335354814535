import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		OrganizationPanel: {
			TabsContent: {
				Delegation: {
					TabsContent: {
						CustomDictionariesConfiguration: {
							CustomDictionaryItemList: {
								CustomDictionaryItemListMenu: {
									menuEdit: 'Edytuj wartość załącznika',
									menuRemove: 'Usuń wartość załącznika',
								},
							},
						},
					},
				},
			},
		},
	},
	en: {
		OrganizationPanel: {
			TabsContent: {
				Delegation: {
					TabsContent: {
						CustomDictionariesConfiguration: {
							CustomDictionaryItemList: {
								CustomDictionaryItemListMenu: {
									menuEdit: 'Edit attachment value',
									menuRemove: 'Remove attachment value',
								},
							},
						},
					},
				},
			},
		},
	},
};
