import { IWorkMgtTemplGroup } from '@sac-tt/tasker-types/dist/types/dataBaseTypes/workMgtTemplGroup';
import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	dash: '_',
	defaultTemplate: {
		Id: -1,
		Name: 'All',
		Order: -1,
		IsActive: true,
	} as Partial<IWorkMgtTemplGroup>,
	blankTemplate: {
		Id: -1,
		Order: -1,
		IsActive: true,
		WorkMgtTemplGroupId: -1,
		WorkMgtTemplId: -1,
	},
};

export const Translation = {
	pl: {
		ProjectCreate: {
			Template: {
				blankTemplate: { Label: 'Pusty projekt' },
			},
		},
	},
	en: {
		ProjectCreate: {
			Template: {
				blankTemplate: { Label: 'Blank project' },
			},
		},
	},
};
