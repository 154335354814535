import clsx from 'clsx';
import parse from 'html-react-parser';
import React from 'react';
import { useSelector } from 'react-redux';
import uuid from 'uuid';

import { TooltipTextBase, VisibilityControl } from '@sac-tw2/tasker-widgets2';

import { Avatar, Link, ListItemText, Tooltip } from '@mui/material';

import { IReducerState } from '@/store/reducers/types';

import { Props } from './model';
import { Resources } from './resources';
import { useStyles } from './styles';

export const SingleIssueInfo = (props: Props): JSX.Element => {
	const { issue } = props;
	const classes = useStyles();

	const isMobile: boolean = useSelector((state: IReducerState) => state?.Session?.isMobile);

	const avatar = (
		<Avatar className={classes.avatar} src={issue?.Title}>
			{issue?.Title}
		</Avatar>
	);

	return (
		<div className={classes.root}>
			<div className={classes.avatarContainer}>
				<VisibilityControl condition={(issue?.IssueUrl?.length ?? 0) > 0} alternative={avatar}>
					<Tooltip
						title={
							<div>
								{issue?.IssueUrl?.map(x => {
									return (
										<Tooltip title={x?.Label} key={uuid()}>
											<Link
												className={classes.url}
												href={x?.Url}
												target={Resources.GlobalResources.blank}
											>
												{parse(x?.UrlType?.ImageCode ?? '')}
											</Link>
										</Tooltip>
									);
								})}
							</div>
						}
					>
						{avatar}
					</Tooltip>
				</VisibilityControl>
			</div>
			<VisibilityControl condition={isMobile === true}>
				<div className={clsx(classes.flexColumn, classes.text)}>
					<Tooltip title={`#${issue?.DisplayId} ${issue?.Title}`}>
						<span className={classes.textSpan}>{`#${issue?.DisplayId}`}</span>
					</Tooltip>
					<Tooltip title={`#${issue?.DisplayId} ${issue?.Title}`}>
						<span className={classes.textSpan}>{`${issue?.Title}`}</span>
					</Tooltip>
				</div>
			</VisibilityControl>
			<VisibilityControl condition={isMobile === false}>
				<ListItemText
					className={classes.text}
					primary={
						<TooltipTextBase
							className={clsx(classes?.title, classes.overflowHidden)}
							text={`#${issue?.DisplayId} ${issue?.Title}`}
						/>
					}
					primaryTypographyProps={{ variant: Resources.GlobalResources.body1 }}
				/>
			</VisibilityControl>
		</div>
	);
};
