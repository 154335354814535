import { AnyAction } from 'redux';

import { AsyncAction } from '@sac-rp/redux-promisify/dist';
import { IAuthData, ITaskerUserSetting } from '@sac-tt/tasker-types';

import { CodeResponse } from '@react-oauth/google';

import { IJson } from '@/store/ITypes';
import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

export const SESSION_START_LOGIN: ActionType = 'SESSION_START_LOGIN_RECEIVE';
export const SESSION_LOGOUT: ActionType = 'SESSION_LOGOUT';
export const SESSION_RESET: ActionType = 'SESSION_RESET';
export const SESSION_FINISH_LOGIN: ActionType = 'SESSION_FINISH_LOGIN_RECEIVE';
export const SESSION_REMEMBER_ME_SET: ActionType = 'SESSION_REMEMBER_ME_SET';
export const SESSION_UPDATE_AUTH_INFO: ActionType = 'SESSION_UPDATE_AUTH_INFO';
export const SESSION_CHECK_PASSWORD_RECOVERY: ActionType = 'SESSION_CHECK_PASSWORD_RECOVERY';
export const SESSION_RESET_PASSWORD_RECEIVE: ActionType = 'SESSION_RESET_PASSWORD_RECEIVE';
export const SESSION_SET_LOCAL_AVATAR: ActionType = 'SESSION_SET_LOCAL_AVATAR';
export const SESSION_SET_IS_LOADING_V2: ActionType = 'SESSION_SET_IS_LOADING_V2';

export const SESSION_SET_IS_SMALL_DEVICE: ActionType = 'SESSION_SET_IS_SMALL_DEVICE';
export const SESSION_SET_LANGUAGE: ActionType = 'SESSION_SET_LANGUAGE';
export const SESSION_RECALCULATE: ActionType = 'SESSION_RECALCULATE';
export const SESSION_TERMINATE_SESSION: ActionType = 'SESSION_TERMINATE_SESSION';

const basePathSession = 'Session/';
const basePath = 'auth';
const basePathLanguage = '/TaskerUserSettings/language';

function _startLogin(profiles: { name: string; tenantName: string }[]): Action {
	return {
		type: SESSION_START_LOGIN,
		profiles,
	};
}

function _finishLogin(args: IAuthData): Action {
	return {
		...args,
		type: SESSION_FINISH_LOGIN,
	};
}
function _checkRecover(token: string): Action {
	return {
		type: SESSION_CHECK_PASSWORD_RECOVERY,
		recoverToken: token,
	};
}

function _resetPassword(): Action {
	return {
		type: SESSION_RESET_PASSWORD_RECEIVE,
	};
}

export function logOut(): Action {
	return function(context, dispatch) {
		context.then(() => dispatch(reset_session_metadata()));
		return axios().post(`${basePath}/logout`);
	};
}

export function reset_session_metadata(): Action {
	return {
		type: SESSION_LOGOUT,
	};
}
export function sessionRecalculate(): Action {
	return {
		type: SESSION_RECALCULATE,
	};
}
export function session_setIsSmallDevice(isMobile: boolean): Action {
	return {
		type: SESSION_SET_IS_SMALL_DEVICE,
		isMobile,
	};
}

export function session_setLanguage(language: string, data?: ITaskerUserSetting): Action {
	return {
		type: SESSION_SET_LANGUAGE,
		language,
		languageId: data?.Id,
	};
}

export function session_terminateSession(): Action {
	return {
		type: SESSION_TERMINATE_SESSION,
	};
}

export function session_updateAuthInfo(args: IAuthData): Action {
	return {
		...args,
		type: SESSION_UPDATE_AUTH_INFO,
	};
}

export function sesion_setIsLoading_v2(isLoading: boolean): AnyAction {
	return {
		data: isLoading,
		type: SESSION_SET_IS_LOADING_V2,
	};
}

export function session_superLogin(userId: number, orgId: number): AsyncAction {
	return function(context, dispatch) {
		context.then((json: unknown) => dispatch(_finishLogin((json as IJson).data)));
		return axios().post(`${basePath}/superLogin/user/${userId}/org/${orgId}`);
	};
}

export function session_loginV2(login: string, password: string): AsyncAction {
	return function(context, dispatch) {
		context.then((json: unknown) => dispatch(_finishLogin((json as IJson).data)));
		return axios().post(`${basePath}/login`, { login, password });
	};
}

export function session_loginWithGoogle(
	data: Omit<CodeResponse, 'error' | 'error_description' | 'error_uri'>
): AsyncAction {
	return function(context, dispatch) {
		context.then((json: unknown) => dispatch(_finishLogin((json as IJson).data)));
		return axios().post(`${basePath}/loginWithGoogle`, data);
	};
}

export function changeOrganization(organizationId: number): AsyncAction {
	return function(context, dispatch) {
		context.then((json: unknown) => dispatch(_finishLogin((json as IJson).data)));
		return axios().post(`${basePath}/changeOrg`, { organizationId });
	};
}

export function session_loginWithToken(): AsyncAction {
	return function(context, dispatch) {
		context.then((json: unknown) => dispatch(_finishLogin((json as IJson).data)));
		return axios().post(`${basePath}/loginWithToken`);
	};
}

export function login(login: string, password: string): AsyncAction {
	return function(context, dispatch) {
		context.then((json: unknown) => dispatch(_startLogin((json as IJson).data.profiles)));
		return axios().post(`${basePath}/login`, { login, password, organizationId: 2 });
	};
}

export function session_checkPasswordChange(token: string): AsyncAction {
	return function(context, dispatch) {
		context.then((json: unknown) => dispatch(_checkRecover((json as IJson).data.auth)));
		return axios().post(`${basePath}/recover/check`, { token });
	};
}

export function session_resetPassword(token: string, password: string): AsyncAction {
	return function(context, dispatch) {
		context.then(() => dispatch(_resetPassword()));
		return axios().post(`${basePath}/recover/complete`, { token, password });
	};
}

export function session_changeLanguageForUser(language: string, id?: number): AsyncAction {
	return id ? session_updateLanguageForUser(language, id) : session_createLanguageForUser(language);
}

function session_createLanguageForUser(language: string): AsyncAction {
	return function(context, dispatch) {
		context.then((json: unknown) => dispatch(session_setLanguage(language, (json as IJson).data)));
		return axios().post(basePathLanguage, { language });
	};
}

function session_updateLanguageForUser(language: string, id: number): AsyncAction {
	return function(context, dispatch) {
		context.then((json: unknown) => dispatch(session_setLanguage(language, (json as IJson).data)));
		return axios().patch(`${basePathLanguage}/${id}`, { language });
	};
}

export function terminateUserSession(taskerUserId: number): AsyncAction {
	return function(context, dispatch) {
		context.then(() => dispatch(session_terminateSession()));
		return axios().post(`${basePathSession}terminate/${taskerUserId}`);
	};
}
