import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		TagGroupInfo: {
			TagsCard: {
				DraggableTagsWidget: {
					title: 'Czy jesteś pewien?',
					subTitle: 'Tagów może być usunięty tylko gdy nic go nie używa.',
				},
			},
		},
	},
	en: {
		TagGroupInfo: {
			TagsCard: {
				DraggableTagsWidget: {
					title: 'Are you sure?',
					subTitle: 'Tag can be deleted if nothing using it.',
				},
			},
		},
	},
};
