import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		ProjectDetails: {
			Form: {
				IssueForm: {
					CommonComponents: {
						IssueFormField: {
							DictionaryModal: {
								Record: {
									label: 'Rekord numer ',
								},
							},
						},
					},
				},
			},
		},
	},
	en: {
		ProjectDetails: {
			Form: {
				IssueForm: {
					CommonComponents: {
						IssueFormField: {
							DictionaryModal: {
								Record: {
									label: 'Record ',
								},
							},
						},
					},
				},
			},
		},
	},
};
