import { format } from 'date-fns';
import * as xlsx from 'exceljs';
import { floor } from 'lodash';

import { EDateFNS } from '@sac-tw2/tasker-widgets2/dist/utils';

export interface IDelegationExcelTemplatePerDiemOld {
	Date: Date;
	Time: string;
	CountryCode: string;
	Currency: string;
	ConversionRate?: number;
	ConversionDate?: Date;
	LimitAllowanceBeforeConversion?: number;
	LimitAllowance: number;
	BreakfastBeforeConversion?: number;
	Breakfast: number;
	DinnerBeforeConversion?: number;
	Dinner: number;
	SupperBeforeConversion?: number;
	Supper: number;
	HospitalizationBeforeConversion?: number;
	Hospitalization: number;
	AccommodationBeforeConversion?: number;
	Accommodation: number;
	TransportationBeforeConversion?: number;
	Transportation: number;
	TotalBeforeConversion?: number;
	Total: number;
}

export const DelegationExcelTemplatePerdiemsOld = (
	props: IDelegationExcelTemplatePerDiemOld[],
	workbook: xlsx.Workbook
) => {
	const sheet = workbook.addWorksheet('Per Diems');

	sheet.addTable({
		name: 'Delegation per diems',
		ref: 'A1',
		headerRow: true,
		totalsRow: false,
		style: {
			theme: 'TableStyleLight2',
			showRowStripes: true,
		},
		columns: [
			{ name: 'Date' },
			{ name: 'Time' },
			{ name: 'Country code' },
			{ name: 'Currency' },
			{ name: 'Conversion rate' },
			{ name: 'Conversion date' },
			{ name: 'Limit allowance before conversion' },
			{ name: 'Limit allowance' },
			{ name: 'Breakfast before conversion' },
			{ name: 'Breakfast' },
			{ name: 'Dinner before conversion' },
			{ name: 'Dinner' },
			{ name: 'Supper before conversion' },
			{ name: 'Supper' },
			{ name: 'Hospitalization before conversion' },
			{ name: 'Hospitalization' },
			{ name: 'Accommodation before conversion' },
			{ name: 'Accommodation' },
			{ name: 'Transportation before conversion' },
			{ name: 'Transportation' },
			{ name: 'Total before conversion' },
			{ name: 'Total' },
		],
		rows: props.map(perDiem => [
			format(perDiem.Date, EDateFNS.DateWithoutTime),
			perDiem.Time,
			perDiem.CountryCode,
			perDiem.Currency,
			perDiem.ConversionRate ?? '-',
			perDiem.ConversionDate && perDiem.Currency.toUpperCase() !== 'PLN'
				? format(perDiem.ConversionDate, EDateFNS.DateWithoutTime)
				: '-',
			perDiem.LimitAllowanceBeforeConversion ? floor(perDiem.LimitAllowanceBeforeConversion, 2).toFixed(2) : '-',
			floor(perDiem.LimitAllowance, 2).toFixed(2),
			perDiem.BreakfastBeforeConversion ? floor(perDiem.BreakfastBeforeConversion, 2).toFixed(2) : '-',
			floor(perDiem.Breakfast, 2).toFixed(2),
			perDiem.DinnerBeforeConversion ? floor(perDiem.DinnerBeforeConversion, 2).toFixed(2) : '-',
			floor(perDiem.Dinner, 2).toFixed(2),
			perDiem.SupperBeforeConversion ? floor(perDiem.SupperBeforeConversion, 2).toFixed(2) : '-',
			floor(perDiem.Supper, 2).toFixed(2),
			perDiem.HospitalizationBeforeConversion
				? floor(perDiem.HospitalizationBeforeConversion, 2).toFixed(2)
				: '-',
			floor(perDiem.Hospitalization, 2).toFixed(2),
			perDiem.AccommodationBeforeConversion ? floor(perDiem.AccommodationBeforeConversion, 2).toFixed(2) : '-',
			floor(perDiem.Accommodation, 2).toFixed(2),
			perDiem.TransportationBeforeConversion ? floor(perDiem.TransportationBeforeConversion, 2).toFixed(2) : '-',
			floor(perDiem.Transportation, 2).toFixed(2),
			perDiem.TotalBeforeConversion ? floor(perDiem.TotalBeforeConversion, 2).toFixed(2) : '-',
			floor(perDiem.Total, 2).toFixed(2),
		]),
	});

	sheet.eachRow(row => {
		row.eachCell(cell => {
			cell.alignment = { vertical: 'top', horizontal: 'left' };
		});
	});

	sheet.columns.forEach(function(column) {
		let maxLength = 0;
		if (column && typeof column['eachCell'] === 'function') {
			column['eachCell']({ includeEmpty: true }, function(cell) {
				const columnLength = cell.value ? cell.value.toString().length : 10;
				if (columnLength > maxLength) {
					maxLength = columnLength;
				}
			});
			column.width = maxLength < 10 ? 10 : maxLength;
		}
	});
};

export interface IDelegationExcelTemplatePerDiem {
	CountryCode: string;
	Time: string;
	Currency: string;
	ConversionRate?: number;
	ConversionDate?: Date;
	LimitAllowanceBeforeConversion?: number;
	LimitAllowance: number;
	BreakfastBeforeConversion?: number;
	Breakfast: number;
	DinnerBeforeConversion?: number;
	Dinner: number;
	SupperBeforeConversion?: number;
	Supper: number;
	HospitalizationBeforeConversion?: number;
	Hospitalization: number;
	AccommodationBeforeConversion?: number;
	Accommodation: number;
	TransportationBeforeConversion?: number;
	Transportation: number;
	TotalBeforeConversion?: number;
	Total: number;
}

export const DelegationExcelTemplatePerdiems = (props: IDelegationExcelTemplatePerDiem[], workbook: xlsx.Workbook) => {
	const sheet = workbook.addWorksheet('Per Diems');

	sheet.addTable({
		name: 'Delegation per diems',
		ref: 'A1',
		headerRow: true,
		totalsRow: false,
		style: {
			theme: 'TableStyleLight2',
			showRowStripes: true,
		},
		columns: [
			{ name: 'Country code' },
			{ name: 'Total time in country' },
			{ name: 'Currency' },
			{ name: 'Conversion rate' },
			{ name: 'Conversion date' },
			{ name: 'Limit allowance before conversion' },
			{ name: 'Limit allowance' },
			{ name: 'Breakfast before conversion' },
			{ name: 'Breakfast' },
			{ name: 'Dinner before conversion' },
			{ name: 'Dinner' },
			{ name: 'Supper before conversion' },
			{ name: 'Supper' },
			{ name: 'Hospitalization before conversion' },
			{ name: 'Hospitalization' },
			{ name: 'Accommodation before conversion' },
			{ name: 'Accommodation' },
			{ name: 'Transportation before conversion' },
			{ name: 'Transportation' },
			{ name: 'Total before conversion' },
			{ name: 'Total' },
		],
		rows: props.map(perDiem => [
			perDiem.CountryCode,
			perDiem.Time,
			perDiem.Currency,
			perDiem.ConversionRate ?? '-',
			perDiem.ConversionDate && perDiem.Currency.toUpperCase() !== 'PLN'
				? format(perDiem.ConversionDate, EDateFNS.DateWithoutTime)
				: '-',
			perDiem.LimitAllowanceBeforeConversion ? floor(perDiem.LimitAllowanceBeforeConversion, 2).toFixed(2) : '-',
			floor(perDiem.LimitAllowance, 2).toFixed(2),
			perDiem.BreakfastBeforeConversion ? floor(perDiem.BreakfastBeforeConversion, 2).toFixed(2) : '-',
			floor(perDiem.Breakfast, 2).toFixed(2),
			perDiem.DinnerBeforeConversion ? floor(perDiem.DinnerBeforeConversion, 2).toFixed(2) : '-',
			floor(perDiem.Dinner, 2).toFixed(2),
			perDiem.SupperBeforeConversion ? floor(perDiem.SupperBeforeConversion, 2).toFixed(2) : '-',
			floor(perDiem.Supper, 2).toFixed(2),
			perDiem.HospitalizationBeforeConversion
				? floor(perDiem.HospitalizationBeforeConversion, 2).toFixed(2)
				: '-',
			floor(perDiem.Hospitalization, 2).toFixed(2),
			perDiem.AccommodationBeforeConversion ? floor(perDiem.AccommodationBeforeConversion, 2).toFixed(2) : '-',
			floor(perDiem.Accommodation, 2).toFixed(2),
			perDiem.TransportationBeforeConversion ? floor(perDiem.TransportationBeforeConversion, 2).toFixed(2) : '-',
			floor(perDiem.Transportation, 2).toFixed(2),
			perDiem.TotalBeforeConversion ? floor(perDiem.TotalBeforeConversion, 2).toFixed(2) : '-',
			floor(perDiem.Total, 2).toFixed(2),
		]),
	});

	sheet.eachRow(row => {
		row.eachCell(cell => {
			cell.alignment = { vertical: 'top', horizontal: 'left' };
		});
	});

	sheet.columns.forEach(function(column) {
		let maxLength = 0;
		if (column && typeof column['eachCell'] === 'function') {
			column['eachCell']({ includeEmpty: true }, function(cell) {
				const columnLength = cell.value ? cell.value.toString().length : 10;
				if (columnLength > maxLength) {
					maxLength = columnLength;
				}
			});
			column.width = maxLength < 10 ? 10 : maxLength;
		}
	});
};
