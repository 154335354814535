import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		Login: {
			ProfileForm: {
				chooseProfile: 'Wybierz profil',
			},
		},
	},
	en: {
		Login: {
			ProfileForm: {
				chooseProfile: 'Choose profile',
			},
		},
	},
};
