import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IArchivedIssues } from '@sac-tt/tasker-types';
import { Button, VisibilityControl } from '@sac-tw2/tasker-widgets2';

import { Typography } from '@mui/material';

import { archiveIssue, unArchiveIssue } from '@/store/actions';
import { IReducerState } from '@/store/reducers/types';
import { Dispatch } from '@/store/types';
import { useTrans } from '@/utils/hooks/useTrans';
import { getFirst } from '@/utils/utils';

import { Resources } from './resources';
import { useStyles } from './styles';

export const Archiv = () => {
	const classes = useStyles();
	const dispatch: Dispatch = useDispatch();

	const { t } = useTrans('IssueModal.IssueModalTitle.MoreOptions.Archiv');

	const issue = useSelector((state: IReducerState) => state?.IssueModalEditor?.issueModal);
	const kanbanDetails: { IsKanban: boolean; KanbanId?: number } = useSelector(
		(state: IReducerState) => state?.IssueModalEditor?.kanbanDetails
	);

	const isArchived = useMemo(() => {
		return issue?.ArchivedIssues && (issue?.ArchivedIssues?.length ?? 0) > 0;
	}, [issue]);

	const handleManageArchive = () => {
		if (isArchived) {
			const id = getFirst(issue?.ArchivedIssues as IArchivedIssues[])?.Id;
			dispatch(unArchiveIssue(id));
		} else if (kanbanDetails?.KanbanId) dispatch(archiveIssue(issue?.Id, kanbanDetails?.KanbanId));
	};

	return (
		<div className={clsx(classes.flexColumn, classes.container)}>
			<Button
				onClick={handleManageArchive}
				variant={Resources.GlobalResources.contained}
				className={clsx(isArchived && classes.deleteArchiv)}
			>
				<VisibilityControl condition={!!isArchived} alternative={t('archive')}>
					{t('deleteArchive')}
				</VisibilityControl>
			</Button>
			<Typography className={classes.subText} variant={Resources.GlobalResources.body2}>
				<VisibilityControl condition={!isArchived} alternative={t('description2')}>
					{t('description')}
				</VisibilityControl>
			</Typography>
		</div>
	);
};
