import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	issueId: '?issueId=',
};

export const Translation = {
	pl: {
		Dashboard: {
			WorklogStarter: {
				IconActions: {
					issueMore: 'Więcej o zgłoszeniu',
				},
			},
		},
	},
	en: {
		Dashboard: {
			WorklogStarter: {
				IconActions: {
					issueMore: 'More about the issue',
				},
			},
		},
	},
};

export enum EWorklogState {
	Normal = 'NORMAL',
	Finish = 'FINISH',
	Pause = 'PAUSE',
}
