import clsx from 'clsx';
import { KeyboardEvent, MouseEventHandler, useEffect, useState } from 'react';

import { IFullFiltersIssueTextBy } from '@sac-tt/tasker-types';
import { isRtl } from '@sac-tw2/tasker-widgets2/dist/utils';
import { VisibilityControl } from '@sac-tw2/tasker-widgets2/dist/widgets/VisibilityControl';

import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, InputBase, Paper } from '@mui/material';

import { useToggle } from '@/utils/hooks/useToggle';
import { useTrans } from '@/utils/hooks/useTrans';

import { Props } from './model';
import { Resources } from './resources';
import { SearchBy } from './SearchBy';
import { useStyles } from './styles';

export const Search = (props: Props): JSX.Element => {
	const {
		onSearch,
		className,
		onClearFilters,
		placeholder,
		onSearchOptionsChange,
		onClearText,
		didCleared = 0,
		...rest
	} = props;
	const classes = useStyles();
	const { t } = useTrans('SearchBar.Search');

	const [value, setValue] = useState<string>(Resources.GlobalResources.empty);
	const [isFiltered, setIsFiltered] = useState<boolean>(false);
	const [isFilteredText, setIsFilteredText] = useState<boolean>(false);
	const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLDivElement) | null>(null);
	const [open, toggleOn, toggleOff] = useToggle(false);

	useEffect(() => {
		if (didCleared > 0) setValue(Resources.GlobalResources.empty);
	}, [didCleared]);

	const handleKeyPress = (event: KeyboardEvent<HTMLDivElement>): void => {
		if (event.key === Resources.GlobalResources.enterEvent) {
			event.preventDefault();
			handleSearch();
		}
	};

	const onSearchOptionsChangeWrapper = (searchOptions: IFullFiltersIssueTextBy): void => {
		if (onSearchOptionsChange) onSearchOptionsChange(searchOptions);
	};

	const handleSearch = () => {
		if (value) {
			onSearch(value);
			setIsFiltered(true);
			setIsFilteredText(true);
		}
		handleClear(false);
	};

	const handleClear = (clearFilters = false): void => {
		if (onClearFilters && clearFilters) onClearFilters();
		setIsFiltered(false);
	};

	const someEventHandler: MouseEventHandler<HTMLDivElement> = e => {
		e.preventDefault();
		if (e) setAnchorEl(e.currentTarget);
		toggleOn();
	};

	const onClearTextWrapper = () => {
		setValue(Resources.GlobalResources.empty);
		setIsFilteredText(false);
		if (onClearText) onClearText();
	};

	return (
		<div {...rest} className={clsx(classes.root, className)}>
			<Paper className={clsx(classes.search, classes.flexRow)} component={Resources.GlobalResources.form}>
				<InputBase
					onContextMenu={someEventHandler}
					dir={isRtl() ? 'rtl' : 'ltr'}
					className={classes.searchInput}
					placeholder={placeholder ?? t('placeHolder')}
					value={value}
					onChange={event => setValue(event.target.value)}
					onKeyPress={handleKeyPress}
				/>
				<VisibilityControl condition={!!onClearFilters && isFiltered}>
					<IconButton size={Resources.GlobalResources.small} onClick={() => handleClear(true)}>
						<CloseTwoToneIcon fontSize={Resources.GlobalResources.medium} />
					</IconButton>
				</VisibilityControl>
				<VisibilityControl condition={!!onClearText && (isFilteredText || value?.length > 0)}>
					<IconButton
						className={classes.icon}
						size={Resources.GlobalResources.small}
						onClick={onClearTextWrapper}
					>
						<CancelRoundedIcon fontSize={Resources.GlobalResources.small} />
					</IconButton>
				</VisibilityControl>
				<IconButton className={classes.icon} size={Resources.GlobalResources.small} onClick={handleSearch}>
					<SearchIcon fontSize={Resources.GlobalResources.medium} />
				</IconButton>
				<VisibilityControl condition={!!onSearchOptionsChange}>
					<SearchBy
						isOpen={open}
						anchorEl={anchorEl}
						onClose={toggleOff}
						onChange={onSearchOptionsChangeWrapper}
					/>
				</VisibilityControl>
			</Paper>
		</div>
	);
};

export default Search;
