import { AsyncAction } from '@sac-rp/redux-promisify';
import {
	EDelegationDictionaryType,
	IDelegationDictionary,
	IDelegationDictionaryType,
	IPage,
} from '@sac-tt/tasker-types';

import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

import { IJson } from '../ITypes';

export const DELEGATION_SET_CUSTOM_DICTIONARIES: ActionType = 'DELEGATION_SET_CUSTOM_DICTIONARIES';
export const DELEGATION_FETCH_CUSTOM_DICTIONARIES: ActionType = 'DELEGATION_FETCH_CUSTOM_DICTIONARIES';
export const DELEGATION_CREATE_CUSTOM_DICTIONARY: ActionType = 'DELEGATION_CREATE_CUSTOM_DICTIONARY';
export const DELEGATION_UPDATE_CUSTOM_DICTIONARY: ActionType = 'DELEGATION_UPDATE_CUSTOM_DICTIONARY';
export const DELEGATION_REMOVE_CUSTOM_DICTIONARY: ActionType = 'DELEGATION_REMOVE_CUSTOM_DICTIONARY';

export const DELEGATION_FETCH_ALL_ATTACHMENT_DICTIONARIES: ActionType = 'DELEGATION_FETCH_ALL_ATTACHMENT_DICTIONARIES';
export const DELEGATION_FETCH_ALL_TRANSPORT_DICTIONARIES: ActionType = 'DELEGATION_FETCH_ALL_TRANSPORT_DICTIONARIES';

const basePath = 'DelegationDictionary';

function _setDelegationCustomDictionaries(data: IPage<IDelegationDictionary[]>): Action {
	return {
		type: DELEGATION_SET_CUSTOM_DICTIONARIES,
		data,
	};
}

function _fetchDelegationCustomDictionaries(data: IPage<IDelegationDictionary[]>): Action {
	return {
		type: DELEGATION_FETCH_CUSTOM_DICTIONARIES,
		data,
	};
}

function _createDelegationCustomDictionary(data: IPage<IDelegationDictionary[]>): Action {
	return {
		type: DELEGATION_CREATE_CUSTOM_DICTIONARY,
		data,
	};
}

function _updateDelegationCustomDictionary(data: IPage<IDelegationDictionary[]>): Action {
	return {
		type: DELEGATION_UPDATE_CUSTOM_DICTIONARY,
		data,
	};
}

function _removeDelegationCustomDictionary(data: IPage<IDelegationDictionary[]>): Action {
	return {
		type: DELEGATION_REMOVE_CUSTOM_DICTIONARY,
		data,
	};
}

function _fetchAllDelegationAttachmentDictionaries(data: IDelegationDictionary[]): Action {
	return {
		type: DELEGATION_FETCH_ALL_ATTACHMENT_DICTIONARIES,
		data,
	};
}

function _fetchAllDelegationTransportDictionaries(data: IDelegationDictionary[]): Action {
	return {
		type: DELEGATION_FETCH_ALL_TRANSPORT_DICTIONARIES,
		data,
	};
}

export function delegation_setCustomDictionaries(data: IPage<IDelegationDictionary[]>): Action {
	return _setDelegationCustomDictionaries(data);
}

export function delegation_fetchCustomDictionaries(from: number, to: number, name?: string): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchDelegationCustomDictionaries((json as IJson).data)));
		return axios().get(`${basePath}/from/${from}/count/${to}`, {
			data: { name },
		});
	};
}

export function delegation_createCustomDictionary(data: Partial<IDelegationDictionary>): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_createDelegationCustomDictionary((json as IJson).data)));
		return axios().post(basePath, data);
	};
}

export function delegation_updateCustomDictionary(data: Partial<IDelegationDictionary>, id: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_updateDelegationCustomDictionary((json as IJson).data)));
		return axios().patch(`${basePath}/${id}`, data);
	};
}

export function delegation_removeCustomDictionary(id: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_removeDelegationCustomDictionary((json as IJson).data)));
		return axios().delete(`${basePath}/${id}`);
	};
}

export function delegation_fetchAllAttachmentDictionaries(): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchAllDelegationAttachmentDictionaries((json as IJson).data)));
		return axios().get(`${basePath}/all/${EDelegationDictionaryType.Attachment}`);
	};
}

export function delegation_fetchAllTransportDictionaries(): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchAllDelegationTransportDictionaries((json as IJson).data)));
		return axios().get(`${basePath}/all/${EDelegationDictionaryType.MeansOfTransport}`);
	};
}
