import clsx from 'clsx';
import { useContext, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { EInputTypes, IIssueKanbanDetails, IProject, IProjectIssueType, ITag } from '@sac-tt/tasker-types';
import { CustomInput, CustomSelect, TaskerTag } from '@sac-tw2/tasker-widgets2';

import { Typography } from '@mui/material';

import { IReducerState } from '@/store/reducers/types';
import { getContrastTagTextColor } from '@/utils/contrastColor';
import { useQuery } from '@/utils/hooks/useQuery';
import { useTrans } from '@/utils/hooks/useTrans';

import { issueModalDataContext } from 'view/IssueModal';

import { EIssueModalDetailsPageFields } from '../model';
import { SingleValueProjectCustom } from '../RightSideIssueData/ExtraIssueInfo/SingleValueProjectCustom';

import { DependentInputs } from './DependentInputs';
import { CustomOption } from './DependentInputs/Option';
import { SingleValueCustom } from './DependentInputs/SingleValueCustom';
import { Resources } from './resources';
import { useStyles } from './styles';
import {
	getProjectClasses,
	getProjectErrors,
	getProjectIssueTypeClasses,
	getProjectIssueTypeErrors,
	getProjectIssueTypeValues,
	getProjectName,
	getProjectValues,
} from './utils';

export const MainIssueData = () => {
	const classes = useStyles();
	const { t } = useTrans('IssueModal.IssueModalContent.IssueDetailsPage.MainIssueData.BasicIssueDate');
	const query = useQuery();
	const {
		issueState,
		inputsInEdit,
		handleEditStateChange,
		handleInputChange,
		issueErrors,
		handleProjectChange,
		handleIssueTypeChange,
		handleReset,
		isEdittingIssue,
	} = useContext(issueModalDataContext);

	const issue = useSelector((state: IReducerState) => state?.IssueModalEditor?.issueModal);
	const isDarkMode: boolean = useSelector((state: IReducerState) => state?.Session?.darkMode);
	const projectIssueTypes: IProjectIssueType[] = useSelector(
		(state: IReducerState) => state?.IssueModalEditor?.projectIssueTypes
	);
	const kanbanDetails: IIssueKanbanDetails = useSelector(
		(state: IReducerState) => state?.IssueModalEditor?.issueModalKanbanDetails
	);
	const projectsList = useSelector((state: IReducerState) => state?.IssueModalEditor?.projectsListNew)?.filter(
		project => !kanbanDetails?.projectIds || kanbanDetails?.projectIds?.includes(project?.Id)
	);

	const projectId = useMemo(() => parseInt(query['projectId']), [query['projectId']]);

	useEffect(() => {
		if (!!projectId && !Number.isNaN(projectId)) onProjectChange(projectId);
	}, [projectId]);

	const onProjectChange = (projectId: number) => {
		handleInputChange(EIssueModalDetailsPageFields.projectId, projectId);
		handleProjectChange(projectId);
	};

	const handleInputChangeWrapper = (projectIssueType: IProjectIssueType | IProjectIssueType[]) => {
		handleInputChange(EIssueModalDetailsPageFields.projectIssueTypeId, (projectIssueType as IProjectIssueType).Id);
		handleIssueTypeChange();
	};

	const handleInEditStateChangeWrapper = () => {
		handleEditStateChange(EIssueModalDetailsPageFields.projectIssueTypeId, true);
	};

	return (
		<div className={clsx(classes.mainContainer)}>
			<CustomInput
				isRequired
				canSetInputInNonEdit={isEdittingIssue}
				inEdit={!!inputsInEdit[EIssueModalDetailsPageFields.title]}
				type={EInputTypes.text}
				label={t('issueName')}
				handleChange={val => handleInputChange(EIssueModalDetailsPageFields.title, val)}
				errorMessage={
					issueErrors.find(error => error.inputName === EIssueModalDetailsPageFields.title)?.errorMessage
				}
				value={issueState?.Title ?? issue?.Title ?? ''}
				handleInEditStateChange={() => handleEditStateChange(EIssueModalDetailsPageFields.title, true)}
				handleReset={() => handleReset(EIssueModalDetailsPageFields.title)}
				nonEditComponent={<Typography>{issueState?.Title}</Typography>}
			/>
			<div className={clsx(classes.secondRow, classes.flexYCenter, classes.contentSpaceBetween)}>
				<CustomSelect<IProject>
					isRequired
					isDarkMode={isDarkMode}
					label={t('project')}
					canSetInputInNonEdit={isEdittingIssue}
					inEdit={!!inputsInEdit[EIssueModalDetailsPageFields.projectId]}
					errorMessage={getProjectErrors(issueErrors)}
					items={projectsList ?? []}
					args={{ ['components']: { SingleValue: SingleValueProjectCustom }, menuPosition: Resources.fixed }}
					values={getProjectValues(projectsList, issueState?.ProjectId ?? issue.ProjectId ?? projectId)}
					className={getProjectClasses(classes, issueErrors)}
					onChange={project => onProjectChange((project as IProject)?.Id)}
					getName={(project: IProject) => project?.Name ?? Resources.GlobalResources.empty}
					getKey={(project: IProject) => project?.Id}
					handleInEditStateChange={() => handleEditStateChange(EIssueModalDetailsPageFields.projectId, true)}
					handleReset={() => handleReset(EIssueModalDetailsPageFields.projectId)}
					nonEditComponent={
						<Typography>
							{getProjectName(projectsList, issueState?.ProjectId ?? issue.ProjectId)}
						</Typography>
					}
				/>

				<CustomSelect<IProjectIssueType>
					isRequired
					isDarkMode={isDarkMode}
					inEdit={!!inputsInEdit[EIssueModalDetailsPageFields.projectIssueTypeId]}
					label={t('issueType')}
					errorMessage={getProjectIssueTypeErrors(issueErrors)}
					args={{
						['components']: {
							SingleValue: SingleValueCustom,
							Option: CustomOption,
						},
						menuPosition: Resources.fixed,
						isDisabled: !issueState?.ProjectId,
					}}
					items={projectIssueTypes ?? []}
					canSetInputInNonEdit={isEdittingIssue}
					values={getProjectIssueTypeValues(projectIssueTypes, issueState?.ProjectIssueTypeId as number)}
					className={getProjectIssueTypeClasses(classes, issueErrors)}
					onChange={handleInputChangeWrapper}
					getName={x => x?.IssueType?.Label ?? Resources.GlobalResources.empty}
					getKey={x => x?.Id}
					handleInEditStateChange={handleInEditStateChangeWrapper}
					handleReset={() => handleReset(EIssueModalDetailsPageFields.projectIssueTypeId)}
					nonEditComponent={<TaskerTag tag={issue?.ProjectIssueType?.IssueType as ITag} />}
				/>

				<DependentInputs />
			</div>
		</div>
	);
};
