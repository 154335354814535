import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	redirect: {
		empty: '/',
	},
	password: 'password' as const,
};

export const Translation = {
	pl: {
		ResetPassword: {
			pageTitle: 'Zapomniałem hasła',
			onResetPasswordMessage: 'Link wygasł',
			differencePassword: 'Hasla są różne',
			passwordChanged: 'Hasło zostało zmienione',
			passwordNotChanged: 'Coś poszło nie tak spróbuj później',
			resetPasswordLabel: 'Zmiana hasła',
			passwordTextBoxLabel: 'Hasło',
			passwordRepeatTextBoxLabel: 'Powtórz hasło',
			changePassword: 'Zmień hasło',
		},
	},
	en: {
		ResetPassword: {
			pageTitle: 'Forgot password',
			onResetPasswordMessage: 'The link expired',
			differencePassword: 'The password are not identical',
			passwordChanged: 'Your password was changed',
			passwordNotChanged: 'Something wrong. Try again later',
			resetPasswordLabel: 'Reset password',
			passwordTextBoxLabel: 'Password',
			passwordRepeatTextBoxLabel: 'Repeat password',
			changePassword: 'Change password',
		},
	},
};
