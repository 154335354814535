import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { EInputTypes } from '@sac-tt/tasker-types';
import { Button, CustomInput, CustomSelect } from '@sac-tw2/tasker-widgets2';

import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';

import { IReducerState } from '@/store/reducers/types';
import { useTrans } from '@/utils/hooks/useTrans';

import { ESocialMediaLinkInputName, IEnumAsObject, ISocialMediaLink, Props } from './model';
import { Resources } from './resources';
import { useStyles } from './styles';
import { getArrayFromUrlType } from './utils';

export const Form = (props: Props) => {
	const { handleSave, initialFormState, handleCancel, formErrors, setFormErrors } = props;
	const classes = useStyles();
	const { t } = useTrans('IssueModal.IssueModalTitle.MoreOptions.SocialMediaLinks.Form');

	const [formState, setFormState] = useState<Partial<ISocialMediaLink>>({});

	const isDarkMode: boolean = useSelector((state: IReducerState) => state?.Session?.darkMode);

	useEffect(() => {
		if (!initialFormState) return;

		setFormState(initialFormState);
	}, []);

	const handleChange = (inputName: ESocialMediaLinkInputName, value: string | number) => {
		setFormState(prevState => ({
			...prevState,
			[`${inputName}`]: value,
		}));
		setFormErrors(prevState => prevState.filter(error => error.inputName !== inputName));
	};

	const getError = (inputName: ESocialMediaLinkInputName) => {
		return formErrors.find(error => error.inputName === inputName)?.errorMessage;
	};

	return (
		<div>
			<div>
				<div>
					<CustomInput
						label={t('url')}
						type={EInputTypes.text}
						handleChange={value => handleChange(ESocialMediaLinkInputName.Url, value as string)}
						value={formState?.Url ?? ''}
						errorMessage={getError(ESocialMediaLinkInputName.Url)}
						isRequired
					/>
				</div>
				<div className={clsx(classes.bottomContainer)}>
					<CustomInput
						isRequired
						label={t('name')}
						type={EInputTypes.text}
						handleChange={value => handleChange(ESocialMediaLinkInputName.Label, value as string)}
						value={formState?.Label ?? Resources.GlobalResources.empty}
						errorMessage={getError(ESocialMediaLinkInputName.Label)}
					/>
					<CustomSelect<IEnumAsObject>
						isRequired
						isDarkMode={isDarkMode}
						label={t('type')}
						onChange={linkType =>
							handleChange(ESocialMediaLinkInputName.UrlTypeId, (linkType as IEnumAsObject)?.Key)
						}
						values={getArrayFromUrlType()?.find(linkType => linkType?.Key === formState?.UrlTypeId)}
						placeholder={''}
						items={getArrayFromUrlType()}
						getName={c => c?.Label}
						getKey={c => c?.Key}
						args={{ ['menuPosition']: Resources.fixed }}
						errorMessage={getError(ESocialMediaLinkInputName.UrlTypeId)}
					/>
				</div>
				<div className={classes.buttonContainer}>
					<Button onClick={() => handleSave(formState)} variant={Resources.GlobalResources.contained}>
						<SaveIcon />
						{t('save')}
					</Button>
					<Button
						className={classes.cancelButton}
						onClick={handleCancel}
						variant={Resources.GlobalResources.contained}
					>
						<CloseIcon />
						{t('cancel')}
					</Button>
				</div>
			</div>
		</div>
	);
};
