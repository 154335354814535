import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		OrganizationPanel: {
			GeneralTab: {
				general: 'Ogólne',
				addressData: 'Dane adresowe',
				name: 'Nazwa organizacji',
				nameError: 'Nazwa organizacji jest niepoprawna',
				city: 'Nazwa miasta',
				cityError: 'Nazwa miasta jest niepoprawna',
				zip: 'Kod pocztowy',
				zipError: 'Błędny format kodu pocztowego (xx-xxx)',
				nip: 'Numer identyfikacji podatkowej',
				nipError: 'Błędny format numeru identyfikacji podatkowej (max 10 cyfr)',
				email: 'Adres email',
				emailError: 'Niepoprawny adres email',
				phone: 'Numer telefonu',
				phoneError: 'Błędny format numeru telefonu (max 9 cyfr)',
				bank: 'Numer konta bankowego',
				bankError: 'Błędny format numeru konta bankowego (opcjonalnie kod kraju + 26 cyfr)',
				logo: 'Url logotypu',
				logoError: 'Niepoprawny adres url logotypu',
			},
		},
	},
	en: {
		OrganizationPanel: {
			GeneralTab: {
				general: 'General',
				addressData: 'Address data',
				name: 'Organization name',
				nameError: 'Organization name is incorrect',
				city: 'City name',
				cityError: 'City name is incorrect',
				zip: 'Zip code',
				zipError: 'Incorrect zip code format (xx-xxx)',
				nip: 'Tax identification number',
				nipError: 'Incorrect tax identification number format (max 10 digits)',
				email: 'Email address',
				emailError: 'Incorrect email address',
				phone: 'Phone number',
				phoneError: 'Incorrect phone number format (max 9 digits)',
				bank: 'Bank account number',
				bankError: 'Incorrect bank account number format (optionally country code + 26 digits)',
				logo: 'Logo url',
				logoError: 'Incorrect logo url address',
			},
		},
	},
};
