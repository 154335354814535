import { t } from 'i18next';

import { EIssueRole, FullFilters, ITaskerUser } from '@sac-tt/tasker-types';
import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

import { IDimension } from './model';

export const Resources = {
	...GlobalResources,
	avatarKey: (id: number, index: number, isSelected: boolean, isDefault: boolean) =>
		`Profile_AvatarTooltip_${id}_index_${index}_${isSelected ? 'selected' : 'unselected'}_${
			isDefault ? 'default' : 'regular'
		}`,
	keys: {
		menu: (id: number) => `Profile_menu_type_${id}`,
	},
};

export const Translation = {
	pl: {
		SearchBar: {
			DimensionProfileSelector: {
				clear: 'Wyczyść zaznaczone',
				unassigned: 'Nieprzypisane',
				menu: {
					[EIssueRole.Reporter]: 'Osoba raportująca o zgłoszeniu',
					[EIssueRole.Assigned]: 'Osoba wykonująca zgłoszenie',
					[EIssueRole.Observer]: 'Osoba obserwująca zgłoszenie',
					[EIssueRole.Creator]: 'Osoba tworząca zgłoszenie',
					[EIssueRole.Auditor]: 'Osoba sprawdzająca zgłoszenie',
				},
			},
		},
	},
	en: {
		SearchBar: {
			DimensionProfileSelector: {
				clear: 'Clear all selected',
				unassigned: 'Unassigned',
				menu: {
					[EIssueRole.Reporter]: 'Person who reported the issue',
					[EIssueRole.Assigned]: 'Person who doing the issue',
					[EIssueRole.Observer]: 'Person who watching the issue',
					[EIssueRole.Creator]: 'Person who created the issue',
					[EIssueRole.Auditor]: 'Person who checking the issue',
				},
			},
		},
	},
};

export const getDimensions: (
	items: Partial<ITaskerUser>[],
	filter: FullFilters
) => IDimension<Partial<ITaskerUser>>[] = (items, filter) => [
	{
		key: Resources.keys.menu(EIssueRole.Assigned),
		items: items,
		label: t(`SearchBar.DimensionProfileSelector.menu.${EIssueRole.Assigned}`),
		selected: filter?.Issue?.Assigned ?? [],
		role: EIssueRole.Assigned,
	},
	{
		key: Resources.keys.menu(EIssueRole.Reporter),
		items: items,
		label: t(`SearchBar.DimensionProfileSelector.menu.${EIssueRole.Reporter}`),
		selected: filter?.Issue?.Reporter ?? [],
		role: EIssueRole.Reporter,
	},
	{
		key: Resources.keys.menu(EIssueRole.Auditor),
		items: items,
		label: t(`SearchBar.DimensionProfileSelector.menu.${EIssueRole.Auditor}`),
		selected: filter?.Issue?.Auditor ?? [],
		role: EIssueRole.Auditor,
	},
	{
		key: Resources.keys.menu(EIssueRole.Creator),
		items: items,
		label: t(`SearchBar.DimensionProfileSelector.menu.${EIssueRole.Creator}`),
		selected: filter?.Issue?.Created ?? [],
		role: EIssueRole.Creator,
	},
	{
		key: Resources.keys.menu(EIssueRole.Observer),
		items: items,
		label: t(`SearchBar.DimensionProfileSelector.menu.${EIssueRole.Observer}`),
		selected: filter?.Issue?.Observer ?? [],
		role: EIssueRole.Observer,
	},
];

export const getAssignedDimension: (
	items: Partial<ITaskerUser>[],
	filter: FullFilters
) => IDimension<Partial<ITaskerUser>> = (items, filter) => {
	const dimensions = getDimensions(items, filter);
	return dimensions[0];
};
