import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		DelegationsAcceptanceList: {
			TileComponents: {
				DelegationMenu: {
					menuRequestToCorrection: 'Sprawdź i odrzuć delegację',
					menuRequestToSettlementAcceptance: 'Sprawdź i wyślij do akceptacji rozliczenia',
					menuRequestToDone: 'Sprawdź i zakończ delegację',
					menuRequestToReturnAcceptance: 'Sprawdź i zwróć delegację do akceptacji',
					menuRaportPDF: 'Pobierz raport w formacie PDF',
					menuRaportXLSX: 'Pobierz raport w formacie XLSX',
				},
			},
		},
	},
	en: {
		DelegationsAcceptanceList: {
			TileComponents: {
				DelegationMenu: {
					menuRequestToCorrection: 'Validate and reject delegation',
					menuRequestToSettlementAcceptance: 'Validate and send for settlement acceptance',
					menuRequestToDone: 'Validate and end delegation',
					menuRequestToReturnAcceptance: 'Validate and return delegation for acceptance',
					menuRaportPDF: 'Download report in PDF format',
					menuRaportXLSX: 'Download report in XLSX format',
				},
			},
		},
	},
};
