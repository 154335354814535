import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	ProjectTextField: {
		field: 'Name',
		name: 'Name',
	},
	ProjectPrefixTextField: {
		field: 'Prefix',
		name: 'Name',
		label: 'Prefix',
	},

	Description: {
		field: 'Description',
		name: 'Description',
	},
	StartDate: {
		name: 'StartDate',
		field: 'StartDate',
	},
	EndDate: {
		name: 'EndDate',
		field: 'EndDate',
	},
	mask: '____/__/__',
};

export const Translation = {
	pl: {
		ProjectCreate: {
			AboutProject: {
				title: 'O projekcie',
				ProjectTextField: {
					label: 'Nazwa projektu',
				},
				StartDate: {
					label: 'Data początku',
				},
				EndDate: {
					label: 'Data końca',
				},
				Description: {
					label: 'Opis',
				},
				cardHeaderTitle: 'O projekcie',
				customPrefixToolTip: 'Użyj niestandardowego prefixu',
				customPrefixDisabled: 'Niestandardowy prefix nie został włączony',
				noDate: 'Niewłaściwa data została wybrana',
			},
		},
	},
	en: {
		ProjectCreate: {
			AboutProject: {
				title: 'About this project',
				ProjectTextField: {
					label: 'Project Name',
				},
				StartDate: {
					label: 'Start Date',
				},
				EndDate: {
					label: 'End Date',
				},
				Description: {
					label: 'Description',
				},
				cardHeaderTitle: 'About this project',
				customPrefixToolTip: 'Use custom prefix',
				customPrefixDisabled: 'Custom prefix is not enabled',
				noDate: 'Incorrect date',
			},
		},
	},
};
