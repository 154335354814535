import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	mousedown: 'mousedown' as const,
	onBlur: 'onBlur',
	menuPortalTarget: 'menuPortalTarget',
	menuIsOpen: 'menuIsOpen',
	openMenuOnClick: 'openMenuOnClick',
	body: 'body',
	ref: 'ref',
	issue: 'Issue',
};
