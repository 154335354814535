import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	chartWidth: '100%',
	chartHeight: '90%',
	worklogsFinished: 'worklogsFinished',
	dayOfWeek: 'dayOfWeek',
	worklogActive: 'worklogActive',
	worklogSum: 'worklogSum',
	stackId: '1',
	one: '1',
	zero: '0',
	colorActive1: '#259449',
	colorActive2: '#33b35c',
	colorActive3: '#5ace7e',
	colorInactive1: '#1e5d33',
	colorInactive2: '#20753c',
	colorInactive3: '#259449',
	start: '0%',
	center: '50%',
	end: '100%',
	activeGradientId: 'gradient',
	inactiveGradientId: 'inactive_gradient',
	strokeDasharray: '4 1',
	xAxisTick: { fontSize: '12px' },
};

export const Translation = {
	pl: {
		Activities: {
			ActivityList: {
				Chart: {
					summary: 'Łączny tygodniowy czas:',
					summaryTime: 'Suma z dnia',
					loggedTime: 'Zalogowany czas',
					activeWorklog: 'Aktywne zadania',
					issues: 'Twoje zgłoszenia',
					today: 'Dziś',
					lastWeek: 'Ostatni tydzień',
					move: 'Przejdź do issue ',
					comments: 'Komentarze w twoich zgłoszeniach',
					title: 'Tytuł:',
					comment: 'Komentarz:',
					lastComments: 'Ostatnie komentarze',
					noComments: 'Nic nie dodano...',
					worklogs: 'Zalogowany czas w tym tygodniu',
					loggedMonthTime: 'Miesięczny zalogowany czas: ',
					loading: 'Ładowanie',
				},
			},
		},
	},
	en: {
		Activities: {
			ActivityList: {
				Chart: {
					summary: 'Summary week time:',
					summaryTime: 'Summary day time',
					loggedTime: 'Logged time',
					activeWorklog: 'Active issues',
					issues: 'Your Issues',
					today: 'Today',
					lastWeek: 'Last week',
					move: 'Open issue ',
					comments: 'Comments in your issues',
					title: 'Title:',
					comment: 'Comment:',
					lastComments: 'Last comments',
					noComments: 'Nothing added...',
					worklogs: 'Logged time this week',
					loggedMonthTime: 'Month logged time: ',
					loading: 'Loading',
				},
			},
		},
	},
};
