import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	mediaQuery: '(max-width: 1400px)',
};

export const Translation = {
	pl: {
		Legend: {
			legend: 'Aby zobaczyć legendę kliknij lewy przycisk myszy.',
		},
	},
	en: {
		Legend: {
			legend: 'In order to see the legend press left mouse button.',
		},
	},
};
