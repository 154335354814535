import { createContext } from 'react';

import { EFieldDefinitionType, IDictionaryItem, ITag } from '@sac-tt/tasker-types';
import { IIssueFormSectionToIssueField } from '@sac-tt/tasker-types/dist/types/dataBaseTypes/issueFormSectionToIssueField';

import { IssueFormSection } from './IssueFormSection';
import { Props } from './model';
import { Resources } from './resources';

export enum EValueToUse {
	FieldValue = 'FieldValue',
	CheckListValue = 'CheckListValue',
}

interface IIssueFormSectionsWidgetContext {
	handleChange?: (
		val: boolean | ITag | ITag[] | string | number | Date | IDictionaryItem | IDictionaryItem[],
		issueFormSectionToIssueField: IIssueFormSectionToIssueField
	) => void;
	state?: Record<EFieldDefinitionType, Record<number, unknown>>;
	valueToUse: EValueToUse;
}

export const IssueFormSectionsWidgetContext = createContext<IIssueFormSectionsWidgetContext>(
	{} as IIssueFormSectionsWidgetContext
);

export const IssueFormSectionsWidget = (props: Props) => {
	const { IssueFormSections, handleChange, state, valueToUse, inEdit } = props;

	return (
		<IssueFormSectionsWidgetContext.Provider value={{ handleChange, state, valueToUse }}>
			<div>
				{IssueFormSections?.map(issueFormSection => (
					<div key={Resources.key(issueFormSection.Id)}>
						<IssueFormSection issueFormSection={issueFormSection} inEdit={inEdit} />
					</div>
				))}
			</div>
		</IssueFormSectionsWidgetContext.Provider>
	);
};
