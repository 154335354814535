import { getGlobalStyles } from '@sac-tw2/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(
	(theme: Theme) => ({
		...getGlobalStyles(),
		info_tooltip: {
			padding: theme.spacing(0.25, 0.25, 0.25, 0.25),
		},
		divider: {
			width: '2px',
			backgroundColor: theme.palette.lightGray.light,
			height: '80%',
			marginTop: '4px',
			marginBottom: '2px',
		},
	}),
	{ name: `Dashboard_WorklogStarter_IconActions` }
);
