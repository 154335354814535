import { PL, SA, US } from 'country-flag-icons/react/3x2';
import { MouseEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ETaskerTranslationLanguage } from '@sac-tt/tasker-types/dist/types/custom/language';

import FlagSharpIcon from '@mui/icons-material/FlagSharp';
import PublicIcon from '@mui/icons-material/Public';
import TranslateIcon from '@mui/icons-material/Translate';
import { Button, IconButton, Menu, MenuItem, Tooltip } from '@mui/material';

import { session_changeLanguageForUser } from '@/store/actions';
import { IReducerState } from '@/store/reducers/types';
import { Dispatch } from '@/store/types';
import { useTrans } from '@/utils/hooks/useTrans';

import { Props } from './model';
import { Resources } from './resources';
import { useStyles } from './styles';

export const LanguageButton = (props: Props): JSX.Element => {
	const { logged } = props;
	const dispatch: Dispatch = useDispatch();
	const classes = useStyles();
	const { tString, setLng } = useTrans('Dashboard.TopBar.LanguageButton');

	const languageId = useSelector((state: IReducerState) => state.Session.languageId);
	const [icon, setIcon] = useState<JSX.Element>(<FlagSharpIcon />);

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const handleOpenMenu = (event: MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	useEffect(() => {
		if (!localStorage.getItem('language')) localStorage.setItem('language', 'pl');

		switch (localStorage.getItem('language')) {
			case ETaskerTranslationLanguage.en:
				setIcon(<US className={classes.flag} />);
				break;
			case ETaskerTranslationLanguage.ar:
				setIcon(<SA className={classes.flag} />);
				break;
			default:
				setIcon(<PL className={classes.flag} />);
				break;
		}
	}, [localStorage.getItem('language')]);

	const handleSelectLanguage = (item: ETaskerTranslationLanguage) => {
		setAnchorEl(null);
		setLng(item);
		if (logged) dispatch(session_changeLanguageForUser(item, languageId));
		localStorage.setItem('language', item);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<Tooltip title={tString('tooltip')}>
				<Button onClick={handleOpenMenu} className={classes.button}>
					<PublicIcon />

					{localStorage.getItem('language')}
				</Button>
			</Tooltip>
			<Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
				<MenuItem
					onClick={() => {
						handleSelectLanguage(ETaskerTranslationLanguage.en);
					}}
				>
					<US className={classes.flag} />
					{Resources.english}
				</MenuItem>
				<MenuItem
					onClick={() => {
						handleSelectLanguage(ETaskerTranslationLanguage.pl);
					}}
				>
					<PL className={classes.flag} />
					{Resources.polish}
				</MenuItem>
				<MenuItem
					onClick={() => {
						handleSelectLanguage(ETaskerTranslationLanguage.ar);
					}}
				>
					<SA className={classes.flag} />
					{Resources.arabic}
				</MenuItem>
			</Menu>
		</>
	);
};

export default LanguageButton;
