import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	URL: '/kanban/',
	kanbanUrl: '/kanban',
	pageTitle: {
		kanban: 'Kanban',
		loading: 'Loading',
	},
	px: 'px',
	generalPagePadding: 16,
	general: 'general',
	swimlaneCookieName: 'SwimlaneCounts',
	issueIdRegex: '/issueId=[0-9]*/',
	...GlobalResources,
};

export enum ETagType {
	Icon = 1,
	Label = 2,
	IconAndLabel = 3,
}
const complexUpdateInitialValue = {
	addItems: [],
	updateItems: [],
	deleteItems: [],
};

export const updateItemsInitialValue = {
	kanbanName: '',
	projectIds: [],
	columnTags: complexUpdateInitialValue,
	maps: complexUpdateInitialValue,
};

export const Translation = {
	pl: {
		Kanban: {
			KanbanBoard: { noSuchKanban: 'Nie ma takiego kanbana lub nie masz do niego dostępu!' },
		},
	},
	en: {
		Kanban: {
			KanbanBoard: { noSuchKanban: 'There is no such kanban or you dont have permission!' },
		},
	},
};
