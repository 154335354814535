import { getGlobalStyles } from '@sac-tw2/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(
	(theme: Theme) => ({
		...getGlobalStyles(),
		container: {
			marginTop: theme.spacing(5),
			marginBottom: theme.spacing(1),
			marginLeft: theme.spacing(1),
			width: theme.spacing(50),
		},
	}),
	{ name: `IssueModal_IssueModalContent_Relation_SelectUser` }
);
