import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
	EFieldDefinitionType,
	ICheckListValue,
	IDictionaryItem,
	IIssueFormSectionToIssueField,
	ITag,
	ITaskerUser,
} from '@sac-tt/tasker-types';
import { IIssueFormSection } from '@sac-tt/tasker-types/dist/types/dataBaseTypes/issueFormSection';

import { EValueToUse, IssueFormSectionsWidget } from '@/layouts/Widgets/IssueFormSectionWidget';
import { resetEndOfWork, setLocalValues } from '@/store/actions';
import { IReducerState } from '@/store/reducers/types';

import { IModalFormStateSimple, Props } from './model';
import { getState } from './utils';

export const Form = (props: Props): JSX.Element => {
	const { isEdit, form } = props;
	const dispatch = useDispatch();

	const loggedUser: ITaskerUser = useSelector((state: IReducerState) => state?.Session?.taskerUser);
	const values: { [x: number]: ICheckListValue[] } = useSelector(
		(state: IReducerState) => state?.DashBoard?.EndOfWork.values
	);

	const [state, setState] = useState<IModalFormStateSimple>(getState(values));

	useEffect(() => {
		return () => {
			dispatch(resetEndOfWork());
		};
	}, []);

	const onFormChange = (
		val: boolean | ITag | ITag[] | string | number | Date | IDictionaryItem | IDictionaryItem[],
		issueFormSectionToIssueField: IIssueFormSectionToIssueField
	) => {
		const fieldDefinition = issueFormSectionToIssueField?.AttributeDefinition;
		const fieldValueTmp: { [x: number]: Partial<ICheckListValue>[] } = values ?? {};

		const objects: Partial<ICheckListValue>[] = [];

		switch (fieldDefinition?.FieldDefinitionTypeId) {
			case EFieldDefinitionType.Boolean: {
				objects.push({
					FieldDefinitionId: fieldDefinition?.Id,
					TaskerUserId: loggedUser?.Id,
					DateOfCompletion: new Date(),
					ValueBoolean: val as boolean,
				});
				break;
			}
			case EFieldDefinitionType.TagGroup: {
				if (fieldDefinition?.IsMultiValue) {
					const tags = val as ITag[];
					for (let i = 0; i < tags?.length; i++) {
						const tag = tags[i];
						objects.push({
							FieldDefinitionId: fieldDefinition?.Id,
							TaskerUserId: loggedUser?.Id,
							DateOfCompletion: new Date(),
							ValueTagId: tag?.Id,
							ValueTag: tag,
						});
					}
				} else {
					const tag = val as ITag;
					objects.push({
						FieldDefinitionId: fieldDefinition?.Id,
						TaskerUserId: loggedUser?.Id,
						DateOfCompletion: new Date(),
						ValueTagId: tag?.Id,
						ValueTag: tag,
					});
				}
				break;
			}
			case EFieldDefinitionType.Date: {
				objects.push({
					FieldDefinitionId: fieldDefinition?.Id,
					TaskerUserId: loggedUser?.Id,
					DateOfCompletion: new Date(),
					ValueDate: val as Date,
				});
				break;
			}
			case EFieldDefinitionType.DateTime: {
				objects.push({
					FieldDefinitionId: fieldDefinition?.Id,
					TaskerUserId: loggedUser?.Id,
					DateOfCompletion: new Date(),
					ValueDateTime: val as Date,
				});
				break;
			}
			case EFieldDefinitionType.Decimal: {
				objects.push({
					FieldDefinitionId: fieldDefinition?.Id,
					TaskerUserId: loggedUser?.Id,
					DateOfCompletion: new Date(),
					ValueDecimal: val as number,
				});
				break;
			}
			case EFieldDefinitionType.Dictionary: {
				if (fieldDefinition?.IsMultiValue) {
					const tags = val as IDictionaryItem[];
					for (let i = 0; i < tags?.length; i++) {
						const dictionaries = tags[i];
						objects.push({
							FieldDefinitionId: fieldDefinition?.Id,
							TaskerUserId: loggedUser?.Id,
							DateOfCompletion: new Date(),
							ValueDictionaryItemId: dictionaries?.Id,
							ValueDictionaryItem: dictionaries,
						});
					}
				} else {
					const dictionary = val as IDictionaryItem;
					objects.push({
						FieldDefinitionId: fieldDefinition?.Id,
						TaskerUserId: loggedUser?.Id,
						DateOfCompletion: new Date(),
						ValueDictionaryItemId: dictionary?.Id,
						ValueDictionaryItem: dictionary,
					});
				}
				break;
			}
			case EFieldDefinitionType.Integer: {
				objects.push({
					FieldDefinitionId: fieldDefinition?.Id,
					TaskerUserId: loggedUser?.Id,
					DateOfCompletion: new Date(),
					ValueInteger: val as number,
				});
				break;
			}
			case EFieldDefinitionType.Text: {
				objects.push({
					FieldDefinitionId: fieldDefinition?.Id,
					TaskerUserId: loggedUser?.Id,
					DateOfCompletion: new Date(),
					ValueText: val as string,
				});
				break;
			}
			case EFieldDefinitionType.TextArea: {
				objects.push({
					FieldDefinitionId: fieldDefinition?.Id,
					TaskerUserId: loggedUser?.Id,
					DateOfCompletion: new Date(),
					ValueText: val as string,
				});
				break;
			}
			default:
				break;
		}

		if (fieldDefinition?.Id && fieldDefinition.FieldDefinitionTypeId) {
			const type: EFieldDefinitionType = fieldDefinition.FieldDefinitionTypeId;
			const tmpValues = state?.[type];
			setState({
				...state,
				[fieldDefinition.FieldDefinitionTypeId]: {
					...tmpValues,
					[fieldDefinition.Id]: val,
				},
			});
		}

		const fieldDefinitionId: number = fieldDefinition?.Id as number;
		fieldValueTmp[fieldDefinitionId] = objects;
		dispatch(setLocalValues(fieldValueTmp as { [x: number]: ICheckListValue[] }));
	};

	return (
		<div>
			<IssueFormSectionsWidget
				inEdit={isEdit}
				IssueFormSections={form?.IssueFormSection as IIssueFormSection[]}
				valueToUse={EValueToUse.CheckListValue}
				state={state}
				handleChange={onFormChange}
			/>
		</div>
	);
};
