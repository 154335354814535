import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationDetails: {
						DelegationSettlementAttachments: {
							add: 'Dodaj',
						},
					},
				},
			},
		},
	},
	en: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationDetails: {
						DelegationSettlementAttachments: {
							add: 'Add',
						},
					},
				},
			},
		},
	},
};
