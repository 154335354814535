import { IProject, ITaskerObject, ITaskerUser, IUserNotification } from '@sac-tt/tasker-types';

import * as actionTypes from '@/store/actions';
import { ObjectAction } from '@/store/types';

export const State = {
	userNotificationsList: [] as IUserNotification[],
	countOfAllUserNotificationsList: 0 as number,
	projectsOptions: [] as IProject[],
	taskerObjects: [] as ITaskerObject[],
	taskerUsers: [] as Partial<ITaskerUser>[],
};
export type INotificationsListEditorState = typeof State;

const notificationsListReducer = (state = State, action: ObjectAction): INotificationsListEditorState => {
	switch (action?.type) {
		case actionTypes.SET_USER_NOTIFICATIONS_LIST_DATA: {
			return {
				...state,
				userNotificationsList: action?.notifications,
				countOfAllUserNotificationsList: action?.countOfAllUserNotifications,
			};
		}
		case actionTypes.PROJECT_FETCH_TYPE_NEW: {
			return {
				...state,
				projectsOptions: action.projects,
			};
		}
		case actionTypes.ISSUE_MODAL_FETCH_BASIC: {
			return {
				...state,
				projectsOptions: action.modal.projects,
			};
		}
		case actionTypes.TASKER_OBJECTS_FETCH: {
			return {
				...state,
				taskerObjects: action.objects,
			};
		}
		case actionTypes.FETCH_ALL_USERS: {
			return {
				...state,
				taskerUsers: action.taskerUsers,
			};
		}
		default: {
			return state;
		}
	}
};

export default notificationsListReducer;
