import axios, { AxiosInstance } from 'axios';
import uuid from 'uuid';

import { IAuthData } from '@sac-tt/tasker-types';

import { store } from '@/App';
import { reset_session_metadata, sesion_setIsLoading_v2, session_updateAuthInfo } from '@/store/actions';
import { IReducerState } from '@/store/reducers/types';
import { Action } from '@/store/types';
import { encryptOrigin } from '@/utils/encryption';
import { redirectError } from '@/utils/utils';

let numbersOfRequestInUse = 0;

export default (turnOfLoading = false): AxiosInstance => {
	const dispatch = store.dispatch as (action: Action) => any;
	const reducer: IReducerState = store.getState() as IReducerState;
	const tasker_Lang = reducer?.Session.language;
	const address = process?.env?.REACT_APP_BACKEND_ADDRESS ?? '';
	const protocol = address.match(/([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(localhost)/) ? 'http' : 'https';
	const key = turnOfLoading ? undefined : uuid();
	const startLoading = () => {
		if (key && reducer) dispatch(sesion_setIsLoading_v2(true));
	};

	if (key) startLoading();
	const instance = axios.create({ baseURL: `${protocol}://${address}/` });

	const removeLoading = () => {
		if (key) dispatch(sesion_setIsLoading_v2(false));
	};

	instance.interceptors.request.use(async config => {
		config.headers['X-Origin'] = await encryptOrigin();
		config.withCredentials = true;
		config.headers.TaskerLanguage = tasker_Lang ? tasker_Lang : undefined;
		return config;
	});

	numbersOfRequestInUse++;

	startLoading();
	instance.interceptors.response.use(
		value => {
			numbersOfRequestInUse--;
			if (typeof value?.data === 'number' || typeof value?.data === 'string') {
				removeLoading();
				return value;
			}
			if ('data' in value.data || 'auth' in value.data) {
				if ('auth' in value.data) {
					const auth = value.data.auth;
					dispatch(session_updateAuthInfo(auth as IAuthData));
				}
				value.data = value.data.data;
			}
			removeLoading();
			if (numbersOfRequestInUse == 0) removeLoading();
			return value;
		},
		error => {
			numbersOfRequestInUse--;
			const isRedirectError = redirectError(error);
			if (error?.response?.status == 401 || isRedirectError) {
				removeLoading();
				(store?.dispatch as (action: Action) => any)(reset_session_metadata());
			}
			if (numbersOfRequestInUse == 0) removeLoading();
			throw error;
		}
	);
	return instance;
};
