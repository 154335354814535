import { IBudget } from '@sac-tt/tasker-types';

export function extendList(list: IBudget[], id: number, newList: IBudget): IBudget[] {
	for (let i = 0; i < list.length; i++) {
		if (list[i].Id === id) {
			list[i] = newList;
			break;
		} else if (list[i].BudgetChildren) extendList(list[i].BudgetChildren as IBudget[], id, newList);
	}
	return list;
}

export function updateBudgetById(data: IBudget[], updates: Partial<IBudget>[]): IBudget[] {
	for (let i = 0; i < data?.length; i++) {
		let updatedItem = data[i];

		for (let j = 0; j < updates.length; j++) {
			if (data[i].Id === updates[j].Id) {
				updatedItem = updates[j] as IBudget;
			}
		}

		if (updatedItem.BudgetChildren)
			updatedItem.BudgetChildren = updateBudgetById(updatedItem.BudgetChildren, updates);

		data[i] = updatedItem;
	}

	return data;
}

export function updateOrAddBudgetById(dataInput: IBudget[], updatesInput: IBudget, id?: number): IBudget[] {
	let isReplaced = false;

	const replaceFunc = (data: IBudget[], updates: Partial<IBudget>): IBudget[] => {
		for (let i = 0; i < data?.length; i++) {
			let updatedItem = data[i];

			if (data[i].Id === updates?.Id) {
				updatedItem = updates as IBudget;
				isReplaced = true;
			}

			if (updatedItem.BudgetChildren)
				updatedItem.BudgetChildren = replaceFunc(updatedItem.BudgetChildren, updates);

			data[i] = updatedItem;
		}
		return data;
	};
	dataInput = replaceFunc(dataInput, updatesInput);
	if (isReplaced === false && id) return extendList(dataInput, id, updatesInput);

	return dataInput;
}

export function removeIncomeLine(budgets: IBudget[], id: number): IBudget[] {
	for (let i = 0; i < budgets.length; i++) {
		const budget = budgets[i];
		if (budget.IncomeLine) {
			budget.IncomeLine = budget.IncomeLine.filter(line => line.Id !== id);
		}
		if (budget.BudgetChildren) {
			for (let j = 0; j < budget.BudgetChildren.length; j++) {
				removeIncomeLine([budget.BudgetChildren[j]], id);
			}
		}
	}
	return budgets;
}

export function removeOutcomeLine(budgets: IBudget[], id: number): IBudget[] {
	for (let i = 0; i < budgets.length; i++) {
		const budget = budgets[i];
		if (budget.OutcomeLine) {
			budget.OutcomeLine = budget.OutcomeLine.filter(line => line.Id !== id);
		}
		if (budget.BudgetChildren) {
			for (let j = 0; j < budget.BudgetChildren.length; j++) {
				removeOutcomeLine([budget.BudgetChildren[j]], id);
			}
		}
	}
	return budgets;
}

export function removeBudget(budgets: IBudget[], id: number): IBudget[] {
	for (let i = 0; i < budgets.length; i++) {
		const budget = budgets[i];
		if (budget.BudgetChildren) {
			budget.BudgetChildren = budget.BudgetChildren.filter(child => child.Id !== id);
			for (let j = 0; j < budget.BudgetChildren.length; j++) {
				removeBudget([budget.BudgetChildren[j]], id);
			}
		}
	}
	return budgets;
}

export function removeIncomeLineForBudget(budgets: IBudget[], budgetId: number): IBudget[] {
	for (let i = 0; i < budgets.length; i++) {
		const budget = budgets[i];
		if (budget.Id === budgetId && budget.IncomeLine) {
			budget.IncomeLine = [];
		}
		if (budget.BudgetChildren) {
			for (let j = 0; j < budget.BudgetChildren.length; j++) {
				removeIncomeLineForBudget([budget.BudgetChildren[j]], budgetId);
			}
		}
	}
	return budgets;
}

export function removeOutcomeLineForBudget(budgets: IBudget[], budgetId: number): IBudget[] {
	for (let i = 0; i < budgets.length; i++) {
		const budget = budgets[i];
		if (budget?.Id === budgetId && budget.OutcomeLine) {
			budget.OutcomeLine = [];
		}
		if (budget.BudgetChildren) {
			for (let j = 0; j < budget.BudgetChildren.length; j++) {
				removeOutcomeLineForBudget([budget.BudgetChildren[j]], budgetId);
			}
		}
	}
	return budgets;
}

export function removeLinePeriodical(budgets: IBudget[], linePeriodicalId: number): IBudget[] {
	for (let i = 0; i < budgets.length; i++) {
		const budget = budgets[i];
		if (budget?.LinePeriodical) {
			budget.LinePeriodical = budget?.LinePeriodical?.filter(line => line?.Id !== linePeriodicalId) ?? [];
		}

		if (budget?.BudgetChildren) {
			budget.BudgetChildren = removeLinePeriodical(budget?.BudgetChildren ?? [], linePeriodicalId);
		}
	}

	return budgets;
}

export function removeLinePeriodicalByBudget(budgets: IBudget[], budgetId: number): IBudget[] {
	for (let i = 0; i < budgets.length; i++) {
		const budget = budgets[i];
		if (budget?.LinePeriodical) {
			budget.LinePeriodical = budget?.LinePeriodical?.filter(line => line?.BudgetId !== budgetId) ?? [];
		}

		if (budget?.BudgetChildren) {
			budget.BudgetChildren = removeLinePeriodical(budget?.BudgetChildren ?? [], budgetId);
		}
	}

	return budgets;
}
