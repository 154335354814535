import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	avatar: 'avatar',
	lastName: 'lastName',
	ASC: 'ASC',
	DESC: 'DESC',
	id: 'id',
	rdgLight: 'rdg-light',
};

export const Translation = {
	pl: {
		ProjectDetails: {
			Overview: {
				ProjectMembers: {
					membersTitle: 'Zespół projektowy',
					delete: 'Usuń',
					chooseUsers: 'Dodaj użytkowników...',
					deleteIcon: 'Porzuć zmiany',
					saveSuccess: 'Zapisano zmiany',
					constraintErrorOnAddedUsers: 'Następujący użytkownicy są już w projekcie: {{addedUsers}}',
					constraintErrorOnDeletedUsers:
						'Następujący użytkownicy nie są obecni w projekcie: {{deletedUsers}}',
				},
			},
		},
	},
	en: {
		ProjectDetails: {
			Overview: {
				ProjectMembers: {
					membersTitle: 'Project team',
					delete: 'Delete',
					chooseUsers: 'Add users...',
					deleteIcon: 'Abandon the changes',
					saveSuccess: 'Changes have been saved',
					constraintErrorOnAddedUsers: 'Following users are already in project: {{addedUsers}}',
					constraintErrorOnDeletedUsers:
						'The following users are not present in the project: {{deletedUsers}}',
				},
			},
		},
	},
};
