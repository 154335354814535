import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};
export const Translation = {
	pl: {
		ProjectDetails: {
			Form: {
				IssueForm: {
					CommonComponents: {
						IssueFormField: {
							FieldDefinitionClockConfiguration: {
								clockConfigurationTitle: 'Konfiguracja zegarka',
								clockConfigurationSubtitle:
									'Przypisz tagi do odpowiednich statusów zegarka, tak aby akcje zegarka mogły zarządzać tagiem tego pola statusowego',
								save: 'Zapisz',
								startTag: 'Start zadania',
								pauseTag: 'Zatrzymanie zadania',
								doneTag: 'Zakończenie zadania',
							},
						},
					},
				},
			},
		},
	},
	en: {
		ProjectDetails: {
			Form: {
				IssueForm: {
					CommonComponents: {
						IssueFormField: {
							FieldDefinitionClockConfiguration: {
								clockConfigurationTitle: 'Clock configuration',
								clockConfigurationSubtitle:
									'Assign tags to the appropriate clock statuses so that clock actions can manage the tag of this status field',
								save: 'Save',
								startTag: 'Start issue',
								pauseTag: 'Pause issue',
								doneTag: 'Finish issue',
							},
						},
					},
				},
			},
		},
	},
};
