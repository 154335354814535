import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationAcceptance: {
						DelegationAcceptanceComments: {
							CommentCard: {
								CommentCardMenu: {
									edit: 'Edytuj komentarz',
									delete: 'Usuń komentarz',
								},
							},
						},
					},
				},
			},
		},
	},
	en: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationAcceptance: {
						DelegationAcceptanceComments: {
							CommentCard: {
								CommentCardMenu: {
									edit: 'Edit',
									delete: 'Delete',
								},
							},
						},
					},
				},
			},
		},
	},
};
