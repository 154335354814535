import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	invalidDate: 'Invalid Date',
	...GlobalResources,
};

export const Translation = {
	pl: {
		LeaveRequestCalendar: {
			Toolbar: {
				Header: {
					today: 'Dziś',
					date: 'Data',
					addEvent: 'Dodaj urlop',
					selectDate: 'Wybierz datę',
				},
			},
		},
	},
	en: {
		LeaveRequestCalendar: {
			Toolbar: {
				Header: {
					today: 'Today',
					date: 'Data',
					addEvent: 'Add leave request',
					selectDate: 'Select date',
				},
			},
		},
	},
};
