import { History } from 'history';
import { t } from 'i18next';

import { Permission } from '@sac-tt/tasker-types';
import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

import { store } from '@/App';
import { IReducerState } from '@/store/reducers/types';

export const Resources = {
	...GlobalResources,
	forbidden: '/errors/error-401',
	tabs: {
		Roles: {
			value: 'Roles',
			visible: true,
		},
		Permissions: {
			value: 'Permissions',
			visible: true,
		},
	},
};

export const Translation = {
	pl: {
		Roles: {
			pageTitle: 'Role',
			Header: {
				title: 'Role',
			},
			Tabs: {
				pageTitle: 'Role',
				permission: 'Konfiguracja roli',
				role: 'Przypisywanie ról do użytkowników',
			},
		},
	},
	en: {
		Roles: {
			pageTitle: 'Role',
			Header: {
				title: 'Role',
			},
			Tabs: {
				pageTitle: 'Roles',
				permission: 'Role configuration',
				role: 'Assigning roles to users',
			},
		},
	},
};

export const getTabs = (history: History): { value: string; label?: string; visible: boolean }[] => {
	const reducer: IReducerState = store.getState() as IReducerState;
	const permissions = reducer?.Session?.permissions;

	const adminPermissionsPanelCanBe = permissions?.includes(Permission.AdminPermissions);
	const adminRolePanelCanBe = permissions?.includes(Permission.AdminRoles);

	const tabs: { value: string; label?: string; visible: boolean }[] = [];
	if (adminPermissionsPanelCanBe) tabs.push({ ...Resources.tabs.Permissions, label: t('Roles.Tabs.permission') });
	if (adminRolePanelCanBe) tabs.push({ ...Resources.tabs.Roles, label: t('Roles.Tabs.role') });
	if (tabs?.length === 0) history.push(Resources.forbidden);
	return tabs;
};
