import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	inputPassword: 'password',
	autoComplete: {
		password: 'current-password',
	},
};

export const Translation = {
	pl: {
		Settings: {
			Security: {
				CollapseContent: {
					passwordLabel: 'Hasło',
				},
			},
		},
	},
	en: {
		Settings: {
			Security: {
				CollapseContent: {
					passwordLabel: 'Password',
				},
			},
		},
	},
};
