import { FullFilters, ICustomer, IWorkLog } from '@sac-tt/tasker-types';

import { IJson } from '@/store/ITypes';
import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

export const GANT_WORKLOG_FETCH: ActionType = 'GANT_WORKLOG_FETCH';
export const GANT_CUSTOMER_FETCH: ActionType = 'GANT_CUSTOMER_FETCH';

const basePath = 'Gant';

function _fetch_gant_worklog(worklogs: Partial<IWorkLog>): Action {
	return {
		type: GANT_WORKLOG_FETCH,
		worklogs,
	};
}

function _fetch_gant_customer(customers: Partial<ICustomer>): Action {
	return {
		type: GANT_CUSTOMER_FETCH,
		customers,
	};
}

export function gant_workLog_fetch(filters: FullFilters): Action {
	return function(context, dispatch) {
		context.then(data => dispatch(_fetch_gant_worklog((data as IJson).data)));
		return axios().post(`${basePath}/WorkLogs`, filters);
	};
}

export function gant_customer_fetch(): Action {
	return function(context, dispatch) {
		context.then(data => dispatch(_fetch_gant_customer((data as IJson).data)));
		return axios().get(`${basePath}/Customers`);
	};
}
