import clsx from 'clsx';
import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';

import { EIssueModalTabs } from '@sac-tt/tasker-types';
import { VisibilityControl } from '@sac-tw2/tasker-widgets2';

import { IReducerState } from '@/store/reducers/types';

import { issueModalDataContext } from '..';

import { IssueDetialsPage } from './IssueDetailsPage';
import { RelationTab } from './Relation';
import { ERelation } from './Relation/model';
import { useStyles } from './styles';
import { Tabs } from './Tabs';
import { Worklogs } from './Worklogs';

export const IssueModalContent = () => {
	const classes = useStyles();
	const { isEdittingIssue, issueState } = useContext(issueModalDataContext);

	const [selectedTab, setSelectedTabInner] = useState<EIssueModalTabs>(EIssueModalTabs.details);

	const issueModalChildren = useSelector((state: IReducerState) => state?.IssueModalEditor?.issueModalChildren);
	const issueSubtasksCount = useSelector((state: IReducerState) => state?.IssueModalEditor?.issueSubtasksCount);
	const issueHigherOrderTasksCount = useSelector(
		(state: IReducerState) => state?.IssueModalEditor?.issueHigherOrderTasksCount
	);
	const issueEquivalentTasksCount = useSelector(
		(state: IReducerState) => state?.IssueModalEditor?.issueEquivalentTasksCount
	);
	const issueModalParent = useSelector((state: IReducerState) => state?.IssueModalEditor?.issueModalParent);
	const issueModalEquivalent = useSelector((state: IReducerState) => state?.IssueModalEditor?.issueModalEquivalent);
	const issue = useSelector((state: IReducerState) => state?.IssueModalEditor?.issueModal);

	return (
		<div className={clsx(classes.fullHeight, classes.flexColumn)}>
			<Tabs selectedTab={selectedTab} setSelectedTab={setSelectedTabInner} />
			<VisibilityControl condition={selectedTab === EIssueModalTabs.details}>
				<IssueDetialsPage />
			</VisibilityControl>
			<VisibilityControl condition={selectedTab === EIssueModalTabs.childTasks}>
				<RelationTab
					tab={EIssueModalTabs.childTasks}
					issuesCount={isEdittingIssue ? issueSubtasksCount : issueState?.SubTasks?.length ?? 0}
					issues={isEdittingIssue ? issueModalChildren : issueState?.SubTasks ?? []}
					relation={ERelation.Children}
					isLoading={false}
					issueId={issue?.Id}
					changeRelationToBasic={() => setSelectedTabInner(EIssueModalTabs.details)}
				/>
			</VisibilityControl>
			<VisibilityControl condition={selectedTab === EIssueModalTabs.parentTasks}>
				<RelationTab
					tab={EIssueModalTabs.parentTasks}
					relation={ERelation.Parent}
					isLoading={false}
					issueId={issue?.Id}
					issuesCount={isEdittingIssue ? issueHigherOrderTasksCount : issueState?.ParentTasks?.length ?? 0}
					issues={isEdittingIssue ? issueModalParent : issueState?.ParentTasks ?? []}
					changeRelationToBasic={() => setSelectedTabInner(EIssueModalTabs.details)}
				/>
			</VisibilityControl>
			<VisibilityControl condition={selectedTab === EIssueModalTabs.equivalentTasks}>
				<RelationTab
					tab={EIssueModalTabs.equivalentTasks}
					relation={ERelation.Equivalent}
					isLoading={false}
					issueId={issue?.Id}
					issuesCount={isEdittingIssue ? issueEquivalentTasksCount : issueState?.EquivalentTasks?.length ?? 0}
					issues={isEdittingIssue ? issueModalEquivalent : issueState?.EquivalentTasks ?? []}
					changeRelationToBasic={() => setSelectedTabInner(EIssueModalTabs.details)}
				/>
			</VisibilityControl>
			<VisibilityControl condition={selectedTab === EIssueModalTabs.worklogs}>
				<Worklogs />
			</VisibilityControl>
		</div>
	);
};
