import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		ProjectDetails: {
			ProjectMembersConfiguration: {
				AddUsersToProjectPopupContent: {
					ProjectRolesSelection: {
						Filters: {
							searchUserAndEmail: 'Wyszukaj użytkownika ...',
							showOnlyUsersWithoutRoles: 'Pokaż tylko osoby bez ról projektowych',
						},
					},
				},
			},
		},
	},
	en: {
		ProjectDetails: {
			ProjectMembersConfiguration: {
				AddUsersToProjectPopupContent: {
					ProjectRolesSelection: {
						Filters: {
							searchUserAndEmail: 'Search for user ...',
							showOnlyUsersWithoutRoles: 'Show people with no selected project roles',
						},
					},
				},
			},
		},
	},
};
