import Axios, { CancelTokenSource } from 'axios';

import { AsyncAction } from '@sac-rp/redux-promisify';
import { FullFilters, ITimeReport, IWorkLog, IWorkLogAcceptance } from '@sac-tt/tasker-types';
import { ICSV } from '@sac-tt/tasker-types/dist/types/endPoints/timeRaport';

import { IJson } from '@/store/ITypes';
import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

export const REPORT_TIME_FETCH: ActionType = 'REPORT_TIME_FETCH';
export const REPORT_TIME_FETCH_CSV: ActionType = 'REPORT_TIME_FETCH_CSV';
export const ACCEPT_WORKLOG: ActionType = 'ACCEPT_WORKLOG';
export const ACCEPT_ALL_WORKLOG: ActionType = 'ACCEPT_ALL_WORKLOG';

function _reportFetchTime(timeReport: ITimeReport): Action {
	return {
		type: REPORT_TIME_FETCH,
		timeReport,
	};
}

function _reportFetchTimeCSV(data: ICSV[]): Action {
	return {
		type: REPORT_TIME_FETCH_CSV,
		data,
	};
}

function _acceptWorklog(data: JSON): Action {
	return {
		type: ACCEPT_WORKLOG,
		data,
	};
}

function _createWorklogAcceptance(data: IWorkLogAcceptance[] | IWorkLog[]): Action {
	return {
		type: ACCEPT_ALL_WORKLOG,
		data,
	};
}

let source: CancelTokenSource | undefined;
export function fetch_reportTime(filters: FullFilters): AsyncAction {
	if (source) source?.cancel();
	source = Axios.CancelToken.source();
	const offset = new Date().getTimezoneOffset();
	return function(context, dispatch) {
		context.then(json => {
			source = undefined;
			return dispatch(_reportFetchTime((json as IJson).data));
		});
		return axios().post(`Report`, { ...filters, offset }, { cancelToken: source?.token });
	};
}

let sourceSecond: CancelTokenSource | undefined;
export function fetch_reportTime_CSV(filters: FullFilters): AsyncAction {
	if (source) sourceSecond?.cancel();
	source = Axios.CancelToken.source();
	const offset = new Date().getTimezoneOffset();
	return function(context, dispatch) {
		context.then(json => {
			sourceSecond = undefined;
			return dispatch(_reportFetchTimeCSV((json as IJson).data));
		});
		return axios().post(`Report/csv`, { ...filters, offset }, { cancelToken: sourceSecond?.token });
	};
}

let sourceThird: CancelTokenSource | undefined;
export function fetch_reportTime_CSVProject(range: { start: Date; end: Date }): AsyncAction {
	if (sourceThird) sourceSecond?.cancel();
	sourceThird = Axios.CancelToken.source();
	return function(context, dispatch) {
		context.then(json => {
			sourceThird = undefined;
			return dispatch(_reportFetchTimeCSV((json as IJson).data));
		});
		return axios().post(`Report/project/csv`, range, { cancelToken: sourceThird?.token });
	};
}

let sourceFourth: CancelTokenSource | undefined;
export function fetch_reportTime_CSVCustomer(range: { start: Date; end: Date }): AsyncAction {
	if (sourceFourth) sourceSecond?.cancel();
	sourceFourth = Axios.CancelToken.source();
	return function(context, dispatch) {
		context.then(json => {
			sourceFourth = undefined;
			return dispatch(_reportFetchTimeCSV((json as IJson).data));
		});
		return axios().post(`Report/customer/csv`, range, { cancelToken: sourceFourth?.token });
	};
}

let sourceFifth: CancelTokenSource | undefined;
export function fetch_reportTime_CSVOrlen(id: number): AsyncAction {
	if (sourceFourth) sourceFifth?.cancel();
	sourceFifth = Axios.CancelToken.source();
	return function(context, dispatch) {
		context.then(json => {
			sourceFifth = undefined;
			return dispatch(_reportFetchTimeCSV((json as IJson).data));
		});
		return axios().post(`Report/Orlen/csv/${id}`, { cancelToken: sourceFifth?.token });
	};
}

export function accept_worklog(data: {
	TaskerUserId: number;
	DateRange: { Start: string | Date; End: string | Date };
	IssueId: number;
}): AsyncAction {
	return function(context, dispatch) {
		context.then(data => dispatch(_acceptWorklog((data as IJson).data)));
		return axios().post(`WorkLogAcceptance/addMany`, data, { cancelToken: source?.token });
	};
}

export function create_worklogAcceptanceAll(data: {
	TaskerUserId: number[];
	DateRange: { Start: string | Date; End: string | Date };
}): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_createWorklogAcceptance((json as IJson)?.data)));
		return axios().post(`WorkLogAcceptance/users/many`, { ...data, CalendarFetch: true });
	};
}
