import { isRtl } from '@sac-tw2/tasker-widgets2/dist/utils';
import { getGlobalStyles } from '@sac-tw2/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

import { colors, Theme, tooltipClasses } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { StyleProps } from './model';

export const useStyles = makeStyles<Theme, StyleProps>(
	(theme: Theme) => ({
		...getGlobalStyles(),
		root: {
			maxHeight: props => `${props.navBarHeight}`,
			minHeight: props => `${props.navBarHeight}`,
			color: 'inherit',
			width: '100%',
		},
		fullWidth: {
			width: '100%',
			display: 'flex',
			flexDirection: isRtl() ? 'row-reverse' : 'row',
		},
		search: {
			backgroundColor: 'rgba(255,255,255, 0.1)',
			borderRadius: 4,
			flexBasis: 300,
			height: 36,
			padding: theme.spacing(0, 2),
			display: 'flex',
			alignItems: 'center',
		},
		searchIcon: {
			marginRight: theme.spacing(2),
			color: 'inherit',
		},
		wrap: {
			flexFlow: props => `${props.wrap}`,
		},
		searchInput: {
			flexGrow: 1,
			color: 'inherit',
			'& input::placeholder': {
				opacity: 1,
				color: 'inherit',
			},
		},
		searchPopper: {
			zIndex: theme.zIndex.appBar + 100,
		},
		searchPopperContent: {
			marginTop: theme.spacing(1),
		},
		trialButton: {
			marginLeft: theme.spacing(2),
			color: theme.palette.common.white,
			backgroundColor: colors.green[600],
			'&:hover': {
				backgroundColor: colors.green[900],
			},
		},
		trialIcon: {
			marginRight: theme.spacing(1),
		},
		menuButton: {
			height: theme.spacing(5),
			color: theme.palette.lightGray.main,
			minWidth: 'auto',
			padding: theme.spacing(0, 1.125),
			'&:hover': {
				backgroundColor: `${theme.palette.lightGray.main}10`,
			},
		},
		avatar: {
			height: theme.spacing(3),
			width: theme.spacing(3),
			backgroundColor: 'transparent',
			color: theme.palette.lightGray.main,
			'&>svg': {
				width: theme.spacing(3.75),
				height: theme.spacing(3.75),
			},
		},

		marginOneChar: { marginRight: theme.spacing(0.5) },
		marginTwoChar: { marginRight: theme.spacing(0.75) },
		marginThreeChar: { marginRight: theme.spacing(1) },

		notificationsIcon: {
			width: theme.spacing(4),
			height: theme.spacing(4),
			color: colors.red[600],
		},
		chatButton: {
			marginLeft: theme.spacing(1),
		},

		badge: {
			'&>span': { scale: 0.75 },
		},
		logoutButton: {
			marginLeft: theme.spacing(1),
			color: theme.palette.text.secondary,
		},
		activeTask: {
			marginLeft: theme.spacing(0),
			marginRight: theme.spacing(1),
		},
		logoutIcon: {
			marginRight: theme.spacing(1),
		},
		menu: {
			margin: '0px auto',
			cursor: 'pointer',
			width: 'unset!important',
		},
		marginLeftNone: {
			marginRight: '0px',
		},
		cursorPointer: {
			cursor: 'pointer',
		},
		tooltipWidth: {
			[`& .${tooltipClasses.tooltip}`]: {
				maxWidth: 400,
			},
		},
		version: {
			position: 'fixed',
			right: isRtl() ? 'unset' : theme.spacing(2),
			left: isRtl() ? '0px' : 'unset',
			bottom: '0px',
		},
		logoWrapper: { margin: 'auto' },
	}),
	{ name: `Dashboard_TopBar` }
);
