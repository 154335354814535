import { getGlobalStyles } from '@sac-tw2/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(
	(theme: Theme) => ({
		...getGlobalStyles(),
		addRelationText: { fontWeight: '400' },
		addRelationContainer: { marginTop: theme.spacing(1) },
	}),
	{ name: `IssueModal_IssueModalTitle_MoreOptions_SocialMediaLinks` }
);
