import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	white: 'white' as const,
};

export const Translation = {
	pl: {
		ProjectDetails: {
			Form: {
				IssueForm: {
					CommonComponents: {
						TextFieldWithLabel: {
							groupNameError: 'Podaj unikalną nazwę grupy!',
							labelNameError: 'Nazwy etykiet muszą się różnić',
						},
					},
				},
			},
		},
	},
	en: {
		ProjectDetails: {
			Form: {
				IssueForm: {
					CommonComponents: {
						TextFieldWithLabel: {
							groupNameError: 'Enter a unique group name!',
							labelNameError: 'Label names must be different!',
						},
					},
				},
			},
		},
	},
};
//ProjectDetails:{Form:{IssueForm:{CommonComponents:{TextFieldWithLabel:{
