import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources.js';

export const Resources = {
	issuePath: '?issueId=',
	projectsPath: '/projects/',
	...GlobalResources,
};

export const Translation = {
	pl: {
		CustomerDetail: {
			Content: {
				Invoices: {
					hooks: {
						invoiceName: 'Nazwa faktury',
						invoiceCreatedBy: 'Utworzona przez',
						invoiceSerialNumber: 'Numer faktury',
						invoiceDueDate: 'Termin płatności',
					},
				},
			},
		},
	},
	en: {
		CustomerDetail: {
			Content: {
				Invoices: {
					hooks: {
						invoiceName: 'Invoice name',
						invoiceCreatedBy: 'Created by',
						invoiceSerialNumber: 'Invoice number',
						invoiceDueDate: 'Due date',
					},
				},
			},
		},
	},
};
