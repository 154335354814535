import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	fixed: 'fixed' as const,
};

export const Translation = {
	pl: {
		Dictionaries: {
			Modal: {
				Content: {
					Items: {
						Form: {
							Value: {
								value: 'Wartość',
								today: 'Dzisiaj',
							},
						},
					},
				},
			},
		},
	},
	en: {
		Dictionaries: {
			Modal: {
				Content: {
					Items: {
						Form: {
							Value: {
								value: 'Value',
								today: 'Today',
							},
						},
					},
				},
			},
		},
	},
};
