import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		Kanban: {
			Filters: {
				Options: {
					Archived: {
						tab: 'Kanban',
						archived: 'Pokaż zarchiwizowane',
					},
				},
			},
		},
	},
	en: {
		Kanban: {
			Filters: {
				Options: {
					Archived: {
						tab: 'Kanban',
						archived: 'Show archived',
					},
				},
			},
		},
	},
};
