import { IIssue, IWorkLog } from '@sac-tt/tasker-types';

import { headerHeightInString } from '@/utils/configuration';

import { StyleProps } from './model';
import { Resources } from './resources';

export const getIssue = (workLog: IWorkLog, issues: IIssue[]) => {
	return workLog?.Issue ?? (issues?.filter(issue => issue.Id === workLog?.IssueId)[0] as IIssue);
};

export const getStyleProps = (isClockOpen: boolean): StyleProps => {
	return {
		navBarHeight: isClockOpen ? Resources.unset : headerHeightInString,
		wrap: isClockOpen ? Resources.wrap : Resources.noWrap,
	};
};
