import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	checkBoxAddProps: { 'aria-label': 'primary checkbox' },
};

export const Translation = {
	pl: {
		TimeAssign: {
			Footer: {
				save: 'Zapisz',
				close: 'Zamknij',
				general: 'Ogólne',
				history: 'Historia',
				createAnother: 'Zaloguj kolejny',
			},
		},
	},
	en: {
		TimeAssign: {
			Footer: {
				save: 'Save',
				close: 'Close',
				general: 'General',
				history: 'History',
				createAnother: 'Log another',
			},
		},
	},
};
