import clsx from 'clsx';
import { useMemo } from 'react';

import { IIssue } from '@sac-tt/tasker-types';

import OpenWithRoundedIcon from '@mui/icons-material/OpenWithRounded';
import { IconButton } from '@mui/material';

import { WorklogTile } from '../../../WorklogTile';

import { Props } from './model';
import { Resources } from './resources';
import { useStyles } from './styles';

export const DraggableWorklogTile = (props: Props): JSX.Element => {
	const { isMobile, issue, handleOnStart, handleOnPause, handleOnFinish, provided, isDragging, isActive } = props;
	const classes = useStyles();

	const item = useMemo(
		() => (
			<>
				<WorklogTile
					inEdit
					open={false}
					active={isActive}
					fullWidth={isMobile}
					issue={issue as IIssue}
					handleOnStart={handleOnStart}
					handleOnPause={handleOnPause}
					handleOnFinish={handleOnFinish}
				/>
			</>
		),
		[]
	);

	return useMemo(
		() => (
			<div
				{...provided.draggableProps}
				{...provided.dragHandleProps}
				ref={provided.innerRef}
				style={{ ...provided.draggableProps.style }}
			>
				<div style={{ position: 'absolute', zIndex: 1, display: 'flex' }}>
					<IconButton size={Resources.GlobalResources.small} color={Resources.GlobalResources.inherit}>
						<span className={clsx(classes.flexRow)}>
							<OpenWithRoundedIcon />
						</span>
					</IconButton>
					<div style={{ flex: 1 }}>{item}</div>
				</div>
			</div>
		),
		[provided, item, isDragging]
	);
};
