import { AsyncAction } from '@sac-rp/redux-promisify';
import { EThreeStateSwitch, IDelegationDefaultValueConfiguration, IDelegationField } from '@sac-tt/tasker-types';

import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

import { IJson } from '../ITypes';

export const DELEGATION_DEFAULTS_CONFIGURATION_FETCH: ActionType = 'DELEGATION_DEFAULTS_CONFIGURATION_FETCH';
export const DELEGATION_DEFAULTS_CONFIGURATION_MANAGE: ActionType = 'DELEGATION_DEFAULTS_CONFIGURATION_MANAGE';

const basePath = 'Delegation/DefaultValue';

function _fetchDelegationDefaultsConfiguration(data: IDelegationField[]): Action {
	return {
		type: DELEGATION_DEFAULTS_CONFIGURATION_FETCH,
		data,
	};
}

function _manageDelegationDefaultsConfiguration(data: Partial<IDelegationDefaultValueConfiguration>): Action {
	return {
		type: DELEGATION_DEFAULTS_CONFIGURATION_MANAGE,
		data,
	};
}

export function delegation_fetchDefaultsConfiguration(): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchDelegationDefaultsConfiguration((json as IJson).data)));
		return axios().get(`${basePath}/getMany`);
	};
}

export function delegation_manageDefaultsConfiguration(
	data: Partial<IDelegationDefaultValueConfiguration>,
	value: EThreeStateSwitch
): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_manageDelegationDefaultsConfiguration(data)));
		return axios().post(`${basePath}/manage/${value}`, data);
	};
}
