import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	issueCardKey: 'IssueCard',
	assignedTo: 'Assigned',
	issues: 'issues',
};

export const Translation = {
	pl: {
		IssueList: {
			TileComponents: {
				StartPause: {
					tile: {
						assignedTo: 'Przypisano do ',
					},
				},
			},
		},
	},
	en: {
		IssueList: {
			TileComponents: {
				StartPause: {
					tile: {
						assignedTo: 'Assigned to ',
					},
				},
			},
		},
	},
};
