import { EFieldDefinitionType, ICheckListValue, IDictionaryItem, ITag } from '@sac-tt/tasker-types';

import { IModalFormStateSimple, IPossibleValues } from '@/layouts/Dashboard/TopBar/EndOfDay/Form/model';
import { getFirst } from '@/utils/utils';

import { Resources } from './resources';

export const getState = (values: { [x: number]: ICheckListValue[] }): IModalFormStateSimple => {
	const modalFormState: IModalFormStateSimple = {
		[EFieldDefinitionType.Text]: {},
		[EFieldDefinitionType.TextArea]: {},
		[EFieldDefinitionType.Integer]: {},
		[EFieldDefinitionType.Decimal]: {},
		[EFieldDefinitionType.TagGroup]: {},
		[EFieldDefinitionType.Boolean]: {},
		[EFieldDefinitionType.Date]: {},
		[EFieldDefinitionType.DateTime]: {},
		[EFieldDefinitionType.Dictionary]: {},
	};

	const tmpValues = Object.entries(values);

	for (const key in values) {
		const value = values[key];
		const firstValue = getFirst(value);
		const fieldDefinitionTypeId: EFieldDefinitionType = firstValue?.FieldDefinition
			?.FieldDefinitionTypeId as EFieldDefinitionType;

		if (
			fieldDefinitionTypeId === EFieldDefinitionType.TagGroup ||
			fieldDefinitionTypeId === EFieldDefinitionType.Dictionary
		) {
			const valuesTMP: IPossibleValues[] = value
				?.map(x => getValue(x))
				?.filter(x => x !== undefined) as IPossibleValues[];

			modalFormState[fieldDefinitionTypeId] = {
				...modalFormState[fieldDefinitionTypeId],
				...valuesTMP,
			} as Record<number, any>;
		} else {
			modalFormState[fieldDefinitionTypeId] = {
				...modalFormState[fieldDefinitionTypeId],
				[firstValue?.FieldDefinitionId]: getValue(firstValue),
			} as Record<number, any>;
		}
	}

	return modalFormState;
};

const getValues = (checkListValue: ICheckListValue[]): Record<number, IPossibleValues> => {
	return checkListValue.map(getValue)?.filter(x => x != undefined) as IPossibleValues[];
};

const getValue = (checkListValue: ICheckListValue): IPossibleValues | undefined => {
	switch (checkListValue?.FieldDefinition?.FieldDefinitionTypeId) {
		case EFieldDefinitionType.Text:
		case EFieldDefinitionType.TextArea: {
			return checkListValue?.ValueText ?? Resources.GlobalResources.empty;
		}
		case EFieldDefinitionType.Integer: {
			return checkListValue?.ValueInteger as number;
		}
		case EFieldDefinitionType.Decimal: {
			return checkListValue?.ValueDecimal as number;
		}
		case EFieldDefinitionType.DateTime: {
			return checkListValue?.ValueDateTime as Date;
		}
		case EFieldDefinitionType.Date: {
			return checkListValue?.ValueDate as Date;
		}
		case EFieldDefinitionType.Boolean: {
			return checkListValue?.ValueBoolean as boolean;
		}
		case EFieldDefinitionType.Dictionary: {
			return checkListValue?.ValueDictionaryItem as IDictionaryItem;
		}
		case EFieldDefinitionType.TagGroup: {
			return checkListValue?.ValueTag as ITag;
		}
		default:
			return undefined;
	}
};
