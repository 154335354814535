import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		Kanban: {
			KanbanContent: {
				ColumnsKanbanContent: {
					Swimlane: {
						TaskList: {
							KanbanTile: {
								ChooseTagContent: {
									headerTitle: 'Konfiguracja pól zgłoszenia',
									headerSubtitle: 'Wybierz tagi, które chcesz ustawić w polach zgłoszenia',
									issueProperties: 'Właściwości zgłoszenia',
									buttons: {
										save: 'Zapisz',
									},
									pickTagTooltip: 'Wybierz tag do ustawienia w zgłoszeniu',
									pickMappingError: 'Brak wybranych tagów w polu!',
								},
							},
						},
					},
				},
			},
		},
	},
	en: {
		Kanban: {
			KanbanContent: {
				ColumnsKanbanContent: {
					Swimlane: {
						TaskList: {
							KanbanTile: {
								ChooseTagContent: {
									headerTitle: 'Issue Field Configuration',
									headerSubtitle: 'Select the tags you want to set in the issue fields',
									issueProperties: 'Issue properties',
									buttons: {
										save: 'Save',
									},
									pickTagTooltip: 'Select a tag to set in the issue',
									pickMappingError: 'No tags selected in the field!',
								},
							},
						},
					},
				},
			},
		},
	},
};
