import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { VisibilityControl } from '@sac-tw2/tasker-widgets2';
import { errorVariant } from '@sac-tw2/tasker-widgets2/dist/utils/snackBar';

import GradeRoundedIcon from '@mui/icons-material/GradeRounded';
import StarOutlineRoundedIcon from '@mui/icons-material/StarOutlineRounded';
import { IconButton, Tooltip } from '@mui/material';

import { Dispatch } from '@/store/types';
import { useTrans } from '@/utils/hooks/useTrans';

import { add_issue_to_favourites, remove_issue_from_favourites } from '../../../../store/actions';

import { Props } from './model';

export const AddToFavourites = (props: Props): JSX.Element => {
	const { issue, setFavourite } = props;
	const dispatch: Dispatch = useDispatch();
	const snackbar = useSnackbar();
	const { tString } = useTrans('IssueList.AddToFavourites');

	const [isOver, setIsOver] = useState(false);
	const [isFavourite, setIsFavourite] = useState<boolean>(
		!!issue?.FavouriteIssues && issue?.FavouriteIssues?.length > 0
	);

	useEffect(() => {
		setIsFavourite(!!issue?.FavouriteIssues && issue?.FavouriteIssues?.length > 0);
	}, [issue.FavouriteIssues?.length]);

	const handleManageFavorite = () => {
		if (setFavourite) {
			setFavourite(!isFavourite);
			setIsFavourite(!isFavourite);
			return;
		}
		if (isFavourite) {
			setIsFavourite(false);
			dispatch(remove_issue_from_favourites(issue?.Id)).catch(response => {
				snackbar.enqueueSnackbar(response?.response.data.message, errorVariant);
			});
		} else {
			setIsFavourite(true);
			dispatch(add_issue_to_favourites(issue?.Id)).catch(response => {
				snackbar.enqueueSnackbar(response?.response.data.message, errorVariant);
			});
		}
	};

	return (
		<div onMouseEnter={() => setIsOver(true)} onMouseLeave={() => setIsOver(false)}>
			<VisibilityControl
				condition={isFavourite}
				alternative={
					<IconButton onClick={handleManageFavorite}>
						<VisibilityControl condition={isOver && !isFavourite} alternative={<StarOutlineRoundedIcon />}>
							<StarOutlineRoundedIcon
								sx={{
									color: '#ffc31f',
								}}
							/>
						</VisibilityControl>
					</IconButton>
				}
			>
				<Tooltip title={isFavourite ? tString('remove') : tString('add')}>
					<IconButton onClick={handleManageFavorite}>
						<GradeRoundedIcon sx={{ color: '#ffc31f' }} />
					</IconButton>
				</Tooltip>
			</VisibilityControl>
		</div>
	);
};
