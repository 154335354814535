import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	issueCardKey: 'IssueCard',
	confirmationYesButton: 'confirmation-message-yes-button',
	confirmationNoButton: 'confirmation-message-no-button',
};

export const Translation = {
	pl: {
		UnsavedCommentAlert: {
			yes: 'Tak',
			no: 'Nie',
			back: 'Wróć',
			title: 'Masz nie wysłane komentarze. Chcesz je ominąć?',
		},
	},
	en: {
		UnsavedCommentAlert: {
			yes: 'Yes',
			no: 'No',
			back: 'Back',
			title: 'You have not sent comments. Do you want to skip them?',
		},
	},
};

export enum Options {
	no = 0,
	yes = 1,
	back = 2,
}
