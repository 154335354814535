import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { IReducerState } from '@sac-tt/tasker-types';

export const Theme = () => {
	const darkMode = useSelector((x: IReducerState) => x.Session?.darkMode);

	useEffect(() => {
		document.body.classList.toggle('darkMode', darkMode);
	}, [darkMode]);

	return null;
};

export default Theme;
