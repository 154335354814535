import uuid from 'uuid';

import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	fixed: 'fixed' as const,

	path: (id: number) => `/reports/project/${id}`,
	getCustomerKey: (id: number) => `customer_${id}_${uuid()}`,
	getProjectKey: (id: number) => `project_${id}_${uuid()}`,
};

export const Translation = {
	pl: {
		Reports: {
			Project: {
				pageTitle: 'Raport projektu',
				cardHeading: 'Raport biznesowy projektu',
				customerName: 'Klient',
				projectName: 'Projekt',
			},
		},
	},
	en: {
		Reports: {
			Project: {
				pageTitle: 'Project report',
				cardHeading: 'Project business report',
				customerName: 'Client',
				projectName: 'Project',
			},
		},
	},
};
