import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	issuePath: `?issueId=0`,
	swimlanesContainerId: `swimlanesContainer`,
	swimlaneKey: `swimlaneUnique`,
	defaultColor: '#33b35c',
	paramFieldDefinition: 'FieldDefinitionId',
	...GlobalResources,
};

export const Translation = {
	pl: {
		Kanban: {
			KanbanBoard: {
				KanbanContent: {
					ColumnsKanbanContent: {
						headingTags: 'Brak kolumn kanbana',
						descriptionTags: 'Stwórz kolumny dla kanbana w zakładce edycji.',
						heading: 'Lista swimlane kanbanów jest pusta',
						description:
							'Stwórz swimlany, oraz skonfiguruj mapowania dla kolumn kanbana w zakładce edycji.',
						chooseTags: 'Wybierz tagi, które chcesz przypisać do zmapowanych pól',
						save: 'Zapisz',
					},
				},
			},
		},
	},
	en: {
		Kanban: {
			KanbanBoard: {
				KanbanContent: {
					ColumnsKanbanContent: {
						headingTags: 'There is no columns in kanban',
						descriptionTags: 'Create columns for kanban in edit tab.',
						heading: 'The list of kanban is empty',
						description: 'Create swimlanes and configure mapping for kanban columns in edit tab.',
						chooseTags: 'Choose tags, which you want to assign to status field',
						save: 'Zapisz',
					},
				},
			},
		},
	},
};

export enum ERoles {
	Portal = 'Portal',
}
