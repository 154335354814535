import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	redirect: {
		overview: (id: number) => `/projects/${id}/overview`,
		notFound: '/errors/error-404',
		forbidden: '/errors/error-401',
	},
};

export const Translation = {
	pl: {
		ProjectDetails: {
			pageTitle: 'Szczegóły projektu',
			tabs: {
				Overview: 'Przegląd',
				Issues: 'Zgłoszenia',
				'Clock tags configuration': 'Konfiguracja etykiet zegara',
				'Active tasks in project': 'Aktywne zadania w projekcie',
				'Configure project members': 'Konfiguracja członków projektu',
				Forms: 'Formularze',
				'Project relations': 'Relacje projektu',
				'Show project budget': 'Pokaż budżet projektu',
			},
		},
	},
	en: {
		ProjectDetails: {
			pageTitle: 'Project details',
			tabs: {
				Overview: 'Overview',
				Issues: 'Issues',
				'Clock tags configuration': 'Clock tags configuration',
				'Active tasks in project': 'Active tasks in project',
				'Configure project members': 'Configure project members',
				Forms: 'Forms',
				'Project relations': 'Project relations',
				'Show project budget': 'Show project budget',
			},
		},
	},
};
