import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	redirect: '/projects/create/',
	customerAdd: (customerId: number) => `/customers/${customerId}/projectsAdd/`,
};

export const Translation = {
	pl: {
		ProjectDetail: {
			ProjectNesting: {
				HeaderSuffix: {
					add: 'Dodaj',
					cancel: 'Anuluj',
				},
			},
		},
	},
	en: {
		ProjectDetail: {
			ProjectNesting: {
				HeaderSuffix: {
					add: 'Add',
					cancel: 'Cancel',
				},
			},
		},
	},
};
