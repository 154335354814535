import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	path: '/auth/login',
	...GlobalResources,
};

export const Translation = {
	pl: {
		Error401: {
			pageTitle: 'Błąd 401',
			unAuthenticated: '401: Przepraszamy ale nie mogliśmy cię uwierzytelnić',
			info: 'Nie posiadasz uprawnień do wejścia na tą stronę.',
			goHome: 'Wróć do strony głównej',
		},
	},
	en: {
		Error401: {
			pageTitle: 'Error 401',
			unAuthenticated: '401: We are sorry but we are not able to authenticate you.',
			info:
				'You either tried some shady route or you came here by mistake. Whichever it is, try using the navigation',
			goHome: 'Back to home',
		},
	},
};
