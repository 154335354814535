import { t } from 'i18next';

import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	children: { name: 'children' },
	parent: { name: 'parent' },
};

export const Translation = {
	pl: {
		ProjectDetail: {
			ProjectNesting: {
				pageTitle: 'Relacje projektowe',
				children: { label: 'Projekty podrzędne' },
				parent: { label: 'Projekty nadrzędne' },
			},
		},
	},
	en: {
		ProjectDetail: {
			ProjectNesting: {
				pageTitle: 'Project relations',
				children: { label: 'Subordinate projects' },
				parent: { label: 'Master projects' },
			},
		},
	},
};

export const getTabs = (): { value: string; label?: string; visible: boolean }[] => {
	return [
		{
			value: Resources.children.name,
			label: t('ProjectDetail.ProjectNesting.children.label'),
			visible: true,
		},
		{
			value: Resources.parent.name,
			label: t('ProjectDetail.ProjectNesting.parent.label'),
			visible: true,
		},
	];
};
