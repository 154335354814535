import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	createCustomer: '/customers/create',
	clKey: 'CL',
	customerPath: '/customers/',
	...GlobalResources,
	// Header: {
	// 	add: 'Header.add',
	// 	tooltipTittle: 'Header.tooltipTittle',
	// 	type: 'Header.type',
	// 	name: 'Header.name',
	// },
};

export const Translation = {
	pl: {
		CustomerList: {
			Header: {
				type: 'Klienci',
				add: 'Dodaj klienta',
				tooltipTittle: 'Stwórz nowy rekord',
				name: 'Nazwa klienta',
				creationDate: 'Data stworzenia',
				createdBy: 'Utworzony przez',
				modificationDate: 'Data edycji',
				modifiedBy: 'Edytowany przez',
			},
		},
	},
	en: {
		CustomerList: {
			Header: {
				add: 'Add customer',
				tooltipTittle: 'Create new record',
				type: 'Customers',
				name: 'Customer name',
				creationDate: 'Creation date',
				createdBy: 'Created by',
				modificationDate: 'Modification date',
				modifiedBy: 'Modified by',
			},
		},
	},
};
