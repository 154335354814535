import { t } from 'i18next';

import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';
export const Resources = {
	customers: '/customers/',
	summaryTabValue: 'summary',
	projectsTabValue: 'projects',
	projectsAddTabValue: 'projectsAdd',
	invoicesTabValue: 'invoices',
	budgetsTabValue: 'budgets',
	formsTabValues: 'forms',
	redirect: {
		error: '/errors/error-404',
		customer: (id: number): string => `/customers/${id}/summary`,
	},
	...GlobalResources,
	// Header: {
	// 	management: 'Header.management',
	// 	type: 'Header.type',
	// },
	// Page: {
	// 	title: 'Page.title',
	// },
};

export const getTabsObject = () => {
	return [
		{ value: Resources.summaryTabValue, label: t('CustomerDetail.summaryTabLabel') },
		{ value: Resources.projectsTabValue, label: t('CustomerDetail.projectsTabLabel') },
		{ value: Resources.invoicesTabValue, label: t('CustomerDetail.invoicesTabLabel') },
		{ value: Resources.budgetsTabValue, label: t('CustomerDetail.budgetsTabLabel') },
	];
};

export const Translation = {
	pl: {
		CustomerDetail: {
			Header: {
				management: 'Nowy klient',
				type: 'Rejestrowanie klienta',
			},
			Page: {
				title: 'Klient',
			},
			addProjectToCustomer: 'Dodaj projekty do klienta z pośród istniejących',
			summaryTabLabel: 'Podsumowanie',
			projectsTabLabel: 'Projekty',
			formTabValue: 'Formularze',
			projectsAddTabLabel: 'Dodaj projekty',
			invoicesTabLabel: 'Faktury',
			budgetsTabLabel: 'Budżet',
			close: 'Zamknij',
			editCustomer: 'Edytuj klienta',
			handleCreateProject: 'Stwórz projekt',
		},
	},
	en: {
		CustomerDetail: {
			Header: {
				management: 'New customer',
				type: 'Registering customer',
			},
			Page: {
				title: 'Customer',
			},
			addProjectToCustomer: 'Add projects to customer from existing ones',
			summaryTabLabel: 'Summary',
			projectsTabLabel: 'Projects',
			formTabValue: 'Forms',
			projectsAddTabLabel: 'Add projects',
			invoicesTabLabel: 'Invoices',
			budgetsTabLabel: 'Budget',
			close: 'Close',
			editCustomer: 'Edit customer',
			handleCreateProject: 'Create project',
		},
	},
};
