import clsx from 'clsx';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { VisibilityControl } from '@sac-tw2/tasker-widgets2';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Divider, IconButton } from '@mui/material';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

import { IReducerState } from '@/store/reducers/types';
import { useTrans } from '@/utils/hooks/useTrans';

import { Archiv } from './Archiv';
import { DeleteIssue } from './DeleteIssue';
import { EMoreOptionsTabs, Props } from './modal';
import { Resources } from './resources';
import { SocialMediaLinks } from './SocialMediaLinks';
import { useStyles } from './styles';

export const MoreOptions = (props: Props) => {
	const { handleDeleteIssueWithConfirmation } = props;
	const classes = useStyles();
	const { t } = useTrans('IssueModal.IssueModalTitle.MoreOptions');

	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [selectedTab, setSelectedTab] = useState<EMoreOptionsTabs>(EMoreOptionsTabs.socialLinks);

	const kanbanDetails: { IsKanban: boolean; KanbanId?: number } = useSelector(
		(state: IReducerState) => state?.IssueModalEditor?.kanbanDetails
	);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	return (
		<div>
			<IconButton onClick={handleClick}>
				<MoreVertIcon />
			</IconButton>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: Resources.GlobalResources.bottom,
					horizontal: Resources.GlobalResources.left,
				}}
				transformOrigin={{
					vertical: Resources.GlobalResources.top,
					horizontal: Resources.GlobalResources.right,
				}}
			>
				<div className={clsx(classes.flexRow, classes.container)}>
					<div className={clsx(classes.flexColumn, classes.menu)}>
						<Button
							className={clsx(classes.button)}
							onClick={() => setSelectedTab(EMoreOptionsTabs.socialLinks)}
						>
							<Typography
								className={clsx(selectedTab === EMoreOptionsTabs.socialLinks && classes.boldMenuItem)}
							>
								{t('links')}
							</Typography>
						</Button>
						<Divider />
						<Button
							className={clsx(classes.button, classes.flexColumn, classes.archivMenuItem)}
							onClick={() => setSelectedTab(EMoreOptionsTabs.archiving)}
							disabled={!kanbanDetails?.IsKanban}
						>
							<Typography
								className={clsx(selectedTab === EMoreOptionsTabs.archiving && classes.boldMenuItem)}
							>
								{t('archive')}
							</Typography>
							<VisibilityControl condition={!kanbanDetails?.IsKanban}>
								<Typography className={classes.subText} variant={Resources.GlobalResources.body2}>
									{t('archiveDescription')}
								</Typography>
							</VisibilityControl>
						</Button>
						<Divider />
						<Button
							className={clsx(classes.button)}
							onClick={() => setSelectedTab(EMoreOptionsTabs.deleteIssue)}
						>
							<Typography
								className={clsx(selectedTab === EMoreOptionsTabs.deleteIssue && classes.boldMenuItem)}
							>
								{t('deleteIssue')}
							</Typography>
						</Button>
					</div>
					<div className={classes.content}>
						<VisibilityControl condition={selectedTab === EMoreOptionsTabs.socialLinks}>
							<SocialMediaLinks />
						</VisibilityControl>
						<VisibilityControl condition={selectedTab === EMoreOptionsTabs.archiving}>
							<Archiv />
						</VisibilityControl>
						<VisibilityControl condition={selectedTab === EMoreOptionsTabs.deleteIssue}>
							<DeleteIssue handleDeleteIssueWithConfirmation={handleDeleteIssueWithConfirmation} />
						</VisibilityControl>
					</div>
				</div>
			</Popover>
		</div>
	);
};
