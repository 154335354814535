import { getGlobalStyles } from '@sac-tw2/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(
	(theme: Theme) => ({
		...getGlobalStyles(),
		navigation: {
			overflow: 'auto',
			padding: theme.spacing(0, 2, 2, 2),
		},
	}),
	{ name: `Dashboard_NavBar_DrawerContent` }
);
