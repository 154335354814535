import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};
export const Translation = {
	pl: {
		Kanban: {
			KanbanBoard: {
				KanbanHeader: {
					KanbanHeaderEdit: {
						deleteKanbanConfirmation: 'Czy na pewno chcesz usunąć ten kanban?',
						back: 'Wstecz',
						deleteKanban: 'Usuń kanban',
					},
				},
			},
		},
	},
	en: {
		Kanban: {
			KanbanBoard: {
				KanbanHeader: {
					KanbanHeaderEdit: {
						deleteKanbanConfirmation: 'Are you sure you want to delete this kanban?',
						back: 'Back',
						deleteKanban: 'Delete kanban',
					},
				},
			},
		},
	},
};
