import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	notificationsListPath: '/notifications',
	notificationsPopoverDefaultSkip: 0,
	notificationsPopoverDefaultTake: 6,
	cardHeaderTitle: 'cardHeaderTitle',
	readAll: 'readAll',
	showAll: 'showAll',
	...GlobalResources,
	issueId: 'issueId',
};

export const Translation = {
	pl: {
		NotificationPopover: {
			cardHeaderTitle: 'Powiadomienia',
			readAll: 'Oznacz wszystkie jako przeczytane',
			showAll: 'Pokaż wszystkie',
		},
	},
	en: {
		NotificationPopover: {
			cardHeaderTitle: 'Notifications',
			readAll: 'Mark all as read',
			showAll: 'Show all',
		},
	},
};
