import { IIssueFormCreate } from '@sac-tt/tasker-types';
import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	path: '/issueForms',
	key: 'issueForm_Main_Section',
	dash: '_',
	defaultCoordinates: {
		sectionLevel: undefined,
		index: undefined,
	},
};

export const Translation = {
	pl: {
		ProjectDetails: {
			Form: {
				IssueForm: {
					Edit: {
						formCardTitle: 'Formularz zgłoszeniowy',
						pageTitle: 'Formularz',
						Header: {
							formPropertiesTitle: 'Konfiguracja właściwości formularza',
							subTitleNew: 'Nowy formularz',
						},
						createForm: 'Zaktualizuj formularz',
						saveForm: 'Zapisz',
						cancel: 'Anuluj',
						addGroupIcon: 'Dodaj grupę',
						deleteConfirmation: 'Czy na pewno chcesz usunąć tę grupę?',
					},
				},
			},
		},
	},
	en: {
		ProjectDetails: {
			Form: {
				IssueForm: {
					Edit: {
						formCardTitle: 'Issue form',
						pageTitle: 'Form',
						Header: {
							formPropertiesTitle: 'Configuration form properties',
							subTitleNew: 'Update form',
						},
						createForm: 'Update form',
						saveForm: 'Save',
						cancel: 'Cancel',
						addGroupIcon: 'Add group',
						deleteConfirmation: 'Are you sure you want to delete this group?',
					},
				},
			},
		},
	},
};
