import { getGlobalStyles } from '@sac-tw2/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(
	() => ({
		...getGlobalStyles(),
		root: {
			margin: '0px',
			width: '600px',
			height: 'calc( 100vh - 100)',
		},
	}),
	{ name: `Template` }
);
