import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	redirect: '/projects/create',
};

export const Translation = {
	pl: {
		TimeReport: {
			HeaderSuffix: {
				add: 'Dodaj',
				title: 'Stwórz nowy rekord',
			},
		},
	},
	en: {
		TimeReport: {
			HeaderSuffix: {
				add: 'Add',
				title: 'Create a new record',
			},
		},
	},
};
