import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		NotificationList: {
			Filters: {
				TaskerObjectFilter: {
					placeholder: 'Wybierz kontekst powiadomienia...',
				},
			},
		},
	},
	en: {
		NotificationList: {
			Filters: {
				TaskerObjectFilter: {
					placeholder: 'Select notification context...',
				},
			},
		},
	},
};
