import { format } from 'date-fns';
import { floor } from 'lodash';

import { EDateFNS } from '@sac-tw2/tasker-widgets2/dist/utils';

export interface IDelegationTemplateExpensesHeading {
	DelegationId: number;
	Name: string;
	Description: string;
	Payout: number;
	Conversion: {
		Currency: string;
		Rate: number;
		Date: Date;
	}[];
	Company: string;
	Employee: string;
	Project: string;
	Budget: string;
	DateStart: Date;
	DateEnd: Date;
}

export const DelegationTemplateExpensesHeading = (props: IDelegationTemplateExpensesHeading): string => {
	const formattedDateStart = format(props.DateStart, EDateFNS.DateWithoutTime);
	const formattedDateEnd = props.DateEnd
		? ` - ${format(props.DateEnd, EDateFNS.DateWithoutTime)}`
		: ` - ${format(props.DateStart, EDateFNS.DateWithoutTime)}`;

	return `
    <p class="report-heading">Report ${props.DelegationId}</p>
    <p class="company-name">${props.Company}</p>
    <p class="report-details">
      Employee: ${props.Employee}<br>
      Project: ${props.Project}<br>
      Budget: ${props.Budget}<br>
      Date: ${formattedDateStart}${formattedDateEnd}<br>
    </p>
    <p class="payout-amount">${floor(props.Payout, 2).toFixed(2)} zł</p>
		<div class="conversion-rate-wrapper">
			${props.Conversion.map(conversion => {
				return `<p class="conversion-rate">1 ${conversion.Currency} = ${conversion.Rate} PLN as of ${format(
					conversion.Date,
					EDateFNS.DateWithoutTime
				)}</p>`;
			})}
		</div>
    <p class="delegation-info">${props.Name}${props.Description ? ` - ${props.Description}` : ''}</p>
  `;
};

export interface IDelegationTemplatePerdiemHeading {
	Name: string;
	Description: string;
	Payout: number;
}

export const DelegationTemplatePerdiemHeading = (props: IDelegationTemplatePerdiemHeading): string => {
	return `
		<p class="report-heading">Per diem calculation</p>
		<p class="report-description">Calculation based on the documents: "Rozporządzenie Ministra Pracy i Polityki Społecznej z dnia 29.01.2013 r. w sprawie należności przysługujących pracownikowi zatrudnionemu w państwowej lub samorządowej jednostce sfery budżetowej z tytułu podróży służbowej"</p>
		<p class="payout-amount">${floor(props.Payout, 2)} zł</p>
		<p class="delegation-info">${props.Name}${props.Description ? ` - ${props.Description}` : ''}</p>
	`;
};
