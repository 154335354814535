import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	userPath: '/user/',
	taskerIcon: process.env.PUBLIC_URL + '/favicon.png',
	issueIdPath: '?issueId=',
	projectPath: 'projects/',
	overview: '/overview',
	hash: '#',
	user: '#/user/',
	textFieldInputProps: {
		style: {
			background: 'white',
		},
	},
};

export const Translation = {
	pl: {
		Activities: {
			ActivityList: {
				WorkingUsers: {
					header: 'Pracujący użytkownicy',
					pageTitle: { activeUsersList: 'Lista pracujących użytkowników', loading: 'Ładowanie' },
					time: 'Czas pracy',
					project: 'Projekt',
					issue: 'Zgłoszenie',
					fullName: 'Pracownik',
					avatar: 'Użytkownik',
				},
			},
		},
	},
	en: {
		Activities: {
			ActivityList: {
				WorkingUsers: {
					header: 'Working Users',
					pageTitle: { activeUsersList: 'Working Users List', loading: 'Loading' },
					time: 'Work time',
					project: 'Project',
					issue: 'Issue',
					fullName: 'Employee',
					avatar: 'User',
				},
			},
		},
	},
};
