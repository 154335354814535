import uuid from 'uuid';

import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	emptyCell: '----------',
	settlementNestedTableElementKey: (key: string) => `SettlementNestedTableElement_${uuid()}_${key}`,
	settlementNestedTableFieldKey: (key: string) => `SettlementNestedTableField_${uuid()}_${key}`,
};

export const Translation = {
	pl: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationSettlement: {
						SettlementTable: {
							SettlementAttachmentsNestedTable: {
								headingElement: 'Informacje o załączniku',
								headingType: 'Rodzaj załącznika',
								headingCharge: 'Należność',
								1: 'Zakwaterowanie',
								2: 'Inne',
							},
						},
					},
				},
			},
		},
	},
	en: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationSettlement: {
						SettlementTable: {
							SettlementAttachmentsNestedTable: {
								headingElement: 'Attachment information',
								headingType: 'Attachment type',
								headingCharge: 'Charge',
								1: 'Accommodation',
								2: 'Other',
							},
						},
					},
				},
			},
		},
	},
};
