import { format } from 'date-fns';
import { floor } from 'lodash';

import { EDateFNS } from '@sac-tw2/tasker-widgets2/dist/utils';

import { Resources } from './resources';

export interface IDelegationTemplatePerdiemTable {
	data: IDelegationTemplatePerdiemRow[];
	from?: number;
	to?: number;
}

export const DelegationTemplatePerdiemTableOld = (props: IDelegationTemplatePerdiemTable): string => {
	return `
    <div class="perdiem-table-wrapper">
      <table id="perdiem-table" class="perdiem-table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Per diem</th>
            <th>
              <div class="header-icon-wrapper">
                <svg class="header-icon" viewBox="0 0 24 24"><path d="M20 3H4v10c0 2.21 1.79 4 4 4h6c2.21 0 4-1.79 4-4v-3h2c1.11 0 2-.9 2-2V5c0-1.11-.89-2-2-2zm0 5h-2V5h2v3zM4 19h16v2H4z"></path></svg>
              </div>
            </th>
            <th>
              <div class="header-icon-wrapper">
                <svg class="header-icon" viewBox="0 0 24 24"><path d="M11 9H9V2H7v7H5V2H3v7c0 2.12 1.66 3.84 3.75 3.97V22h2.5v-9.03C11.34 12.84 13 11.12 13 9V2h-2v7zm5-3v8h2.5v8H21V2c-2.76 0-5 2.24-5 4z"></path></svg>
              </div>
            </th>
            <th>
              <div class="header-icon-wrapper">
                <svg class="header-icon" viewBox="0 0 24 24"><path fill-rule="evenodd" d="M22 10c.32-3.28-4.28-6-9.99-6S1.7 6.72 2.02 10H22zM5.35 13.5c.55 0 .78.14 1.15.36.45.27 1.07.64 2.18.64s1.73-.37 2.18-.64c.37-.23.59-.36 1.15-.36.55 0 .78.14 1.15.36.45.27 1.07.64 2.18.64s1.73-.37 2.18-.64c.37-.23.59-.36 1.15-.36.55 0 .78.14 1.15.36.45.27 1.07.63 2.17.64v-1.98s-.79-.16-1.16-.38c-.45-.27-1.07-.64-2.18-.64s-1.73.37-2.18.64c-.37.23-.6.36-1.15.36s-.78-.14-1.15-.36c-.45-.27-1.07-.64-2.18-.64s-1.73.37-2.18.64c-.37.23-.59.36-1.15.36-.55 0-.78-.14-1.15-.36-.45-.27-1.07-.64-2.18-.64s-1.73.37-2.18.64c-.37.23-.59.36-1.15.36v2c1.11 0 1.73-.37 2.21-.64.37-.23.59-.36 1.14-.36zM2 16v2c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-2H2z"></path></svg>
              </div>
            </th>
            <th>
              <div class="header-icon-wrapper">
                <svg class="header-icon" viewBox="0 0 24 24"><path d="M6 3h12v2H6zm11 3H7c-1.1 0-2 .9-2 2v11c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm-1 9h-2.5v2.5h-3V15H8v-3h2.5V9.5h3V12H16v3z"></path></svg>
              </div>
            </th>
            <th>
              <div class="header-icon-wrapper">
                <svg class="header-icon" viewBox="0 0 24 24"><path d="M7 13c1.66 0 3-1.34 3-3S8.66 7 7 7s-3 1.34-3 3 1.34 3 3 3zm12-6h-8v7H3V5H1v15h2v-3h18v3h2v-9c0-2.21-1.79-4-4-4z"></path></svg>
              </div>
            </th>
            <th>
              <div class="header-icon-wrapper">
                <svg class="header-icon" viewBox="0 0 24 24"><path d="M4 16c0 .88.39 1.67 1 2.22V20c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-1h8v1c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-1.78c.61-.55 1-1.34 1-2.22V6c0-3.5-3.58-4-8-4s-8 .5-8 4v10zm3.5 1c-.83 0-1.5-.67-1.5-1.5S6.67 14 7.5 14s1.5.67 1.5 1.5S8.33 17 7.5 17zm9 0c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm1.5-6H6V6h12v5z"></path></svg>
              </div>
            </th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          ${props.data
				.slice(props.from, props.to)
				.map(row => DelegationTemplatePerdiemRow(row))
				.join('')}
        </tbody>
      </table>
    </div>
  `;
};

export const DelegationTemplatePerdiemTable = (props: IDelegationTemplatePerdiemTable): string => {
	return `
    <div class="perdiem-table-wrapper">
      <table id="perdiem-table" class="perdiem-table">
        <thead>
          <tr>
            <th>Country</th>
            <th>Per diem</th>
            <th>
              <div class="header-icon-wrapper">
                <svg class="header-icon" viewBox="0 0 24 24"><path d="M20 3H4v10c0 2.21 1.79 4 4 4h6c2.21 0 4-1.79 4-4v-3h2c1.11 0 2-.9 2-2V5c0-1.11-.89-2-2-2zm0 5h-2V5h2v3zM4 19h16v2H4z"></path></svg>
              </div>
            </th>
            <th>
              <div class="header-icon-wrapper">
                <svg class="header-icon" viewBox="0 0 24 24"><path d="M11 9H9V2H7v7H5V2H3v7c0 2.12 1.66 3.84 3.75 3.97V22h2.5v-9.03C11.34 12.84 13 11.12 13 9V2h-2v7zm5-3v8h2.5v8H21V2c-2.76 0-5 2.24-5 4z"></path></svg>
              </div>
            </th>
            <th>
              <div class="header-icon-wrapper">
                <svg class="header-icon" viewBox="0 0 24 24"><path fill-rule="evenodd" d="M22 10c.32-3.28-4.28-6-9.99-6S1.7 6.72 2.02 10H22zM5.35 13.5c.55 0 .78.14 1.15.36.45.27 1.07.64 2.18.64s1.73-.37 2.18-.64c.37-.23.59-.36 1.15-.36.55 0 .78.14 1.15.36.45.27 1.07.64 2.18.64s1.73-.37 2.18-.64c.37-.23.59-.36 1.15-.36.55 0 .78.14 1.15.36.45.27 1.07.63 2.17.64v-1.98s-.79-.16-1.16-.38c-.45-.27-1.07-.64-2.18-.64s-1.73.37-2.18.64c-.37.23-.6.36-1.15.36s-.78-.14-1.15-.36c-.45-.27-1.07-.64-2.18-.64s-1.73.37-2.18.64c-.37.23-.59.36-1.15.36-.55 0-.78-.14-1.15-.36-.45-.27-1.07-.64-2.18-.64s-1.73.37-2.18.64c-.37.23-.59.36-1.15.36v2c1.11 0 1.73-.37 2.21-.64.37-.23.59-.36 1.14-.36zM2 16v2c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-2H2z"></path></svg>
              </div>
            </th>
            <th>
              <div class="header-icon-wrapper">
                <svg class="header-icon" viewBox="0 0 24 24"><path d="M6 3h12v2H6zm11 3H7c-1.1 0-2 .9-2 2v11c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm-1 9h-2.5v2.5h-3V15H8v-3h2.5V9.5h3V12H16v3z"></path></svg>
              </div>
            </th>
            <th>
              <div class="header-icon-wrapper">
                <svg class="header-icon" viewBox="0 0 24 24"><path d="M7 13c1.66 0 3-1.34 3-3S8.66 7 7 7s-3 1.34-3 3 1.34 3 3 3zm12-6h-8v7H3V5H1v15h2v-3h18v3h2v-9c0-2.21-1.79-4-4-4z"></path></svg>
              </div>
            </th>
            <th>
              <div class="header-icon-wrapper">
                <svg class="header-icon" viewBox="0 0 24 24"><path d="M4 16c0 .88.39 1.67 1 2.22V20c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-1h8v1c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-1.78c.61-.55 1-1.34 1-2.22V6c0-3.5-3.58-4-8-4s-8 .5-8 4v10zm3.5 1c-.83 0-1.5-.67-1.5-1.5S6.67 14 7.5 14s1.5.67 1.5 1.5S8.33 17 7.5 17zm9 0c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm1.5-6H6V6h12v5z"></path></svg>
              </div>
            </th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          ${props.data
				.slice(props.from, props.to)
				.map(row => DelegationTemplatePerdiemRow(row))
				.join('')}
        </tbody>
      </table>
    </div>
  `;
};

export interface IDelegationTemplatePerdiemRowOld {
	Date: Date;
	CountryCode: string;
	Time: string;
	CurrencyBeforeConversion?: string;
	LimitAllowanceBeforeConversion?: number;
	LimitAllowance: number;
	BreakfastBeforeConversion?: number;
	Breakfast: number;
	DinnerBeforeConversion?: number;
	Dinner: number;
	SupperBeforeConversion?: number;
	Supper: number;
	HospitalizationBeforeConversion?: number;
	Hospitalization: number;
	AccommodationBeforeConversion?: number;
	Accommodation: number;
	TransportationBeforeConversion?: number;
	Transportation: number;
	TotalBeforeConversion?: number;
	Total: number;
}

export const DelegationTemplatePerdiemRowOld = (props: IDelegationTemplatePerdiemRowOld): string => {
	const formattedDate = format(props.Date, EDateFNS.DateWithoutTime);

	return `
    <tr>
      <td>
        <p>${formattedDate}</p>
        <p class='small'>[${props.CountryCode}]</p>
      </td>
      <td>
        <p>${floor(props.LimitAllowance, 2).toFixed(2)} PLN</p>
        <p class='small'>${props.Time}</p>
      </td>
      <td class="${props.Breakfast > 0 ? 'perdiem-positive' : props.Breakfast < 0 ? 'perdiem-negative' : ''}">
        ${props.Breakfast !== 0 ? `${floor(props.Breakfast, 2).toFixed(2)} PLN` : Resources.hyphen}
        ${
			props.Breakfast !== 0 && props.CurrencyBeforeConversion && props.CurrencyBeforeConversion !== 'PLN'
				? `
          <p class='small'>${floor(props.BreakfastBeforeConversion ?? 0, 2).toFixed(2)} ${
						props.CurrencyBeforeConversion
				  }</p>
        `
				: Resources.GlobalResources.empty
		}
      </td>
      <td class="${props.Dinner > 0 ? 'perdiem-positive' : props.Dinner < 0 ? 'perdiem-negative' : ''}">
        ${props.Dinner !== 0 ? `${floor(props.Dinner, 2).toFixed(2)} PLN` : Resources.hyphen}
        ${
			props.Dinner !== 0 && props.CurrencyBeforeConversion && props.CurrencyBeforeConversion !== 'PLN'
				? `
          <p class='small'>${floor(props.DinnerBeforeConversion ?? 0, 2).toFixed(2)} ${
						props.CurrencyBeforeConversion
				  }</p>
        `
				: Resources.GlobalResources.empty
		}
      </td>
      <td class="${props.Supper > 0 ? 'perdiem-positive' : props.Supper < 0 ? 'perdiem-negative' : ''}">
        ${props.Supper !== 0 ? `${floor(props.Supper, 2).toFixed(2)} PLN` : Resources.hyphen}
        ${
			props.Supper !== 0 && props.CurrencyBeforeConversion && props.CurrencyBeforeConversion !== 'PLN'
				? `
          <p class='small'>${floor(props.SupperBeforeConversion ?? 0, 2).toFixed(2)} ${
						props.CurrencyBeforeConversion
				  }</p>
        `
				: Resources.GlobalResources.empty
		}
      </td>
      <td class="${
			props.Hospitalization > 0 ? 'perdiem-positive' : props.Hospitalization < 0 ? 'perdiem-negative' : ''
		}">
        ${props.Hospitalization !== 0 ? `${floor(props.Hospitalization, 2).toFixed(2)} PLN` : Resources.hyphen}
        ${
			props.Hospitalization !== 0 && props.CurrencyBeforeConversion && props.CurrencyBeforeConversion !== 'PLN'
				? `
          <p class='small'>${floor(props.HospitalizationBeforeConversion ?? 0, 2).toFixed(2)} ${
						props.CurrencyBeforeConversion
				  }</p>
        `
				: Resources.GlobalResources.empty
		}
      </td>
      <td class="${props.Accommodation > 0 ? 'perdiem-positive' : props.Accommodation < 0 ? 'perdiem-negative' : ''}">
        ${props.Accommodation !== 0 ? `${floor(props.Accommodation, 2).toFixed(2)} PLN` : Resources.hyphen}
        ${
			props.Accommodation !== 0 && props.CurrencyBeforeConversion && props.CurrencyBeforeConversion !== 'PLN'
				? `
          <p class='small'>${floor(props.AccommodationBeforeConversion ?? 0, 2).toFixed(2)} ${
						props.CurrencyBeforeConversion
				  }</p>
        `
				: Resources.GlobalResources.empty
		}
      </td>
      <td class="${props.Transportation > 0 ? 'perdiem-positive' : props.Transportation < 0 ? 'perdiem-negative' : ''}">
        ${props.Transportation !== 0 ? `${floor(props.Transportation, 2).toFixed(2)} PLN` : Resources.hyphen}
        ${
			props.Transportation !== 0 && props.CurrencyBeforeConversion && props.CurrencyBeforeConversion !== 'PLN'
				? `
          <p class='small'>${floor(props.TransportationBeforeConversion ?? 0, 2).toFixed(2)} ${
						props.CurrencyBeforeConversion
				  }</p>
        `
				: Resources.GlobalResources.empty
		}
      </td>
      <td>
        ${floor(props.Total, 2).toFixed(2)} PLN
        ${
			props.CurrencyBeforeConversion && props.CurrencyBeforeConversion !== 'PLN'
				? `
          <p class='small'>${floor(props.TotalBeforeConversion ?? 0, 2).toFixed(2)} ${
						props.CurrencyBeforeConversion
				  }</p>
        `
				: Resources.GlobalResources.empty
		}
      </td>
    </tr>
  `;
};

export interface IDelegationTemplatePerdiemRow {
	CountryCode: string;
	Time: string;
	CurrencyBeforeConversion?: string;
	LimitAllowanceBeforeConversion?: number;
	LimitAllowance: number;
	BreakfastBeforeConversion?: number;
	Breakfast: number;
	DinnerBeforeConversion?: number;
	Dinner: number;
	SupperBeforeConversion?: number;
	Supper: number;
	HospitalizationBeforeConversion?: number;
	Hospitalization: number;
	AccommodationBeforeConversion?: number;
	Accommodation: number;
	TransportationBeforeConversion?: number;
	Transportation: number;
	TotalBeforeConversion?: number;
	Total: number;
}

export const DelegationTemplatePerdiemRow = (props: IDelegationTemplatePerdiemRow): string => {
	return `
    <tr>
      <td>
        <img src="https://www.countryflags.io/${props.CountryCode}/flat/24.png" alt="${props.CountryCode}">
        <p>[${props.CountryCode}]</p>
      </td>
      <td>
        <p>${floor(props.LimitAllowance, 2).toFixed(2)} PLN</p>
        <p class='small'>${props.Time}</p>
      </td>
      <td class="${props.Breakfast > 0 ? 'perdiem-positive' : props.Breakfast < 0 ? 'perdiem-negative' : ''}">
        ${props.Breakfast !== 0 ? `${floor(props.Breakfast, 2).toFixed(2)} PLN` : Resources.hyphen}
        ${
			props.Breakfast !== 0 && props.CurrencyBeforeConversion && props.CurrencyBeforeConversion !== 'PLN'
				? `
          <p class='small'>${floor(props.BreakfastBeforeConversion ?? 0, 2).toFixed(2)} ${
						props.CurrencyBeforeConversion
				  }</p>
        `
				: Resources.GlobalResources.empty
		}
      </td>
      <td class="${props.Dinner > 0 ? 'perdiem-positive' : props.Dinner < 0 ? 'perdiem-negative' : ''}">
        ${props.Dinner !== 0 ? `${floor(props.Dinner, 2).toFixed(2)} PLN` : Resources.hyphen}
        ${
			props.Dinner !== 0 && props.CurrencyBeforeConversion && props.CurrencyBeforeConversion !== 'PLN'
				? `
          <p class='small'>${floor(props.DinnerBeforeConversion ?? 0, 2).toFixed(2)} ${
						props.CurrencyBeforeConversion
				  }</p>
        `
				: Resources.GlobalResources.empty
		}
      </td>
      <td class="${props.Supper > 0 ? 'perdiem-positive' : props.Supper < 0 ? 'perdiem-negative' : ''}">
        ${props.Supper !== 0 ? `${floor(props.Supper, 2).toFixed(2)} PLN` : Resources.hyphen}
        ${
			props.Supper !== 0 && props.CurrencyBeforeConversion && props.CurrencyBeforeConversion !== 'PLN'
				? `
          <p class='small'>${floor(props.SupperBeforeConversion ?? 0, 2).toFixed(2)} ${
						props.CurrencyBeforeConversion
				  }</p>
        `
				: Resources.GlobalResources.empty
		}
      </td>
      <td class="${
			props.Hospitalization > 0 ? 'perdiem-positive' : props.Hospitalization < 0 ? 'perdiem-negative' : ''
		}">
        ${props.Hospitalization !== 0 ? `${floor(props.Hospitalization, 2).toFixed(2)} PLN` : Resources.hyphen}
        ${
			props.Hospitalization !== 0 && props.CurrencyBeforeConversion && props.CurrencyBeforeConversion !== 'PLN'
				? `
          <p class='small'>${floor(props.HospitalizationBeforeConversion ?? 0, 2).toFixed(2)} ${
						props.CurrencyBeforeConversion
				  }</p>
        `
				: Resources.GlobalResources.empty
		}
      </td>
      <td class="${props.Accommodation > 0 ? 'perdiem-positive' : props.Accommodation < 0 ? 'perdiem-negative' : ''}">
        ${props.Accommodation !== 0 ? `${floor(props.Accommodation, 2).toFixed(2)} PLN` : Resources.hyphen}
        ${
			props.Accommodation !== 0 && props.CurrencyBeforeConversion && props.CurrencyBeforeConversion !== 'PLN'
				? `
          <p class='small'>${floor(props.AccommodationBeforeConversion ?? 0, 2).toFixed(2)} ${
						props.CurrencyBeforeConversion
				  }</p>
        `
				: Resources.GlobalResources.empty
		}
      </td>
      <td class="${props.Transportation > 0 ? 'perdiem-positive' : props.Transportation < 0 ? 'perdiem-negative' : ''}">
        ${props.Transportation !== 0 ? `${floor(props.Transportation, 2).toFixed(2)} PLN` : Resources.hyphen}
        ${
			props.Transportation !== 0 && props.CurrencyBeforeConversion && props.CurrencyBeforeConversion !== 'PLN'
				? `
          <p class='small'>${floor(props.TransportationBeforeConversion ?? 0, 2).toFixed(2)} ${
						props.CurrencyBeforeConversion
				  }</p>
        `
				: Resources.GlobalResources.empty
		}
      </td>
      <td>
        ${floor(props.Total, 2).toFixed(2)} PLN
        ${
			props.CurrencyBeforeConversion && props.CurrencyBeforeConversion !== 'PLN'
				? `
          <p class='small'>${floor(props.TotalBeforeConversion ?? 0, 2).toFixed(2)} ${
						props.CurrencyBeforeConversion
				  }</p>
        `
				: Resources.GlobalResources.empty
		}
      </td>
    </tr>
  `;
};

export interface IDelegationTemplatePerdiemSummary {
	Payout: number;
}

export const DelegationTemplatePerdiemSummary = (props: IDelegationTemplatePerdiemSummary): string => {
	return `
    <div class="summary">
      <p class="summary-payout-label">Payout:</p>
      <p class="summary-payout-amount">${floor(props.Payout, 2).toFixed(2)} PLN</p>
    </div>
  `;
};
