import clsx from 'clsx';
import _ from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { v1 as uuid } from 'uuid';

import { ITaskerUser } from '@sac-tt/tasker-types';
import { getFullNameNew } from '@sac-tw2/tasker-widgets2/dist/utils';

import { Tooltip } from '@mui/material';
import Avatar from '@mui/material/Avatar';

import { IReducerState } from '@/store/reducers/types';
import { getDataForTaskerUserSelector } from '@/utils/profile';

import { AvatarTooltip } from './AvatarTooltip';
import { Props } from './model';
import { useStyles } from './styles';

export const ProfileSelector = (props: Props<any>) => {
	const {
		items,
		asFilter = true,
		selected = [],
		getKey,
		getAvatar,
		getTooltip,
		limit = -1,
		className,
		reverseStack = false,
		onSelectValue,
		loggedUserId,
		navigateOnClick = false,
		isMultiple = false,
		key = uuid(),
		size,
		useItemsList = false,
		...rest
	} = props;

	const [itemsToDisplay, setItemsToDisplay] = useState(items);
	const [isMouseOver, setIsMouseOver] = useState<boolean>(false);
	const classes = useStyles({
		reverseStack,
		isMouseOver,
		size,
	});
	const usersFromActiveUserTeam = useSelector((state: IReducerState) => state.Settings.usersFromActiveUserTeam);

	useEffect(() => {
		if (usersFromActiveUserTeam?.length && onSelectValue) {
			setItemsToDisplay(getDataForTaskerUserSelector(useItemsList ? items : usersFromActiveUserTeam));
		}
	}, [usersFromActiveUserTeam?.length]);

	useEffect(() => {
		// if (!usersFromActiveUserTeam?.length) setItemsToDisplay(items);

		setItemsToDisplay(items);
	}, [items]);

	const ref = useRef(null);

	const getSize = (size: 'small' | 'medium' | 'large' | undefined) => {
		switch (size) {
			case 'small': {
				return { width: 24, height: 24 };
			}
			case 'medium': {
				return { width: 36, height: 36 };
			}
			case 'large': {
				return { width: 56, height: 56 };
			}
			default: {
				return { width: 36, height: 36 };
			}
		}
	};

	const sizePx = getSize(size);

	let slicedAvatars = limit === -1 ? itemsToDisplay : itemsToDisplay.slice(0, limit);

	const [selectedUsers, setSelectedUsers] = useState<Partial<ITaskerUser>[]>(selected ?? undefined);

	useEffect(() => {
		setSelectedUsers(selected);
	}, [selected?.length]);

	if (loggedUserId) {
		const temp = _.cloneDeep(slicedAvatars.filter(profile => profile.id === loggedUserId));
		if (temp?.length > 0) {
			slicedAvatars = slicedAvatars.filter(profile => profile.getKey() != loggedUserId);
			slicedAvatars.splice(1, 0, temp[0]);
		}
	}

	const handleChoose = (user: any | undefined) => {
		if (isMultiple && onSelectValue) {
			if (user?.Id == 0) {
				const newUsers: Partial<ITaskerUser>[] = [];
				setSelectedUsers(newUsers);
				onSelectValue(newUsers);
			}
			if (user?.Id != 0) {
				const isSelected = Boolean(selectedUsers?.find(y => getKey(y) === getKey(user)));
				const index = selectedUsers?.findIndex(a => getKey(user) === getKey(a));
				if (selectedUsers?.length === 1 && isSelected) {
					setSelectedUsers([]);
					onSelectValue([]);
				} else if (index >= 0 && selectedUsers?.length > 1 && isSelected) {
					const users = _.uniqBy(
						selectedUsers.filter(selectedUser => selectedUser.Id !== user?.Id),
						'Id'
					);
					setSelectedUsers(users);
					onSelectValue(users);
				} else if (!isSelected) {
					const users = _.uniqBy([...selectedUsers, user], 'Id');
					setSelectedUsers(users);
					onSelectValue(users);
				}
			}
		}
		if (!isMultiple && onSelectValue) {
			if (user?.Id == 0) {
				selectedUsers.pop();
				onSelectValue(selectedUsers);
			}
			if (user?.Id != 0) {
				const isSelected = Boolean(selectedUsers?.find(y => getKey(y) === getKey(user)));
				if (isSelected) {
					const users = _.uniqBy(selectedUsers.slice(-1), 'Id');
					setSelectedUsers(users);
					onSelectValue(selectedUsers);
				}
				if (!isSelected) {
					setSelectedUsers([user]);
					onSelectValue(user);
				}
			}
		}
	};

	const avatarNodes = useMemo(
		() =>
			slicedAvatars?.map(item => {
				const isSelected = Boolean(selectedUsers?.find(y => getKey(y) === getKey(item)));
				const isDefault = selectedUsers?.length == 0 && item?.Id == 0;
				const avatar = getAvatar(item);

				return (
					<AvatarTooltip
						key={uuid()}
						avatar={avatar}
						isDefault={isDefault}
						item={item}
						isSelected={isSelected}
						sizePx={sizePx}
						navigateOnClick={navigateOnClick}
						getTooltip={getTooltip}
						handleChoose={handleChoose}
					/>
				);
			}),
		[itemsToDisplay, selectedUsers]
	);

	if (limit !== -1 && itemsToDisplay.length > limit) {
		avatarNodes.push(
			<Tooltip
				key={uuid()}
				title={
					<div className={classes.tooltip}>
						{itemsToDisplay?.map((x: Partial<ITaskerUser>) => {
							return (
								<div key={uuid()} className={classes.tooltipFlex}>
									<Avatar sx={sizePx} src={x?.AvatarPath} />
									{getFullNameNew(x)}
								</div>
							);
						})}
					</div>
				}
			>
				<Avatar sx={sizePx} className={clsx(classes.more, classes.inheritPosition)}>
					+{itemsToDisplay.length - limit}
				</Avatar>
			</Tooltip>
		);
	}

	return useMemo(
		() => (
			<div
				{...rest}
				ref={ref}
				className={clsx(classes.root, className, classes.inheritPosition, classes.fullWidth)}
				onMouseEnter={() => setIsMouseOver(true)}
				onMouseLeave={() => setIsMouseOver(false)}
			>
				{avatarNodes}
			</div>
		),
		[itemsToDisplay, selectedUsers]
	);
};
