import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	temporary: 'temporary' as const,
};

export const Translation = {
	pl: {
		PortalList: {
			Filters: {
				Options: {
					Buttons: {
						clear: 'Wyczyść',
						apply: 'Zastosuj filtry',
					},
				},
			},
		},
	},
	en: {
		PortalList: {
			Filters: {
				Options: {
					Buttons: {
						clear: 'Clear',
						apply: 'Apply filters',
					},
				},
			},
		},
	},
};
