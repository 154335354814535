import { useSelector } from 'react-redux';

import { IReducerState } from '@sac-tt/tasker-types';
import { VisibilityControl } from '@sac-tw2/tasker-widgets2/dist/widgets/VisibilityControl';

import { Grid, Typography } from '@mui/material';

import { useTrans } from '@/utils/hooks/useTrans';

import { Resources } from './resources';
import { useStyles } from './styles';
import { getHotkeys } from './utils';

export const HotkeysTooltip = (): JSX.Element => {
	const classes = useStyles();
	const { t } = useTrans('Dashboard.TopBar.HotkeysTooltip');

	const canOpenIssueModal = useSelector((state: IReducerState) => state?.Session?.canOpenIssueModal);

	return (
		<VisibilityControl
			condition={getHotkeys(canOpenIssueModal)?.length > 0}
			alternative={
				<div>
					<Typography className={classes.lightText} variant={Resources.GlobalResources.h5}>
						{t('noHotkeys')}
					</Typography>
					<Typography className={classes.lightText} variant={Resources.GlobalResources.h5}>
						{t('more')}
					</Typography>
				</div>
			}
		>
			<Typography className={classes.lightText} variant={Resources.GlobalResources.h5}>
				{t('hello')}
			</Typography>
			{getHotkeys(canOpenIssueModal)?.map((item, key) => {
				return (
					<Grid container alignItems={Resources.GlobalResources.center} key={key}>
						<Grid item>
							<Grid container>
								{item?.keys?.map((button, key) => {
									return (
										<Grid item key={`${key}${button}`}>
											<Grid container alignItems={Resources.GlobalResources.center}>
												<Typography className={classes.border}>{button}</Typography>
												<VisibilityControl
													condition={button == Resources.alt || button == Resources.ctrl}
												>
													<Typography
														className={classes.lightText}
														variant={Resources.GlobalResources.h5}
													>
														{Resources.plus}
													</Typography>
												</VisibilityControl>
												<VisibilityControl condition={button == Resources.arrowLeft}>
													<Typography
														className={classes.lightText}
														variant={Resources.GlobalResources.h5}
													>
														{t('or')}
													</Typography>
												</VisibilityControl>
											</Grid>
										</Grid>
									);
								})}
							</Grid>
						</Grid>
						<Typography className={classes.lightText} variant={Resources.GlobalResources.h6}>
							{item?.label}
						</Typography>
					</Grid>
				);
			})}
			<Typography className={classes.lightText} variant={Resources.GlobalResources.h5}>
				{t('more')}
			</Typography>
		</VisibilityControl>
	);
};

export default HotkeysTooltip;
