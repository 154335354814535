import { AsyncAction } from '@sac-rp/redux-promisify';
import { IVersion } from '@sac-tt/tasker-types/dist/types/dataBaseTypes/version';

import { IJson } from '@/store/ITypes';
import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

const basePath = 'Version';

export const GET_VERSIONS: ActionType = 'GET_VERSIONS';
export const GET_NEWEST_VERSION: ActionType = 'GET_NEWEST_VERSION';

export function set_Versions(versions: IVersion[]): Action {
	return {
		type: GET_VERSIONS,
		data: versions,
	};
}

export function set_Newest_Version(version: IVersion): Action {
	return {
		type: GET_NEWEST_VERSION,
		data: version,
	};
}

export function fetch_Versions(data?: string): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(set_Versions((json as IJson).data)));
		return axios().post(`${basePath}/get`, { data: data });
	};
}

export function fetch_Newest_Version(): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(set_Newest_Version((json as IJson).data)));
		return axios().get(`${basePath}/Newest`);
	};
}
