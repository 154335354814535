import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		IssueList: {
			TileComponents: {
				CreationDate: {
					creationDate: 'Data stworzenia',
				},
			},
		},
	},
	en: {
		IssueList: {
			TileComponents: {
				CreationDate: {
					creationDate: 'Creation date',
				},
			},
		},
	},
};
