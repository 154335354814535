import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	overline: 'overline' as const,
};

export const Translation = {
	pl: {
		IssueList: {
			Filters: {
				Options: {
					TagFilter: {
						Icons: {
							add: 'Dodaj',
							remove: 'Usuń',
							negateAdd: 'Dodaj do wykluczenia',
						},
					},
				},
			},
		},
	},
	en: {
		IssueList: {
			Filters: {
				Options: {
					TagFilter: {
						Icons: {
							add: 'Add',
							remove: 'Remove',
							negateAdd: 'Add not in',
						},
					},
				},
			},
		},
	},
};
