import { useSelector } from 'react-redux';

import { displayTimeSpan } from '@sac-tw2/tasker-widgets2/dist/utils';

import { Typography } from '@mui/material';

import { IReducerState } from '@/store/types';

export const TotalTimeSpentOnIssue = () => {
	const totalTimeSpentOnIssue = useSelector((state: IReducerState) => state?.IssueModalEditor?.totalTimeSpentOnIssue);

	return <Typography>{displayTimeSpan(totalTimeSpentOnIssue)}</Typography>;
};
