import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	basicButtonId: 'basic-button' as const,
	defaultColor: '#FFFFFF',

	droppableId: 'swimloneConfigurationDroppableId',
	smoothBehavior: 'smooth' as const,
	virtual: 'virtual' as const,
	swimlaneConfigurationList: 'swimlaneConfigurationList',
	xSmall: 'xSmall' as const,
	...GlobalResources,
};
export const Translation = {
	pl: {
		Kanban: {
			KanbanBoard: {
				KanbanCreator: {
					CreationPopupContent: {
						SwimlaneStepContent: {
							title: 'Konfiguracja swimlane',
							subtext: `W tym miejscu możesz zarządzać swimlane'ami - ich widocznością oraz kolejnością wyświetlania`,
							ifYouWantToConfigure: `Jeśli chcesz konfigurować  swimlane'y, `,
							useYourOwnConfiguration: 'użyj swojej konfiguracji',
							name: 'Nazwa',
							color: 'Kolor',
							creationDate: 'Data stworzenia',
							createdBy: 'Stworzone przez',
							youAreUsingConfiguration: 'Używasz konfiguracji użytkownika:',
							addSwimlane: 'Dodaj Swimlane',
							exit: 'Zamknij',
							save: 'Zapisz zmiany',
							createPopupTitle: 'Kreator swimlane',
							createPopupSubtitle:
								'Swtórz oraz skonfigurj nowy swimlane aby jeszcze lepiej zarządzać zadaniami',
							updatePopupTitle: 'Edycja swimlane',
							updatePopupSubtitle: 'Edytuj właściwości swimlane oraz zarządzaj jego parametrami ',
						},
					},
				},
			},
		},
	},
	en: {
		Kanban: {
			KanbanBoard: {
				KanbanCreator: {
					CreationPopupContent: {
						SwimlaneStepContent: {
							title: 'Swimeline configuration',
							subtext: 'In this place, you can manage swimlanes - their visibility and display order',
							ifYouWantToConfigure: 'If you want to configure the swimlanes, ',
							useYourOwnConfiguration: 'use your own configuration',
							name: 'Name',
							color: 'Color',
							creationDate: 'Creation date',
							createdBy: 'Created by',
							youAreUsingConfiguration: 'You are using a configuration of user:',
							addSwimlane: 'Add new swimlane',
							save: 'Save changes',
							exit: 'Exit',
							createPopupTitle: 'Swimlane creator',
							createPopupSubtitle: 'Create and configure new swimlne to manage your issues even better',
							updatePopupTitle: 'Edit swimlane',
							updatePopupSubtitle: 'Edit swimlane properties and manage its parameters',
						},
					},
				},
			},
		},
	},
};
