import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationAcceptance: {
						DelegationAcceptanceInfo: {
							delegationLength: 'Czas trwania delegacji:',
							delegationRoute: 'Trasa delegacji:',
							desc: 'Opis delegacji',
							noDesc: 'Brak opisu delegacji.',
						},
					},
				},
			},
		},
	},
	en: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationAcceptance: {
						DelegationAcceptanceInfo: {
							delegationLength: 'Delegation time:',
							delegationRoute: 'Delegation route:',
							desc: 'Delegation description',
							noDesc: 'No description',
						},
					},
				},
			},
		},
	},
};
