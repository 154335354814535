import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

import { Resources as sub } from '../resources';

export const Resources = {
	...sub,
	...GlobalResources,
};

export const Translation = {
	pl: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationElements: {
						DelegationPerDiem: {
							PerDiemColorLegend: {
								system: 'System',
								user: 'Użytkownik',
								blocked: 'Zablokowane',
							},
						},
					},
				},
			},
		},
	},
	en: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationElements: {
						DelegationPerDiem: {
							PerDiemColorLegend: {
								system: 'System',
								user: 'User',
								blocked: 'Blocked',
							},
						},
					},
				},
			},
		},
	},
};
