import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import uuid from 'uuid';

import { IReducerState } from '@sac-tt/tasker-types';
import { VisibilityControl } from '@sac-tw2/tasker-widgets2';
import { errorVariant } from '@sac-tw2/tasker-widgets2/dist/utils';

import { useTrans } from '@/utils/hooks/useTrans';

import { DropzoneFile } from './DropzoneAttachmentRecord';
import { EDropzoneFileStatus } from './DropzoneAttachmentRecord/model';
import { DropzoneInfo } from './DropzoneInfo';
import { EDropzonePlacement, EDropzoneType, IDropzoneAttachment, Props } from './model';
import { Resources } from './resources';
import { useStyles } from './styles';

export const Dropzone = <T extends IDropzoneAttachment & { DisableFunctions?: boolean }>(
	props: Props<T>
): JSX.Element => {
	const { baseProps, handlers, classes: classesInput, dropZonePlacement = EDropzonePlacement.Bottom } = props;
	const { attachments, setAttachments, allowedFiletypes, maxFilesize, isDisabled } = baseProps;
	const { type } = handlers;

	const darkMode: boolean = useSelector((state: IReducerState) => state.Session.darkMode);
	const classes = useStyles({ darkMode });
	const snackBar = useSnackbar();
	const { t } = useTrans(
		'DelegationList.DelegationModal.DelegationModalContent.DelegationDetails.DelegationAttachments.Dropzone'
	);

	const [isDragging, setIsDragging] = useState<boolean>(false);
	const [isHovered, setIsHovered] = useState<boolean>(false);

	const handleSetFiles = (files: File[]) => {
		const validFiles: T[] = [];

		for (let i = 0; i < files.length; i++) {
			const file = files[i];
			const nameSlitted = file?.name?.split('.');
			const extension = `.${nameSlitted?.[nameSlitted.length - 1]}`;
			if (allowedFiletypes.Extensions.includes(extension?.toLowerCase()) && file?.size <= maxFilesize) {
				validFiles.push({
					file: file,
					fileLocalId: uuid(),
					fileStatus: type === EDropzoneType.Drop ? EDropzoneFileStatus.Saved : EDropzoneFileStatus.Waiting,
					collapsed: true,
					formReady: false,
					DisableFunctions: true,
				} as T);
			}
		}

		if (validFiles.length !== files.length) {
			snackBar.enqueueSnackbar(t('alert'), errorVariant);
		} else {
			setAttachments(prev => {
				return [
					...validFiles.map(file => ({
						...file,
					})),
					...prev,
				];
			});
			if (type === EDropzoneType.Drop) {
				handlers.onDrop(validFiles);
			}
		}
	};

	const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		event.stopPropagation();
		setIsDragging(false);
		if (isDisabled) return;

		handleSetFiles([...event.dataTransfer.files]);
	};

	const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		event.preventDefault();
		event.stopPropagation();
		if (event.target.files) {
			handleSetFiles(Array.from(event.target.files));
		}
	};

	return (
		<div
			onDragEnter={event => {
				event.preventDefault();
				event.stopPropagation();
				setIsDragging(true);
			}}
			onDragOver={event => {
				event.preventDefault();
				event.stopPropagation();
				setIsDragging(true);
			}}
			onDragLeave={event => {
				event.preventDefault();
				event.stopPropagation();
				setIsDragging(false);
			}}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
			onDrop={handleDrop}
			className={clsx(classes.dropzone, isDisabled && classes.dropzoneDisabled)}
		>
			<label
				htmlFor={Resources.inputId}
				className={clsx(
					classes.dropzoneLabel,
					isDragging && classes.dropzoneLabelActive,
					isHovered && classes.dropzoneLabelActive,
					classesInput?.dropzoneLabel
				)}
			>
				<div className={clsx(classes.dropzoneContent, classesInput?.dropzoneContent)}>
					<VisibilityControl condition={dropZonePlacement === EDropzonePlacement.Top}>
						<DropzoneInfo
							allowedFiletypes={allowedFiletypes}
							maxFilesize={maxFilesize}
							classes={classesInput}
							dropZonePlacement={dropZonePlacement}
						/>
					</VisibilityControl>
					<VisibilityControl condition={attachments?.length > 0}>
						{attachments?.map((attachment, index) => (
							<DropzoneFile<T>
								key={attachment?.fileLocalId ?? index}
								baseProps={{
									...baseProps,
									index,
									attachment,
									setIsHovered,
								}}
								handlers={handlers}
								classes={classesInput}
							/>
						))}
					</VisibilityControl>
					<VisibilityControl condition={dropZonePlacement === EDropzonePlacement.Bottom}>
						<DropzoneInfo
							allowedFiletypes={allowedFiletypes}
							maxFilesize={maxFilesize}
							classes={classesInput}
							dropZonePlacement={dropZonePlacement}
						/>
					</VisibilityControl>
				</div>
				<input
					multiple
					id={Resources.inputId}
					type={Resources.GlobalResources.file}
					accept={allowedFiletypes.Extensions.join(',')}
					onChange={handleFileInputChange}
					className={classes.dropzoneInput}
					disabled={isDisabled}
				/>
			</label>
		</div>
	);
};
