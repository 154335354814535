import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { ITaskerUser } from '@sac-tt/tasker-types';
import { AsyncSelect } from '@sac-tw2/tasker-widgets2';

import { fetch_taskerUser_page } from '@/store/actions';
import { Dispatch } from '@/store/types';
import { getFullNameNew, useTrans } from '@/utils';

import { Props } from './model';
import { Resources } from './resources';
import { useStyles } from './styles';

export const SelectUser = (props: Props) => {
	const { handleChosenUserChange, chosenUser } = props;
	const classes = useStyles();
	const dispatch: Dispatch = useDispatch();

	const [issueInputValue, setIssueInputValue] = useState<string>(Resources.GlobalResources.empty);
	const { t } = useTrans('IssueModal.IssueModalContent.Worklogs');

	const issueSelectGetOptionName = (value: ITaskerUser): string => {
		return getFullNameNew(value);
	};

	const issueSelectGetOptionKey = (value: ITaskerUser): number => {
		return value.Id;
	};

	const selectCallBack = async (inputValue = Resources.GlobalResources.empty) => {
		const response = await dispatch(fetch_taskerUser_page(0, 5, inputValue));

		return response.data.Data as ITaskerUser[];
	};

	return (
		<div className={classes.container}>
			<AsyncSelect<ITaskerUser>
				getOptionKey={issueSelectGetOptionKey}
				getOptionName={issueSelectGetOptionName}
				label={t('user')}
				value={chosenUser}
				setInputValue={setIssueInputValue}
				inputValue={issueInputValue}
				callback={selectCallBack}
				handleChange={user => handleChosenUserChange(user)}
				noOptionsText={Resources.noUsers}
			/>
		</div>
	);
};
