import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	socialMediaLinks: {
		FBLink: GlobalResources.GlobalResources.empty,
		InstagramLink: GlobalResources.GlobalResources.empty,
		TwitterLink: GlobalResources.GlobalResources.empty,
		LinkedInLink: GlobalResources.GlobalResources.empty,
	},
};

export const Translation = {
	pl: {
		Settings: {
			General: {
				ProfileDetails: {
					AddSocialMediaCard: {
						popupAddNewSocialMedia: {
							title: 'Konfiguracja social media linków',
						},
						successSave: 'Pomyślnie skonfigurowano linki',
						errorSave: 'Błąd podczas zapisu. Zmiany w linkach nie zostały zapisane',
					},
				},
			},
		},
	},
	en: {
		Settings: {
			General: {
				ProfileDetails: {
					AddSocialMediaCard: {
						popupAddNewSocialMedia: {
							popupAddNewSocialMedia: {
								title: 'Configuration of social media links',
							},
							successSave: 'Links successfully changed',
							errorSave: 'Error while saving. The changes to the links have not been saved',
						},
					},
				},
			},
		},
	},
};
