import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	issueCardKey: 'IssueCard',
	assignedTo: 'Assigned',
	issues: 'issues',
};

export const Translation = {
	pl: {
		IssueList: {
			TileComponents: {
				headerType: 'Zgłoszenia',
				pageTitle: { issueList: 'Lista zgłoszeń', loading: 'Ładowanie' },
				workLogIsActive: 'Zgłoszenie zostało rozpoczęte przez inną osobę.',
				tile: {
					startDate: 'Data rozpoczęcia',
					creationDate: 'Data stworzenia',
					by: 'Stworzony przez',
					deadLine: 'Deadline',
					details: 'Szczegóły',
					assignedTo: 'Przypisano do ',
					project: 'Projekt',
					customer: 'Klient',
				},
				openIssue: 'Otwórz zgłoszenie',
				avatarAlt: 'Przypisano do',
				viewProfile: 'Pokaż profil',
				portalIssue: 'Zgłoszone przez portal',
				attachmentCount: 'Liczba załączników',
				unassigned: 'Brak przypisanej osoby',
			},
		},
	},
	en: {
		IssueList: {
			TileComponents: {
				headerType: 'Issues',
				pageTitle: { issueList: 'Issue List', loading: 'Loading' },
				workLogIsActive: 'Issue has been started by other user.',
				tile: {
					startDate: 'Start date',
					creationDate: 'Creation date',
					by: 'Created by',
					deadLine: 'Deadline',
					details: 'Details',
					assignedTo: 'Assigned to ',
					project: 'Project',
					customer: 'Klient',
				},
				openIssue: 'Open Issue',
				avatarAlt: 'Assigned to',
				viewProfile: 'View profile',
				portalIssue: 'Created by portal',
				attachmentCount: 'Number of attachments',
				unassigned: 'No user assigned',
			},
		},
	},
};
