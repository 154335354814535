import { EFieldDefinitionType } from '@sac-tt/tasker-types';
import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		OrganizationPanel: {
			EndWorkDay: {
				EndWorkDayList: {
					EndWorkDayDetails: {
						InfoComponent: {
							InfoComponentFormPart: {
								IssueFormGroup: {
									IssueFormField: {
										type: 'Typ pola',
										description: 'Opis',
										isMandatory: 'Rekord obowiązkowy',
										isActive: 'Rekord aktywny',
										isMultiValue: 'Zezwól na wiele',
										mandatoryTooltip:
											'Jeśli aktywne, pole będzie obowiązkowe do uzupełnienia podczas wypełniania formularza',
										isActiveTooltip:
											'Jeśli aktywne, pole będzie widoczne podczas podczas wypełniania formularza',
										multiValueTooltip:
											'Jeśli aktywne, będzie możliwy wybór wielu wartości w tym polu podczas wypełniania formularza',
										fieldDefinitionType: {
											[EFieldDefinitionType.Boolean]: 'Prawda/Fałsz',
											[EFieldDefinitionType.Integer]: 'Całkowica',
											[EFieldDefinitionType.Decimal]: 'Dziesiętne',
											[EFieldDefinitionType.Text]: 'Tekst',
											[EFieldDefinitionType.Dictionary]: 'Słownik',
											[EFieldDefinitionType.TagGroup]: 'Tag',
											[EFieldDefinitionType.Date]: 'Data',
											[EFieldDefinitionType.DateTime]: 'Zakres daty',
											[EFieldDefinitionType.TextArea]: 'Tekst',
										},
										tagGroupLabel: 'Grupa tagów',
										dictionaryLabel: 'Słownik',
										defaultValueTooltip:
											'Wartość w polu, to wartość która pojawi się domyślnie w tym polu przy wypełnianiu formularza',
									},
								},
							},
						},
					},
				},
			},
		},
	},
	en: {
		OrganizationPanel: {
			EndWorkDay: {
				EndWorkDayList: {
					EndWorkDayDetails: {
						InfoComponent: {
							InfoComponentFormPart: {
								IssueFormGroup: {
									IssueFormField: {
										type: 'Field type',
										description: 'Description',
										isMandatory: 'Record mandatory',
										isActive: 'Record active',
										isMultiValue: 'Is multi value',
										mandatoryTooltip:
											'If active, the field will be required to fill in when completing the form',
										isActiveTooltip:
											'If active, the field will be visible when completing the form',
										multiValueTooltip:
											'If active, multiple values can be selected in this field when completing the form',
										fieldDefinitionType: {
											[EFieldDefinitionType.Boolean]: 'Switch',
											[EFieldDefinitionType.Integer]: 'Integer',
											[EFieldDefinitionType.Decimal]: 'Decimal',
											[EFieldDefinitionType.Text]: 'Text',
											[EFieldDefinitionType.Dictionary]: 'Dictionary',
											[EFieldDefinitionType.TagGroup]: 'Tag',
											[EFieldDefinitionType.Date]: 'Date',
											[EFieldDefinitionType.DateTime]: 'Date range',
											[EFieldDefinitionType.TextArea]: 'Text',
										},
										tagGroupLabel: 'Tag group',
										dictionaryLabel: 'Dictionary',
										defaultValueTooltip:
											'The value in the field is the value that will appear by default in this field when filling out the form',
									},
								},
							},
						},
					},
				},
			},
		},
	},
};
