import clsx from 'clsx';
import { useContext } from 'react';

import { EFieldDefinitionType, EInputTypes } from '@sac-tt/tasker-types';
import { CustomInput } from '@sac-tw2/tasker-widgets2';

import { Typography } from '@mui/material';

import { IssueFormSectionsWidgetContext } from '../..';
import { IssuePropertyTitle } from '../IssuePropertyTitle';
import { useInitialAttributeDefinitionValues } from '../utils/useInitialAttributeDefinitionValues';

import { Props } from './model';
import { useStyles } from './styles';

export const DecimalIssueProperty = (props: Props) => {
	const { issueFormSectionToIssueField, inEdit } = props;
	const classes = useStyles();

	const { handleChange, state } = useContext(IssueFormSectionsWidgetContext);

	const AttributeDefinitionId = issueFormSectionToIssueField?.AttributeDefinitionId as number;
	const initial = useInitialAttributeDefinitionValues(issueFormSectionToIssueField);

	const initialInputValue = initial?.length ? (initial?.[0]?.ValueDecimal as number) : null;

	const newValue = state && (state?.[EFieldDefinitionType.Decimal]?.[AttributeDefinitionId] as number | undefined);

	const value = newValue ?? initialInputValue ?? null;

	return (
		<div className={clsx(classes.flexYCenter, classes.container)}>
			<IssuePropertyTitle {...props} />
			<div className={clsx(classes.flexGrow, classes.inputContainer)}>
				<CustomInput
					type={EInputTypes.number}
					label=""
					handleChange={val => handleChange && handleChange(Number(val), issueFormSectionToIssueField)}
					value={value}
					inEdit={inEdit}
					canSetInputInNonEdit
					nonEditComponent={<Typography className={classes.valueContainer}>{initialInputValue}</Typography>}
				/>
			</div>
		</div>
	);
};
