import { AsyncAction } from '@sac-rp/redux-promisify';
import { IInvoiceItem } from '@sac-tt/tasker-types';

import { IJson } from '@/store/ITypes';
import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

export const INVOICE_ITEM_DELETE: ActionType = 'INVOICE_ITEM_DELETE';
export const INVOICE_ITEM_CREATE: ActionType = 'INVOICE_ITEM_CREATE';
export const INVOICE_ITEM_ADD: ActionType = 'INVOICE_ITEM_ADD';
export const INVOICE_ITEM_UPDATE: ActionType = 'INVOICE_ITEM_UPDATE';
export const INVOICE_ITEM_EDIT: ActionType = 'INVOICE_ITEM_EDIT';

const basePath = 'InvoiceItem';

function _deleteInvoiceItem(id: number): Action {
	return {
		type: INVOICE_ITEM_DELETE,
		data: id,
	};
}

function _createInvoiceItem(data: IInvoiceItem): Action {
	return {
		type: INVOICE_ITEM_CREATE,
		data,
	};
}

function _updateInvoiceItem(data: IInvoiceItem): Action {
	return {
		type: INVOICE_ITEM_UPDATE,
		data,
	};
}

export function setInvoiceItemToEdit(data: IInvoiceItem): Action {
	return {
		type: INVOICE_ITEM_EDIT,
		data,
	};
}

export function setInvoiceItemAdd(data: Partial<IInvoiceItem>): Action {
	return {
		type: INVOICE_ITEM_ADD,
		data,
	};
}

export function invoiceItem_delete(id: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_deleteInvoiceItem((json as IJson).data)));
		return axios().delete(`${basePath}/${id}`);
	};
}

export function invoiceItem_create(data: Partial<IInvoiceItem>): AsyncAction | Promise<unknown> {
	return function(context, dispatch) {
		context.then(json => dispatch(_createInvoiceItem((json as IJson).data)));
		return axios().post(basePath, { ...data });
	};
}

export function invoiceItem_update(data: Partial<IInvoiceItem>, id: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_updateInvoiceItem((json as IJson).data)));
		return axios().patch(`${basePath}/${id}`, { ...data });
	};
}
