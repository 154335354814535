import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		RolePanel: {
			CustomCard: {
				Available: 'Dostępni użytkownicy',
			},
		},
	},
	en: {
		RolePanel: {
			CustomCard: {
				Available: 'Available users',
			},
		},
	},
};
