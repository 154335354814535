import { EIssueModalTabs, IIssue, IPage } from '@sac-tt/tasker-types';

export enum ERelation {
	Children,
	Parent,
	Equivalent,
}

export interface ITableProps {
	issuesPage: IPage<IIssueRelationship[]>;
	changeRelationToBasic: () => void;
	onDelete: (issueId: number, connectionId: number, uId?: string) => void;
}

export interface IIssueRelationship extends IIssue {
	RelationId: number;
}

export interface Props {
	issues: (IIssueRelationship & { uId?: string })[];
	issuesCount: number;
	tab: EIssueModalTabs;
	relation: ERelation;
	isLoading: boolean;
	issueId: number;
	changeRelationToBasic: () => void;
}

export interface IIssueAsync extends IIssue {
	IsDefault?: boolean;
}

// export interface IExtendedIIssueToIssueRelationship extends Partial<IIssueToIssueRelationship> {
// 	uId?: string;
// }
