import { t } from 'i18next';

import { ICreateIssueFormSection, IIssueFormCreate } from '@sac-tt/tasker-types';
import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	path: '/issueForms',
	key: 'issueForm_Main_Section',
	dash: '_',
	fieldSectionBase: {
		Name: '',
		FieldDefinition: [],
		SectionChildren: [],
	} as ICreateIssueFormSection,
};

export const Translation = {
	pl: {
		ProjectDetails: {
			Form: {
				IssueForm: {
					New: {
						formCardTitle: 'Formularz zgłoszeniowy',
						pageTitle: 'Formularz',
						Header: {
							formPropertiesTitle: 'Konfiguracja właściwości formularza',
							subTitleNew: 'Nowy formularz',
						},
						createForm: 'Stwórz formularz',
						saveForm: 'Zapisz',
						cancel: 'Anuluj',
						addGroupIcon: 'Dodaj grupę',
						deleteConfirmation: 'Czy na pewno chcesz usunąć tę grupę?',
						Errors: {
							Name: '^Nazwa musi zawierać od 1 do 150 znaków',
						},
					},
				},
			},
		},
	},
	en: {
		ProjectDetails: {
			Form: {
				IssueForm: {
					New: {
						formCardTitle: 'Issue form',
						pageTitle: 'Form',
						Header: {
							formPropertiesTitle: 'Configuration form properties',
							subTitleNew: 'New form',
						},
						createForm: 'Create form',
						saveForm: 'Save',
						cancel: 'Cancel',
						addGroupIcon: 'Add group',
						deleteConfirmation: 'Are you sure you want to delete this group?',
						Errors: {
							Name: '^Name should be between 1 to 150 characters',
						},
					},
				},
			},
		},
	},
};

export const getValidationFormSchema = () => {
	return {
		IssueFormName: {
			presence: { allowEmpty: false, message: t('ProjectDetails.Form.IssueForm.New.Errors.Name') },

			length: { maximum: 150, message: t('ProjectDetails.Form.IssueForm.New.Errors.Name') },
		},
	};
};
