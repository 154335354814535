import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		Reckon: {
			Agreements: {
				AgreementEdit: {
					SalaryForm: {
						SalaryTimeStep: {
							mainTitle: 'Okres wynagrodzenia',
							mainSubtitle:
								'W przypadku braku określenia daty zakończenia za czas zakończenia wynagrodzenia przyjęto datę zakończenia umowy',
						},
					},
				},
			},
		},
	},
	en: {
		Reckon: {
			Agreements: {
				AgreementEdit: {
					SalaryForm: {
						SalaryTimeStep: {
							mainTitle: 'Pay period',
							mainSubtitle:
								'If no end date is specified, the end date of the contract is assumed as the end of remuneration',
						},
					},
				},
			},
		},
	},
};
