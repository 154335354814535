import uuid from 'uuid';

import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	number: 'number' as const,
	periodKey: (x?: string | number) => `Period_${x}_${uuid()}`,
	incomeTypeKey: (x?: string | number) => `IncomeType_${x}_${uuid()}`,
	incomeUnitKey: (x?: string | number) => `IncomeUnit_${x}_${uuid()}`,
	typeLineKey: (x?: string | number) => `TypeLine_${x}_${uuid()}`,
};

export const Translation = {
	pl: {
		Budgets: {
			AddEditForm: {
				AddingBudget: 'Dodawanie budżetu',
				EditingBudget: 'Edycja budżetu',
				AddingIncomeLine: 'Dodawanie wpływu budżetowego',
				EditingIncomeLine: 'Edycja wpływu budżetowego',
				AddingOutcomeLine: 'Dodawanie wydatków',
				EditingOutcomeLine: 'Edycja wydatków',
				AddingPeriodicalLine: 'Dodawanie stałej linii budżetowej',
				EditingPeriodicalLine: 'Edycja stałej linii budżetowej',
				Currency: 'Waluta',
				Budget: {
					Name: 'Nazwa budżetu',
				},
				IncomeLine: {
					Name: 'Nazwa przychodu',
				},
				OutcomeLine: {
					Name: 'Nazwa wydatku',
				},
				Line: {
					AmountNet: 'Kwota netto',
					AmountVat: 'Kwota Vat',
					AmountGross: 'Kwota Brutto',
					Period: 'Zakres dat',
				},
				PeriodLine: {
					Month: 'Miesiąc',
					Day: 'Dzień',
					Week: 'Tydzień',
					Quarter: 'Kwartał',
					Year: 'Rok',
					Amount: 'Ilość',
					Hour: 'Godzina',
					Periodical: 'Cyklicznie',
					PeriodicalPackage: 'Cyklicznie w pakiecie',
					IncomeLine: 'Przychód',
					OutComeLine: 'Wydatek',
					period: 'Co jaki okres czasu ma dodawać się linia budżetowa',
					incomeType: 'Typ',
					incomeUnit: 'Co jaki czas ma się wykonywać tworzenie lini?',
					typeLine: 'Typ linii budżetowej',
				},
				save: 'Zapisz',
				cancel: 'Anuluj',
			},
		},
	},
	en: {
		Budgets: {
			AddEditForm: {
				AddingBudget: 'Adding budget',
				EditingBudget: 'Editing budget',
				AddingIncomeLine: 'Adding income line',
				EditingIncomeLine: 'Editing income line',
				AddingOutcomeLine: 'Adding outcome line',
				EditingOutcomeLine: 'Editing outcome line',
				AddingPeriodicalLine: 'Adding periodical line',
				EditingPeriodicalLine: 'Editing periodical line',
				Currency: 'Currency',
				Budget: {
					Name: 'Budget name',
				},
				IncomeLine: {
					Name: 'Income line name',
				},
				Line: {
					Description: 'Description',
					AmountNet: 'Amount net',
					AmountVat: 'Amount vat',
					AmountGross: 'Amount gross',
					Period: 'Period',
				},
				OutcomeLine: {
					Name: 'Outcome line name',
				},
				PeriodLine: {
					Month: 'Month',
					Day: 'Day',
					Week: 'Week',
					Quarter: 'Quarter',
					Year: 'Year',
					Amount: 'Ilość',
					Hour: 'Godzina',
					Periodical: 'Cyklicznie',
					PeriodicalPackage: 'Cyklicznie w pakiecie',
					IncomeLine: 'Income line',
					OutComeLine: 'OutCome line',
					period: 'In what period of time it should be repeated',
					incomeType: 'Type',
					typeLine: 'Type of line',
				},
				save: 'Save',
				cancel: 'Cancel',
			},
		},
	},
};
