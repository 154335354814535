import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	usersPath: '/organization/tabs/users' as const,
};

export const Translation = {
	pl: {
		OrganizationPanel: {
			UsersTab: {
				UserDetails: {
					personal: 'Dane osobowe',
					credentials: 'Dane uwierzytelniające',
					metadata: 'Metadane',
					labels: {
						role: 'Rola',
						login: 'Login',
						password: 'Hasło',
						email: 'Email',
						avatar: 'Ścieżka awatara',
						name: 'Imie',
						surname: 'Nazwisko',
					},
					errors: {
						login: 'Login jest wymagany',
						loginInUse: 'Login jest już zajęty',
						password: 'Hasło jest puste lub nie spełnia polityki bezpieczeństwa organizacji',
						email: 'Niepoprawny adres email',
						emailInUse: 'Email jest już zajęty',
						role: 'Rola jest wymagana',
						avatar: 'Niepoprawna ścieżka awatara',
						noActivePolicies: 'Brak aktywnych polityk bezpieczeństwa',
					},
				},
			},
		},
	},
	en: {
		OrganizationPanel: {
			UsersTab: {
				UserDetails: {
					personal: 'Personal data',
					credentials: 'Credentials',
					metadata: 'Metadata',
					labels: {
						role: 'Role',
						login: 'Login',
						password: 'Password',
						email: 'Email',
						avatar: 'Avatar path',
						name: 'Name',
						surname: 'Surname',
					},
					errors: {
						login: 'Login is required',
						loginInUse: 'Login is already in use',
						password: 'Password is empty or does not meet the organization security policy',
						email: 'Incorrect email address',
						emailInUse: 'Email is already in use',
						role: 'Role is required',
						avatar: 'Incorrect avatar path',
						noActivePolicies: 'No active security policies',
					},
				},
			},
		},
	},
};
