import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { EParameter, IOrganization, ITaskerUserSetting } from '@sac-tt/tasker-types';
import { IFilterCustomer, IFilterSimplifiedCustomer } from '@sac-tt/tasker-types/dist/types/endPoints/filter';

import {
	fetch_Newest_Version,
	get_Customer_Filter_Options,
	get_Customer_Filter_Simplified_Options,
	session_loginWithToken,
	userHasEndedDay as user_HasEndedDay,
	userMustChangePassword,
} from '@/store/actions';
import { IReducerState } from '@/store/reducers/types';
import { Dispatch } from '@/store/types';

import { Resources } from './resources';
import { isEmpty } from './utils';

export const LazyLoading = () => {
	const dispatch: Dispatch = useDispatch();
	const history = useHistory();

	const language: string = useSelector((state: IReducerState) => state?.Session.language);
	const chosenOrganization: IOrganization | undefined = useSelector(
		(state: IReducerState) => state?.Session.chosenOrganization
	);
	const preferences: ITaskerUserSetting[] = useSelector((state: IReducerState) => state?.Session?.preferences);
	const issueListFilters: IFilterCustomer[] = useSelector(
		(state: IReducerState) => state?.IssueList?.filters?.customerFilters
	);
	const issueListFiltersSimplified: IFilterSimplifiedCustomer[] = useSelector(
		(state: IReducerState) => state?.IssueList?.filters?.customerFiltersSimplified
	);
	const kanbanFilters: IFilterCustomer[] = useSelector(
		(state: IReducerState) => state?.Kanban?.filters?.customerFilters
	);
	const kanbanFiltersSimplified: IFilterSimplifiedCustomer[] = useSelector(
		(state: IReducerState) => state?.Kanban?.filters?.customerFiltersSimplified
	);
	const customerFiltersNeedReset: number = useSelector(
		(state: IReducerState) => state?.Session?.customerFiltersNeedReset
	);

	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (language) dispatch(fetch_Newest_Version());
		dispatch(user_HasEndedDay());
		dispatch(userMustChangePassword());
	}, [chosenOrganization?.Id]);

	useEffect(() => {
		const handleHashChange = () => {
			if (window.location.hash === Resources.login) {
				dispatch(session_loginWithToken()).then(() => {
					try {
						history.push(Resources.activity);
					} catch (error) {
						window.location.href = Resources.activity;
					}
				});
			}
		};

		handleHashChange();
		window.addEventListener(Resources.hashchange, handleHashChange);

		return () => {
			window.removeEventListener(Resources.hashchange, handleHashChange);
		};
	}, [dispatch]);

	useEffect(() => {
		fetch();
	}, [preferences?.length]);

	useEffect(() => {
		const filtersAreEmpty: boolean =
			isEmpty(issueListFilters) ||
			isEmpty(issueListFiltersSimplified) ||
			isEmpty(kanbanFilters) ||
			isEmpty(kanbanFiltersSimplified);
		fetch(customerFiltersNeedReset > 0 || filtersAreEmpty);
	}, [customerFiltersNeedReset]);

	const fetch = (additionalQuery = true) => {
		const preference = preferences?.find(x => x?.ParameterId === EParameter.User_UISimplifyFilters);
		if (preference?.Id) {
			if (isLoading === false && additionalQuery) {
				load();
				if ((preference?.ValueBoolean ?? false) === true)
					dispatch(get_Customer_Filter_Simplified_Options()).finally(loaded);
				else dispatch(get_Customer_Filter_Options()).finally(loaded);
			}
		} else dispatch(get_Customer_Filter_Options()).finally(loaded);
	};

	const loaded = () => setIsLoading(false);
	const load = () => setIsLoading(true);
	return <></>;
};
