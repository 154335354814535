import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,

	agreementListPath: '/agreements/agreements',
};

export const Translation = {
	pl: {
		Reckon: {
			Agreements: {
				AgreementDetails: {
					CardHeader: {
						ReturnToAgreementList: {
							goBack: 'Wróć do listy umów',
						},
					},
				},
			},
		},
	},
	en: {
		Reckon: {
			Agreements: {
				AgreementDetails: {
					CardHeader: {
						ReturnToAgreementList: {
							goBack: 'Go back to agreements list',
						},
					},
				},
			},
		},
	},
};
