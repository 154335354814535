import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	step: 'step' as const,
	defaultColor: '#00ff30' as const,
	startPosition: 'start' as const,

	propertyField: {
		label: 'Label' as const,
		imageLink: 'ImageLink' as const,
		color: 'TagColor' as const,
	},
};

export const Translation = {
	pl: {
		TagGroupInfo: {
			TagsCard: {
				TagPopup: {
					Content: {
						toLongLink: 'Link do zdjęcia jest za długi',
						imageName: 'Link do zdjęcia',
						labelName: 'Etykieta',
						colorName: 'Kolor',
						emptyLabel: 'Etykieta nie może być pusta',
						wrongColorFormat: 'Niepoprawny format koloru',
					},
				},
			},
		},
	},
	en: {
		TagGroupInfo: {
			TagsCard: {
				TagPopup: {
					Content: {
						toLongLink: 'Image link is to long',
						imageName: 'Image link',
						labelName: 'Label',
						colorName: 'Color',
						emptyLabel: 'Label cannot be empty',
						wrongColorFormat: 'Invalid color format',
					},
				},
			},
		},
	},
};
