import { AsyncAction } from '@sac-rp/redux-promisify';
import { IComplexUpdate, IHoliday, IPage } from '@sac-tt/tasker-types';

import { IJson } from '@/store/ITypes';
import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

const basePath = 'Holiday';

export const HOLIDAY_FETCH: ActionType = 'HOLIDAY_FETCH';
export const HOLIDAYS_FETCH: ActionType = 'HOLIDAYS_FETCH';
export const HOLIDAY_RESET: ActionType = 'HOLIDAY_RESET';
export const HOLIDAY_DELETE: ActionType = 'HOLIDAY_DELETE';

function _fetchHolidays(holidays: IPage<IHoliday>): Action {
	return {
		type: HOLIDAYS_FETCH,
		holidays: holidays,
	};
}

function _fetchHoliday(holiday: IHoliday): Action {
	return {
		type: HOLIDAY_FETCH,
		holiday: holiday,
	};
}

function _deleteHoliday(holidays: IPage<IHoliday>): Action {
	return {
		type: HOLIDAY_DELETE,
		holidays: holidays,
	};
}

export function holidayReset(): Action {
	return { type: HOLIDAY_RESET };
}

export function getHolidays(from: number, count: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchHolidays((json as IJson)?.data)));
		return axios().get(`${basePath}/from/${from}/count/${count}`);
	};
}

export function getHoliday(id: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchHoliday((json as IJson)?.data)));
		return axios().get(`${basePath}/${id}`);
	};
}

export function updateHoliday(holiday: Partial<IHoliday>, deleteIds: number[], addYears: number[]): AsyncAction {
	const complexUpdate: IComplexUpdate = { addItems: addYears, deleteItems: deleteIds };
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchHoliday((json as IJson)?.data)));
		return axios().patch(`${basePath}/${holiday?.Id}`, { holiday: holiday, exceptions: complexUpdate });
	};
}

export function createHoliday(holiday: Partial<IHoliday>, deleteIds: number[], addYears: number[]): AsyncAction {
	const complexUpdate: IComplexUpdate = { addItems: addYears, deleteItems: deleteIds };
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchHoliday((json as IJson)?.data)));
		return axios().post(basePath, { holiday: holiday, exceptions: complexUpdate });
	};
}

export function deleteHoliday(id: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchHoliday((json as IJson)?.data)));
		return axios().delete(`${basePath}/${id}`);
	};
}
