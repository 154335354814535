import uuid from 'uuid';

import { EDelegationDictionaryType } from '@sac-tt/tasker-types';
import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	getTypeKey: (item: EDelegationDictionaryType) => `Type_${item}_${uuid()}`,
	fixed: 'fixed' as const,
};

export const Translation = {
	pl: {
		OrganizationPanel: {
			TabsContent: {
				Delegation: {
					TabsContent: {
						CustomDictionariesConfiguration: {
							CustomDictionaryForm: {
								nameLabel: 'Nazwa',
								typeLabel: 'Rodzaj słownika',
								type1: 'Rodzaje załączników',
								type2: 'Rodzaje środków transportu',
								descriptionLabel: 'Opis',
								fieldsRequired: 'Pola wymagane',
								cancel: 'Anuluj',
								save: 'Zapisz',
								createSuccess: 'Słownik został utworzony pomyślnie',
								createError: 'Wystąpił błąd podczas tworzenia słownika',
								updateSuccess: 'Słownik został zaktualizowany pomyślnie',
								updateError: 'Wystąpił błąd podczas aktualizacji słownika',
								missing:
									'Nie podano wszystkich wymaganych pól w formularzu. Proszę uzupełnić wszystkie wymagane pola.',
							},
						},
					},
				},
			},
		},
	},
	en: {
		OrganizationPanel: {
			TabsContent: {
				Delegation: {
					TabsContent: {
						CustomDictionariesConfiguration: {
							CustomDictionaryForm: {
								nameLabel: 'Name',
								typeLabel: 'Dictionary type',
								type1: 'Attachment types',
								type2: 'Means of transport types',
								descriptionLabel: 'Description',
								fieldsRequired: 'Fields required',
								cancel: 'Cancel',
								save: 'Save',
								createSuccess: 'The dictionary has been created successfully',
								createError: 'An error occurred while creating the dictionary',
								updateSuccess: 'The dictionary has been updated successfully',
								updateError: 'An error occurred while updating the dictionary',
								missing:
									'Not all required fields have been provided in the form. Please fill in all required fields.',
							},
						},
					},
				},
			},
		},
	},
};
