import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationSettlement: {
						SettlementAlternative: {
							heading: 'Tabela rozliczeń jest pusta',
							description:
								'Uzupełnij formularz delegacji, po uzupełnieniu wszystkich dat i lokacji w ścieżce delegacji zostaną wygenerowane wiersze rozliczeń zgodnie z ustawieniami.',
						},
					},
				},
			},
		},
	},
	en: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationSettlement: {
						SettlementAlternative: {
							heading: 'Settlement table is empty',
							description:
								'Fill in the delegation form, after filling in all dates and locations in the delegation path, settlement rows will be generated according to the settings.',
						},
					},
				},
			},
		},
	},
};
