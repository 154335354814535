import { isMobile, isRtl } from '@sac-tw2/tasker-widgets2/dist/utils';
import { getGlobalStyles } from '@sac-tw2/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(
	(theme: Theme) => ({
		...getGlobalStyles(),
		root: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'stretch',
			cursor: 'default',
		},
		scrollContainer: {
			paddingRight: '6px !important',
		},
		flex: {
			alignItems: 'center',
			display: 'flex',
			justifyContent: 'space-between',
		},
		activeTask: {
			backgroundColor: theme.palette.background.paper,
			borderRadius: 25,
			padding: 10,
		},
		flexActiveTask: {
			border: '1px solid #bdbdbd',
			backgroundColor: theme.palette.background.paper,
			borderRadius: 15,
			paddingLeft: 10,
			height: '40px',
			background: 'yellow',
		},
		flexActiveTaskWidth: {
			border: '1px solid #bdbdbd',
			backgroundColor: theme.palette.background.paper,
			borderRadius: 15,
			paddingLeft: 10,
			height: '40px',
			background: 'yellow',
		},
		flexTask: {
			border: '1px solid #bdbdbd',
			backgroundColor: theme.palette.background.paper,
			borderRadius: 15,
			paddingLeft: 10,
			height: '40px',
			background: 'yellow',
		},
		padding: {
			padding: theme.spacing(1, 2),
		},
		info_tooltip: {
			padding: theme.spacing(1, 1, 1, 1),
		},
		clock: {
			padding: theme.spacing(1, 2, 1, 1),
		},
		titleWorkingOn: {
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
		},
		notificationsButton: {
			marginLeft: theme.spacing(1),
		},
		badge: {
			color: theme.palette.green.light,
			backgroundColor: theme.palette.background.paper,
			border: `1px solid`,
		},
		divider: {
			width: '2px',
			backgroundColor: theme.palette.lightGray.light,
			height: '80%',
			marginTop: '4px',
			marginBottom: '2px',
		},
		widthXL: {
			width: '500px',
		},
		widthL: {
			width: '465px',
		},
		widthLEdit: {
			width: '435px',
		},
		fullFlexWidth: {
			display: 'flex',
			width: '100%',
		},
		hotelIcon: {
			color: theme.palette.lightGray.light,
			paddingTop: '3px',
		},
		avatarContainer: {
			alignItems: 'center',
			paddingBottom: '4px',
		},
		titleContainer: {
			maxWidth: '320px',
			width: isMobile() ? '40%' : '75%',
			flexDirection: isRtl() ? 'row-reverse' : 'row',
		},
		activeIssue: {
			WebkitBoxShadow: 'inset 0px 0px 20px -9px rgb(0 213 36)',
			MozBoxShadow: 'inset 0px 0px 20px -9px rgb(0 213 36)',
			boxShadow: 'inset 0px 0px 20px -9px rgb(0 213 36)',
			border: '2px solid rgb(0 213 36 / 22%)',
		},
	}),
	{ name: `Dashboard_WorklogStarter_WorklogTile` }
);
