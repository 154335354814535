import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	small: 'small' as const,
	id: 'Id',
	bottomEnd: 'bottom-end' as const,
};

export const Translation = {
	pl: {
		ProjectDetails: {
			ProjectMembersConfiguration: {
				ProjectUsers: {
					ProjectUser: {
						ActionsCell: {
							saveChanges: 'Zapisz zmiany',
							deleteUserFromProject: 'Usuń użytkownika z projektu',
							abandonChanges: 'Porzuć zmiany',
						},
					},
				},
			},
		},
	},
	en: {
		ProjectDetails: {
			ProjectMembersConfiguration: {
				ProjectUsers: {
					ProjectUser: {
						ActionsCell: {
							saveChanges: 'Save changes',
							deleteUserFromProject: 'Remove the user from the project',
							abandonChanges: 'Abandon changes',
						},
					},
				},
			},
		},
	},
};
