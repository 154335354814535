import axios from 'axios';
import { gapi } from 'gapi-script';
import React, { useEffect } from 'react';
import { Provider as StoreProvider } from 'react-redux';

import { registerLocales } from '@sac-tw2/tasker-widgets2/dist/utils';

import { GoogleOAuthProvider } from '@react-oauth/google';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'react-datepicker/dist/react-datepicker.css';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './utils/assets/scss/main.scss';
import './utils/mixins/chartjs';
import './utils/mixins/moment';
import './utils/mixins/prismjs';
import './utils/mixins/validate';

import ThemeProviderWrapper from '@/layouts/ThemeProviderWrapper';

import { configureStore } from './store';

export const store = configureStore();

function App(): JSX.Element {
	registerLocales();
	//TODO types backend
	useEffect(() => {
		gapi.load('client:auth2', start);
	}, []);

	function start() {
		gapi.client.init({
			clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
			scope: process.env.REACT_APP_GOOGLE_SCOPE,
		});
	}

	axios.defaults.withCredentials = false;

	if (!process.env.REACT_APP_GOOGLE_CLIENT_ID) throw new Error('REACT_APP_GOOGLE_CLIENT_ID is not defined');
	const clientId: string = process.env.REACT_APP_GOOGLE_CLIENT_ID;

	return (
		<StoreProvider store={store}>
			<GoogleOAuthProvider clientId={clientId}>
				<ThemeProviderWrapper />
			</GoogleOAuthProvider>
		</StoreProvider>
	);
}

export default App;
