import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Permission } from '@sac-tt/tasker-types';
import { VisibilityControl } from '@sac-tw2/tasker-widgets2/dist/widgets/VisibilityControl';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

import { comment_delete } from '@/store/actions';
import { IReducerState } from '@/store/reducers/types';
import { useTrans } from '@/utils/hooks/useTrans';
import { CheckRoles } from '@/utils/permissions';

import { Props } from './model';
import { Resources } from './resources';
import { useStyles } from './styles';

export const Footer = (props: Props): JSX.Element => {
	const { comment, onClickEdit, onDelete, isFakeComment } = props;
	const classes = useStyles();
	const dispatch = useDispatch();
	const { tString } = useTrans(
		'IssueModal.IssueModalContent.IssueDetailsPage.TabsContent.IssueComments.Comments.CommentCard.Footer'
	);

	const [canDelete, setCanDelete] = useState<boolean>(false);
	const [canEdit, setCanEdit] = useState<boolean>(false);

	const currentUserPermissions = useSelector((state: IReducerState) => state.Session?.permissions);
	const currentProfile = useSelector((state: IReducerState) => state.Session?.profile);

	useEffect(() => {
		const isOwner = currentProfile?.Id === comment?.AddedByTaskerUserId;
		const canDelete = CheckRoles(currentUserPermissions, Permission.DeleteComment);
		const canEdit = CheckRoles(currentUserPermissions, Permission.UpdateComment);
		setCanDelete(canDelete && isOwner);
		setCanEdit(canEdit && isOwner);
	}, [comment?.AddedByTaskerUserId]);

	const onClickDelete = () => {
		if (!comment?.Id) return;
		if (onDelete) {
			onDelete(comment?.Id);
			return;
		}

		if (!isFakeComment) dispatch(comment_delete(comment?.Id));
	};

	return (
		<div className={classes.content}>
			<VisibilityControl condition={!isFakeComment}>
				<VisibilityControl condition={canDelete && !comment?.DeletedByTaskerUserId}>
					<Tooltip title={tString('delete')}>
						<IconButton
							size={Resources.GlobalResources.small}
							onClick={onClickDelete}
							className={classes.deleteIcon}
						>
							<DeleteIcon fontSize={Resources.GlobalResources.small} />
						</IconButton>
					</Tooltip>
				</VisibilityControl>
				<VisibilityControl condition={canEdit}>
					<Tooltip title={tString('edit')}>
						<IconButton
							size={Resources.GlobalResources.small}
							onClick={onClickEdit}
							color={Resources.GlobalResources.primary}
							className={classes.editIcon}
						>
							<EditIcon />
						</IconButton>
					</Tooltip>
				</VisibilityControl>
			</VisibilityControl>
		</div>
	);
};
