import { ITeamData } from '@sac-tt/tasker-types';
import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	teamName: 'teamName' as const,
	teamDescription: 'teamDescription' as const,
	addedTeamMembers: 'addedTeamMembers' as const,
	menuPosition: 'menuPosition',
	fixed: 'fixed',
};

export const Translation = {
	pl: {
		Settings: {
			WidgetsConfigurationMain: {
				ProfileSelectorConfiguration: {
					PopupContent: {
						teamCreated: 'Stworzono listę',
						teamUpdated: 'Zaktualizowano listę',
						teamCreation: 'Tworzenie listy',
						teamEdit: 'Edycja listy',
						teamNameLabel: 'Nazwa listy',
						teamDescLabel: 'Opis listy',
						saveChanges: 'Zapisz',
						cancel: 'Anuluj',
						teamMembers: 'Użytkownicy',
						selectUsers: 'Wybierz użytkowników',
					},
				},
			},
		},
	},

	en: {
		Settings: {
			WidgetsConfigurationMain: {
				ProfileSelectorConfiguration: {
					PopupContent: {
						teamCreated: 'Team has been created',
						teamUpdated: 'The team has been updated',
						teamCreation: 'Team creation',
						teamEdit: 'Team edit',
						teamNameLabel: 'Team name',
						teamDescLabel: 'Team description',
						saveChanges: 'Save',
						cancel: 'Cancel',
						teamMembers: 'Team members',
						selectUsers: 'Select users',
					},
				},
			},
		},
	},
};

export const initialFormData: ITeamData = {
	teamName: '',
	teamDescription: '',
	addedTeamMembers: [],
};
