import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	RadioButtonGroup: {
		with: 'with',
		withOut: 'without',
		off: 'off',
	},
	fixed: 'fixed' as const,
	...GlobalResources,
};

export const Translation = {
	pl: {
		SearchPanel: {
			Filter: {
				IssueTabFilter: {
					TagsFilter: {
						tagTitle: 'Tagi',
						Group: {
							label: 'Grupa tagów',
						},
						label: 'Tag',
						tagGroupLabel: 'Grupa tagów',
						Comment: {
							withComment: 'Komentarze',
							onlyWithComments: 'Tylko z komentarzami',
							onlyWithoutComments: 'Tylko bez komentarzy',
							off: 'Wyłączone',
						},
					},
				},
			},
		},
	},
	en: {
		SearchPanel: {
			Filter: {
				IssueTabFilter: {
					TagsFilter: {
						tagTitle: 'Tags',
						Group: {
							label: 'Tag group',
						},
						label: 'Tag',
						tagGroupLabel: 'Tag group',
						Comment: {
							withComment: 'Comments',
							onlyWithComments: 'Only with comments',
							onlyWithoutComments: 'Only with out comments',
							off: 'Off',
						},
					},
				},
			},
		},
	},
};
