import clsx from 'clsx';
import { useState } from 'react';
import { matchPath, useLocation } from 'react-router';
import { NavLink as RouterLink } from 'react-router-dom';

import { VisibilityControl } from '@sac-tw2/tasker-widgets2/dist/widgets';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Collapse, ListItem } from '@mui/material';

import { useTrans } from '@/utils/hooks/useTrans';
import { collapseEndListener } from '@/utils/utils';

import { Props } from './model';
import { Resources } from './resources';
import { useStyles } from './style';

export const CreateNavItem = (item: any, depth = 0): JSX.Element => {
	const pathname = useLocation().pathname;

	const open = matchPath(pathname, {
		path: item.href,
		exact: false,
	});

	return <NavItem depth={depth} open={Boolean(open)} item={item} key={item.title} />;
};

export const NavItem = (props: Props): JSX.Element => {
	const { open: startOpen, item, depth, className, isStoryBook = false } = props;
	const { icon: Icon, title, href, items: children, label: Label } = item;
	const classes = useStyles();
	const [open, setOpen] = useState<boolean>(startOpen);
	const { t } = useTrans('Nav');
	const handleToggle = () => setOpen(prevOpen => !prevOpen);
	const paddingLeft = depth > 0 ? 32 + 8 * depth : 8;
	const style = { paddingLeft };

	if (children) {
		return (
			<ListItem className={clsx(classes.item, className)} disableGutters>
				<Button
					color={Resources.GlobalResources.inherit}
					className={clsx(classes.fullWidth, classes.button)}
					onClick={handleToggle}
					style={style}
				>
					<VisibilityControl condition={Icon}>
						<Icon className={clsx(classes.flexYCenter, classes.icon)} />
					</VisibilityControl>
					{t(title)}
					<VisibilityControl
						condition={open}
						alternative={
							<ExpandMoreIcon className={classes.expandIcon} color={Resources.GlobalResources.inherit} />
						}
					>
						<ExpandLessIcon className={classes.expandIcon} color={Resources.GlobalResources.inherit} />
					</VisibilityControl>
				</Button>
				<Collapse in={open} addEndListener={collapseEndListener}>
					<ul>{children.map((child: any) => CreateNavItem(child, depth + 1))}</ul>
				</Collapse>
			</ListItem>
		);
	}

	const propsButton: any = {
		activeClassName: classes.active,
		className: clsx(classes.buttonLeaf, `${Resources.dep}${depth}`),
		component: !isStoryBook ? RouterLink : undefined,
		style: style,
		to: href,
	};

	let ComponentLabel: () => JSX.Element | null = () => null;
	if (Label) {
		// eslint-disable-next-line react/display-name
		ComponentLabel = () => (
			<span className={clsx(classes.label, classes.flexYCenter)}>
				<Label />
			</span>
		);
	}

	return (
		<ListItem disableGutters key={title} className={clsx(classes.flexRow, classes.itemLeaf, className)}>
			<Button color={Resources.GlobalResources.inherit} {...propsButton}>
				<VisibilityControl condition={Icon}>
					<Icon className={clsx(classes.flexYCenter, classes.icon)} />
				</VisibilityControl>
				{t(title)}
				<VisibilityControl condition={ComponentLabel() != null}>
					<ComponentLabel />
				</VisibilityControl>
			</Button>
		</ListItem>
	);
};
