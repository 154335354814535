import uuid from 'uuid';

import { ITaskerUser } from '@sac-tt/tasker-types';
import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	tasker: 'Tasker',
	disabled: 'disabled' as const,
	darkMode: 'rdg-dark',
	lightMode: 'rdg-light',

	primary25: 'rgba(75,253,0,0.71)',
	primary: '#33b35c',
	empty: '',
	selectEmpty: '-',
	mailURL: (email: string, subject: string) => `mailto:${email}?"&subject="${subject}"`,
	defaultOption: { value: {}, label: '' },
	selectProps: {
		menuPosition: 'fixed',
		isSearchable: false,
		closeMenuOnSelect: false,
		valuesLimit: 10,
	},
	uuidTaskerUser: (x: Partial<ITaskerUser>) => `Tasker_User_${uuid()}_${x?.Id}`,
};

export const Translation = {
	pl: {
		ProjectDetails: {
			Overview: {
				ProjectInfo: {
					ProjectOwnerManager: {
						delete: 'Zaznacz do usunięcia',
						none: 'Brak',
					},
				},
			},
		},
	},
	en: {
		ProjectDetails: {
			Overview: {
				ProjectInfo: {
					ProjectOwnerManager: {
						delete: 'Mark to delete',
						none: 'None',
					},
				},
			},
		},
	},
};
