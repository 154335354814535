import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		ProjectDetails: {
			ProjectMembersConfiguration: {
				CommonCells: {
					UserProjectRoles: {
						Placeholder: {
							addRole: 'Dodaj role',
						},
					},
				},
			},
		},
	},
	en: {
		ProjectDetails: {
			ProjectMembersConfiguration: {
				CommonCells: {
					UserProjectRoles: {
						Placeholder: {
							addRole: 'Add role',
						},
					},
				},
			},
		},
	},
};
