import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		Reckon: {
			Agreements: {
				Shared: {
					components: {
						InputsContainer: {
							SalaryInputsContainerLabel: {
								salary: 'Wynagrodzenie',
								untilTheEnd: 'Do zakończenia umowy',
							},
						},
					},
				},
			},
		},
	},

	en: {
		Reckon: {
			Agreements: {
				Shared: {
					components: {
						InputsContainer: {
							SalaryInputsContainerLabel: {
								salary: 'Salary',
								untilTheEnd: 'Until the end of the contract',
							},
						},
					},
				},
			},
		},
	},
};
