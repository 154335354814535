import { GlobalStyles } from '@sac-tw2/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(
	(theme: Theme) => ({
		...GlobalStyles,

		avatar: {
			width: '26px',
			height: '26px',
			marginRight: theme.spacing(1),
		},

		nameContainer: {
			maxWidth: '100px',
		},

		tagContainer: {
			borderRadius: '20px',
			display: 'flex',
		},

		tag: {
			borderRadius: '20px',
			padding: '1px 5px',
			fontSize: '12px',
			// marginLeft: theme.spacing(0.25),
			// width: 'max-content',
			maxWidth: '60px',
		},
	}),
	{ name: `ProjectDetails_ProjectMembersConfiguration_CommonCells_UserProjectRoles_ValueContainer` }
);
