import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationAcceptance: {
						DelegationAcceptanceComments: {
							EditCommentEditor: {
								save: 'Zapisz komentarz',
								cancel: 'Anuluj',
								edit: 'Edycja komentarza',
							},
						},
					},
				},
			},
		},
	},
	en: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationAcceptance: {
						DelegationAcceptanceComments: {
							EditCommentEditor: {
								save: 'Save comment',
								cancel: 'Cancel',
								edit: 'Edit comment',
							},
						},
					},
				},
			},
		},
	},
};
