import { getGlobalStyles } from '@sac-tw2/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(
	() => {
		return {
			...getGlobalStyles(),
		};
	},
	{ name: `IssueModal_IssueModalContent_IssueDetailsPage_TabsContent_IssueComments` }
);
