import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

const address = process?.env?.REACT_APP_BACKEND_ADDRESS ?? '';

export const Resources = {
	urlDownload: (id: number) => `${process.env.REACT_APP_PROTOCOL}://${address}/Attachment/streamable/${id}`,
	...GlobalResources,
};

export const Translation = {
	pl: {
		PortalIssue: {
			IssueColumn: {
				TabsContent: {
					Attachment: {
						deleteSuccess: 'Usunięto pomyślnie',
						uploadSuccess: 'Pomyślnie wrzucono pliki',
					},
				},
			},
		},
	},
	en: {
		PortalIssue: {
			IssueColumn: {
				TabsContent: {
					Attachment: {
						deleteSuccess: 'Delete success',
						uploadSuccess: 'Upload success',
					},
				},
			},
		},
	},
};
