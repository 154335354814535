import { getGlobalStyles } from '@sac-tw2/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(
	() => ({
		...getGlobalStyles(),
		menu: {
			maxWidth: '270px',
			'& .MuiMenu-list': {
				paddingTop: '4px',
				paddingBottom: '4px',
				color: 'red',
			},
		},
	}),
	{ name: 'SearchBar_Search_SearchBy' }
);
