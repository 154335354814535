import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	Email: 'Email',
	mailURL: (email: string) => `mailto:${email}?"&subject="Tasker"`,
};

export const Translation = {
	pl: {
		ProjectDetails: {
			ProjectMembersConfiguration: {
				CommonCells: {
					UserCell: {
						noEmail: 'There is no email',
					},
				},
			},
		},
	},
	en: {
		ProjectDetails: {
			ProjectMembersConfiguration: {
				CommonCells: {
					UserCell: {
						noEmail: 'Brak maila',
					},
				},
			},
		},
	},
};
