import clsx from 'clsx';
import parse from 'html-react-parser';
import { useEffect, useState } from 'react';

import { IComment } from '@sac-tt/tasker-types';
import { VisibilityControl } from '@sac-tw2/tasker-widgets2';
import { getFullNameNew } from '@sac-tw2/tasker-widgets2/dist/utils';

import { Typography } from '@mui/material';

import { useTrans } from '@/utils/hooks/useTrans';
import { getFirst } from '@/utils/utils';

import { Footer } from './Footer';
import { Header } from './Header';
import { Props } from './model';
import { Resources } from './resources';
import { useStyles } from './styles';

export const CommentCard = (props: Props): JSX.Element => {
	const { comment, onEditComment, onDelete, isFakeComment, isOnIssue = true } = props;
	const { t } = useTrans(
		'IssueModal.IssueModalContent.IssueDetailsPage.TabsContent.IssueComments.Comments.CommentCard'
	);
	const classes = useStyles();

	const [commentState, setCommentState] = useState<Partial<IComment>>(comment);

	useEffect(() => {
		setCommentState(comment);
	}, [comment]);

	const onClickEditWrapper = () => {
		if (onEditComment) onEditComment(comment);
	};

	return (
		<div className={classes.root}>
			<div className={classes.flexRow}>
				<Header
					avatar={comment?.AddedByTaskerUser?.AvatarPath ?? Resources.GlobalResources.empty}
					user={comment?.AddedByTaskerUser ?? {}}
					createdAt={new Date(comment?.CreatedDateTime as string)}
					nickName={getFullNameNew(comment?.AddedByTaskerUser)}
					isDeleted={!!comment?.DeletedByTaskerUserId}
					isPortal={getFirst(comment?.IssueComment ?? [])?.IsPortal ?? false}
					isOnIssue={isOnIssue}
				>
					<Footer
						isFakeComment={isFakeComment}
						comment={commentState}
						onClickEdit={onClickEditWrapper}
						onDelete={onDelete}
					/>
				</Header>
				<VisibilityControl condition={!!isFakeComment}>
					<Typography
						variant={Resources.GlobalResources.h6}
						className={clsx(classes.defaultCursor, classes.inlineFlex, classes.pinned)}
						color={Resources.GlobalResources.primary}
					>
						{t('pinned')}
					</Typography>
				</VisibilityControl>
			</div>
			<div className={classes.content}>{parse(commentState?.CommentHtml ?? Resources.GlobalResources.empty)}</div>
		</div>
	);
};
