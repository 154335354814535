import uuid from 'uuid';

import { ECurrency } from '@sac-tt/tasker-types';
import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	getCurrencyKey: (item: ECurrency) => `Currency_${item}_${uuid()}`,
	fixed: 'fixed' as const,
	number: 'number' as const,
};

export const Translation = {
	pl: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationDetails: {
						DelegationAdvances: {
							header:
								'Zaliczka to kwota wypłacona pracownikowi z przeznaczeniem na wydatki pracodawcy (na poczet wydatków z wiązanych z podróżą służbową), z której musi się on rozliczyć po powrocie z podróży służbowej. Dodaj ją, jeśli uprzednio wypłacono zaliczkę na podróż służbową.',
							add: 'Dodaj zaliczkę',
							include: 'Uwzględnij zaliczki',
							noAdvances: 'Brak dodanych zaliczek',
							value: 'Wartość',
							currency: 'Waluta',
							confirm: 'Czy na pewno chcesz usunąć ten rekord?',
							noInclude: 'Brak uwzględnionych zaliczek.',
						},
					},
				},
			},
		},
	},
	en: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationDetails: {
						DelegationAdvances: {
							header:
								"An advance is an amount paid to an employee for the employer's expenses (for expenses related to a business trip), which the employee must settle upon returning from a business trip. Add it if an advance for a business trip has been previously paid.",
							add: 'Add advance',
							include: 'Include advances',
							noAdvances: 'No advances added',
							value: 'Value',
							currency: 'Currency',
							confirm: 'Are you sure you want to delete the record?',
							noInclude: 'Advance payments are not included in this delegation.',
						},
					},
				},
			},
		},
	},
};
