import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		ProjectDetails: {
			ProjectMembersConfiguration: {
				AddUsersToProjectPopupContent: {
					StepperContainer: {
						firstStep: 'Wybór użytkowników',
						secondStep: 'Dobór ról',
						thirdStep: 'Zapisanie zmian',
					},
				},
			},
		},
	},
	en: {
		ProjectDetails: {
			ProjectMembersConfiguration: {
				AddUsersToProjectPopupContent: {
					StepperContainer: {
						firstStep: 'Choose users',
						secondStep: 'Choose project roles',
						thirdStep: 'Save changes',
					},
				},
			},
		},
	},
};
