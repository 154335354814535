import { AsyncAction } from '@sac-rp/redux-promisify';
import { EDelegationRole, IDelegationRole } from '@sac-tt/tasker-types';

import { IJson } from '@/store/ITypes';
import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

const basePath = 'DelegationAcceptance';

export const ACCEPTANCE_IS_REQUIRED: ActionType = 'ACCEPTANCE_IS_REQUIRED';
export const DELEGATION_ACCEPTANCE: ActionType = 'DELEGATION_ACCEPTANCE';

function _getAcceptanceRequired(data: IDelegationRole[]): Action {
	return {
		type: ACCEPTANCE_IS_REQUIRED,
		data: data,
	};
}

function _createAcceptance(data: IDelegationRole[]): Action {
	return {
		type: DELEGATION_ACCEPTANCE,
		data: data,
	};
}

export function delegationAcceptanceAllowed_Fetch(delegationId: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_getAcceptanceRequired((json as IJson).data)));
		return axios().get(`${basePath}/${delegationId}`);
	};
}

export function delegationAcceptance_Create(delegationId: number, roleId: EDelegationRole): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_createAcceptance((json as IJson).data)));
		return axios().post(`${basePath}/${delegationId}/${roleId}`);
	};
}
