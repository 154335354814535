import { getGlobalStyles } from '@sac-tw2/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(
	(theme: Theme) => {
		return {
			...getGlobalStyles(),

			tag: {
				borderRadius: theme.spacing(2.5),
				padding: theme.spacing(0.375, 1.25),
				fontSize: theme.spacing(1.5),
				marginLeft: theme.spacing(0.55),
				width: 'max-content',
			},
		};
	},
	{ name: `IssueModal_IssueModalContent_IssueDetailsPage_MainIssueData_DependsInput_Option` }
);
