import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	mask: '____/__/__',
	noDate: 'Incorrect date',
	...GlobalResources,
};

export const Translation = {
	pl: {
		ProjectDetails: {
			Overview: {
				ProjectInfo: {
					GeneralLeft: {
						ProjectCreatedLabel: {
							creationDate: 'Data stworzenia:',
							endDate: 'Data zakończenia:',
							startDate: 'Data rozpoczęcia:',
						},
					},
				},
			},
		},
	},
	en: {
		ProjectDetails: {
			Overview: {
				ProjectInfo: {
					GeneralLeft: {
						ProjectCreatedLabel: {
							creationDate: 'Creation date:',
							endDate: 'End date:',
							startDate: 'Start date:',
						},
					},
				},
			},
		},
	},
};
