import { AsyncAction } from '@sac-rp/redux-promisify';
import { ITaskerObject } from '@sac-tt/tasker-types';

import { IJson } from '@/store/ITypes';
import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

export const TASKER_OBJECTS_FETCH: ActionType = 'TASKER_OBJECTS_FETCH';

const basePath = 'TaskerObject';

function _fetchTaskerObjects(objects: ITaskerObject[]): Action {
	return {
		type: TASKER_OBJECTS_FETCH,
		objects,
	};
}

export function get_taskerObjects(): AsyncAction {
	return function(context, dispatch) {
		context.then(data => dispatch(_fetchTaskerObjects((data as IJson).data)));
		return axios().get(basePath);
	};
}
