import { IWorkingUserNEW } from '@sac-tt/tasker-types';

import * as actionTypes from '@/store/actions';
import { ObjectAction } from '@/store/types';

export const State = {
	workingUsers: [] as IWorkingUserNEW[],
	workingUsersCount: 0 as number,
};
export type IWorkingUsersListState = typeof State;

const workingUsersListReducer = (state = State, action: ObjectAction): IWorkingUsersListState => {
	switch (action?.type) {
		case actionTypes.WORKINGUSER_FETCH: {
			return {
				...state,
				workingUsers: action.workingUsers,
				workingUsersCount: action.workingUsersCount,
			};
		}
		default: {
			return state;
		}
	}
};

export default workingUsersListReducer;
