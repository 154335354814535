import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	ariaValue: 'primary checkbox' as const,
	hashIssue: '#',
	https: 'https://',
	http: 'http://',
};

export const Translation = {
	pl: {
		DelegationList: {
			DelegationModal: {
				DelegationModalTitle: {
					newDelegation: 'Nowa delegacja',
					editDelegation: 'Edycja delegacji',
				},
			},
		},
	},
	en: {
		DelegationList: {
			DelegationModal: {
				DelegationModalTitle: {
					newDelegation: 'New delegation',
					editDelegation: 'Edit delegation',
				},
			},
		},
	},
};
