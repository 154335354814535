import translationReduced from '@sac-t/translate/dist/ar/default.json';
import { LangResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/languageResources';

import { multipleMerge } from '@/locales/utils';

const translation = multipleMerge([translationReduced.FRONTEND, LangResources.ar]);
const widgets = multipleMerge([translationReduced.WIDGETS, LangResources.ar]);

export default {
	ar_default: {
		FRONTEND: translation,
		WIDGETS: widgets,
		BACKEND: translationReduced.BACKEND,
	},
};
