import { IIssue, IWorkLog } from '@sac-tt/tasker-types';

import * as actionTypes from '@/store/actions';
import { ObjectAction } from '@/store/types';

export const State = {
	issuesOptions: [] as IIssue[],
	issuesOptionsCount: 0 as number,
	workLogByIssue: [] as IWorkLog[],
	workLogByIssueCount: 0 as number,
	issueTimeModal: {} as IIssue,
	createNext: 0,
};
export type ITimeAssignEditorState = typeof State;

const timeAssignReducer = (state = State, action: ObjectAction): ITimeAssignEditorState => {
	switch (action?.type) {
		case actionTypes.ISSUE_FETCH_OPTIONS: {
			return {
				...state,
				issuesOptions: action.issues?.Data,
				issuesOptionsCount: action?.issues?.Count,
			};
		}
		case actionTypes.WORKLOG_DELETE: {
			return {
				...state,
				workLogByIssue: state.workLogByIssue.filter(worklog => worklog?.Id != action?.id),
			};
		}
		case actionTypes.WORKLOG_FETCH_BY_ISSUE_NEW: {
			return {
				...state,
				workLogByIssue: action?.worklogs,
				workLogByIssueCount: action?.count,
			};
		}
		case actionTypes.ISSUE_RESET: {
			return {
				...state,
				issueTimeModal: {} as IIssue,
			};
		}
		case actionTypes.ISSUE_FETCH_ONE_NEW: {
			return {
				...state,
				issueTimeModal: action?.data?.Issue,
			};
		}
		case actionTypes.ISSUE_TIME_MODAL_LOCAL: {
			return {
				...state,
				issueTimeModal: action?.issue,
			};
		}
		case actionTypes.WORKLOG_CREATE_NEW: {
			return {
				...state,
				issueTimeModal: action?.issue,
				createNext: state?.createNext + action?.isMultiple ? 1 : 10,
			};
		}
		case actionTypes.ISSUE_MODAL_ISSUE_OPTIONS: {
			return {
				...state,
				issuesOptions: action?.data.Data,
				issuesOptionsCount: action?.data.Count,
			};
		}
		default: {
			return state;
		}
	}
};

export default timeAssignReducer;
