import { GlobalStyles } from '@sac-tw2/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(
	(theme: Theme) => ({
		...GlobalStyles,

		// icon: {
		// 	// color: '#d2d2d2',
		// 	color: '#263238',
		// 	padding: '2px',
		// 	marginLeft: theme.spacing(1),
		// 	backgroundColor: 'rgba(230,230,230)',

		// 	'&:hover': {
		// 		backgroundColor: 'rgba(200,200,200)',
		// 	},

		// 	svg: {
		// 		width: '20px',
		// 		height: '20px',
		// 	},
		// },

		icon: {
			fontSize: '15px',
		},

		dots: {
			position: 'relative',
			top: '3px',
			// marginRight: '4px',
			margin: '0px 6px 0px 2px',
		},

		counter: {
			padding: theme.spacing(0.3),
			backgroundColor: '#5c6277',
			color: 'white',
			borderRadius: '3px',
			marginLeft: theme.spacing(1),
		},

		counterText: {
			color: 'white',
			fontSize: '15px',
		},
	}),
	{ name: `ProjectDetails_ProjectMembersConfiguration_CommonCells_UserProjectRoles_ValueContainer` }
);
