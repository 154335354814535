import { isRtl } from '@sac-tw2/tasker-widgets2/dist/utils';
import { getGlobalStyles } from '@sac-tw2/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(
	(theme: Theme) => ({
		...getGlobalStyles(),
		root: {
			display: 'flex',
			flexDirection: 'row',
			height: '100%',
			overflow: 'hidden',
		},
		textSpan: {
			fontSize: '10px',
		},
		text: {
			justifyContent: 'center',
			alignSelf: 'center',
			marginTop: 'auto',
			marginBottom: 'auto',
			width: '100%',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			color: theme.palette.text.primary,
		},
		avatar: {
			marginRight: '5px',
			marginTop: '5px',
			width: 20,
			height: 20,
		},
		title: {
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			fontWeight: 500,
			display: 'flex',
			marginRight: isRtl() ? theme.spacing(1) : 'unset',
			justifyContent: isRtl() ? 'flex-end' : 'unset',
			flexWrap: 'wrap',
		},
		avatarContainer: {
			alignItems: 'center',
			paddingBottom: '4px',
			margin: 'auto',
		},
		url: {
			display: 'flex',
			width: '38px',
			height: '38px',
		},
	}),
	{ name: `Dashboard_WorklogStarter_SingleIssue` }
);
