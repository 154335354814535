import { t } from 'i18next';

import { ICustomer, IProject } from '@sac-tt/tasker-types';
import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

import DateRangeIcon from '@mui/icons-material/DateRange';
import ViewAgendaIcon from '@mui/icons-material/ViewAgendaOutlined';
import ViewConfigIcon from '@mui/icons-material/ViewComfyOutlined';
import ViewDayIcon from '@mui/icons-material/ViewDayOutlined';
import ViewWeekIcon from '@mui/icons-material/ViewWeekOutlined';

import { CalendarView } from '../resources';

export const Resources = {
	dayGridMonthName: 'dayGridMonth',
	timeGridWeek: 'timeGridWeek',
	timeGridDay: 'timeGridDay',
	listWeek: 'listWeek',
	month: 'month' as const,
	week: 'week' as const,

	idV2New: 'Id' as keyof IProject,
	idNew: 'Id' as keyof ICustomer,
	customerIdNew: 'CustomerId' as keyof IProject,
	...GlobalResources,
};

export const Translation = {
	pl: {
		Gant: {
			Toolbar: {
				monthLabel: 'Miesiąc',
				weekLabel: 'Tydzień',
				dayLabel: 'Dzień',
				dayLabelRange: 'Dzień',
				customRange: 'Niestandardowy',
				agendaLabel: 'Agenda',
				projectName: 'Projekt',
				customerName: 'Klient',
			},
		},
	},
	en: {
		Gant: {
			Toolbar: {
				monthLabel: 'Month',
				weekLabel: 'Week',
				dayLabel: 'Day',
				dayLabelRange: 'Day',
				customRange: 'Custom',
				agendaLabel: 'Agenda',
				projectName: 'Project',
				customerName: 'Customer',
			},
		},
	},
};

export const getViewOptions = () => {
	return [
		{
			label: t('Gant.Toolbar.monthLabel'),
			value: CalendarView.dayGridMonth,
			icon: ViewConfigIcon,
		},
		{
			label: t('Gant.Toolbar.weekLabel'),
			value: CalendarView.timeGridWeek,
			icon: ViewWeekIcon,
		},
		{
			label: t('Gant.Toolbar.dayLabel'),
			value: CalendarView.timeGridDay,
			icon: ViewDayIcon,
		},
		{
			label: t('Gant.Toolbar.agendaLabel'),
			value: CalendarView.listWeek,
			icon: ViewAgendaIcon,
		},
		{
			label: t('Gant.Toolbar.customRange'),
			value: CalendarView.dayGridCustom,
			icon: DateRangeIcon,
		},
	];
};
