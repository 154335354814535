import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	temporary: 'temporary' as const,
};

export const Translation = {
	pl: {
		IssueList: {
			Filters: {
				Options: {
					Buttons: {
						clear: 'Wyczyść',
						apply: 'Zastosuj filtry',
						saveAs: 'Zapisz jako szybki filter',
						save: 'Zapisz wybrany filter',
						simpleSave: 'Zapisz',
						name: 'Nazwa filtru',
						notFound: 'Modyfikowany filter nie został odnaleziony',
						notFilter: 'Modyfikowany filter nie został wybrany',
						quickFilterCreated: 'Stworzono filter',
						quickFilterCreatedError: 'Stworzenie filtra zakończone niepowodzeniem',
						quickFilterRequirements:
							'Szybki filtr stworzy się na podstawie już zastosowanych na liście filtrów.',
						nameIsToLong: 'Maksymalna długość nazwy jest 50 znaków. Zostanie zapisane jako: ',
					},
				},
			},
		},
	},
	en: {
		IssueList: {
			Filters: {
				Options: {
					Buttons: {
						clear: 'Clear',
						apply: 'Apply filters',
						saveAs: 'Save as quick filter',
						save: 'Save choosen filter',
						simpleSave: 'Save',
						name: 'Filters name',
						notFound: 'Filter which u try to save was not found',
						notFilter: 'Filter was not choosen',
						quickFilterCreated: 'Filter created',
						quickFilterCreatedError: 'Filter creation error',
						quickFilterRequirements: 'Quick filter will create based on already used filter on list.',
						nameIsToLong: 'Name length can be 50 characters or shorter. Will be saved as: ',
					},
				},
			},
		},
	},
};
