import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	redirect: {
		projects: '/projects',
	},
};

export const Translation = {
	pl: {
		ProjectDetails: {
			Overview: {
				ProjectInfo: {
					generalTitle: 'Ogólne',
					projectDetails: 'Szczegóły dotyczące projektu',
					projectDescription: 'Opis projektu',
					saveSuccess: 'Pomyślnie zapisano',
					deleteProject: 'Usuń projekt',
					deleteSuccess: 'Projekt usunięty pomyślnie',
					deleteIcon: 'Porzuć zmiany',
					deleteProjectTitle: 'Czy na pewno chcesz usunąć projekt?',
					deleteProjectSubtitle: 'Usunięcie projektu jest permanentne i nieodwracalne',
					deleteProjectSubmit: 'Tak',
					deleteProjectCancel: 'Nie',
				},
			},
		},
	},
	en: {
		ProjectDetails: {
			Overview: {
				ProjectInfo: {
					generalTitle: 'General',
					projectDetails: 'Project details',
					projectDescription: 'Project description',
					saveSuccess: 'Save success',
					deleteProject: 'Delete Project',
					deleteSuccess: 'Project successfully deleted',
					deleteIcon: 'Abandon the changes',
					deleteProjectTitle: 'Are you sure, you want to delete this project?',
					deleteProjectSubtitle: 'Deleting project is permanent and irreversible',
					deleteProjectSubmit: 'Yes',
					deleteProjectCancel: 'No',
				},
			},
		},
	},
};
