import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

import { IIssueFilterDisable } from './IssueTabFilter/model';

export const Resources = {
	...GlobalResources,
	defaultCollapseState: true,
	defaultSubCollapseState: true,
	defaultId: -1,
	temporary: 'temporary' as const,
};

export const Translation = {
	pl: {
		SearchBar: {
			Filter: {
				clear: 'Wyczyść',
				apply: 'Zastosuj filtry',
				issue: 'Zgłoszenie',
				project: 'Projekt',
				customer: 'Klient',
				portal: 'Portal',
			},
		},
	},
	en: {
		SearchBar: {
			Filter: {
				clear: 'Clear',
				apply: 'Apply filters',
				issue: 'Issue',
				project: 'Project',
				customer: 'Customer',
				portal: 'Portal',
			},
		},
	},
};

export const DefaultIssueEnabledFilters: IIssueFilterDisable = {
	tags: false,
	userFilters: false,
	date: false,
	all: false,
};

export const IDisableDefault = {
	issue: DefaultIssueEnabledFilters,
	project: false,
	customer: false,
	portal: false,
};
