import { AsyncAction } from '@sac-rp/redux-promisify';
import { IDelegationAcceptanceSettings } from '@sac-tt/tasker-types';

import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

import { IJson } from '../ITypes';

export const DELEGATION_ACCEPTANCE_SETTINGS_FETCH: ActionType = 'DELEGATION_ACCEPTANCE_SETTINGS_FETCH';
export const DELEGATION_ACCEPTANCE_SETTINGS_MANAGE: ActionType = 'DELEGATION_ACCEPTANCE_SETTINGS_MANAGE';

const basePath = 'DelegationAcceptanceSettings';

function _fetchDelegationAcceptanceSettings(data: IDelegationAcceptanceSettings): Action {
	return {
		type: DELEGATION_ACCEPTANCE_SETTINGS_FETCH,
		data,
	};
}

function _manageDelegationAcceptanceSettings(data: IDelegationAcceptanceSettings): Action {
	return {
		type: DELEGATION_ACCEPTANCE_SETTINGS_MANAGE,
		data,
	};
}

export function delegation_fetchAcceptanceSettings(): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchDelegationAcceptanceSettings((json as IJson).data)));
		return axios().get(`${basePath}/getMany`);
	};
}

export function delegation_manageAcceptanceSettings(data: Partial<IDelegationAcceptanceSettings>): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_manageDelegationAcceptanceSettings((json as IJson).data)));
		return axios().post(`${basePath}/manage`, data);
	};
}
