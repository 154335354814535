import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		Calendar: {
			Toolbar: {
				Header: {
					DisplayDate: {
						selectDate: 'Wybierz datę',
					},
				},
			},
		},
	},
	en: {
		Calendar: {
			Toolbar: {
				Header: {
					DisplayDate: {
						selectDate: 'Select date',
					},
				},
			},
		},
	},
};
