import {
	IDelegationAcceptanceSettings,
	IDelegationComment,
	IDelegationField,
	IDelegationHistory,
	IDictionary,
	IOrganizationParameter,
	IPage,
} from '@sac-tt/tasker-types';
import { IDelegationLimits } from '@sac-tt/tasker-types/dist/types/dataBaseTypes/delegationLimits';
import { IDelegationExchange } from '@sac-tt/tasker-types/dist/types/endPoints/delegationDetail';

import * as actionTypes from '@/store/actions';
import { ObjectAction } from '@/store/types';
import { EDropzoneFileStatus } from '@/views/DelegationsList/DelegationModal/DelegationModalContent/DelegationDetails/DelegationSettlementAttachments/Dropzone/DropzoneAttachmentRecord/model';
import { IDelegationAttachment } from '@/views/DelegationsList/DelegationModal/DelegationModalContent/DelegationDetails/DelegationSettlementAttachments/Dropzone/model';
import { IModalData } from '@/views/DelegationsList/DelegationModal/model';

export const State = {
	Dictionaries: {} as {
		Budget: IDictionary;
		Project: IDictionary;
	},
	Configs: {} as IDelegationField[],
	Limits: {} as IDelegationLimits[],
	ModalData: {} as IModalData,
	Attachments: {
		Data: [],
		Count: 0,
	} as IPage<IDelegationAttachment[]>,
	SettlementAttachments: {
		Data: [],
		Count: 0,
	} as IPage<IDelegationAttachment[]>,
	History: [] as IDelegationHistory[],
	Comments: [] as IDelegationComment[],
	GeneralSettings: [] as IOrganizationParameter[],
	AcceptanceSettings: {
		Configuration: {} as IDelegationAcceptanceSettings,
	},
};
export type IDelegationModalState = typeof State;

const delegationListReducer = (state = State, action: ObjectAction): IDelegationModalState => {
	switch (action?.type) {
		case actionTypes.GET_DELEGATION_DICTIONARIES: {
			return {
				...state,
				Dictionaries: action?.data,
			};
		}
		case actionTypes.DELEGATION_DEFAULTS_CONFIGURATION_FETCH: {
			return {
				...state,
				Configs: action?.data,
			};
		}
		case actionTypes.DELEGATION_LIMITS_FETCH: {
			return {
				...state,
				Limits: action?.data,
			};
		}
		case actionTypes.DELEGATION_FETCH_ONE: {
			const data: IDelegationExchange = action?.data;
			return {
				...state,
				History: action?.data?.history,
				Comments: action?.data?.comments,
				ModalData: {
					...state.ModalData,
					delegationId: action?.id,
					delegationDetails: {
						name: data?.delegationDetails?.name,
						project: data?.delegationDetails?.project,
						budget: data?.delegationDetails?.budget,
						description: data?.delegationDetails?.description,
						descriptionHtml: data?.delegationDetails?.descriptionHtml,
						origin: {
							visible: true,
							date: data?.delegationDetails?.origin?.date
								? new Date(data?.delegationDetails?.origin?.date)
								: null,
							location: data?.delegationDetails?.origin?.location,
							distance: data?.delegationDetails?.origin?.distance,
							transport: data?.delegationDetails?.origin?.transport,
							carPlateNumber: data?.delegationDetails?.origin?.carPlateNumber,
						},
						destination: {
							visible: true,
							date: data?.delegationDetails?.destination?.date
								? new Date(data?.delegationDetails?.destination?.date)
								: null,
							location: data?.delegationDetails?.destination?.location,
							distance: data?.delegationDetails?.destination?.distance,
							transport: data?.delegationDetails?.destination?.transport,
							carPlateNumber: data?.delegationDetails?.destination?.carPlateNumber,
						},
						originEnd: {
							visible: true,
							date: data?.delegationDetails?.originEnd?.date
								? new Date(data?.delegationDetails?.originEnd?.date)
								: null,
							location: data?.delegationDetails?.originEnd?.location,
						},
						attachments: data?.delegationDetails?.attachments?.map(file => {
							return {
								...file,
								file: null,
								progress: 100,
								isUploading: false,
								formReady: true,
								collapsed: false,
								fileLocalId: '',
								fileStatus: EDropzoneFileStatus.Waiting,
							};
						}),
						advancedPayment: data?.delegationDetails?.advancedPayment,
					},
					delegationRoutesBeforeDestination:
						data?.delegationRoutesBeforeDestination?.map(route => {
							const { date, crossingBorder, location, distance, transport, carPlateNumber } = route || {};
							const parsedDate = date ? new Date(date) : null;
							return crossingBorder
								? { visible: true, date: parsedDate, crossingBorder }
								: { visible: true, date: parsedDate, location, distance, transport, carPlateNumber };
						}) ?? [],
					delegationRoutesAfterDestination:
						data?.delegationRoutesAfterDestination?.map(route => {
							const { date, crossingBorder, location, distance, transport, carPlateNumber } = route || {};
							const parsedDate = date ? new Date(date) : null;
							return crossingBorder
								? { visible: true, date: parsedDate, crossingBorder }
								: { visible: true, date: parsedDate, location, distance, transport, carPlateNumber };
						}) ?? [],
					delegationPerDiemElements: data?.delegationPerDiemElements?.map(element => {
						return {
							...element,
							visible: true,
						};
					}),
				},
			};
		}
		case actionTypes.DELEGATION_ATTACHMENTS_REMOVE:
		case actionTypes.DELEGATION_ATTACHMENT_UPDATE:
		case actionTypes.DELEGATION_ATTACHMENTS_UPLOAD: {
			return {
				...state,
				ModalData: {
					...state?.ModalData,
					delegationDetails: {
						...state?.ModalData?.delegationDetails,
						attachments: action?.data,
					},
				},
			};
		}
		case actionTypes.SET_MODAL_DATA: {
			return {
				...state,
				ModalData: {
					...state?.ModalData,
					...action?.data,
					delegationDetails: {
						...state?.ModalData?.delegationDetails,
						...action?.data?.delegationDetails,
						origin: {
							...state?.ModalData?.delegationDetails?.origin,
							...action?.data?.delegationDetails?.origin,
						},
						destination: {
							...state?.ModalData?.delegationDetails?.destination,
							...action?.data?.delegationDetails?.destination,
						},
						originEnd: {
							...state?.ModalData?.delegationDetails?.originEnd,
							...action?.data?.delegationDetails?.originEnd,
						},
					},
				},
			};
		}
		case actionTypes.DELEGATION_COMMENT_CREATE:
		case actionTypes.DELEGATION_COMMENT_UPDATE:
		case actionTypes.DELEGATION_COMMENT_DELETE: {
			return {
				...state,
				Comments: action?.data,
			};
		}
		case actionTypes.GET_ORGANIZATION_SETTING_FOR_DELEGATION: {
			return {
				...state,
				GeneralSettings: action?.data,
			};
		}
		case actionTypes.DELEGATION_ACCEPTANCE_SETTINGS_FETCH: {
			return {
				...state,
				AcceptanceSettings: {
					Configuration: action?.data,
				},
			};
		}
		default: {
			return state;
		}
	}
};

export default delegationListReducer;
