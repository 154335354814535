import clsx from 'clsx';
import parse from 'html-react-parser';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TooltipTextBase } from '@sac-tw2/tasker-widgets2';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { IconButton, Tooltip, Typography } from '@mui/material';

import { deleteIssueUrlMeet } from '@/store/actions';
import { IReducerState } from '@/store/reducers/types';
import { Dispatch } from '@/store/types';
import { useTrans } from '@/utils/hooks/useTrans';

import { Props } from './model';
import { Resources } from './resources';
import { useStyles } from './styles';

export const SocialMediaLink = (props: Props) => {
	const { issueUrl, handleEditIconClick } = props;
	const classes = useStyles();
	const dispatch: Dispatch = useDispatch();
	const { tString } = useTrans('IssueModal.IssueModalTitle.MoreOptions.SocialMediaLinks.SocialMediaLink');

	const issue = useSelector((state: IReducerState) => state?.IssueModalEditor?.issueModal);

	const handleDelete = () => {
		dispatch(deleteIssueUrlMeet(issue?.Id, issueUrl?.Id));
	};

	const handleOpenInNewTab = () => {
		if (issueUrl?.Url?.includes(Resources.https) || issueUrl?.Url?.includes(Resources.http))
			window.open(issueUrl?.Url, Resources.GlobalResources.blank);
		else window.open(`${Resources.https}${issueUrl?.Url}`, Resources.GlobalResources.blank);
	};

	return (
		<div>
			<div className={clsx(classes.flexYCenter, classes.contentSpaceBetween, classes.container)}>
				<div className={classes.flexYCenter}>
					<Typography className={classes.url}>
						{parse(issueUrl?.UrlType?.ImageCode ?? Resources.GlobalResources.empty)}
					</Typography>
					<TooltipTextBase text={issueUrl?.Label} className={classes.label} />
				</div>
				<div className={classes.flexYCenter}>
					<Tooltip title={tString('delete')}>
						<IconButton onClick={handleDelete}>
							<DeleteIcon className={classes.icon} />
						</IconButton>
					</Tooltip>
					<Tooltip title={tString('edit')}>
						<IconButton onClick={() => handleEditIconClick(issueUrl)}>
							<EditIcon className={classes.icon} />
						</IconButton>
					</Tooltip>
					<Tooltip title={tString('openInNewTab')}>
						<IconButton onClick={handleOpenInNewTab}>
							<OpenInNewIcon className={classes.icon} />
						</IconButton>
					</Tooltip>
				</div>
			</div>
		</div>
	);
};
