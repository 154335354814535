import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	discordPath: 'integrations#discord',
};
export const Translation = {
	pl: {
		OrganizationPanel: {
			EndWorkDay: {
				EndWorkDayCreate: {
					CreateNotifications: {
						channelId: 'Id kanału',
						message: 'Wiadomość',
						header: 'Nagłówek',
						noConfiguration: 'Brak integracji discorda',
						goToConfiguration: 'Przejdź do konfiguracji',
						noConfigurationSubtitle:
							'Skonfiguruj  integracje z serwerem discord aby móc wysyłać tam powiadomienia',
						useUserAsHeader: 'Użyj użytkownika jako nagłówek',
						nextStep: 'Pomiń krok',
					},
				},
			},
		},
	},
	en: {
		OrganizationPanel: {
			EndWorkDay: {
				EndWorkDayCreate: {
					CreateNotifications: {
						channelId: 'Channel id',
						message: 'Message',
						header: 'Header',
						noConfiguration: 'Discord configuration missing',
						goToConfiguration: 'Go to configuration',
						noConfigurationSubtitle:
							'Configure the integration with the Discord server to be able to send notifications there',
						useUserAsHeader: 'Use user as header',
						nextStep: 'Skip step',
					},
				},
			},
		},
	},
};
