import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { VisibilityControl } from '@sac-tw2/tasker-widgets2/dist/widgets/VisibilityControl';

import { Link } from '@mui/material';

import { IReducerState } from '@/store/reducers/types';

import { Resources } from './resources';
import { useStyles } from './styles';

export const LinkToStartPage = (): JSX.Element => {
	const classes = useStyles();
	const darkMode = useSelector((state: IReducerState) => state.Session?.darkMode);

	return (
		<VisibilityControl
			condition={window?.location?.hash != Resources.links.toActivity}
			alternative={
				<Link
					underline={Resources.GlobalResources.none}
					className={clsx(classes.pointerCursor, classes.logoWrapper, classes.logo)}
				>
					<img
						alt={Resources.logoAlternative}
						src={darkMode ? Resources.links.logo : Resources.links.logoWhite}
						className={classes.logo}
					/>
				</Link>
			}
		>
			<RouterLink to={Resources.links.toActivity} className={clsx(classes.logo, classes.logoWrapper)}>
				<img
					alt={Resources.logoAlternative}
					src={darkMode ? Resources.links.logo : Resources.links.logoWhite}
				/>
			</RouterLink>
		</VisibilityControl>
	);
};
