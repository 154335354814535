import { ITagGroup, IValidationError } from '@sac-tt/tasker-types';

import * as actionTypes from '@/store/actions';
import { ObjectAction } from '@/store/types';

export const State = {
	singleTagGroup: {} as ITagGroup,
	errors: [] as IValidationError[],
};
export type ITagGroupInfoEditorState = typeof State;

const tagGroupInfoReducer = (state = State, action: ObjectAction): ITagGroupInfoEditorState => {
	switch (action?.type) {
		case actionTypes.GET_SINGLE_TAG_GROUP: {
			return {
				...state,
				singleTagGroup: action.group,
			};
		}
		case actionTypes.SET_ERRORS: {
			return {
				...state,
				errors: action?.data,
			};
		}
		default: {
			return state;
		}
	}
};

export default tagGroupInfoReducer;
