import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	mainKey: 'UserList',
	addUserLink: '/organization/user',
	hover: 'hover' as const,
};

export const Translation = {
	pl: {
		OrganizationPanel: {
			UsersTab: {
				UserCard: {
					lastLogin: 'Ostatnie logowanie',
					button: 'Szczegóły',
					inactive: '(Nieaktywny)',
				},
			},
		},
	},
	en: {
		OrganizationPanel: {
			UsersTab: {
				UserCard: {
					lastLogin: 'Last login',
					button: 'Details',
					inactive: '(Inactive)',
				},
			},
		},
	},
};
