import uuid from 'uuid';

import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	menuId: 'menuId',
	ariaLabelledby: 'aria-labelledby',
	basicButton: 'basic-button',
	key: (x: number) => `Tag_${uuid()}_${x}`,
	...GlobalResources,
};
