import { AsyncAction } from '@sac-rp/redux-promisify';
import { IArchivedIssues } from '@sac-tt/tasker-types';

import { IJson } from '@/store/ITypes';
import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

const basePath = 'ArchivedIssues';

export const CREATE_ARCHIVED_ISSUES: ActionType = 'CREATE_ARCHIVED_ISSUES';
export const DELETE_ARCHIVED_ISSUES: ActionType = 'DELETE_ARCHIVED_ISSUES';

export function _unArchiveIssue(): Action {
	return {
		type: DELETE_ARCHIVED_ISSUES,
		data: [],
	};
}

function _archiveIssue(data: IArchivedIssues): Action {
	return {
		type: CREATE_ARCHIVED_ISSUES,
		data: [data],
	};
}

export function archiveIssue(kanbanId: number, issueId: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_archiveIssue((json as IJson).data)));
		return axios().post(`${basePath}`, { IssueId: issueId, KanbanId: kanbanId });
	};
}

export function unArchiveIssue(id: number): AsyncAction {
	return function(context, dispatch) {
		context.then(() => dispatch(_unArchiveIssue()));
		return axios().delete(`${basePath}/${id}`);
	};
}
