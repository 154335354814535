import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	projectPath: 'projecs/',
	user: 'user/',
	customers: '#/customers/',
};

export const Translation = {
	pl: {
		ProjectList: {
			TileComponents: {
				UsersInProject: {
					projectMembers: 'Członkowie projektu',
				},
			},
		},
	},
	en: {
		ProjectList: {
			TileComponents: {
				UsersInProject: {
					projectMembers: 'Project members',
				},
			},
		},
	},
};
