import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		TimeAssign: {
			Content: {
				GeneralTab: {
					Description: {
						descriptionLabel: 'Opis',
					},
				},
			},
		},
	},
	en: {
		TimeAssign: {
			Content: {
				GeneralTab: {
					Description: {
						descriptionLabel: 'Description',
					},
				},
			},
		},
	},
};
