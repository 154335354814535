import { AsyncAction } from '@sac-rp/redux-promisify';
import { IBudget, IIncomeLine } from '@sac-tt/tasker-types';

import { IJson } from '@/store/ITypes';
import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

export const INCOME_LINE_ADD: ActionType = 'INCOME_LINE_ADD';
export const INCOME_LINE_UPDATE: ActionType = 'INCOME_LINE_UPDATE';

const basePath = 'IncomeLine';

function _addIncomeLine(data: IBudget): Action {
	return {
		type: INCOME_LINE_ADD,
		data,
	};
}
function _updateIncomeLine(data: IBudget): Action {
	return {
		type: INCOME_LINE_UPDATE,
		data,
	};
}

export function incomeLine_Create(incomeLine: Partial<IIncomeLine>): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_addIncomeLine((json as IJson)?.data)));
		return axios().post(basePath, incomeLine);
	};
}

export function incomeLine_Update(incomeLine: Partial<IIncomeLine>): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_updateIncomeLine((json as IJson)?.data)));
		return axios().patch(`${basePath}/${incomeLine?.Id}`, incomeLine);
	};
}
