import { AsyncAction } from '@sac-rp/redux-promisify';
import { FullFilters, IInvoiceDetails, ICustomer, IPage } from '@sac-tt/tasker-types';

import { IJson } from '@/store/ITypes';
import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

export const CUSTOMER_FETCH: ActionType = 'CUSTOMER_FETCH';
export const CUSTOMER_CREATE: ActionType = 'CUSTOMER_CREATE';
export const CUSTOMER_UPDATE: ActionType = 'CUSTOMER_UPDATE';
export const CUSTOMER_DELETE: ActionType = 'CUSTOMER_DELETE';

export const CUSTOMER_FETCH_PAGE: ActionType = 'CUSTOMER_FETCH_PAGE';
export const CUSTOMER_FETCH_ONE: ActionType = 'CUSTOMER_FETCH_ONE';
export const CUSTOMER_FETCH_LIST_NEW: ActionType = 'CUSTOMER_FETCH_LIST_NEW';
export const CUSTOMER_FETCH_WITH_PROJECTS: ActionType = 'CUSTOMER_FETCH_WITH_PROJECTS';
export const CUSTOMER_INVOICES_DETAILS_CREATE: ActionType = 'CUSTOMER_INVOICES_DETAILS_CREATE';

const basePath = 'customer';

function _deleteCustomer(customerId: number): Action {
	return {
		type: CUSTOMER_DELETE,
		id: customerId,
	};
}

function _fetchCustomerListNew(customers: IPage<ICustomer[]>): Action {
	return {
		type: CUSTOMER_FETCH_LIST_NEW,
		customers,
	};
}

function _fetchCustomerWithProjects(data: ICustomer[]): Action {
	return {
		type: CUSTOMER_FETCH_WITH_PROJECTS,
		data,
	};
}

function _fetchCustomerPage(customers: IPage<ICustomer[]>): Action {
	return {
		type: CUSTOMER_FETCH_PAGE,
		customers: customers?.Data,
		customersCount: customers?.Count,
	};
}
function _fetchOneCustomer(customer: ICustomer): Action {
	return {
		type: CUSTOMER_FETCH_ONE,
		customer,
	};
}

function _createCustomer(customers: IPage<ICustomer[]>): Action {
	return {
		type: CUSTOMER_CREATE,
		customers: customers?.Data,
		customersCount: customers?.Count,
	};
}

function _createCustomerAddress(data: IInvoiceDetails): Action {
	return {
		type: CUSTOMER_INVOICES_DETAILS_CREATE,
		data,
	};
}

export function customer_fetchNew(): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchCustomerListNew((json as IJson).data)));
		return axios().get(basePath);
	};
}

export function fetch_customer_page(from: number, count: number, data?: Partial<FullFilters>): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchCustomerPage((json as IJson).data)));
		return axios().post(`${basePath}/from/${from}/count/${count}`, data);
	};
}

export function fetch_one_customer(id: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchOneCustomer((json as IJson).data)));
		return axios().get(`${basePath}/${id}`);
	};
}

export function update_customer(customerId: number, newName: string): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchOneCustomer((json as IJson).data)));
		return axios().patch(`${basePath}/${customerId}`, { newName });
	};
}

export function create_customer(data: Partial<ICustomer>): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_createCustomer((json as IJson).data)));
		return axios().post(basePath, data);
	};
}

export function delete_customer(id: number): AsyncAction {
	return function(context, dispatch) {
		context.then(() => dispatch(_deleteCustomer(id)));
		return axios().delete(`${basePath}/${id}`);
	};
}

export function customerWithProjects_fetch(): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchCustomerWithProjects((json as IJson).data)));
		return axios().get(`${basePath}/Projects`);
	};
}

export function customerAddAddress(address: Partial<IInvoiceDetails>, customerId: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_createCustomerAddress((json as IJson).data)));
		return axios().post(`${basePath}/Address/${customerId}`, { ...address });
	};
}
