import { useContext } from 'react';

import { IFieldValue } from '@sac-tt/tasker-types';
import { ICheckListValue } from '@sac-tt/tasker-types/dist/types/dataBaseTypes/checkListValue';
import { IIssueFormSectionToIssueField } from '@sac-tt/tasker-types/dist/types/dataBaseTypes/issueFormSectionToIssueField';

import { EValueToUse, IssueFormSectionsWidgetContext } from '../..';

export const useInitialAttributeDefinitionValues = (issueFormSectionToIssueField: IIssueFormSectionToIssueField) => {
	const { valueToUse: widgetPlaceOfUse } = useContext(IssueFormSectionsWidgetContext);

	let initialAttributeDefinitionValues: IFieldValue[] | ICheckListValue[] = [];

	if (widgetPlaceOfUse === EValueToUse.FieldValue) {
		initialAttributeDefinitionValues = issueFormSectionToIssueField?.AttributeDefinition?.FieldValue ?? [];
	} else if (widgetPlaceOfUse === EValueToUse.CheckListValue) {
		initialAttributeDefinitionValues = issueFormSectionToIssueField?.AttributeDefinition?.CheckListValue ?? [];
	}

	return initialAttributeDefinitionValues;
};
