import Axios, { CancelTokenSource } from 'axios';

import { AsyncAction } from '@sac-rp/redux-promisify';
import {
	ActivityWorklog,
	IIssue,
	IPage,
	ITaskerUser,
	IWorkLog,
	IWorklogPaginationData,
	IWorklogTimer,
} from '@sac-tt/tasker-types';
import { EWorkLogOperation } from '@sac-tt/tasker-types/dist/types/endPoints/worklogShortcut';

import { store } from '@/App';
import { IJson } from '@/store/ITypes';
import { IReducerState } from '@/store/reducers/types';
import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

import { _fetchWorklogData } from './organizationActions';

const basePath = 'Worklog';

export const WORKLOG_CREATE_NEW: ActionType = 'WORKLOG_CREATE_NEW';
export const WORKLOG_DELETE: ActionType = 'WORKLOG_DELETE';
export const ACTIVITY_WORKLOG_GET: ActionType = 'ACTIVITY_WORKLOG_GET';
export const WORKLOG_FETCH_ACTIVE_NEW: ActionType = 'WORKLOG_FETCH_ACTIVE_NEW';
export const FETCH_PROJECT_WORKLOGS: ActionType = 'FETCH_PROJECT_WORKLOGS';
export const WORKLOG_FETCH_BY_ISSUE_NEW: ActionType = 'WORKLOG_FETCH_BY_ISSUE_NEW';
export const CLEAR_CLOCK_DID_CHANGE: ActionType = 'CLEAR_CLOCK_DID_CHANGE';
export const ACTION_TIME_DID_CHANGE: ActionType = 'ACTION_TIME_DID_CHANGE';
export const FETCH_ACTIVE_ISSUES: ActionType = 'FETCH_ACTIVE_ISSUES';
export const USE_CLOCK: ActionType = 'USE_CLOCK';
export const USE_CLOCK_PAUSE_FINISH: ActionType = 'USE_CLOCK_PAUSE_FINISH';
export const TIME_MODAL_WORKLOG_DID_UPDATE: ActionType = 'TIME_MODAL_WORKLOG_DID_UPDATE';
export const ACTIVITY_WORKLOG_MONTH_GET: ActionType = 'ACTIVITY_WORKLOG_MONTH_GET';
export const KEY_WORKLOG_MANAGE: ActionType = 'KEY_WORKLOG_MANAGE';

function _fetchIssueNew(data: IPage<IWorkLog[]>): Action {
	return {
		type: WORKLOG_FETCH_BY_ISSUE_NEW,
		worklogs: data.Data,
		count: data.Count,
	};
}

function _createWorklog(worklogs: IWorkLog[], isMultiple = false): Action {
	return {
		type: WORKLOG_CREATE_NEW,
		worklogs,
		isMultiple,
	};
}

function _fetchActiveWorkLog_NEW(worklogs: { active: IWorkLog[]; stopped: IWorkLog[] }): Action {
	return {
		type: WORKLOG_FETCH_ACTIVE_NEW,
		worklogs,
	};
}

function _activityWorklogGet(activity: ActivityWorklog[]): Action {
	return {
		type: ACTIVITY_WORKLOG_GET,
		activity,
	};
}

function _activityWorklogMonthGet(activity: ActivityWorklog[]): Action {
	return {
		type: ACTIVITY_WORKLOG_MONTH_GET,
		activity,
	};
}

function _deleteWorkLog(id: number): Action {
	return {
		type: WORKLOG_DELETE,
		id,
	};
}
function _fetchProjectWorklogs(worklogs: ActivityWorklog[]): Action {
	return {
		type: FETCH_PROJECT_WORKLOGS,
		worklogs,
	};
}

function _useClock(worklogs: { active: IWorkLog[]; stopped: IWorkLog[] }): Action {
	const reducer: IReducerState = store.getState() as IReducerState;
	return {
		type: USE_CLOCK_PAUSE_FINISH,
		worklogs: {
			...worklogs,
			preferences: reducer.Session.preferences,
		},
	};
}

function _useClockPauseFinish(worklogs: { active: IWorkLog[]; stopped: IWorkLog[] }): Action {
	const reducer: IReducerState = store.getState() as IReducerState;
	return {
		type: USE_CLOCK_PAUSE_FINISH,
		worklogs: {
			...worklogs,
			preferences: reducer.Session.preferences,
		},
	};
}

function _updatedWorkLogTimeModal(workLog: IWorkLog): Action {
	return {
		type: TIME_MODAL_WORKLOG_DID_UPDATE,
		data: workLog,
	};
}

function _fetchActiveIssues(issues: IIssue[]): Action {
	return {
		type: FETCH_ACTIVE_ISSUES,
		issues,
	};
}

function _work_log_manage_clock(worklogs: { active: IWorkLog[]; stopped: IWorkLog[] }): Action {
	return {
		type: KEY_WORKLOG_MANAGE,
		worklogs,
	};
}

export function worklog_fetch_by_issue_NEW(
	issueId: number,
	from: number,
	count: number,
	displayMyIssues = false,
	chosenUserId?: number
): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchIssueNew((json as IJson)?.data)));
		return axios().get(`${basePath}/issue/${issueId}${displayMyIssues ? '/my' : ''}/from/${from}/count/${count}`, {
			params: { chosenUserId },
		});
	};
}

export function work_log_fetchActive_NEW(): AsyncAction {
	return function(context, dispatch) {
		context.then(json => {
			dispatch(_fetchActiveWorkLog_NEW({ active: (json as IJson)?.data, stopped: [] }));
		});
		return axios().get(`${basePath}/active`);
	};
}

export function fetch_Active_Issues(): AsyncAction {
	return function(context, dispatch) {
		context.then(json => {
			dispatch(_fetchActiveIssues((json as IJson).data[0]));
		});
		return axios().get(`${basePath}/activeIssues`);
	};
}

export function work_log_create_new(worklog: Partial<IWorkLog>, isMultiple = false): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_createWorklog((json as IJson)?.data, isMultiple)));
		return axios().post(basePath, worklog);
	};
}

export function work_log_update_new(id: number, worklog: Partial<IWorkLog>): AsyncAction {
	return function(context, dispatch) {
		context.then(data => dispatch(_updatedWorkLogTimeModal((data as IJson).data)));
		return axios().patch(basePath, { Id: id, ...worklog });
	};
}

export function work_log_delete(workLogId: number, paginationData?: IWorklogPaginationData): AsyncAction {
	return function(context, dispatch) {
		if (paginationData) context.then(json => dispatch(_fetchIssueNew((json as IJson)?.data)));
		else context.then(() => dispatch(_deleteWorkLog(workLogId)));

		return axios().delete(`${basePath}/${workLogId}`, { data: paginationData });
	};
}

export function create_work_log_Timer(organizationId: number, data: Partial<IWorklogTimer>): AsyncAction {
	return function(context, dispatch) {
		context.then(data => dispatch(_fetchWorklogData((data as IJson).data)));
		return axios().post(`WorklogTimer/Organization`, data);
	};
}

export function update_work_log_Timer(data: Partial<IWorklogTimer>): AsyncAction {
	return function(context, dispatch) {
		context.then(data => dispatch(_fetchWorklogData((data as IJson).data)));
		return axios().patch(`worklogtimer/${data.Id}`, data);
	};
}

export function delete_work_log_Timer(worklogTimerId: number): AsyncAction {
	return function(context, dispatch) {
		context.then(data => dispatch(_fetchWorklogData((data as IJson).data)));
		return axios().delete(`worklogtimer/${worklogTimerId}`);
	};
}

export function activity_work_logs_get(userId: number): AsyncAction {
	const offset = new Date().getTimezoneOffset();
	return function(context, dispatch) {
		context.then(data => dispatch(_activityWorklogGet((data as IJson).data)));
		return axios().post(`${basePath}/activity/${userId}`, { offset });
	};
}

export function activity_work_logs_month_get(): AsyncAction {
	const offset = new Date().getTimezoneOffset();
	return function(context, dispatch) {
		context.then(data => dispatch(_activityWorklogMonthGet((data as IJson).data)));
		return axios().post(`${basePath}/activityMonth/`, { offset });
	};
}
export function project_work_logs_get(projectId: number): AsyncAction {
	const offset = new Date().getTimezoneOffset();
	return function(context, dispatch) {
		context.then(data => dispatch(_fetchProjectWorklogs((data as IJson).data)));
		return axios().post(`${basePath}/project/${projectId}`, { offset });
	};
}

let work_log_start_NEWSource: CancelTokenSource | undefined;
export function work_log_start_NEW(issueId: number): AsyncAction {
	if (work_log_start_NEWSource) work_log_start_NEWSource?.cancel();
	work_log_start_NEWSource = Axios.CancelToken.source();
	return function(context, dispatch) {
		context.then(json => {
			dispatch(_useClock((json as IJson).data));
		});
		return axios().post(`Worklog/start/issue/${issueId}`);
	};
}

let work_log_pause_NEWSource: CancelTokenSource | undefined;
export function work_log_pause_NEW(issueId: number, description?: string): AsyncAction {
	if (work_log_pause_NEWSource) work_log_pause_NEWSource?.cancel();
	work_log_pause_NEWSource = Axios.CancelToken.source();
	return function(context, dispatch) {
		context.then(json => {
			dispatch(_useClockPauseFinish({ active: [], stopped: (json as IJson)?.data }));
		});
		return axios().post(`worklog/pause/issue/${issueId}`, { description });
	};
}

let work_log_finish_NEWSource: CancelTokenSource | undefined;
export function work_log_finish_NEW(issueId: number, description?: string): AsyncAction {
	if (work_log_finish_NEWSource) work_log_finish_NEWSource?.cancel();
	work_log_finish_NEWSource = Axios.CancelToken.source();
	return function(context, dispatch) {
		context.then(json => {
			dispatch(_useClockPauseFinish({ active: [], stopped: (json as IJson)?.data }));
		});
		return axios().post(`worklog/finish/issue/${issueId}`, { description });
	};
}

export function clearClockDidChange(): Action {
	return {
		type: CLEAR_CLOCK_DID_CHANGE,
	};
}

let work_log_manage_clock_Source: CancelTokenSource | undefined;
export function work_log_manage_clock(workLogOperation: EWorkLogOperation): AsyncAction {
	if (work_log_manage_clock_Source) work_log_manage_clock_Source?.cancel();
	work_log_manage_clock_Source = Axios.CancelToken.source();
	return function(context, dispatch) {
		context.then(json => {
			dispatch(_work_log_manage_clock((json as IJson).data));
		});
		return axios().post(`worklog/shortcut`, { WorkLogOperation: workLogOperation });
	};
}
