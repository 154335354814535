import { CommonResources as GlobalResources } from '@sac-tw2/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	caption: 'caption' as const,
};

export const Translation = {
	pl: {
		TagAdminPanel: {
			HeaderContainer: {
				searchHelperText: 'Wyszukaj po nazwie grupy, kontekstu lub nazwie tagu',
			},
		},
	},
	en: {
		TagAdminPanel: {
			HeaderContainer: {
				searchHelperText: 'Search by group name, context or tag name',
			},
		},
	},
};
