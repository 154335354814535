import Axios, { CancelTokenSource } from 'axios';

import { AsyncAction } from '@sac-rp/redux-promisify';
import { ICalendarResponse, ITaskerUser, IWorkLog } from '@sac-tt/tasker-types';

import { IJson } from '@/store/ITypes';
import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

const basePath = 'WorklogCalendar';

export const CALENDAR_WORKLOG_FETCH: ActionType = 'CALENDAR_WORKLOG_FETCH';
export const CALENDAR_USERS_FETCH: ActionType = 'CALENDAR_USERS_FETCH';
export const CALENDAR_WORKLOG_CREATE: ActionType = 'CALENDAR_WORKLOG_CREATE';

function _fetchCalendarWorklogs(
	workLogs: ICalendarResponse,
	options: { startDate: Date; endDate: Date; userIds: number[] }
): Action {
	return {
		type: CALENDAR_WORKLOG_FETCH,
		worklogs: workLogs,
		options,
	};
}

function _fetchCalendarTaskerUsers(users: ITaskerUser[]): Action {
	return {
		type: CALENDAR_USERS_FETCH,
		users,
	};
}

function _createWorklog(workLog: IWorkLog): Action {
	return {
		type: CALENDAR_WORKLOG_CREATE,
		data: workLog,
	};
}

let source: CancelTokenSource | undefined;
export function calendar_getWorklog(startDate: Date, endDate: Date, userIds: number[]): AsyncAction {
	if (source) source?.cancel();
	source = Axios.CancelToken.source();
	if (!startDate || !endDate || !userIds) throw 'To fetch calendar date assign startDate, endDate and user Id';
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchCalendarWorklogs((json as IJson)?.data, { startDate, endDate, userIds })));
		return axios().post(
			`${basePath}/user`,
			{
				StartDate: startDate,
				EndDate: endDate,
				UserIds: userIds,
			},
			{ cancelToken: source?.token }
		);
	};
}

export function calendar_workLogUpdate(
	id: number,
	worklog: Partial<IWorkLog>,
	startDate: Date,
	endDate: Date,
	userIds: number[]
): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchCalendarWorklogs((json as IJson)?.data, { startDate, endDate, userIds })));
		return axios().patch(basePath, {
			WorkLog: { Id: id, ...worklog },
			Fetch: {
				StartDate: startDate,
				EndDate: endDate,
				UserIds: userIds,
			},
		});
	};
}

export function calendar_getTaskerUser(): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchCalendarTaskerUsers((json as IJson)?.data)));
		return axios().get(`${basePath}/TaskerUser`);
	};
}

export function calendar_workLogCreate(worklog: Partial<IWorkLog>): AsyncAction {
	if ((worklog?.Minutes ?? 0) <= 0) return Promise.reject;
	return function(context, dispatch) {
		context.then(json => dispatch(_createWorklog((json as IJson)?.data)));
		return axios().post(basePath, worklog);
	};
}
